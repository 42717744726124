import { EBestPlaceAnalyticsActionTypes, TBestPlaceAnalyticsActions } from 'shared/actions/bestPlaceAnalytics/actions';
import { IBestPlaceAnalyticsState } from 'shared/types/bestPlaceAnalytics';

export const DEFAULT_STATE: IBestPlaceAnalyticsState = {
  amount: null,
  discount: null,
  discountPrice: null,

  paymentUrl: null,
  status: null,
  statusMessage: null,
  userEmail: null,
};

export const bestPlaceAnalyticsReducer = (
  state: IBestPlaceAnalyticsState = DEFAULT_STATE,
  action: TBestPlaceAnalyticsActions,
): IBestPlaceAnalyticsState => {
  switch (action.type) {
    case EBestPlaceAnalyticsActionTypes.SetReportPrices: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case EBestPlaceAnalyticsActionTypes.SetStatus: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case EBestPlaceAnalyticsActionTypes.SetPaymentUrl: {
      return {
        ...state,
        paymentUrl: action.payload,
      };
    }

    case EBestPlaceAnalyticsActionTypes.SetUserEmail: {
      if (action.payload === state.userEmail) {
        return state;
      }

      return {
        ...state,
        userEmail: action.payload,
      };
    }

    case EBestPlaceAnalyticsActionTypes.SetStatusMessage: {
      return {
        ...state,
        statusMessage: action.payload,
      };
    }

    default:
      return state;
  }
};
