/* eslint-disable max-lines, @typescript-eslint/no-non-null-assertion */
import { fetchRecommendedVillages } from '../../../node/services/fetchRecommendedVillages';
import { IRecommendedVillages } from '../../types/recommendedVillages';
import { IThunkActionCreator } from '../index';

const SET_RECOMMENDED_VILLAGES_ACTION = 'ISetRecommendedVillages';

export interface ISetRecommendedVillages {
  type: typeof SET_RECOMMENDED_VILLAGES_ACTION;
  payload: IRecommendedVillages | null;
}

interface IParams {
  subdomain?: string;
  locationId?: number;
}

export function getRecommendedVillages(parameters: IParams): IThunkActionCreator<Promise<void>> {
  return async (dispatch, _, context) => {
    const recommendedVillages = await fetchRecommendedVillages(context, {
      jsonQuery: {},
      regionId: parameters.locationId,
    });

    dispatch<ISetRecommendedVillages>({
      payload: recommendedVillages,
      type: SET_RECOMMENDED_VILLAGES_ACTION,
    });
  };
}

export function recommendedVillagesReducer(
  state: IRecommendedVillages | null = null,
  action: ISetRecommendedVillages,
): IRecommendedVillages | null {
  switch (action.type) {
    case SET_RECOMMENDED_VILLAGES_ACTION:
      return action.payload;
    default:
      return state;
  }
}
