import { DegradationWarning } from '../../utils/errors';
import { IThunkActionCreator } from '../index';

export interface ISeoLink {
  uri: string;
  name: string;
}

export interface ISeoBlock {
  links: ISeoLink[];
  title: string;
}

export interface ISeoLinks {
  seoBlocks: ISeoBlock[];
}

export interface ISeoLinksState {
  seoLinks: ISeoLinks;
  isLoad: boolean;
}

export interface ISeoLinkRequest {
  type: 'ISeoLinkRequest';
}

export interface ISeoLinkResponseSuccess {
  type: 'ISeoLinkResponseSuccess';
  payload: {
    seoBlocks: ISeoBlock[];
  };
}

export interface ISeoLinkResponseError {
  type: 'ISeoLinkResponseError';
}

/**
 * Запрос идет только на ноде, но сам стор shared, пыоэтому тут промис
 * TODO: Избавится от схемы формирования состояния на ноде через dispatch
 */
export function getSeoLinks(fetchSeoLinksPromise: Promise<ISeoBlock[]>): IThunkActionCreator {
  return async (dispatch, getState, context) => {
    const { logger } = context;

    dispatch({
      type: 'ISeoLinkRequest',
    });

    try {
      const seoBlocks = await fetchSeoLinksPromise;

      dispatch({
        payload: { seoBlocks },
        type: 'ISeoLinkResponseSuccess',
      });
    } catch (error) {
      logger.error(
        new DegradationWarning({
          domain: 'getSeoLinks',
          message: error.message,
          details: { error },
        }),
      );

      dispatch({ type: 'ISeoLinkResponseError' });
    }
  };
}

export type TSeoLinksACtions = ISeoLinkRequest | ISeoLinkResponseSuccess | ISeoLinkResponseError;

const initialState: ISeoLinksState = {
  isLoad: false,
  seoLinks: {
    seoBlocks: [],
  },
};

export function seoLinksReducer(state: ISeoLinksState = initialState, action: TSeoLinksACtions) {
  switch (action.type) {
    case 'ISeoLinkRequest':
      return {
        ...state,
        isLoad: true,
      };

    case 'ISeoLinkResponseError':
      return {
        ...state,
        isLoad: false,
      };

    case 'ISeoLinkResponseSuccess':
      return {
        ...state,
        isLoad: false,
        seoLinks: {
          seoBlocks: action.payload.seoBlocks,
        },
      };

    default:
      return state;
  }
}
