import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { ICreateRealtyResponseV2Schema } from 'shared/repositories/price-estimator/entities/web/CreateRealtyResponseV2Schema';
import { IMappers } from 'shared/repositories/price-estimator/v1/create-realty-by-cian-id';

export const createAddMyHomeObjectMapper = (): IMappers<TRes200, TRes400> => ({
  200: response => response.myHomeId,
  400: ({ message }) => {
    return new HttpBadStatusCodeError({
      domain: 'fetchAddToMyHomeObject.ts',
      statusCode: 400,
      message: message || /* istanbul ignore next */ '',
    });
  },
});

export type TRes200 = ICreateRealtyResponseV2Schema['myHomeId'];
export type TRes400 = HttpBadStatusCodeError;
