import { offerSelector } from 'shared/selectors';
import { fetchAddToMyHomeObject } from 'shared/services/valuationTraps/fetchAddToMyHomeObject';
import { IThunkActionCreator } from 'shared/store';

export const addToMyHomeObject = (): IThunkActionCreator<Promise<void>> => async (dispatch, getState, context) => {
  const { cianId } = offerSelector(getState());

  const myHomeId = await fetchAddToMyHomeObject(context, cianId, 'offer_owner');
  const url = `https://www.cian.ru/my-home/?myHomeId=${myHomeId}&from=offer_owner`;

  window.open(url, '_blank');
};
