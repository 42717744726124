import { TSubscriberHandler } from '@cian/events-log';
import { Store } from '@reduxjs/toolkit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsAuthenticated } from 'shared/selectors';
import { IState } from 'shared/store';

import { consumeUser } from '../../eventsLogs/consumers';
import { createAuthenticationEventHandler } from '../../eventsLogs/handlers';

interface IParameters {
  reduxStore: Store<IState>;
}

let unsubscribe: VoidFunction | undefined;

export function useAuthenticationSubscription({ reduxStore }: IParameters) {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  React.useEffect(() => {
    if (isAuthenticated) {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = undefined;
      }

      return;
    }

    if (!unsubscribe && !isAuthenticated) {
      const authenticationEventHandler = createAuthenticationEventHandler({ reduxStore });
      unsubscribe = consumeUser(authenticationEventHandler as TSubscriberHandler);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
}
