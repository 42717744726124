import {
  EStatus,
  IHouseOfferHistoryV4Schema,
} from 'shared/repositories/valuation-offer-history/entities/offer_history/HouseOfferHistoryV4Schema';
import { EDealType } from 'shared/store/offerHistory';

export const getOfferDateEnd = (offer: IHouseOfferHistoryV4Schema, dealType: EDealType): string => {
  const activeTitle = dealType === EDealType.Sale ? 'В продаже' : 'Активно';

  return offer.status === EStatus.Published ? activeTitle : offer.dateEnd || '–';
};
