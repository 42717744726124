import { IFlatTourBookingSchedule } from '../../types/flatTourBooking';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EFlatTourBookingActionTypes {
  SetScheduleData = 'flatTourBooking/setScheduleData',
  SetIsTooltipOpen = 'flatTourBooking/setIsTooltipOpen',
}

export type TSetScheduleData = ITypedReduxAction<EFlatTourBookingActionTypes.SetScheduleData, IFlatTourBookingSchedule>;
export type TSetIsTooltipOpen = ITypedReduxAction<EFlatTourBookingActionTypes.SetIsTooltipOpen, boolean>;

export const setScheduleData = actionGenerator<EFlatTourBookingActionTypes.SetScheduleData, IFlatTourBookingSchedule>(
  EFlatTourBookingActionTypes.SetScheduleData,
);

export const setIsTooltipOpen = actionGenerator<EFlatTourBookingActionTypes.SetIsTooltipOpen, boolean>(
  EFlatTourBookingActionTypes.SetIsTooltipOpen,
);
