/* eslint-disable max-lines */
import { getRenterFromState } from 'shared/selectors/renterForm/selectors';

import { IValues as IValuesSendCode } from '../../api/deal_rent/send-validation-code';
import { ECurrentStep } from '../../renterForm/constants';
import {
  fetchSaveForm,
  ISaveFormRequest,
  TSaveFormResponse,
} from '../../repositories/deal-rent-announcements/v1/save-form';
import {
  fetchSendValidationCode,
  TSendValidationCodeResponse,
} from '../../repositories/deal-rent-announcements/v1/send-validation-code';
import { trackSafeRentFormSentSuccess } from '../deal-rent/tracking';
import { IThunkActionCreator } from '../index';

export interface IOpen {
  type: 'renterForm/open';
}
export interface IClose {
  type: 'renterForm/close';
}
export interface IOpenSuccess {
  type: 'renterForm/openSuccess';
}
export interface ICloseSuccess {
  type: 'renterForm/closeSuccess';
}
export interface IOpenFailure {
  type: 'renterForm/openFailure';
}
export interface ICloseFailure {
  type: 'renterForm/closeFailure';
}
export interface IUpdateForm {
  type: 'renterForm/updateForm';
  payload: {
    form: ISaveFormRequest;
  };
}
export interface IUpdateCurrentStep {
  type: 'renterForm/updateCurrentStep';
  payload: ECurrentStep;
}

export interface ISaveFormStart {
  type: 'renterForm/saveFormStart';
}
export interface ISaveFormSuccess {
  type: 'renterForm/saveFormSuccess';
}
export interface ISaveFormError {
  type: 'renterForm/saveFormError';
  payload: string;
}

export interface ISendSMSStart {
  type: 'renterForm/sendSMSStart';
}
export interface ISendSMSSuccess {
  type: 'renterForm/sendSMSSuccess';
  payload: number;
}
export interface ISendSMSError {
  type: 'renterForm/sendSMSError';
  payload: string;
}
export interface IChangePhoneConfirmed {
  type: 'renterForm/changePhoneConfirmed';
  payload: boolean;
}
export interface IPrefetchRenterFormStateStart {
  type: 'renterForm/prefetchRenterFormStateStart';
}
export interface IPrefetchRenterFormStateSuccess {
  type: 'renterForm/prefetchRenterFormStateSuccess';
  payload: {
    form: ISaveFormRequest;
    phoneConfirmed: boolean;
  };
}
export interface IPrefetchRenterFormStateError {
  type: 'renterForm/prefetchRenterFormStateError';
}

export type actions =
  | IOpen
  | IClose
  | IOpenSuccess
  | ICloseSuccess
  | IOpenFailure
  | ICloseFailure
  | IUpdateForm
  | IUpdateCurrentStep
  | ISaveFormStart
  | ISaveFormSuccess
  | ISaveFormError
  | IPrefetchRenterFormStateStart
  | IPrefetchRenterFormStateSuccess
  | IPrefetchRenterFormStateError
  | ISendSMSStart
  | ISendSMSSuccess
  | ISendSMSError
  | IChangePhoneConfirmed;

export const openRenterForm = (): IOpen => ({
  type: 'renterForm/open',
});

export const close = (): IClose => ({
  type: 'renterForm/close',
});

const openSuccess = (): IOpenSuccess => ({
  type: 'renterForm/openSuccess',
});

export const closeSuccess = (): ICloseSuccess => ({
  type: 'renterForm/closeSuccess',
});

const openFailure = (): IOpenFailure => ({
  type: 'renterForm/openFailure',
});

export const closeFailure = (): ICloseFailure => ({
  type: 'renterForm/closeFailure',
});

export const updateForm = (values: { form: ISaveFormRequest }): IUpdateForm => ({
  type: 'renterForm/updateForm',
  payload: values,
});

export const updateCurrentStep = (step: ECurrentStep): IUpdateCurrentStep => ({
  type: 'renterForm/updateCurrentStep',
  payload: step,
});

const saveFormStart = (): ISaveFormStart => ({
  type: 'renterForm/saveFormStart',
});

const saveFormSuccess = (): ISaveFormSuccess => ({
  type: 'renterForm/saveFormSuccess',
});

const saveFormError = (message: string): ISaveFormError => ({
  type: 'renterForm/saveFormError',
  payload: message,
});

const sendSMSStart = (): ISendSMSStart => ({
  type: 'renterForm/sendSMSStart',
});

const sendSMSSuccess = (seconds: number): ISendSMSSuccess => ({
  type: 'renterForm/sendSMSSuccess',
  payload: seconds,
});

const sendSMSError = (error: string): ISendSMSError => ({
  type: 'renterForm/sendSMSError',
  payload: error,
});

export const changePhoneConfirmed = (value: boolean): IChangePhoneConfirmed => ({
  type: 'renterForm/changePhoneConfirmed',
  payload: value,
});

export const prefetchRenterFormStateStart = (): IPrefetchRenterFormStateStart => ({
  type: 'renterForm/prefetchRenterFormStateStart',
});

export const prefetchRenterFormStateSuccess = (values: {
  form: ISaveFormRequest;
  phoneConfirmed: boolean;
}): IPrefetchRenterFormStateSuccess => ({
  type: 'renterForm/prefetchRenterFormStateSuccess',
  payload: values,
});

export const prefetchRenterFormStateError = (): IPrefetchRenterFormStateError => ({
  type: 'renterForm/prefetchRenterFormStateError',
});

export const saveForm =
  (values: ISaveFormRequest): IThunkActionCreator =>
  async (dispatch, getState, context) => {
    dispatch(saveFormStart());

    try {
      const {
        offerData: {
          offer: { id },
        },
      } = getState();

      const responseData: TSaveFormResponse = await fetchSaveForm({
        httpApi: context.httpApi,
        parameters: {
          ...values,
          objectId: id,
        },
        config: {
          requestConfig: { headers: [['Content-Type', 'application/json']], withCredentials: true },
        },
      });

      if (responseData.statusCode !== 200) {
        if (responseData.response.errors.find(({ code }) => code === 'FormAlreadySent')) {
          dispatch(saveFormSuccess());
          dispatch(close());
          dispatch(openFailure());
        } else {
          dispatch(saveFormError(responseData.response.message));
        }
      } else {
        dispatch(saveFormSuccess());
        dispatch(close());
        dispatch(openSuccess());
        trackSafeRentFormSentSuccess();
      }
    } catch (error) {
      dispatch(saveFormError(error));
    }
  };

export const sendValidationCode =
  (values: IValuesSendCode): IThunkActionCreator =>
  async (dispatch, getState, context) => {
    dispatch(sendSMSStart());

    try {
      const responseData: TSendValidationCodeResponse = await fetchSendValidationCode({
        httpApi: context.httpApi,
        parameters: { phone: values.phone },
        config: {
          requestConfig: { headers: [['Content-Type', 'application/json']], withCredentials: true },
        },
      });

      if (responseData.statusCode !== 200) {
        if (responseData.response.errors.find(({ code }) => code === 'NoConfirmationRequired')) {
          const { initialFormState } = getRenterFromState(getState());
          dispatch(saveForm({ ...initialFormState, phone: `+7${initialFormState.phone}` }));
        } else {
          dispatch(sendSMSError(responseData.response.message));
        }
      } else {
        const {
          response: { secondsToRetry },
        } = responseData;
        if (secondsToRetry !== null && secondsToRetry !== undefined) {
          dispatch(sendSMSSuccess(secondsToRetry));
        }
      }
    } catch (error) {
      dispatch(sendSMSError(error));
    }
  };
