import { EActionType } from 'shared/types/dailyrentActionButtonsType';
import { IOfferData } from 'shared/types/offerData';
import { actionGenerator } from 'shared/utils/redux/actionGenerator';

import { EDailyrentActionTypeTypes } from './types';

export const setDailyrentActionType = actionGenerator<EDailyrentActionTypeTypes.SetActionType, EActionType>(
  EDailyrentActionTypeTypes.SetActionType,
);

export const setDailyrentActionTypeFromOffer = actionGenerator<
  EDailyrentActionTypeTypes.SetActionTypeFromOffer,
  IOfferData
>(EDailyrentActionTypeTypes.SetActionTypeFromOffer);
