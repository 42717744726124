import { IState } from '../../../store';

export function isFromBuilderSelector(state: IState): boolean {
  const { offer } = state.offerData;

  return Boolean(
    offer.isFromBuilder ||
      (offer.newbuilding && (offer.newbuilding.isFromDeveloper || offer.newbuilding.isFromLeadFactory)),
  );
}
