import { IChatHintSchema } from 'shared/repositories/chats/entities/chat_hint/ChatHintSchema';

export enum EChatsActionTypes {
  StateReceived = 'IChatsStateReceived',
  OfferChatReceived = 'IOfferChatReceived',
  OnlineStatusReceived = 'IOnlineStatusReceived',
  HintQuestionsReceived = 'IHintQuestionsReceived',
  OpenChat = 'IOpenChat',
  OpenChatbot = 'IOpenChatbot',
  CloseChat = 'ICloseChat',
  OpenChatAfterAuth = 'IOpenChatAfterAuth',
}

export type TChatAction =
  | IChatsStateReceived
  | IOfferChatReceived
  | IOnlineStatusReceived
  | IHintQuestionsReceived
  | IOpenChat
  | IOpenChatbot
  | ICloseChat
  | IOpenChatAfterAuth;

export interface IChatsStateReceived {
  type: EChatsActionTypes.StateReceived;
  payload: {
    enabled: boolean;
  };
}

export const chatsStateReceived = (enabled: boolean): IChatsStateReceived => ({
  payload: { enabled },
  type: EChatsActionTypes.StateReceived,
});

export interface IOfferChatReceived {
  type: EChatsActionTypes.OfferChatReceived;
  payload: {
    chatId: string;
  };
}

export const offerChatReceived = (chatId: string): IOfferChatReceived => ({
  payload: { chatId },
  type: EChatsActionTypes.OfferChatReceived,
});

export interface IOnlineStatusReceived {
  type: EChatsActionTypes.OnlineStatusReceived;
  payload: {
    isOnline: boolean;
  };
}

export const onlineStatusReceived = (isOnline: boolean): IOnlineStatusReceived => ({
  payload: { isOnline },
  type: EChatsActionTypes.OnlineStatusReceived,
});

export interface IHintQuestionsReceived {
  type: EChatsActionTypes.HintQuestionsReceived;
  payload: {
    hintQuestions: IChatHintSchema[];
  };
}

export const hintQuestionsReceived = (hintQuestions: IChatHintSchema[]): IHintQuestionsReceived => ({
  payload: { hintQuestions },
  type: EChatsActionTypes.HintQuestionsReceived,
});

export type TOpenChatParameters = {
  type?: 'open_phone' | 'open_message';
  defaultMessage?: string;
  source?: string;
  messagePayload?: string;
  shouldSendDefaultMessage?: boolean;
};

export interface IOpenChat {
  type: EChatsActionTypes.OpenChat;
  payload: TOpenChatParameters;
}

export const openChat = (parameters: TOpenChatParameters | void): IOpenChat => ({
  type: EChatsActionTypes.OpenChat,
  payload: parameters || {},
});

export interface IOpenChatbot {
  type: EChatsActionTypes.OpenChatbot;
}

export const openChatbot = (): IOpenChatbot => ({
  type: EChatsActionTypes.OpenChatbot,
});

export interface ICloseChat {
  type: EChatsActionTypes.CloseChat;
}

export const closeChat = (): ICloseChat => ({
  type: EChatsActionTypes.CloseChat,
});

export interface IOpenChatAfterAuth {
  type: EChatsActionTypes.OpenChatAfterAuth;
  payload: TOpenChatParameters;
}

export const openChatAfterAuth = (parameters: TOpenChatParameters | void): IOpenChatAfterAuth => ({
  type: EChatsActionTypes.OpenChatAfterAuth,
  payload: parameters || {},
});
