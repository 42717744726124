// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetHouseOfferHistorySpoilerRequest,
  TGetHouseOfferHistorySpoilerModel,
  IMappers,
  TGetHouseOfferHistorySpoilerResponse,
  IGetHouseOfferHistorySpoilerResponse,
  IGetHouseOfferHistorySpoilerResponseError,
} from './types';

export const defaultConfig: TGetHouseOfferHistorySpoilerModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'valuation-offer-history',
  pathApi: '/v1/get-house-offer-history-spoiler/',
  requestType: 'json',
} as TGetHouseOfferHistorySpoilerModel;

export function createGetHouseOfferHistorySpoilerModel(
  parameters: IGetHouseOfferHistorySpoilerRequest,
): TGetHouseOfferHistorySpoilerModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetHouseOfferHistorySpoilerOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetHouseOfferHistorySpoilerRequest;
}

export async function fetchGetHouseOfferHistorySpoiler<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetHouseOfferHistorySpoilerOptions<TResponse200, TResponse400>): Promise<
  TGetHouseOfferHistorySpoilerResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetHouseOfferHistorySpoilerModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetHouseOfferHistorySpoilerResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetHouseOfferHistorySpoilerResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetHouseOfferHistorySpoilerResponse;
}
