/* istanbul ignore next */

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { TEventPropertiesPageDealType } from './types';
import { FDealType, getDealType } from '../deal_type';

export function getDealTypeSopr(offer: IOfferSchema): TEventPropertiesPageDealType {
  const dealType = getDealType(offer);

  const mapDealType: { [key: number]: TEventPropertiesPageDealType } = {
    [FDealType.RentDaily]: 'rent_day',
    [FDealType.RentLongterm]: 'rent_long',
    [FDealType.Sale]: 'sale',
  };

  return mapDealType[dealType];
}
