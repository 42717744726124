import { IConsumer, createConsumer, createSubscriber, IEvent } from '@cian/events-log';
import * as React from 'react';

import { TOnSubmitSaveSearchParams } from '../types/submit';

export const TOPIC_SAVE_SEARCH = 'save_search';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

const getSaveSearchConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getSaveSearchConsumer._instance) {
    getSaveSearchConsumer._instance = createConsumer({ topic: TOPIC_SAVE_SEARCH });
  }

  return getSaveSearchConsumer._instance;
};

export function useSaveSearchEvents(
  onSave: (value: TOnSubmitSaveSearchParams) => void,
  onOpen?: () => void,
  onClose?: () => void,
) {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const consumer = getSaveSearchConsumer();

      const saveSearchSubscriber = createSubscriber(async (event: IEvent<unknown>) => {
        if (event.type === 'search_saved') {
          onSave(event.value as TOnSubmitSaveSearchParams);
        }
        if (event.type === 'modal_open' && onOpen) {
          onOpen();
        }
        if (event.type === 'modal_close' && onClose) {
          onClose();
        }
      });

      consumer.subscribe(saveSearchSubscriber);

      return () => consumer.unsubscribe(saveSearchSubscriber);
    }

    /* istanbul ignore next */
    return;
  }, []);
}
