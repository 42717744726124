import { TURIScheme } from '@cian/http-api/shared/URI/IURI';

import { IMakeRequest } from '../../utils/make_request';
import { filterServerResponse } from '../helpers';

export type TData = [number, number];

export interface IResponseSuccess {
  status: 'ok';
  data: TData[];
  premium_is_available: boolean;
  top3_is_available: boolean;
  total_views_count: number;
  '10_days_views_count': number;
}

export function getOfferStats(makeRequest: IMakeRequest, host: string, id: number): Promise<IResponseSuccess> {
  return makeRequest({
    method: 'GET',
    uri: {
      host,
      path: '/ajax/statistics/offer/show/',
      query: `id=${id}`,
      scheme: document.location.protocol.slice(0, -1) as TURIScheme,
    },
  })
    .then(filterServerResponse)
    .then(response => JSON.parse(response.responseBody))
    .then(response => response);
}
