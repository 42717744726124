import { IHttpApi } from '@cian/http-api/shared';

import { assertUnknownError } from './assert';
import {
  fetchAddOfferToComparison,
  IAddOfferToComparisonRequest,
  IAddOfferToComparisonResponse,
  TAddOfferToComparisonResponse,
} from '../../../repositories/offers-comparison/v2/add-offer-to-comparison';

export async function addOfferToComparisonRequest({
  httpApi,
  parameters,
}: {
  httpApi: IHttpApi;
  parameters: IAddOfferToComparisonRequest;
}): Promise<IAddOfferToComparisonResponse> {
  const response: TAddOfferToComparisonResponse = await fetchAddOfferToComparison({
    httpApi,
    parameters,
  });

  assertUnknownError(response);

  return response.response;
}
