import { GenericError, IErrorDetails } from '@cian/peperrors/shared';
/* istanbul ignore next */
export class AuthRequiredError extends GenericError {
  public constructor(configuration: { domain: string; message: string; details?: IErrorDetails }) {
    super({
      ...configuration,
      type: 'AuthRequiredError',
    });

    Object.setPrototypeOf(this, AuthRequiredError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthRequiredError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
