import { abUseExperimentsSelector } from 'shared/selectors';
import { IState } from 'shared/store';

export type TNewbuildingRentTrapExperimentGroup =
  /** Не показываем баннер **/
  | 'A'
  /** Показываем баннер старого образца **/
  | 'B'
  /** Показываем новый баннер **/
  | 'C';

export const NEWBUILDING_RENT_TRAP = 'newbuilding_rent_trap_desktop';

export function selectNewbuildingRentTrapGroup(state: IState): TNewbuildingRentTrapExperimentGroup {
  const experiments = abUseExperimentsSelector(state);
  const experiment = experiments.find(o => o.experimentName === NEWBUILDING_RENT_TRAP);

  return (experiment?.groupName || 'A') as TNewbuildingRentTrapExperimentGroup;
}
