import { authenticate } from '@cian/auth';

import {
  trackSubscriptionNewsSuccess,
  trackSubscriptionPriceSuccess,
  trackSubscriptionRegistration,
  trackUnsubscriptionSuccess,
} from './tracking';
import { sendSubscriptionPriceChanges, sendUnsubscriptionPriceChanges } from '../../api/subscriptions/price_changes';
import {
  IChangePriceSubscribeResponse,
  IChangePriceSubscribeResponseError,
} from '../../repositories/monolith-python/v1/change-price-subscribe';
import { IThunkActionCreator } from '../index';
import { ISubscribeSuccess, IUnsubscribeSuccess, subscribeSuccess, unsubscribeSuccess } from '../offer';
import { updateUserData } from '../user';

export type TStatusFetch = 'none' | 'ok' | 'isFetching' | 'error';

export interface ISubscriptionPriceChangesPopup {
  isOpen: boolean;
  error: string;
  statusFetch: TStatusFetch;
  isEmailConfirmed: boolean;
}

export interface ISubscriptionPriceChangesPopupOpen {
  type: 'ISubscriptionPriceChangesPopupOpen';
}

export interface ISubscriptionPriceChangesPopupClose {
  type: 'ISubscriptionPriceChangesPopupClose';
}

export interface ISubscriptionPriceChangesPopupFetching {
  payload: {
    message: string;
    statusFetch: TStatusFetch;
  };
  type: 'ISubscriptionPriceChangesPopupFetching';
}

export interface IUnsubscriptionPriceChangesPopupFetching
  extends Pick<ISubscriptionPriceChangesPopupFetching, 'payload'> {
  type: 'IUnsubscriptionPriceChangesPopupFetching';
}

export interface ISubscriptionPriceChangesPopupSuccess {
  payload: {
    statusFetch: TStatusFetch;
    isEmailConfirmed?: boolean;
  };
  type: 'ISubscriptionPriceChangesPopupSuccess';
}

export interface IUnsubscriptionPriceChangesPopupSuccess
  extends Pick<ISubscriptionPriceChangesPopupSuccess, 'payload'> {
  type: 'IUnsubscriptionPriceChangesPopupSuccess';
}

export interface ISubscriptionPriceChangesPopupError {
  payload: {
    message: string;
    statusFetch: TStatusFetch;
  };
  type: 'ISubscriptionPriceChangesPopupError';
}

export interface IUnsubscriptionPriceChangesPopupError extends Pick<ISubscriptionPriceChangesPopupError, 'payload'> {
  type: 'IUnsubscriptionPriceChangesPopupError';
}

export function subscriptionPriceChangesPopupOpen(): ISubscriptionPriceChangesPopupOpen {
  return {
    type: 'ISubscriptionPriceChangesPopupOpen',
  };
}

export function subscriptionPriceChangesPopupClose(): ISubscriptionPriceChangesPopupClose {
  return {
    type: 'ISubscriptionPriceChangesPopupClose',
  };
}

export function submitSubscriptionPriceChanges(
  email: string,
  offerId: number,
  isSubscribeNews: boolean,
  isPushSubscribe: boolean,
): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const {
      pushSubscribed,
      pageData: { subdomain },
    } = getState();

    dispatch({
      payload: {
        message: '',
        statusFetch: 'isFetching',
      },
      type: 'ISubscriptionPriceChangesPopupFetching',
    });

    return sendSubscriptionPriceChanges(context.httpApi, subdomain, {
      email,
      offerId,
      subscribeNews: isSubscribeNews,
    })
      .then((response: IChangePriceSubscribeResponse) => {
        const logOnInfo = response.data && response.data.logOnInfo;
        const login = response.data && response.data.login;
        if (logOnInfo && login) {
          authenticate(logOnInfo, login, true)
            .then(() => {
              dispatch(updateUserData()).catch(e => {
                throw e;
              });

              if (window.__reloadHeader__) {
                window.__reloadHeader__();
              }
              trackSubscriptionRegistration();
            })
            .catch(e => {
              throw e;
            });
        }

        return response;
      })
      .then((response: IChangePriceSubscribeResponse) => {
        dispatch<ISubscribeSuccess>(subscribeSuccess(email));
        dispatch({
          payload: {
            isEmailConfirmed: response.data && response.data.isEmailConfirmed ? response.data.isEmailConfirmed : false,
            statusFetch: 'ok',
          },
          type: 'ISubscriptionPriceChangesPopupSuccess',
        });
        trackSubscriptionPriceSuccess(pushSubscribed, isPushSubscribe);
        if (isSubscribeNews) {
          trackSubscriptionNewsSuccess();
        }
      })
      .catch((error: IChangePriceSubscribeResponseError) => {
        dispatch({
          payload: {
            message: error.status === 'badRequest' ? 'Вы уже подписаны.' : 'Не удалось подписаться. Попробуйте позже.',
            statusFetch: 'error',
          },
          type: 'ISubscriptionPriceChangesPopupError',
        });
      });
  };
}

export function submitUnsubscriptionPriceChanges(offerId: number): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const {
      pageData: { subdomain },
    } = getState();

    dispatch({
      payload: {
        message: '',
        statusFetch: 'isFetching',
      },
      type: 'IUnsubscriptionPriceChangesPopupFetching',
    });

    sendUnsubscriptionPriceChanges(context.httpApi, subdomain, { offerId })
      .then(() => {
        dispatch<IUnsubscribeSuccess>(unsubscribeSuccess());
        dispatch({
          payload: {
            statusFetch: 'ok',
          },
          type: 'IUnsubscriptionPriceChangesPopupSuccess',
        });
        trackUnsubscriptionSuccess();
      })
      .catch(() => {
        dispatch({
          payload: {
            message: 'Не удалось отписаться. Попробуйте позже.',
            statusFetch: 'error',
          },
          type: 'IUnsubscriptionPriceChangesPopupError',
        });
      });
  };
}

export type ISubscriptionPriceChangesPopupActions =
  | ISubscriptionPriceChangesPopupOpen
  | ISubscriptionPriceChangesPopupClose
  | ISubscriptionPriceChangesPopupFetching
  | ISubscriptionPriceChangesPopupSuccess
  | ISubscriptionPriceChangesPopupError
  | IUnsubscriptionPriceChangesPopupFetching
  | IUnsubscriptionPriceChangesPopupSuccess
  | IUnsubscriptionPriceChangesPopupError;

const initialState = {
  error: '',
  isEmailConfirmed: false,
  isOpen: false,
  statusFetch: 'none' as const,
};

export function subscriptionPriceChangesPopupReducer(
  state: ISubscriptionPriceChangesPopup = initialState,
  action: ISubscriptionPriceChangesPopupActions,
): ISubscriptionPriceChangesPopup {
  switch (action.type) {
    case 'ISubscriptionPriceChangesPopupOpen':
      return {
        ...state,
        isOpen: true,
      };

    case 'ISubscriptionPriceChangesPopupClose':
      return {
        ...state,
        error: '',
        isOpen: false,
        statusFetch: 'none',
      };

    case 'ISubscriptionPriceChangesPopupFetching':
    case 'IUnsubscriptionPriceChangesPopupFetching':
      return {
        ...state,
        error: '',
        statusFetch: action.payload.statusFetch,
      };

    case 'ISubscriptionPriceChangesPopupSuccess':
    case 'IUnsubscriptionPriceChangesPopupSuccess':
      return {
        ...state,
        error: '',
        isEmailConfirmed: !!action.payload.isEmailConfirmed,
        statusFetch: action.payload.statusFetch,
      };

    case 'ISubscriptionPriceChangesPopupError':
    case 'IUnsubscriptionPriceChangesPopupError':
      return {
        ...state,
        error: action.payload.message,
        statusFetch: action.payload.statusFetch,
      };

    default:
      return state;
  }
}
