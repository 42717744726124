/* istanbul ignore next */
import { isNil } from 'ramda';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCategory } from './getCategory';
import { getCplPageExtra } from './getCplPageExtra';
import { getCustomUrl } from './getCustomUrl';
import { getDealTypeSopr } from './getDealTypeSopr';
import { ISoprPage, TObjectType } from './types';

export function getPage(agent: IAgentSchema | null, offer: IOfferSchema, siteBlockId?: number | null): ISoprPage {
  return {
    customPageURL: getCustomUrl(offer, agent),
    dealType: getDealTypeSopr(offer),
    objectType: getCategory(offer) as TObjectType,
    offerID: offer.cianId,
    offerPhone:
      offer.phones && offer.phones.length > 0 ? `${offer.phones[0].countryCode}${offer.phones[0].number}` : '',
    pageType: 'Card',
    region:
      offer.trackingData &&
      !isNil(offer.trackingData.moId) &&
      !isNil(offer.trackingData.oblId) &&
      !isNil(offer.trackingData.cityId)
        ? `${offer.trackingData.moId}-${offer.trackingData.oblId}-${offer.trackingData.cityId}`
        : '1',
    siteType: 'desktop',
    extra: getCplPageExtra(offer, siteBlockId),
  };
}
