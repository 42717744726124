export enum EFrequency {
  /** Получать мнгновенно **/
  Asap = 'asap',
  /** Получать каждый день **/
  Daily = 'daily',
  /** Получать каждый час **/
  Hourly = 'hourly',
  /** Получать еженедельно **/
  Weekly = 'weekly',
}

export type TOnSubmitSaveSearchParams = {
  defaultTitle: string | null;
  defaultEmail: string | null;
  title: string;
  email: string;
  frequency: EFrequency;
  subscribeToNews: boolean;
  subscribeToPush: boolean;
  subscribeToPushAvailable: boolean;
};
