/* istanbul ignore next */
import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export const getHeadline = (offer: IOfferSchema): boolean => {
  const { title, valueAddedServices } = offer;

  if (!valueAddedServices) {
    return false;
  }

  return !!(
    (valueAddedServices.isTop3 || valueAddedServices.isPremium) &&
    typeof title === 'string' &&
    title.trim().length
  );
};
