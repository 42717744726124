import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

import { useContext } from '../../utils/useContext';
import { FallbackModalContainer } from '../FallbackModalContainer';
import { SaveSearchModalContainer } from '../SaveSearchModalContainer';

export const RootContainer: React.FC = () => {
  const context = useContext();
  if (!context) {
    throw new ConfigurationError({
      domain: 'SaveSearchModal',
      message: "SaveSearchModal used outside of it's context",
    });
  }

  if (context.status === 'succeed') {
    return <SaveSearchModalContainer />;
  }

  if (!context.open) {
    return null;
  }

  return <FallbackModalContainer />;
};
