import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { createOffersHistoryListingMapper, TMappedResponse } from './mappers';
import {
  fetchGetHouseOfferHistoryDesktop,
  IGetHouseOfferHistoryDesktopRequest,
} from '../../../repositories/valuation-offer-history/v4/get-house-offer-history-desktop';
import { IApplicationContext } from '../../../types/applicationContext';

export const fetchOffersHistoryListing = async (
  context: IApplicationContext,
  parameters: IGetHouseOfferHistoryDesktopRequest,
) => {
  try {
    const res = (await fetchGetHouseOfferHistoryDesktop({
      httpApi: context.httpApi,
      parameters,
      mappers: createOffersHistoryListingMapper(parameters),
    })) as TMappedResponse;

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      context.logger.debug('get-house-offer-history-desktop BadRequest', { details: error.printDetails() });
    } else {
      context.logger.error(error, { message: 'Error in get-house-offer-history-desktop', ...parameters });
    }

    throw error;
  }
};
