/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetInfrastructureForMapModel,
  IGetInfrastructureForMapRequest,
  IMappers,
  IGetInfrastructureForMapResponse,
  IGetInfrastructureForMapResponseError,
  TGetInfrastructureForMapResponse,
} from './types';

const defaultConfig: TGetInfrastructureForMapModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'infrastructure-caching',
  pathApi: '/v1/get-infrastructure-for-map/',
  hostType: 'api',
} as TGetInfrastructureForMapModel;

function createGetInfrastructureForMapModel(
  parameters: IGetInfrastructureForMapRequest,
): TGetInfrastructureForMapModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetInfrastructureForMapOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetInfrastructureForMapRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetInfrastructureForMap<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetInfrastructureForMapOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetInfrastructureForMapResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetInfrastructureForMapModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetInfrastructureForMapResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetInfrastructureForMapResponseError);
    }
  }

  return { response, statusCode } as TGetInfrastructureForMapResponse;
}

export { defaultConfig, createGetInfrastructureForMapModel, fetchGetInfrastructureForMap };
