import * as React from 'react';

import { ERROR_MESSAGES_MAP } from './constants';
import { validateEmail } from './utils';

interface IFormValidationHookProps {
  hasError: boolean;
  errorMessage: string;
  validateForm: (email: string) => boolean;
  resetError: VoidFunction;
}

export const useFormValidation = (): IFormValidationHookProps => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const validateForm = React.useCallback(
    (emailValue: string) => {
      const isEmptyValue = !emailValue;
      const isInvalidValue = !validateEmail(emailValue);
      const hasError = isEmptyValue || isInvalidValue;

      if (isEmptyValue) {
        setErrorMessage(ERROR_MESSAGES_MAP.empty);
      } else if (isInvalidValue) {
        setErrorMessage(ERROR_MESSAGES_MAP.invalid);
      }

      setHasError(hasError);

      return hasError;
    },
    [setErrorMessage],
  );

  const resetError = React.useCallback(() => {
    setHasError(false);
    setErrorMessage('');
  }, [setHasError, setErrorMessage]);

  return {
    hasError,
    errorMessage,
    validateForm,
    resetError,
  };
};
