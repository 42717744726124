export enum EBannerType {
  RealtorRequestTrap,
  RentKpnCallback,
  RentNBListing,
  SuburbanKpnCallback,
  SuburbanListing,
  FlatSaleKpnCallback,
  FlatSaleListing,
  LocationSubscription,
}
