//функции отправки аналитики по всем событиям, связанным со скрытием офера
// описание событиий https://cianru.atlassian.net/wiki/spaces/ML/pages/671222253

import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IState } from '../store';
import { getCustomUrl } from '../utils/tracking/getCustomUrl';
import { getProducts } from '../utils/tracking/get_products';

export enum EHideOfferAnalyticsCategory {
  HIDE = 'HideObject',
  RESTORE = 'RestoreObject',
}

export enum ERestoreEventSource {
  ICON = 'Restore_icon',
  BUTTON = 'Restore_button',
}

export interface IGetProductsParams {
  offer: IOfferSchema;
  agent: IAgentSchema | null;
  kpId: number | null;
}

export interface IHideOfferTrackParams {
  action: string;
  category: EHideOfferAnalyticsCategory;
  productParams?: IGetProductsParams;
}

export function selectProductsParams(state: IState): IGetProductsParams {
  const {
    offerData: { agent, kp, offer },
  } = state;

  return {
    agent,
    offer,
    kpId: kp && kp.id,
  };
}

//функция для отправки событий скрытия/восстановления
export function trackHiddenObjects(params: IHideOfferTrackParams) {
  const { action, category, productParams } = params;
  let products;
  let label;
  if (productParams) {
    const { agent, offer, kpId } = productParams;
    products = getProducts({ agent, offer, kpId });
    label = getCustomUrl(offer, agent);
  }
  ca('eventSite', {
    action,
    category,
    name: 'oldevent',
    products: [products],
    label,
  });
}
