import { fetchSimilarObjects } from '../../api/map_similar';
import { IThunkActionCreator } from '../index';

export function fetchSimilar(bounds: [[number, number], [number, number]]): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const apiBaseUrl = context.config.get('apiBaseUrl') as string;

    if (!apiBaseUrl) {
      throw new Error('apiBaseUrl not defined');
    }

    const similarQS = getState().offerData.similarOffersMapQs;
    const subdomain = getState().pageData.subdomain;

    fetchSimilarObjects(context.custom.makeRequest, apiBaseUrl, bounds, similarQS, subdomain)
      .then(similarObjects => {
        dispatch<ISimilarObjectsFetched>({
          similarObjects,
          type: 'ISimilarObjectsFetched',
        });
      })
      .catch(e => {
        throw e;
      });
  };
}

export function markViewed(id: string): IThunkActionCreator {
  return (dispatch, getState) => {
    const prevViewedSimilar = getState().viewedSimilar.ids || [];

    const viewedSimilar: string[] = [...prevViewedSimilar];

    if (!viewedSimilar.some((item: string) => item === id)) {
      viewedSimilar.push(id);
    }

    dispatch<ISimilarObjectMarked>({
      type: 'ISimilarObjectMarked',
      viewedSimilar,
    });
  };
}

export interface ISimilarObjectsFetched {
  type: 'ISimilarObjectsFetched';
  similarObjects: ISimilarOffersData;
}

export interface ISimilarObjectMarked {
  type: 'ISimilarObjectMarked';
  viewedSimilar: string[];
}

export interface IPointOfferData {
  color: string;
  link_text: string[];
  fav: string;
  price_rur: string;
  property_type: string;
  id: string;
  ownerId: number;
  modelVersion: number;
  services: EBC.TVas[];
  published_user_id: number;
}

export interface IPointStat {
  color: string;
  count: number;
  property_type: string;
}

export interface IPointData {
  content: {
    text: string;
    link: string;
    offers_count: number;
  };
  isStPetersburgOrArea: boolean;
  isMoscowOrArea: boolean;
  offers: IPointOfferData[];
  stat: IPointStat[];
}

export interface IViewedData {
  ids: string[];
}

export interface ISimilarOffersData {
  status: string;
  url: {
    favorite: string;
  };
  offer_type: string;
  pik_promo_enabled: boolean;
  data: {
    offers_count: number;
    stat: [
      {
        count: number;
        property_type: string;
      },
    ];
    points: {
      [index: string]: IPointData;
    };
  };
}

export function mapSimilarReducer(state: ISimilarOffersData, action: ISimilarObjectsFetched) {
  switch (action.type) {
    case 'ISimilarObjectsFetched':
      return { ...state, ...action.similarObjects };

    default:
      return state || null;
  }
}

export function markViewedReducer(state: IViewedData, action: ISimilarObjectMarked) {
  switch (action.type) {
    case 'ISimilarObjectMarked':
      return { ...state, ids: action.viewedSimilar };

    default:
      return state || { ids: [] };
  }
}
