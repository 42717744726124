import { createAppState } from '../appState';
import { createContext } from '../createContext';
import { renderApplication } from '../renderApplication';
import { subscribeToInteractiveTime } from '../subscribeToInteractiveTime';

require('../../index.css');

export function runApp() {
  const context = createContext();
  const store = createAppState(context);

  subscribeToInteractiveTime({ store });
  renderApplication({ context, store });
}
