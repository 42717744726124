import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { fetchCheckAccessToAdminApi } from 'shared/repositories/valuation-offer-history/v1/check-access-to-admin-api';
import { createCheckAccessToAdminMapper } from 'shared/services/offersHistory/fetchCheckAdminAccess/mappers';
import { IApplicationContext } from 'shared/types/applicationContext';

export const fetchCheckAdminAccess = async (context: IApplicationContext) => {
  try {
    const res = await fetchCheckAccessToAdminApi({
      httpApi: context.httpApi,
      mappers: createCheckAccessToAdminMapper(),
    });

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      context.logger.debug('check-access-to-admin-api BadRequest');
    } else {
      context.logger.error(error, { message: 'Error in check-access-to-admin-api' });
    }

    throw error;
  }
};
