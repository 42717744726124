import { selectOfferAgent } from './selectOfferAgent';
import { IState } from '../../store';

export const selectAgentName = (state: IState): string => {
  const agent = selectOfferAgent(state);

  if (!agent) {
    return '';
  }

  const { name, firstName, lastName, id } = agent;

  if (name) {
    return name;
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return `ID ${id}`;
};
