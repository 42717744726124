import { IHttpApi } from '@cian/http-api';

import {
  createHideOfferWebsiteModel,
  IHideOfferWebsiteRequest,
  THideOfferWebsiteResponse,
} from '../repositories/hidden-objects/v1/hide-offer-website';
import {
  createRestoreOfferWebsiteModel,
  IRestoreOfferWebsiteRequest,
  TRestoreOfferWebsiteResponse,
} from '../repositories/hidden-objects/v1/restore-offer-website';

export function postHideOffer(
  httpApi: IHttpApi,
  parameters: IHideOfferWebsiteRequest,
): Promise<THideOfferWebsiteResponse> {
  return httpApi.fetch(createHideOfferWebsiteModel(parameters));
}

export function postRestoreOffer(
  httpApi: IHttpApi,
  parameters: IRestoreOfferWebsiteRequest,
): Promise<TRestoreOfferWebsiteResponse> {
  return httpApi.fetch(createRestoreOfferWebsiteModel(parameters));
}
