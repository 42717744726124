import { EActionTypes, TOfferValuationActions } from '../actions/actions';
import { IOfferValuationState } from '../types';

export const initialState: IOfferValuationState = {
  data: null,
  isFetching: false,
  isError: false,
  estimationPeriod: null,
};

export const offerValuationReducer = (
  state: IOfferValuationState = initialState,
  action: TOfferValuationActions,
): IOfferValuationState => {
  switch (action.type) {
    case EActionTypes.Request:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case EActionTypes.Success:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case EActionTypes.Failure:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case EActionTypes.SetEstimationPeriod:
      return {
        ...state,
        estimationPeriod: action.payload,
      };

    case EActionTypes.SetIsAdmin:
      return {
        ...state,
        isAdmin: action.payload,
      };

    case EActionTypes.ResetEstimation:
      return initialState;

    default:
      return state;
  }
};
