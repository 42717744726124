import { IState } from '../../store';

export function banksListSelector({ banksList, mortgage }: IState) {
  if (!mortgage.bankIds) {
    return banksList.list;
  }
  const actualBankIds = new Set(mortgage.bankIds);

  return banksList.list.filter(bank => actualBankIds.has(bank.id));
}
