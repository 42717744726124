import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export function getVariants(offer: IOfferSchema): string {
  if (offer.valueAddedServices) {
    const variants = Object.keys(offer.valueAddedServices).reduce((acc, vasKey) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((offer.valueAddedServices as any)[vasKey]) {
        switch (vasKey) {
          case 'isColorized':
            acc.push('color');
            break;
          case 'isStandard':
            acc.push('standard');
            break;
          case 'isPremium':
            acc.push('premium');
            break;
          case 'isTop3':
            acc.push('top3');
            break;
          case 'isAuction':
            acc.push('auction');
            break;
          default:
            break;
        }
      }
      // tslint:enable:no-any

      return acc;
    }, [] as string[]);

    return variants.join('/');
  }

  return '';
}
