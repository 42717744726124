import { EOrderBy } from 'shared/repositories/valuation-offer-history/entities/offer_history/HouseOfferHistoryRequestV4Schema';

import {
  IOffersHistoryDefaultFilters,
  IOffersHistoryListingData,
  TCategoriesFilterActiveValue,
  TOfferFromHistoryData,
  TRoomsFilterActiveValue,
  TStatusFilterActiveValue,
  TTypeFilterActiveValue,
} from '../../store/offerHistory';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EGetOffersHistoryListing {
  request = 'get-offers-history-listing/request',
  success = 'get-offers-history-listing/success',
  successLoadMore = 'get-offers-history-listing/success-load-more',
  failure = 'get-offers-history-listing/failure',
}

export enum EGetOffersHistoryListingSpoiler {
  request = 'get-offers-history-listing-spoiler/request',
  success = 'get-offers-history-listing-spoiler/success',
  failure = 'get-offers-history-listing-spoiler/failure',
}

export enum EGetOfferFromHistory {
  reset = 'get-offer-from-history/reset',
  request = 'get-offer-from-history/request',
  success = 'get-offer-from-history/success',
  failure = 'get-offer-from-history/failure',
  authFailure = 'get-offer-from-history/auth-failure',
}

export enum EOffersHistory {
  setPage = 'offers-history/set-page',
  setRoomsFilter = 'offers-history/set-rooms-filter',
  setStatusFilter = 'offers-history/set-status-filter',
  setTypeFilter = 'offers-history/set-type-filter',
  setActiveOfferId = 'offers-history/set-active-offer-id',
  setIsFiltersChanged = 'offers-history/set-is-filters-changed',
  setViewedOffer = 'offers-history/set-viewed-offer',
  resetFilters = 'offers-history/reset-filters',
  resetListingData = 'offers-history/reset-listing-data',
  saveDefaultFilters = 'offers-history/save-default-filters',
  setCategoriesFilter = 'offers-history/set-categories-filter',
  setSortOffers = 'offers-history/set-sort-offers',
  setAdminAccess = 'offers-history/set-admin-access',
}

export const requestOffersHistoryListingActions = {
  request: actionGenerator<EGetOffersHistoryListing.request, boolean>(EGetOffersHistoryListing.request),
  success: actionGenerator<EGetOffersHistoryListing.success, IOffersHistoryListingData>(
    EGetOffersHistoryListing.success,
  ),
  successLoadMore: actionGenerator<EGetOffersHistoryListing.successLoadMore, IOffersHistoryListingData>(
    EGetOffersHistoryListing.successLoadMore,
  ),
  failure: actionGenerator<EGetOffersHistoryListing.failure>(EGetOffersHistoryListing.failure),
};

export const requestOffersHistoryListingSpoilerActions = {
  request: actionGenerator<EGetOffersHistoryListingSpoiler.request>(EGetOffersHistoryListingSpoiler.request),
  success: actionGenerator<EGetOffersHistoryListingSpoiler.success, IOffersHistoryListingData>(
    EGetOffersHistoryListingSpoiler.success,
  ),
  failure: actionGenerator<EGetOffersHistoryListingSpoiler.failure>(EGetOffersHistoryListingSpoiler.failure),
};

export const requestOfferFromHistoryActions = {
  reset: actionGenerator<EGetOfferFromHistory.reset>(EGetOfferFromHistory.reset),
  request: actionGenerator<EGetOfferFromHistory.request>(EGetOfferFromHistory.request),
  success: actionGenerator<EGetOfferFromHistory.success, ISuccessOfferFromHistoryPayload>(EGetOfferFromHistory.success),
  failure: actionGenerator<EGetOfferFromHistory.failure>(EGetOfferFromHistory.failure),
  authFailure: actionGenerator<EGetOfferFromHistory.authFailure>(EGetOfferFromHistory.authFailure),
};

export const setRoomsFilter = actionGenerator<EOffersHistory.setRoomsFilter, TRoomsFilterActiveValue>(
  EOffersHistory.setRoomsFilter,
);
export const setStatusFilter = actionGenerator<EOffersHistory.setStatusFilter, TStatusFilterActiveValue>(
  EOffersHistory.setStatusFilter,
);
export const setTypeFilter = actionGenerator<EOffersHistory.setTypeFilter, TTypeFilterActiveValue>(
  EOffersHistory.setTypeFilter,
);
export const setCategoriesFilter = actionGenerator<EOffersHistory.setCategoriesFilter, TCategoriesFilterActiveValue>(
  EOffersHistory.setCategoriesFilter,
);
export const setSortOffers = actionGenerator<EOffersHistory.setSortOffers, EOrderBy>(EOffersHistory.setSortOffers);

export const setAdminAccess = actionGenerator<EOffersHistory.setAdminAccess, boolean>(EOffersHistory.setAdminAccess);

export const setPage = actionGenerator<EOffersHistory.setPage, number>(EOffersHistory.setPage);

export const resetFilters = actionGenerator<EOffersHistory.resetFilters>(EOffersHistory.resetFilters);
export const resetListingData = actionGenerator<EOffersHistory.resetListingData>(EOffersHistory.resetListingData);

export const setActiveOfferId = actionGenerator<EOffersHistory.setActiveOfferId, number | null>(
  EOffersHistory.setActiveOfferId,
);

export const setIsFiltersChanged = actionGenerator<EOffersHistory.setIsFiltersChanged, boolean>(
  EOffersHistory.setIsFiltersChanged,
);

export const setViewedOffer = actionGenerator<EOffersHistory.setViewedOffer, number>(EOffersHistory.setViewedOffer);

export const saveDefaultFilters = actionGenerator<EOffersHistory.saveDefaultFilters, IOffersHistoryDefaultFilters>(
  EOffersHistory.saveDefaultFilters,
);

interface ISuccessOfferFromHistoryPayload {
  id: number;
  data: TOfferFromHistoryData;
}

export type TOffersHistoryActions =
  | ReturnType<typeof requestOffersHistoryListingActions.request>
  | ReturnType<typeof requestOffersHistoryListingActions.success>
  | ReturnType<typeof requestOffersHistoryListingActions.successLoadMore>
  | ReturnType<typeof requestOffersHistoryListingActions.failure>
  | ReturnType<typeof requestOfferFromHistoryActions.request>
  | ReturnType<typeof requestOfferFromHistoryActions.success>
  | ReturnType<typeof requestOfferFromHistoryActions.failure>
  | ReturnType<typeof requestOfferFromHistoryActions.authFailure>
  | ReturnType<typeof requestOfferFromHistoryActions.reset>
  | ReturnType<typeof requestOffersHistoryListingSpoilerActions.request>
  | ReturnType<typeof requestOffersHistoryListingSpoilerActions.success>
  | ReturnType<typeof requestOffersHistoryListingSpoilerActions.failure>
  | ReturnType<typeof setRoomsFilter>
  | ReturnType<typeof setStatusFilter>
  | ReturnType<typeof setTypeFilter>
  | ReturnType<typeof setCategoriesFilter>
  | ReturnType<typeof setPage>
  | ReturnType<typeof setActiveOfferId>
  | ReturnType<typeof setIsFiltersChanged>
  | ReturnType<typeof setViewedOffer>
  | ReturnType<typeof resetFilters>
  | ReturnType<typeof resetListingData>
  | ReturnType<typeof saveDefaultFilters>
  | ReturnType<typeof setSortOffers>
  | ReturnType<typeof setAdminAccess>;
