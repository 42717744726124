import { TCategory } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { selectIsOfferWithMultiAds } from './offerData';
import { priceSelector } from './price';
import { IState } from '../store';
import { withoutNoBreakSpaces } from '../utils/formatters';
import { getObjectType } from '../utils/getObjectType';
import { getJkName, getTotalArea, getFloorInfo, getDealTypeText, getMultiAdsFloorInfo } from '../utils/sharing';

const isShowTotalArea = (category: TCategory): boolean =>
  category === 'commercialLandSale' || category === 'commercialLandRent' || category === 'landSale';

export function shareTitleSelector(state: IState, isShortened?: boolean): string {
  const isCommercialOfferWithMultiAds = selectIsOfferWithMultiAds(state);

  const { offerData } = state;
  const dealType = getDealTypeText(offerData.offer);
  const objectType = getObjectType(offerData, isShortened);
  const jkName = getJkName(offerData.offer.category, offerData.newObject);

  // для коректной работы в заголовке, заменяем символы No-break space
  const price = withoutNoBreakSpaces(priceSelector(state, { useSharePrefix: true, currencySign: 'руб.' }) || '');

  const totalArea = getTotalArea(
    offerData.offer.totalArea,
    (offerData.offer && isShowTotalArea(offerData.offer.category) && offerData.offer.land) || null,
  );
  const floorInfo = isCommercialOfferWithMultiAds
    ? getMultiAdsFloorInfo(offerData.offer)
    : getFloorInfo(offerData.offer.floorNumber, offerData.offer.building);

  const addition = isShortened ? [] : [totalArea, floorInfo];

  return [[dealType, objectType, jkName, price].filter(item => !!item).join(' '), ...addition]
    .filter(item => !!item)
    .join(', ');
}
