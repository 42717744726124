import { openBuyAnalyticsModal, openBuyAnalyticsModalAfterAuth } from './openBuyAnalyticsModal';
import { sendSoprAnalytics } from './sendSoprAnalytics';
import { selectIsAuthenticated } from '../../selectors';
import { IThunkActionCreator } from '../../store';
import { callAuthenticationPopup } from '../../utils/authentication';

export const startBuyAnalytics = (): IThunkActionCreator => {
  return (dispatch, getState): void => {
    const state = getState();

    dispatch(sendSoprAnalytics('buy_report'));

    const isAuthenticated = selectIsAuthenticated(state);

    if (!isAuthenticated) {
      dispatch(openBuyAnalyticsModalAfterAuth());
      callAuthenticationPopup({
        viewData: { headingType: 'buyCommercialAnalyticsPermission' },
      });

      return;
    }
    dispatch(openBuyAnalyticsModal());
  };
};
