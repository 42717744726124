// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IPricesV4Schema } from './PricesV4Schema';

export interface IHouseOfferHistoryV4Schema {
  /** Дата снятия объекта **/
  dateEnd?: string;
  /** Дата публикации **/
  dateStart: string;
  /** Строка срока показа объявления **/
  exposition: string;
  /** Идентификатор объявления **/
  id: number;
  /** Относительная ссылка на оффер **/
  link?: string;
  /** Фото объявления **/
  previewPhoto?: string;
  /** Цены (цена за объект, цена за кв.м., разница цен при подаче и при снятии оффера) **/
  prices: IPricesV4Schema;
  /** Статус объявления **/
  status: EStatus;
  /** Текстовый заголовок для квартиры, включающий площадь, комнатность и этаж **/
  title: string;
  /** Строка со временем в пути до родительского дома **/
  walkWayMinutes?: string;
}
export enum EStatus {
  /** Снято с публикации **/
  'Deactivated' = 'deactivated',
  /** Опубликовано **/
  'Published' = 'published',
}
