/* eslint-disable max-lines */
import { compose } from 'ramda';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export enum FOfferType {
  /**
   * Do not use
   */
  Unexpected = 0,
  /**
   * Квартира во вторичке
   */
  FlatOld = 1 << 1,
  /**
   * Квартира в новостройке
   */
  FlatNew = 1 << 2,
  /**
   * Квартира
   */
  Flat = FlatOld | FlatNew,
  /**
   * Доля
   */
  FlatShared = 1 << 3,
  /**
   * Комната
   */
  Room = 1 << 4,
  /**
   * Койко-место
   */
  Bed = 1 << 5,
  /**
   * Жилая городская
   */
  Urban = Flat | FlatShared | Room | Bed,
  /**
   * Дом/дача
   */
  House = 1 << 6,
  /**
   * Часть дома
   */
  HousePart = 1 << 7,
  /**
   * Таунхаус
   */
  Townhouse = 1 << 8,
  /**
   * Участок
   */
  Land = 1 << 9,
  /**
   * Загородная
   */
  Suburban = House | HousePart | Townhouse | Land,
  /**
   * Жилая
   */
  Residential = Urban | Suburban,
  /**
   * Офис
   */
  Office = 1 << 10,
  /**
   * Торговая площадь
   */
  TradeArea = 1 << 11,
  /**
   * Склад
   */
  Warehouse = 1 << 12,
  /**
   * ПСН
   */
  FreeAppointmentObject = 1 << 13,
  /**
   * Общепит
   */
  PublicCatering = 1 << 14,
  /**
   * Гараж
   */
  Garage = 1 << 15,
  /**
   * Производство
   */
  Manufacture = 1 << 16,
  /**
   * Автосервис
   */
  AutoService = 1 << 17,
  /**
   * Готовый бизнес
   */
  Business = 1 << 18,
  /**
   * Здание
   */
  Building = 1 << 19,
  /**
   * Бытовые услуги
   */
  DomesticServices = 1 << 20,
  /**
   * Коммерческая земля
   */
  CommercialLand = 1 << 21,
  /**
   * Коммерческая
   */
  Commercial = Office |
    TradeArea |
    Warehouse |
    FreeAppointmentObject |
    PublicCatering |
    Garage |
    Manufacture |
    AutoService |
    Business |
    Building |
    DomesticServices |
    CommercialLand,
  Any = Residential | Commercial,
}

export function getOfferType(offer: IOfferSchema): FOfferType {
  if (
    [
      'buildingRent',
      'buildingSale',
      'businessRent',
      'businessSale',
      'carServiceRent',
      'carServiceSale',
      'commercialLandRent',
      'commercialLandSale',
      'domesticServicesRent',
      'domesticServicesSale',
      'freeAppointmentObjectRent',
      'freeAppointmentObjectSale',
      'garageRent',
      'garageSale',
      'industryRent',
      'industrySale',
      'officeRent',
      'officeSale',
      'publicCateringRent',
      'publicCateringSale',
      'shoppingAreaRent',
      'shoppingAreaSale',
      'warehouseRent',
      'warehouseSale',
    ].includes(offer.category)
  ) {
    if (offer.category.startsWith('building')) {
      return FOfferType.Building;
    }

    if (offer.category.startsWith('business')) {
      return FOfferType.Business;
    }

    if (offer.category.startsWith('carService')) {
      return FOfferType.AutoService;
    }

    if (offer.category.startsWith('commercialLand')) {
      return FOfferType.CommercialLand;
    }

    if (offer.category.startsWith('domesticServices')) {
      return FOfferType.DomesticServices;
    }

    if (offer.category.startsWith('freeAppointmentObject')) {
      return FOfferType.FreeAppointmentObject;
    }

    if (offer.category.startsWith('garage')) {
      return FOfferType.Garage;
    }

    if (offer.category.startsWith('industry')) {
      return FOfferType.Manufacture;
    }

    if (offer.category.startsWith('office')) {
      return FOfferType.Office;
    }

    if (offer.category.startsWith('publicCatering')) {
      return FOfferType.PublicCatering;
    }

    if (offer.category.startsWith('shoppingArea')) {
      return FOfferType.TradeArea;
    }

    if (offer.category.startsWith('warehouse')) {
      return FOfferType.Warehouse;
    }
  }

  const category = offer.category.toLowerCase();

  if (category.includes('bed')) {
    return FOfferType.Bed;
  }

  if (category.includes('room')) {
    return FOfferType.Room;
  }

  if (category.includes('flat')) {
    if (category.includes('newbuilding')) {
      return FOfferType.FlatNew;
    }

    if (category.includes('share')) {
      return FOfferType.FlatShared;
    }

    return FOfferType.FlatOld;
  }

  if (category.includes('cottage')) {
    return FOfferType.House;
  }

  if (category.includes('house')) {
    if (category.includes('townhouse')) {
      return FOfferType.Townhouse;
    }

    if (category.includes('share')) {
      return FOfferType.HousePart;
    }

    return FOfferType.House;
  }

  if (category.includes('land')) {
    return FOfferType.Land;
  }

  return FOfferType.Unexpected;
}

export function isNewbuilding(offerType: FOfferType) {
  return (offerType & FOfferType.FlatNew) !== 0;
}

export function isOldbuilding(offerType: FOfferType) {
  return (offerType & FOfferType.FlatOld) !== 0;
}

export function isUrban(offerType: FOfferType) {
  return (offerType & FOfferType.Urban) !== 0;
}

export function isSuburban(offerType: FOfferType) {
  return (offerType & FOfferType.Suburban) !== 0;
}

export function isResidential(offerType: FOfferType) {
  return (offerType & FOfferType.Residential) !== 0;
}

export function isCommercial(offerType: FOfferType) {
  return (offerType & FOfferType.Commercial) !== 0;
}

function isOffice(offerType: FOfferType) {
  return (offerType & FOfferType.Office) !== 0;
}

export function isFlat(offerType: FOfferType) {
  return (offerType & FOfferType.Flat) !== 0;
}

export const getIsOfficeByOffer = compose(isOffice, getOfferType);

function getIsFreeAppointmentObject(offerType: FOfferType): boolean {
  return (offerType & FOfferType.FreeAppointmentObject) !== 0;
}

export const getIsFreeAppointmentObjectByOffer = compose(getIsFreeAppointmentObject, getOfferType);

function getIsTradeArea(offerType: FOfferType): boolean {
  return (offerType & FOfferType.TradeArea) !== 0;
}

export const getIsTradeAreaByOffer = compose(getIsTradeArea, getOfferType);
