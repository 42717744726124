/* eslint-disable max-lines */
import { TooltipVerifyWay, TTooltipVerifyWay } from '@cian/owner-badge-component';
import { plural } from '@cian/utils/lib/shared/plural';
import { isNil } from 'ramda';

import {
  IAgentSchema,
  IAgentSkillSchema,
  INewObjectBuilderSchema,
  IOfferSchema,
  TAccountType,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { FOfferType, getOfferType } from 'shared/utils/offer_type';

import { isArrayWithItems, unique } from './';
import { getIsCoworkingByOffer } from './getIsCoworkingByOffer';
import { AccountType, UserTrustLevel } from '../store/offer';

export const AGENCY_LABEL = 'Агентство недвижимости';
export const MANAGEMENT_COMPANY_LABEL = 'Управляющая компания';
export const RENT_DEPARTMENT_LABEL = 'Отдел аренды';

export function getAgentType(agent: IAgentSchema | null, offer: IOfferSchema): string | undefined {
  if (!agent || isFromBuilder(offer)) {
    return undefined;
  }

  /**
   * Для подрядчика (представителя застройщика) не показываем лейбл 'Агентство недвижимости'
   */
  if (
    isOfficialSeller(offer) ||
    agent.accountType === AccountType.ManagementCompany ||
    agent.accountType === AccountType.RentDepartment
  ) {
    return undefined;
  }

  if (agent.accountType === AccountType.Agency) {
    return AGENCY_LABEL;
  }

  if (agent.accountType === AccountType.Specialist && agent.companyName) {
    return agent.companyName;
  }

  return undefined;
}

export function isBroker({ companyName }: IAgentSchema) {
  return !isNil(companyName) && companyName.toLowerCase() === 'частный маклер';
}

export function isAgency(agent: IAgentSchema) {
  return agent.accountType === AccountType.Agency;
}

export function isManagementCompany(agent: IAgentSchema) {
  return agent.accountType === AccountType.ManagementCompany;
}

export function isRentDepartment(agent: IAgentSchema) {
  return agent.accountType === AccountType.RentDepartment;
}

export function isHonest(agent: IAgentSchema): boolean {
  return Boolean(agent.userTrustLevel && agent.userTrustLevel === UserTrustLevel.Involved);
}

export function isPro(agent: IAgentSchema): boolean {
  return Boolean(agent.isPro);
}

export function isOfficialSeller(offer: IOfferSchema): boolean {
  return Boolean(offer.isFromSeller);
}

export function isLeadFactory(offer: IOfferSchema): boolean {
  return Boolean(offer.newbuilding && offer.newbuilding.isFromLeadFactory);
}

export function isFromBuilder(offer: IOfferSchema): boolean {
  return Boolean(offer.isFromBuilder);
}

export function isFromBuilderPresenter(offer: IOfferSchema): boolean {
  return Boolean(offer.newbuilding && offer.newbuilding.isFromDeveloper);
}

export function isHomeowner(offer: IOfferSchema): boolean {
  return Boolean(offer.isByHomeowner);
}

export function isOfficialRepresentative(offer: IOfferSchema) {
  return (offer.businessShoppingCenter && offer.businessShoppingCenter.fromRepresentative) || false;
}

/* istanbul ignore next */
export function getAgentExperienceCIAN(agent: IAgentSchema) {
  const lifeTime = getLifeTime(agent);

  return lifeTime ? `${lifeTime} на ЦИАН` : null;
}

/* istanbul ignore next */
export function getLifeTime(agent: IAgentSchema, todayDate?: string): string | undefined {
  const creationDate = new Date(agent.creationDate);

  if (isNaN(Number(creationDate.getTime()))) {
    return undefined;
  }

  const today = todayDate ? new Date(todayDate) : new Date();
  let years = today.getFullYear() - creationDate.getFullYear();
  let months = today.getMonth() - creationDate.getMonth();

  if (months < 0) {
    years--;
    months = 12 + today.getMonth() - creationDate.getMonth();
  }

  if (today.getDate() < creationDate.getDate()) {
    months--;

    if (months < 0) {
      months = 11;
      years--;
    }
  }

  const result: string[] = [];

  if (years > 0) {
    result.push(`${years} ${plural(years, ['год', 'года', 'лет'])}`);
  }

  if (months > 0) {
    result.push(`${months} ${plural(months, ['месяц', 'месяца', 'месяцев'])}`);
  }

  if (years === 0 && months === 0) {
    result.push('Менее 1 месяца');
  }

  return result.join(' ');
}

/* istanbul ignore next */
export function getAgentName(agent: IAgentSchema, offer: IOfferSchema): string | undefined {
  if (isHomeowner(offer)) {
    return `ID ${agent.id}`;
  }

  if (agent.accountType && agent.accountType === AccountType.Agency) {
    return agent.name || undefined;
  }

  if (isFromBuilder(offer)) {
    return agent.name || undefined;
  }

  if (isOfficialSeller(offer)) {
    return agent.name || undefined;
  }

  if (agent.status !== 'hidden' || agent.isSubAgent) {
    if (agent.firstName) {
      let name = agent.firstName;

      if (agent.lastName) {
        name += ` ${agent.lastName}`;
      }

      return name;
    }

    if (agent.name) {
      return agent.name;
    }
  }

  if (agent.status === 'hidden' && !agent.isSubAgent && agent.accountType === 'specialist') {
    if (agent.firstName) {
      let name = agent.firstName;

      if (agent.lastName) {
        name += ` ${agent.lastName}`;
      }

      return name;
    }

    return undefined;
  }

  return undefined;
}

export interface ISkillsTreeItem {
  id?: number;
  name: string;
}

export interface ISkillsTreeParent {
  childs: ISkillsTreeItem[];
  name: string;
}

export function mapSkillsArrayIntoTree(skills: IAgentSkillSchema[] | null): ISkillsTreeParent[] {
  if (!isArrayWithItems(skills)) {
    return [];
  }

  const validSkills = skills.filter(({ name, parentName }) => Boolean(name && parentName));

  if (validSkills.length === 0) {
    return [];
  }

  let parents = validSkills.map(({ parentName }) => parentName as string);
  parents = unique(parents);

  return parents.map<ISkillsTreeParent>(parent => ({
    childs: validSkills
      .filter(({ parentName }) => parentName === parent)
      .map(({ name, id }) => {
        const item: ISkillsTreeItem = { name: name as string };
        if (id) {
          item.id = id as number;
        }

        return item;
      }),
    name: parent,
  }));
}

/* istanbul ignore next */
export function getTrackingLabelPrefix(offer: IOfferSchema) {
  if (isOfficialSeller(offer) || isFromBuilder(offer)) {
    return 'to_developerCard_';
  }

  return 'to_realtorCard_';
}

export function getAuthorLabel(offer: IOfferSchema, showCountrysideBuilderBadge: boolean = false): string | undefined {
  if (isLeadFactory(offer)) {
    return 'Консультант';
  }

  if (isFromBuilder(offer) || showCountrysideBuilderBadge) {
    return 'Застройщик';
  }

  if (isOfficialSeller(offer)) {
    return 'Представитель застройщика';
  }

  if (isHomeowner(offer)) {
    return 'homeowner';
  }

  if (isOfficialRepresentative(offer)) {
    return 'Официальный представитель';
  }

  return undefined;
}

/* istanbul ignore next */
export function getAgentLabel(account: TAccountType | null, { offerType }: IOfferSchema): string | undefined {
  const isValidAccount = account === AccountType.ManagementCompany || account === AccountType.RentDepartment;
  const accountType = {
    [AccountType.ManagementCompany]: 'Управляющая компания',
    [AccountType.RentDepartment]: 'Отдел аренды',
  };

  return account && offerType === 'commercial' && isValidAccount ? accountType[account] : undefined;
}

/* istanbul ignore next */
export function getProfileLink(
  agent: IAgentSchema | null,
  offer: IOfferSchema,
  developer: INewObjectBuilderSchema | null,
) {
  if (isFromBuilder(offer) && developer && developer.url) {
    return developer.url;
  }

  if (agent) {
    if (agent.accountType === 'specialist') {
      return `/agents/${agent.id}`;
    } else if (agent.offersLink) {
      return agent.offersLink;
    }
  }

  return undefined;
}

/* istanbul ignore next */
export const isRosreestrVerified = (offer: IOfferSchema): boolean =>
  !!(offer.rosreestrCheck && offer.rosreestrCheck.status === 'success');

/* istanbul ignore next */
export function isPassportVerified(agent: IAgentSchema): boolean {
  return Boolean(agent.isPassportVerified);
}

/* istanbul ignore next */
export const isHomeownerVerified = (offer: IOfferSchema, agent: IAgentSchema): boolean =>
  isRosreestrVerified(offer) || isPassportVerified(agent);

/* istanbul ignore next */
export const getVerifyWay = (offer: IOfferSchema, agent: IAgentSchema | null): TTooltipVerifyWay | undefined => {
  let res: TTooltipVerifyWay | undefined;

  if (isRosreestrVerified(offer)) {
    res = TooltipVerifyWay.ROSREESTR;
  } else if (agent && isPassportVerified(agent)) {
    res = TooltipVerifyWay.PASSPORT;
  }

  return res;
};

/* istanbul ignore next */
export function createAgentRate(rate: number) {
  return (rate * 5).toFixed(1).replace('.', ',');
}

export const isAgentBrandShown = (agent: IAgentSchema | null, offer: IOfferSchema): boolean => {
  if (!agent) {
    return false;
  }

  const isAccountMustBeShown = [isLeadFactory, isHomeowner, isFromBuilder, isOfficialSeller].some(predicat =>
    predicat(offer),
  );
  const isAgentPublic = agent.status === 'published';

  return isAccountMustBeShown || isAgentPublic;
};

export function isCommerceRealtor({ agent, offer }: { agent: IAgentSchema; offer: IOfferSchema }) {
  if (!agent || getOfferType(offer) === FOfferType.FlatNew) {
    return false;
  }

  return agent.userType === 'realtor_based' || agent.userType === 'realtor_prof';
}

/* istanbul ignore next */
export function getAuthorLabelV2(offer: IOfferSchema, agent: IAgentSchema, isShowCountrysideBuilderBadge: boolean) {
  /**
   * @description В идеале вообще вся логика формирования должна быть перенесена на бекенд и мы должны использовать
   * это поле для отображения лейбла. Но пока такой возможности нет, поэтому используем его для отображения всех
   * реализованных кейсов. Разработчик, если ты зашёл сюда и тебе нужно добавить новый кейс, то скажи, пожалуйста,
   * бекендеру, что бы тот добавил логику на сервере. Спасибо большое!
   */
  if (agent.formattedPublisherType) {
    return agent.formattedPublisherType;
  }

  if (isShowCountrysideBuilderBadge) {
    return 'Застройщик';
  }

  if (isLeadFactory(offer)) {
    return 'Консультант';
  }

  if (getIsCoworkingByOffer(offer)) {
    return 'Оператор коворкингов';
  }

  if (isOfficialSeller(offer)) {
    return 'Представитель застройщика';
  }

  if (isOfficialRepresentative(offer)) {
    return 'Официальный представитель';
  }

  if (isAgency(agent)) {
    return 'Агентство недвижимости';
  }

  if (isManagementCompany(agent)) {
    return 'Управляющая компания';
  }

  if (isRentDepartment(agent)) {
    return 'Отдел аренды';
  }

  if (isCommerceRealtor({ agent, offer })) {
    return 'Риелтор';
  }

  return 'Автор объявления';
}
