import { actions } from './actions';
import { ISaveFormRequest } from '../../repositories/deal-rent-announcements/v1/save-form';

export interface IState {
  isOpen: boolean;
  isLoading: boolean;
  error: string | null;
  isSuccessModal: boolean;
  isSendSimilarModal: boolean;
  isSimilarSuccessModal: boolean;
  isFailureModal: boolean;
  isPrefetching: boolean;
  secondsToRetry: number;
  phoneConfirmed: boolean;
  isSendSMSLoading: boolean;
  initialFormState: ISaveFormRequest;
  isEntryToShowOpen: boolean;
  isEntryToShowSuccessModal: boolean;
}

const initialState: IState = {
  isOpen: false,
  isLoading: false,
  error: null,
  isSuccessModal: false,
  isSendSimilarModal: false,
  isSimilarSuccessModal: false,
  isFailureModal: false,
  isPrefetching: false,
  phoneConfirmed: false,
  isSendSMSLoading: false,
  secondsToRetry: 60,
  isEntryToShowOpen: false,
  isEntryToShowSuccessModal: false,
  initialFormState: {
    personsCount: 1,
    personsInfo: '',
    childrenCount: 0,
    childrenInfo: [],
    petsCount: 0,
    petsInfo: '',
    comment: '',
    activityScope: '',
    name: '',
    isAgent: false,
    phone: '',
    smsCode: '',
  },
};

export function reducer(state: IState = initialState, action: actions): IState {
  switch (action.type) {
    case 'deal-rent/open':
      return { ...state, isOpen: true, error: null };
    case 'deal-rent/close':
      return { ...state, isOpen: false, initialFormState: initialState.initialFormState };
    case 'deal-rent/openSendSimilar':
      return { ...state, isSendSimilarModal: true };
    case 'deal-rent/closeSendSimilar':
      return { ...state, isSendSimilarModal: false };
    case 'deal-rent/openSimilarSuccess':
      return { ...state, isSimilarSuccessModal: true };
    case 'deal-rent/closeSimilarSuccess':
      return { ...state, isSimilarSuccessModal: false };
    case 'deal-rent/openSuccess':
      return { ...state, isSuccessModal: true };
    case 'deal-rent/closeSuccess':
      return { ...state, isSuccessModal: false };
    case 'deal-rent/openFailure':
      return { ...state, isFailureModal: true };
    case 'deal-rent/closeFailure':
      return { ...state, isFailureModal: false };
    case 'deal-rent/saveFormStart':
      return { ...state, isLoading: true, error: null };
    case 'deal-rent/saveFormSuccess':
      return { ...state, isLoading: false };
    case 'deal-rent/saveFormError':
      return { ...state, isLoading: false, error: action.payload };
    case 'deal-rent/prefetchFormStateStart':
      return { ...state, isPrefetching: true, phoneConfirmed: false };
    case 'deal-rent/prefetchFormStateSuccess':
      return {
        ...state,
        isPrefetching: false,
        initialFormState: { ...state.initialFormState, ...action.payload.form },
        phoneConfirmed: action.payload.phoneConfirmed,
      };
    case 'deal-rent/prefetchFormStateError':
      return { ...state, isPrefetching: false };
    case 'deal-rent/sendSMSStart':
      return { ...state, isSendSMSLoading: true, phoneConfirmed: false, secondsToRetry: 60 };
    case 'deal-rent/sendSMSSuccess':
      return { ...state, secondsToRetry: action.payload, phoneConfirmed: false, isSendSMSLoading: false, error: null };
    case 'deal-rent/sendSMSAlreadyConfirmed':
      return { ...state, phoneConfirmed: true, isSendSMSLoading: false };
    case 'deal-rent/sendSMSError':
      return { ...state, isLoading: false, error: action.payload, phoneConfirmed: false, isSendSMSLoading: false };
    case 'deal-rent/changePhoneConfirmed':
      return { ...state, phoneConfirmed: action.payload };
    case 'deal-rent/selectEntryToShow':
      return { ...state, initialFormState: { ...state.initialFormState, showDate: action.payload } };
    case 'deal-rent/openEntryToShow':
      return { ...state, isEntryToShowOpen: true };
    case 'deal-rent/closeEntryToShow':
      return { ...state, isEntryToShowOpen: false };
    case 'deal-rent/openEntryToShowSuccessModal':
      return { ...state, isEntryToShowSuccessModal: true };
    case 'deal-rent/closeEntryToShowSuccessModal':
      return { ...state, isEntryToShowSuccessModal: false };
    default:
      return state;
  }
}
