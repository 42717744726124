import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  IGetHouseOfferHistorySpoilerRequest,
  IMappers,
} from '../../../../repositories/valuation-offer-history/v1/get-house-offer-history-spoiler';
import { EDealType, IOffersHistorySpoilerData } from '../../../../store/offerHistory';
import { prepareOffers } from '../../mappers';
import { prepareSpoilerCategoryFilters } from '../../mappers/prepareFilters';

export const createOffersHistorySpoilerMapper = (
  parameters: IGetHouseOfferHistorySpoilerRequest,
): IMappers<TMappedResponse200, TMappedResponse400> => ({
  200: response => ({
    ...response,
    offers: prepareOffers(response.offers, parameters.dealType as EDealType),
    filters: prepareSpoilerCategoryFilters(response.filters),
  }),
  400: ({ message }) => {
    return new HttpBadStatusCodeError({
      domain: 'fetchOffersHistorySpoiler.ts',
      statusCode: 400,
      message: message || /* istanbul ignore next */ '',
      details: { ...parameters },
    });
  },
});

export type TMappedResponse200 = IOffersHistorySpoilerData;
export type TMappedResponse400 = HttpBadStatusCodeError;

export type TMappedResponse = TMappedResponse200 | TMappedResponse400;
