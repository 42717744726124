// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetOfferCalendarRequest,
  TGetOfferCalendarModel,
  IMappers,
  TGetOfferCalendarResponse,
  IGetOfferCalendarResponse,
  IGetOfferCalendarResponseError,
} from './types';

export const defaultConfig: TGetOfferCalendarModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'dailyrent',
  pathApi: '/v1/get-offer-calendar/',
  requestType: 'json',
} as TGetOfferCalendarModel;

export function createGetOfferCalendarModel(parameters: IGetOfferCalendarRequest): TGetOfferCalendarModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetOfferCalendarOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetOfferCalendarRequest;
}

export async function fetchGetOfferCalendar<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetOfferCalendarOptions<TResponse200, TResponse400>): Promise<
  TGetOfferCalendarResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetOfferCalendarModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetOfferCalendarResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetOfferCalendarResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetOfferCalendarResponse;
}
