import { Microfrontend } from '@cian/mf-react';
import * as React from 'react';

import { useContext } from '../../utils/useContext';

export const SaveSearchModalContainer: React.FC = () => {
  const context = useContext();

  if (context.status !== 'succeed') {
    return null;
  }

  const { microfrontendRef, manifest, params } = context;

  return (
    <Microfrontend
      ref={microfrontendRef}
      name={manifest.microfrontendName}
      image={manifest.imageVersion}
      runtime={manifest.runtimeName}
      instance={manifest.instanceId}
      parameters={params}
    />
  );
};
