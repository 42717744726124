import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './ErrorContent.css';

interface IErrorContentProps {
  text?: string;
  onRetry(): void;
}

export const ErrorContent: React.FC<IErrorContentProps> = ({ text, onRetry }) => {
  return (
    <div data-testid="error-content" className={styles['container']}>
      <p className={styles['text']}>
        {text ||
          'Во время загрузки произошла ошибка. Пожалуйста, проверьте своё интернет соединение и попробуйте ещё раз.'}
      </p>
      <Button onClick={onRetry}>Повторить</Button>
    </div>
  );
};
