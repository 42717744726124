import { IOfferSchema, IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getBrand } from './getBrand';
import { getCategory } from './getCategory';
import { getCplPageExtra } from './getCplPageExtra';
import { getDealTypeSopr } from './getDealTypeSopr';
import { getHeadline } from './getHeadline';
import { getParentId } from './getParentId';
import { getVariants } from './getVariants';
import { ISoprProducts, TObjectType, TVariant } from './types';
import { IPageExtra } from '../../types';
import { IRosreestrInfo } from '../../types/offerData';
import { isHomeowner } from '../agent';

interface IGetProducts {
  agent: IAgentSchema | null;
  offer: IOfferSchema;
  kpId: number | null;
  withOutParent?: boolean;
  siteBlockId?: number | null;
  extra?: Partial<IPageExtra>;
  position?: number;
  rosreestrInfo?: IRosreestrInfo | null;
}

export function getProducts({
  agent,
  offer,
  kpId,
  withOutParent,
  siteBlockId,
  extra,
  position,
  rosreestrInfo,
}: IGetProducts): ISoprProducts {
  let product: ISoprProducts = {
    brand: getBrand(agent),
    cianId: offer.cianId,
    dealType: getDealTypeSopr(offer),
    headline: getHeadline(offer),
    hiddenBase: Boolean(offer.isInHiddenBase),
    id: offer.id,
    objectType: (getCategory(offer) as TObjectType) || 'flat_old,flat_new',
    owner: isHomeowner(offer),
    ownerCianId: agent && agent.cianUserId ? agent.cianUserId : undefined,
    ownerId: agent && agent.realtyUserId ? agent.realtyUserId : undefined,
    photosCount: offer.photos ? offer.photos.length : 0,
    podSnos: Boolean(offer.demolishedInMoscowProgramm),
    position: position || 1,
    price: offer.bargainTerms.prices.rur,
    published: offer.status === 'published',
    variant: getVariants(offer) as TVariant,
    consultant: offer.isDealRequestSubstitutionPhone,
    extra: extra || getCplPageExtra(offer, siteBlockId),
  };

  if (!withOutParent) {
    const parentId = getParentId(offer, kpId);

    if (parentId) {
      product = {
        ...product,
        extra: {
          ...product.extra,
          parentId,
        },
      };
    }
  }

  if (rosreestrInfo?.analyticsMeta) {
    product.extra = {
      ...product.extra,
      rosreestrData: rosreestrInfo.analyticsMeta,
    };
  }

  return product;
}
