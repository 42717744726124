import { TBuyAnalyticsReportLabel } from '../../utils/tracking/trackingBuyAnalyticsReportEvent';

export interface IBuyAnalyticsModalWidgetProps {
  isOpened: boolean;
  buildingName: string | null;
  buildingAddress: string | null;
  userEmail: string;
  reportPrice: string;
  status: EBuyAnalyticsFormStatus | null;
  freeReportData: IFreeReportDataProps;
  legalDocsUrl: string;
  isLegalDocsUrlEnabled: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
  onUpdatePaymentStatus: () => void;
  onTrackEvent: (label: TBuyAnalyticsReportLabel) => void;
}

export interface IFreeReportDataProps {
  name: string;
  date: string;
  url: string;
}

export enum EBuyAnalyticsFormStatus {
  /** Отправка формы **/
  Sending = 'sending',
  /** Отмена **/
  Canceled = 'canceled',
  /** Ошибка **/
  Error = 'error',
  /** Ошибка типа пользователя **/
  UserError = 'userError',
  /** Ожидает оплату **/
  Pending = 'pending',
  /** Успех **/
  Success = 'success',
}
