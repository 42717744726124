import { ca } from '@cian/analytics';
import { Store } from '@reduxjs/toolkit';

import { prepareEbcAction } from 'shared/actions/analytics';
import { selectTrackingUser } from 'shared/selectors';
import { IState } from 'shared/store';
import { updateUserData } from 'shared/store/user';

import { setAuthenticationCancelled, setAuthenticationSucceed } from '../../actions/authentication';
import { EUserEventType, TUserEvent } from '../types/user';

interface IEventHandlerParams {
  reduxStore: Store<IState>;
}

export function createAuthenticationEventHandler({ reduxStore }: IEventHandlerParams) {
  return async function authenticationEventHandler(event: TUserEvent) {
    if (typeof window === 'undefined') {
      return;
    }

    switch (event.type) {
      case EUserEventType.Authenticated: {
        await reduxStore.dispatch(updateUserData());
        reduxStore.dispatch(prepareEbcAction());

        ca('user', selectTrackingUser(reduxStore.getState()));

        if (window.__reloadHeader__) {
          window.__reloadHeader__();
        }

        reduxStore.dispatch(setAuthenticationSucceed());

        break;
      }
      case EUserEventType.AuthenticationModalClosed: {
        reduxStore.dispatch(setAuthenticationCancelled());

        break;
      }
    }
  };
}
