import { IState } from 'shared/store';

export const isPriceElectronicTradingSelector = (state: IState) => {
  const {
    offerData: { offer },
    knAuctionCompanies,
  } = state;

  return offer.cianUserId && knAuctionCompanies.includes(offer.cianUserId);
};
