import { selectOfferAgent, isNewbuildingFromDeveloperSelector } from './offerData';
import { IState } from '../store';
import { isDeveloperCanCallback } from '../utils/isDeveloperCanCallback';

export const getIsCallbackAvailable = (state: IState): boolean => {
  const isNewbuilding = isNewbuildingFromDeveloperSelector(state);
  const agent = selectOfferAgent(state);

  return isNewbuilding && isDeveloperCanCallback(agent);
};
