import { IPublicV1GetCurrentPriceResponseSchema } from 'shared/repositories/commercial-geo-analytics/entities/responses/PublicV1GetCurrentPriceResponseSchema';
import { IPublicV1GetPaymentUrlResponseSchema } from 'shared/repositories/commercial-geo-analytics/entities/responses/PublicV1GetPaymentUrlResponseSchema';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EBestPlaceAnalyticsActionTypes {
  SetReportPrices = 'bestPlaceAnalytics/setReportPrices',
  SetStatus = 'bestPlaceAnalytics/setStatus',
  SetStatusMessage = 'bestPlaceAnalytics/setStatusMessage',
  SetPaymentUrl = 'bestPlaceAnalytics/setPaymentUrl',
  SetUserEmail = 'bestPlaceAnalytics/setUserEmail',
}

export type TSetBestPlaceAnalyticsReportPrices = ITypedReduxAction<
  EBestPlaceAnalyticsActionTypes.SetReportPrices,
  IPublicV1GetCurrentPriceResponseSchema
>;

export const setBestPlaceAnalyticsReportPrices = actionGenerator<
  EBestPlaceAnalyticsActionTypes.SetReportPrices,
  IPublicV1GetCurrentPriceResponseSchema
>(EBestPlaceAnalyticsActionTypes.SetReportPrices);

export type TSetBestPlaceAnalyticsStatus = ITypedReduxAction<
  EBestPlaceAnalyticsActionTypes.SetStatus,
  EBestPlaceReportStatus | null
>;

export const setBestPlaceAnalyticsStatus = actionGenerator<
  EBestPlaceAnalyticsActionTypes.SetStatus,
  EBestPlaceReportStatus | null
>(EBestPlaceAnalyticsActionTypes.SetStatus);

export type TSetBestPlaceAnalyticsPaymentUrl = ITypedReduxAction<
  EBestPlaceAnalyticsActionTypes.SetPaymentUrl,
  IPublicV1GetPaymentUrlResponseSchema['paymentUrl']
>;

export const setBestPlaceAnalyticsPaymentUrl = actionGenerator<
  EBestPlaceAnalyticsActionTypes.SetPaymentUrl,
  IPublicV1GetPaymentUrlResponseSchema['paymentUrl']
>(EBestPlaceAnalyticsActionTypes.SetPaymentUrl);

export type TSetBestPlaceAnalyticsStatusMessage = ITypedReduxAction<
  EBestPlaceAnalyticsActionTypes.SetStatusMessage,
  string | null
>;

export const setBestPlaceAnalyticsStatusMessage = actionGenerator<
  EBestPlaceAnalyticsActionTypes.SetStatusMessage,
  string | null
>(EBestPlaceAnalyticsActionTypes.SetStatusMessage);

export type TSetBestPlaceAnalyticsUserEmail = ITypedReduxAction<
  EBestPlaceAnalyticsActionTypes.SetUserEmail,
  string | null
>;

export const setBestPlaceAnalyticsUserEmail = actionGenerator<
  EBestPlaceAnalyticsActionTypes.SetUserEmail,
  string | null
>(EBestPlaceAnalyticsActionTypes.SetUserEmail);

export type TBestPlaceAnalyticsActions =
  | TSetBestPlaceAnalyticsReportPrices
  | TSetBestPlaceAnalyticsStatus
  | TSetBestPlaceAnalyticsStatusMessage
  | TSetBestPlaceAnalyticsPaymentUrl
  | TSetBestPlaceAnalyticsUserEmail;
