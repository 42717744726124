import { sendReclaim } from '../../api/reclaim/reclaim_popup';
import { IThunkActionCreator } from '../index';

export type TStatusFetch = 'none' | 'isLoad' | 'error' | 'ok';

export interface IReclaimModalState {
  isOpen: boolean;
  errorMessage: string;
  statusFetch: TStatusFetch;
}

export interface IReclaimModalOpen {
  type: 'IReclaimModalOpen';
}

export interface IReclaimModalClose {
  type: 'IReclaimModalClose';
}

export interface IReclaimModalSendConfirm {
  type: 'IReclaimModalSendConfirm';
  payload: {
    errorMessage: string;
    statusFetch: TStatusFetch;
  };
}

export interface IReclaimModalSuccessResponse {
  type: 'IReclaimModalSuccessResponse';
  payload: {
    errorMessage: string;
    statusFetch: TStatusFetch;
  };
}

export interface IReclaimModalErrorResponse {
  type: 'IReclaimModalErrorResponse';
  payload: {
    errorMessage: string;
    statusFetch: TStatusFetch;
  };
}

export function openModal(): IReclaimModalOpen {
  return {
    type: 'IReclaimModalOpen',
  };
}

export function closeModal(): IReclaimModalClose {
  return {
    type: 'IReclaimModalClose',
  };
}

export function submitReclaimConfirm(host: string, realtyOfferId: number): IThunkActionCreator {
  return (dispatch, getState, context) => {
    dispatch({
      payload: {
        errorMessage: '',
        statusFetch: 'isLoad',
      },
      type: 'IReclaimModalSendConfirm',
    });

    sendReclaim(context.custom.makeRequest, host, realtyOfferId)
      .then(() => {
        dispatch({
          payload: {
            errorMessage: '',
            statusFetch: 'ok',
          },
          type: 'IReclaimModalSuccessResponse',
        });
      })
      .catch(() => {
        dispatch({
          payload: {
            errorMessage: 'Сервер временно недоступен, попробуйте повторить попытку позже.',
            statusFetch: 'error',
          },
          type: 'IReclaimModalErrorResponse',
        });
      });
  };
}

export type TReclaimModalActions =
  | IReclaimModalOpen
  | IReclaimModalClose
  | IReclaimModalSendConfirm
  | IReclaimModalSuccessResponse
  | IReclaimModalErrorResponse;

const initialState: IReclaimModalState = {
  errorMessage: '',
  isOpen: false,
  statusFetch: 'none' as const,
};

export function reclaimModalReducer(
  state: IReclaimModalState = initialState,
  action: TReclaimModalActions,
): IReclaimModalState {
  switch (action.type) {
    case 'IReclaimModalOpen':
      return {
        ...state,
        isOpen: true,
      };

    case 'IReclaimModalClose':
      return {
        ...state,
        errorMessage: '',
        isOpen: false,
        statusFetch: 'none',
      };

    case 'IReclaimModalSendConfirm':
    case 'IReclaimModalSuccessResponse':
    case 'IReclaimModalErrorResponse':
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        statusFetch: action.payload.statusFetch,
      };

    default:
      return state;
  }
}
