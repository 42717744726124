export interface INewbuildingBookedFromDeveloperState {
  isConfirmOpen: boolean;
  status: EBookingFromDeveloperStatus;
}

export enum EBookingFromDeveloperStatus {
  Failure = 'failure',
  Initial = 'initial',
  Loading = 'loading',
  Success = 'success',
}

export interface IBookedFromDeveloperDetail {
  label: string;
  text: string;
}
