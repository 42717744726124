// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArrayWithItems<T>(value: any): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyArray<T>(value: any): value is T[] {
  return Array.isArray(value) && value.length === 0;
}

export function getLastArrayItem<T>(arr: T[]): T | undefined {
  if (arr.length > 0) {
    return arr[arr.length - 1];
  }

  return undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDefined<T>(value: any): value is T {
  return typeof value !== 'undefined' && value !== null;
}

export function unique<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}
