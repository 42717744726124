// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IOfferHistoryLocationFilterItemSchema {
  /** Человекочитаемое название для фильтра (В этом доме/В соседних) **/
  name: string;
  /** Значение для фильтра (same_house/nearby_houses) **/
  value: EValue;
}
export enum EValue {
  /** В соседних домах **/
  'NearbyHouses' = 'nearbyHouses',
  /** В этом доме **/
  'SameHouse' = 'sameHouse',
}
