import { TAdfoxRequestedOffer } from '../../../types/adfox';
import { EActionTypes, TAdfoxParamsActions } from '../actions/actions';

export function adfoxOfferReducer(
  state: TAdfoxRequestedOffer | null = null,
  action: TAdfoxParamsActions,
): TAdfoxRequestedOffer | null {
  switch (action.type) {
    case EActionTypes.SetData:
      return action.payload;

    default:
      return state;
  }
}
