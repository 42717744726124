import { IAdfoxOfferResponse200 } from '../../../repositories/monolith-python/v1/adfox-offer';
import { actionGenerator } from '../../../utils/redux/actionGenerator';

export enum EActionTypes {
  SetData = 'adfoxParams/set-data',
}

export const setDataAction = actionGenerator<EActionTypes.SetData, IAdfoxOfferResponse200 | null>(EActionTypes.SetData);

export type TAdfoxParamsActions = ReturnType<typeof setDataAction>;
