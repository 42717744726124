import { pathOr } from 'ramda';

import { IPromoInfoSchema } from '../../repositories/offer-card/entities/promo_info/PromoInfoSchema';
import { IState } from '../../store';
import { IPromoInfo } from '../../types/newbuildingPromos';

export const getPromoInfos = (state: IState): IPromoInfo[] => {
  const promoInfos: IPromoInfoSchema[] = pathOr([], ['offerData', 'newObject', 'promoInfos'], state);

  /* TODO: убрать данную манипуляцию, когда поле появится в api */
  /* istanbul ignore next */
  return promoInfos.map(promo => ({
    ...promo,
    uniquePromoId: promo.uniquePromoId || `${promo.promoId}_${promo.promoType}`,
  }));
};
