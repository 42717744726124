import { EAuthenticationActionType, TAuthenticationActions } from '../../actions/authentication';
import { EBuyAnalyticsModalActionTypes, TBuyAnalyticsModalActions } from '../../actions/buyAnalyticsModal';
import { IBuyAnalyticsModalState } from '../../types/buyAnalyticsModal';

export const DEFAULT_STATE: IBuyAnalyticsModalState = {
  isOpened: false,
  reportPrice: 0,
  property: null,
  status: null,
  paymentUrl: null,
  transactionId: null,
  userEmail: '',
  isReportAvailable: false,
  openReportAfterAuth: false,
};

export const buyAnalyticsModalReducer = (
  state: IBuyAnalyticsModalState = DEFAULT_STATE,
  action: TBuyAnalyticsModalActions | TAuthenticationActions,
): IBuyAnalyticsModalState => {
  switch (action.type) {
    case EBuyAnalyticsModalActionTypes.SetModalVisibility:
      return {
        ...state,
        isOpened: action.payload,
      };

    case EBuyAnalyticsModalActionTypes.SetReportAvailability:
      return {
        ...state,
        property: action.payload.property,
        reportPrice: action.payload.reportPrice,
        isReportAvailable: action.payload.isReportAvailable,
      };

    case EBuyAnalyticsModalActionTypes.SetStatus:
      return {
        ...state,
        status: action.payload,
      };

    case EBuyAnalyticsModalActionTypes.SetPaymentStatusData:
      return {
        ...state,
        status: action.payload.status,
        paymentUrl: action.payload.paymentUrl,
        property: {
          ...state.property,
          name: action.payload.propertyName,
          address: action.payload.propertyAddress,
        },
        userEmail: action.payload.userEmail,
      };

    case EBuyAnalyticsModalActionTypes.SetPaymentTransactionId:
      return {
        ...state,
        transactionId: action.payload,
        isOpened: true,
      };

    case EBuyAnalyticsModalActionTypes.SetModalVisibilityAfterAuth:
      return {
        ...state,
        openReportAfterAuth: true,
      };

    case EAuthenticationActionType.Succeed: {
      if (!state.openReportAfterAuth) {
        return state;
      }

      return {
        ...state,
        isOpened: true,
        openReportAfterAuth: false,
      };
    }

    case EAuthenticationActionType.Cancelled:
      return {
        ...state,
        openReportAfterAuth: false,
      };

    default:
      return state;
  }
};
