import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { isFromBuilder, isFromBuilderPresenter } from '../agent';
import { FOfferType, getOfferType } from '../offer_type';
import { isSuburbanOfferFromBuilder } from '../suburban';

export const getCategory = (offer: IOfferSchema): string | undefined => {
  const offerType = getOfferType(offer);

  let offerTypeStr;
  if (offerType === FOfferType.FlatOld || offerType === FOfferType.Room) {
    offerTypeStr = 'flat_old';
  } else if (offerType === FOfferType.FlatNew && (isFromBuilder(offer) || isFromBuilderPresenter(offer))) {
    offerTypeStr = 'flat_new_dev';
  } else if (offerType === FOfferType.FlatNew) {
    offerTypeStr = 'flat_new';
  } else if (offerType & FOfferType.Commercial) {
    offerTypeStr = 'commercial';
  } else if (offerType & FOfferType.Suburban && isSuburbanOfferFromBuilder(offer)) {
    offerTypeStr = 'suburban_dev';
  } else if (offerType & FOfferType.Suburban) {
    offerTypeStr = 'suburban';
  }

  return offerTypeStr;
};
