/* eslint-disable @typescript-eslint/no-explicit-any */

import { IUserTrackingData, ITrackingPageData } from '../../types';

export interface ITrackingReducerState {
  user: IUserTrackingData;
  page: ITrackingPageData;
}

export interface ITrackingReducerAction {
  type: ETrackingReducerActions;
  payload: {
    // Неизвестно, какими будут данные
    // tslint:disable-next-line
    [key: string]: any;
  };
}

export enum ETrackingReducerActions {
  SetTrackingState = 'TrackingReducer / SetTrackingState',
}
