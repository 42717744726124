import { IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IUserTrackingData } from '../../types';

interface IParams {
  abGroup: number;
  user?: IUserSchema | null;
  sendUxfbFlag?: boolean;
}

export function createUserTrackingData({ abGroup, user, sendUxfbFlag }: IParams): IUserTrackingData {
  if (!user) {
    return notAutorizedUserData(abGroup);
  }

  const { cianUserId, email, ga, realtyUserId: userId } = user;

  const type = (ga && ga.type) || 'not_authorized';

  const extra = sendUxfbFlag ? { uxfb_card_satisfaction: true } : undefined;

  return {
    abGroup,
    cianUserId,
    email,
    type,
    userId,
    extra,
  };
}

function notAutorizedUserData(abGroup: number) {
  return { abGroup, type: 'not_authorized' };
}
