import { EActionType } from 'shared/types/dailyrentActionButtonsType';
import { IOfferData } from 'shared/types/offerData';

import { ITypedReduxAction } from '../../types/redux';

export enum EDailyrentActionTypeTypes {
  SetActionType = 'dailyrentActionType/setActionType',
  SetActionTypeFromOffer = 'dailyrentActionType/setActionTypeFromOffer',
}

export type TTSetActionType = ITypedReduxAction<EDailyrentActionTypeTypes.SetActionType, EActionType>;
export type TTSetActionTypeFromOffer = ITypedReduxAction<EDailyrentActionTypeTypes.SetActionTypeFromOffer, IOfferData>;

export type TDailyrentActionTypeActions = TTSetActionType | TTSetActionTypeFromOffer;
