import { ESimilarOffersActionType, ISimilarOffersReceivedAction } from './types';
import { IThunkActionCreator } from '../../index';
import { ISimilarOffersReceivedData, TModelType } from '../types';

export function similarOffersReceived(data: ISimilarOffersReceivedData, modelType: TModelType): IThunkActionCreator {
  // tslint:disable-next-line:variable-name
  return dispatch => {
    const { offers, modelVersion, withoutCompetitors } = data;
    dispatch<ISimilarOffersReceivedAction>({
      type: ESimilarOffersActionType.ISimilarOffersReceived,
      list: offers,
      withoutCompetitors: withoutCompetitors || null,
      modelVersion: modelVersion || null,
      modelType,
    });
  };
}
