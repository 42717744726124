import { IState } from '../../../store';

export function isInRegionSelector(regions: number[]) {
  return (state: IState) => {
    const address = state.offerData.offer.geo.address;

    return address.some(({ type, id }) => {
      return type === 'location' && regions.includes(id);
    });
  };
}
