import { EBrandingLevel } from 'node/repositories/offer-card/entities/all_offer_data/AllOfferDataSchema';
import { IDailyrentAvailabilitySchema } from 'node/repositories/offer-card/entities/dailyrent_availability/DailyrentAvailabilitySchema';
import { IExtendedDataSchema } from 'node/repositories/offer-card/entities/extended_data/ExtendedDataSchema';
import { IToursSchema } from 'node/repositories/offer-card/entities/tour/ToursSchema';

import { IAmenitiesData } from 'shared/types/amenities';
import {
  IGetOfferDataResponseSchema,
  IOfferSchema as DEPRECATED__IOfferSchema,
  IAgentSchema,
  IPhotoSchema,
  TCategory,
  IBargainTermsSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { ICoworkingAmenitiesData } from 'shared/types/coworkingAmenities';

import { IAreaPart } from './areaParts';
import { IFactoidsData } from './factoid';
import { IFeaturesData } from './features';
import { IGeo } from './geo/geo';
import { INewBuilding } from './newbuilding';
import { ICompanyWithFeatures } from './newbuildingCompany';
import { INewBuildingWithFeatures } from './newobject/newobject';
import { IOfferDiscount } from './offerDiscount';
import { IOffersComparisonStatus } from './offersComparisonStatus';
import { IRosreestrDataV2 } from './rosreestrDataV2';
import { ISidebarData } from './sidebar';
import { IAgentMetricSchema } from '../../node/repositories/offer-card/entities/agent/AgentMetricSchema';
import { IBtiDataSchema } from '../../node/repositories/offer-card/entities/bti_data/BtiDataSchema';
import { IModerationInfoSchema } from '../../node/repositories/offer-card/entities/moderation_info/ModerationInfoSchema';
import { IGetOfferDataResponse } from '../../node/repositories/offer-card/v1/get-offer-data';
import { IAgentModerationInfoSchema } from '../repositories/offer-card/entities/agent/AgentModerationInfoSchema';
import { IOfferSchema } from '../repositories/offer-card/entities/offer/OfferSchema';

// coworkingRent - это computed категория, так как коворкинги это подтип officeRent
export type TCategoryExtended = TCategory | 'coworkingRent' | 'decoration';

export interface IBtiData {
  bti?: IBtiDataSchema | null;
}

export interface IFolderData {
  offerFoldersCount: number;
  removedFromFavorites: boolean;
}

enum EDealRentMonetization {
  /** Комиссию платит собственник **/
  FeeByHomeowner = 'feeByHomeowner',
  /** Комиссию платит арендатор **/
  FeeByRenter = 'feeByRenter',
  FeeIncludedInOfferPrice = 'feeIncludedInOfferPrice',
}

type IOfferSchemaForExtend = Omit<DEPRECATED__IOfferSchema, 'geo' | 'areaParts'>;

export interface IOffer extends IOfferSchemaForExtend {
  newbuilding: INewBuilding;
  cianLayouts: IPhotoSchema[] | null;
  moderationInfo?: IModerationInfoSchema | null;
  dealRentMonetization?: EDealRentMonetization | null;
  isDealRentOffline?: boolean | null;
  isUnique?: boolean | null;
  isVerifiedByCian?: boolean | null;
  /**
   * @todo Удалить строку с экспериментом
   * @description Данный функционал появился в задаче CD-128336, будет удалён в задаче CD-128344
   * Если эксперимент будет удачным, то оставить строку, но удалить комментарий,
   * если эксперимент будет не удачным, то удалить полностью строку
   */
  hasFairPrice?: boolean;
  timezone?: number | null;
  villageMortgageAllowed?: boolean | null;
  bargainTerms: IBargainTerms;
  geo: IGeo;
  areaParts?: IAreaPart[];
  externalOfferUrl: string;
  isCianPartner: boolean;
  dailyrent?: IExtendedDataSchema | null;
  dailyrentAvailability?: IDailyrentAvailabilitySchema;
  /** Признак уникальности объявления Коммерческой **/
  isUniqueForCian?: IOfferSchema['isUniqueForCian'];
  /** Признак наличия отчета от Best Place для объявки (коммерческая) **/
  bestPlaceAnalyticsAvailable: boolean | null;
  /** Объект с незаконным строительством **/
  isIllegalConstruction?: boolean;
  /** Признак возможности подписания Онлайн-договора аренды **/
  isOnlineRentalAgreementEnabled?: boolean;
  isSubsidisedMortgage?: boolean;
  isSoldFurnished?: boolean;
}

export interface IBargainTerms extends IBargainTermsSchema {
  tags?: string[];
  deposit?: number | null;
}

export interface IAgent extends IAgentSchema {
  moderationInfo?: IAgentModerationInfoSchema | null;
  metrics?: IAgentMetricSchema[] | null;
  agentLists?: string[] | null;
}

export interface IRosreestrElement {
  title: string;
  value: string;
}

export interface IRosreestrPart {
  header: string;
  elements: IRosreestrElement[];
}

export interface IRosreestrAnalyticsData {
  status: number;
  updateDate: string;
  ownersNum: number;
  buildingType: string;
  landCategory: string;
  encumbrances: boolean;
}

export interface ICadastralMap {
  /** Расширенное превью для кадастровой карты 1000x1000 **/
  fullUrl?: string | null;
  /** Превью для кадастровой карты 512x512 **/
  previewUrl: string;
}

export interface IRosreestrInfo {
  deck: string;
  header: string;
  parts: IRosreestrPart[];
  analyticsMeta: IRosreestrAnalyticsData;
  cadastralMapPreview?: ICadastralMap | null;
  bottom?: string;
}

export interface IRosreestrData {
  rosreestrInfo: IRosreestrInfo | null;
}

export interface IExternalOfferUrl {
  externalOfferUrl?: string | null;
}

export type IOfferData = Omit<IGetOfferDataResponseSchema, 'housePhotos' | 'newObject' | 'bti'> &
  Pick<
    IGetOfferDataResponse,
    | 'amenities'
    | 'coworkingIncludedAmenities'
    | 'decorationInfo'
    | 'housingDecorations'
    | 'tourV2'
    | 'houseCardEntrypointData'
    | 'newbuildingFlatViewOrder'
    | 'priceInfo'
    | 'floorPlan'
    | 'priceRelevance'
    | 'isOnlineRegistrationAvailable'
    | 'isBookedFromDeveloper'
    | 'factoids'
    | 'features'
    | 'formattedAreaParts'
    | 'sidebar'
    | 'newbuildingReliability'
    | 'newbuildingFlatSaleQueryString'
  > & {
    company: ICompanyWithFeatures | null;
    offer: IOffer;
    newObject: INewBuildingWithFeatures | null;
    offersComparisonStatus: IOffersComparisonStatus | null;
    agent: IAgent | null;
    brandingLevel?: EBrandingLevel | null;
    trackingUrl: string;
    tours: IToursSchema;
  } & IBtiData &
  IFolderData &
  IRosreestrData &
  IFactoidsData &
  IFeaturesData &
  IAmenitiesData &
  ICoworkingAmenitiesData &
  ISidebarData & { discount?: IOfferDiscount } & IRosreestrDataV2;

export type IValidPhone = {
  countryCode: string;
  number: string;
};

export interface ISpecialProjectItemSchema {
  id: string;
  link: string;
}

export { EBrandingLevel };

export type TFeatureTheme =
  | 'toilet'
  | 'fridge'
  | 'dishwasher'
  | 'washing'
  | 'cond'
  | 'tv'
  | 'internet'
  | 'bath'
  | 'shower'
  | 'kitchen'
  | 'furniture'
  | 'banya'
  | 'garage'
  | 'pool'
  | 'car-wash'
  | 'car-service'
  | 'pharmacy'
  | 'atm'
  | 'buffet'
  | 'exhibition-warehouse-complex'
  | 'hotel'
  | 'game-room'
  | 'cafe'
  | 'cinema'
  | 'conference-room'
  | 'medical-center'
  | 'market'
  | 'notary-office'
  | 'bank-department'
  | 'office-space'
  | 'park'
  | 'beauty-shop'
  | 'warehouse'
  | 'fitness-centre'
  | 'photo-studio'
  | 'central-reception'
  | 'clothes-studio'
  | 'baby-sitting'
  | 'bowling'
  | 'video-surveillance'
  | 'entry-by-pass'
  | 'slot-machines'
  | 'rink'
  | 'secure'
  | 'basement'
  | 'inspection-pit'
  | 'tire'
  | 'automatic-gates'
  | 'aquapark';

export interface IFeatureItem {
  theme?: string;
  name: string;
  value?: string;
}

export enum EStatusOffer {
  /** Заблокировано **/
  'Blocked' = 'blocked',
  /** Деактивировано **/
  'Deactivated' = 'deactivated',
  /** Удалено **/
  'Deleted' = 'deleted',
  /** Черновик **/
  'Draft' = 'draft',
  /** Промодерировано **/
  'Moderate' = 'moderate',
  /** Опубликовано **/
  'Published' = 'published',
  /** Отклонено **/
  'Refused' = 'refused',
  /** Удалено модератором **/
  'RemovedByModerator' = 'removedByModerator',
  /** Продано **/
  'Sold' = 'sold',
}
