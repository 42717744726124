/* istanbul ignore next */
import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export const getBrand = (agent: IAgentSchema | null): string => {
  if (agent && agent.realtyUserId && agent.userType) {
    return `${agent.realtyUserId}/${agent.userType}`;
  }

  return '';
};
