import { EGetMyHomeObjectsExists, TValuationTrapsActions } from 'shared/actions/valuationTraps';
import { IValuationTrapsState } from 'shared/store/valuationTraps';

const initialState: IValuationTrapsState = {
  isMyHomeObjectsExists: false,
};

export const valuationTrapsReducer = (
  state: IValuationTrapsState = initialState,
  action: TValuationTrapsActions,
): IValuationTrapsState => {
  switch (action.type) {
    case EGetMyHomeObjectsExists.setMyHomeExists:
      return {
        ...state,
        isMyHomeObjectsExists: action.payload,
      };

    default:
      return state;
  }
};
