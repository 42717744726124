import { parseURI, mergeBaseUri, mergePaths } from '@cian/http-api/shared';

import { filterServerResponse } from './helpers';
import { IRequestToPythonOfferCard, IServerError } from './types';

export interface IMoveOfferToArchiveOptions {
  dealType: 'sale' | 'rent';
  offerId: number;
  offerType: 'flat' | 'suburban' | 'commercial';
  subdomain: string;
}

export function moveOfferToArchive(
  requestOptions: IRequestToPythonOfferCard,
  offerOptions: IMoveOfferToArchiveOptions,
): Promise<{}> {
  const parsedURI = parseURI(requestOptions.pythonOfferCardPublicApi);

  return requestOptions
    .makeRequest({
      body: JSON.stringify(offerOptions),
      method: 'POST',
      withCredentials: true,
      uri: mergeBaseUri(requestOptions.pythonOfferCardPublicApi, {
        path: mergePaths(parsedURI.path, '/v1/archive-offer/'),
      }),
    })
    .then(filterServerResponse)
    .then(response => {
      if (response.responseStatusCode === 204) {
        return {
          status: 'ok',
        };
      }

      throw JSON.parse(response.requestBody) as IServerError;
    });
}
