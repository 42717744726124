// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ICheckPriceRequest,
  TCheckPriceModel,
  IMappers,
  TCheckPriceResponse,
  ICheckPriceResponse,
  ICheckPriceResponseError,
} from './types';

export const defaultConfig: TCheckPriceModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'dailyrent',
  pathApi: '/v1/check-price/',
  requestType: 'json',
} as TCheckPriceModel;

export function createCheckPriceModel(parameters: ICheckPriceRequest): TCheckPriceModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICheckPriceOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICheckPriceRequest;
}

export async function fetchCheckPrice<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICheckPriceOptions<TResponse200, TResponse400>): Promise<TCheckPriceResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createCheckPriceModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICheckPriceResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICheckPriceResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCheckPriceResponse;
}
