import { EReviewsGoToAppModalActionTypes, TReviewsGoToAppModalActions } from 'shared/actions/reviewsGoToAppModal';
import { IReviewsGoToAppModalState } from 'shared/types/reviewsGoToAppModal';

const DEFAULT_STATE: IReviewsGoToAppModalState = {
  isOpen: false,
};

export const reviewsGoToAppModalReducer = (
  state: IReviewsGoToAppModalState = DEFAULT_STATE,
  action: TReviewsGoToAppModalActions,
): IReviewsGoToAppModalState => {
  switch (action.type) {
    case EReviewsGoToAppModalActionTypes.Close:
      return {
        ...state,
        isOpen: false,
      };

    case EReviewsGoToAppModalActionTypes.Open:
      return {
        ...state,
        isOpen: true,
      };

    default:
      return state;
  }
};
