import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { isNil } from 'ramda';

import { IOfferSchema, IUtilitiesTermsSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { CurrencySymbol, withNoBreakSpaces } from './formatters';
import { IUtilitiesTermsSettings } from '../store';

/**
 * Для аренды жилой выводим общую стоимость - Цена аренды + коммунальные платежи, если указали
 *
 * Eсли регион объявления входит в excludedUtilitiesTermsRegions
 * то коммунальные платежи не учитываются в общей стоимости
 */
export function getRentPrice(
  offer: IOfferSchema,
  utilitiesTermsSettings: IUtilitiesTermsSettings,
  postfix: string = CurrencySymbol.Rur,
): string {
  const { prices, utilitiesTerms } = offer.bargainTerms;
  if (!utilitiesTermsSettings.isUtilitiesTermsEnabled) {
    return `${numberToPrettyString(prices.rur)} ${postfix}`;
  }
  const offerRegionId = offer.geo.address[0].id;
  if (utilitiesTerms && !utilitiesTerms.includedInPrice && utilitiesTerms.price > 0) {
    const totalPrice = prices.rur + utilitiesTerms.price;

    if (utilitiesTermsSettings.useIncludeUtilitiesTermsRegions) {
      if (utilitiesTermsSettings.includeUtilitiesTermsRegions.includes(offerRegionId)) {
        return `${numberToPrettyString(totalPrice)} ${postfix}`;
      }
    } else if (!utilitiesTermsSettings.excludeUtilitiesTermsRegions.includes(offerRegionId)) {
      return `${numberToPrettyString(totalPrice)} ${postfix}`;
    }
  }

  return `${numberToPrettyString(prices.rur)} ${postfix}`;
}

export function getMoreRentPrice(offer: IOfferSchema): string | null {
  const { utilitiesTerms, utilitiesTermsPrices } = offer.bargainTerms;

  /**
   * Если utilitiesTerms c бекенда равно null, значит ком. платежи включены,
   * так исторически сложилось.
   */
  if (isNil(utilitiesTerms)) {
    return 'Комм. платежи включены (без\u00a0счётчиков)';
  }

  if (utilitiesTerms) {
    if (utilitiesTerms.includedInPrice) {
      return `Комм. платежи включены ${getFlowMetersInfo(utilitiesTerms)}`;
    }

    if (!utilitiesTerms.includedInPrice && utilitiesTerms.price === 0) {
      return 'Комм. платежи не включены';
    }

    if (!utilitiesTerms.includedInPrice && utilitiesTerms.price > 0) {
      const priceRent = withNoBreakSpaces(
        `${numberToPrettyString(offer.bargainTerms.prices.rur)} ${CurrencySymbol.Rur}`,
      );
      const priceUtilities = withNoBreakSpaces(
        `${numberToPrettyString(utilitiesTermsPrices.rur)} ${CurrencySymbol.Rur}`,
      );

      return `${priceRent} + ${priceUtilities} комм. платежи ${getFlowMetersInfo(utilitiesTerms)}`;
    }
  }

  return null;
}

export function getFlowMetersInfo(utilitiesTerms: IUtilitiesTermsSchema) {
  if (utilitiesTerms.flowMetersNotIncludedInPrice === false) {
    return withNoBreakSpaces('(счётчики включены)');
  }

  return withNoBreakSpaces('(без счётчиков)');
}
