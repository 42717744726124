import { numberToPrettyString } from '@cian/utils';

import { EBuyAnalyticsFormStatus } from '../../components/BuyAnalyticsModal';
import { IState } from '../../store';
import { isFakeEmail } from '../../utils/validate';
import { selectBuyAnalyticsButtonEnabled } from '../featureToggle';
import { selectUserEmail } from '../offerData';

export const selectBuyAnalyticsReportPrice = (state: IState): string => {
  return `${numberToPrettyString(state.buyAnalyticsModal.reportPrice || 0)} ₽`;
};

export const selectIsBuyAnalyticsModalOpened = (state: IState): boolean => {
  return state.buyAnalyticsModal.isOpened;
};

export const selectIsBuyAnalyticsModalFormSending = (state: IState): boolean => {
  return state.buyAnalyticsModal.status === EBuyAnalyticsFormStatus.Sending;
};

export const selectIsBuyAnalyticsModalStatusPending = (state: IState): boolean => {
  return state.buyAnalyticsModal.status === EBuyAnalyticsFormStatus.Pending;
};

export const selectBuyAnalyticsPropertyId = (state: IState): number | null => {
  return state.buyAnalyticsModal.property?.id || null;
};

export const selectBuyAnalyticsPropertyName = (state: IState): string | null => {
  return state.buyAnalyticsModal.property?.name || null;
};

export const selectBuyAnalyticsPropertyAddress = (state: IState): string | null => {
  return state.buyAnalyticsModal.property?.address || null;
};

export const selectBuyAnalyticsModalStatus = (state: IState): EBuyAnalyticsFormStatus | null => {
  return state.buyAnalyticsModal.status;
};

export const selectFreeReportData = (state: IState) => {
  return state.rs.buyAnalyticsFreeReportData;
};

export const selectLegalDocsUrl = (state: IState) => {
  return state.rs.buyAnalyticsLegalDocsUrl;
};

export const selectBuyAnalyticsPaymentTransactionId = (state: IState): string | null => {
  return state.buyAnalyticsModal.transactionId;
};

export const selectBuyAnalyticsUserEmail = (state: IState): string => {
  const userEmail = selectUserEmail(state);

  return !isFakeEmail(userEmail) ? userEmail : '';
};

export const selectBuyAnalyticsPaymentEmail = (state: IState): string => {
  const transactionId = selectBuyAnalyticsPaymentTransactionId(state);
  const userEmail = selectBuyAnalyticsUserEmail(state);

  return transactionId ? state.buyAnalyticsModal.userEmail : userEmail;
};

export const selectBuyAnalyticsReportAvailability = (state: IState) => {
  /**
   * @todo Удалить проверку фичатоггла
   * @description Данный функционал появился в задаче CD-132044, будет удалён в задаче CD-131583
   */
  const isReportButtonEnabled = selectBuyAnalyticsButtonEnabled(state);

  return isReportButtonEnabled && state.buyAnalyticsModal.isReportAvailable;
};
