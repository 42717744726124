export type TMapTab = 'default' | 'panorama' | 'similarOffers' | 'routeMap';
export const MapTab = {
  default: 'default' as const,
  panorama: 'panorama' as const,
  similarOffers: 'similarOffers' as const,
};

export interface IMapTabs {
  activeTab: TMapTab;
}

export interface IMapTabChanged {
  newActiveTab: TMapTab;
  type: 'IMapTabChanged';
}

export function changeTab(newActiveTab: TMapTab): IMapTabChanged {
  return {
    newActiveTab,
    type: 'IMapTabChanged',
  };
}

const defaultState = {
  activeTab: MapTab.similarOffers,
};

export function mapTabsReducer(state: IMapTabs, action: IMapTabChanged): IMapTabs {
  switch (action.type) {
    case 'IMapTabChanged':
      return { ...state, activeTab: action.newActiveTab };

    default:
      return state || defaultState;
  }
}
