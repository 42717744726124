import {
  IAddOfferToComparisonResponse,
  IAddOfferToComparisonResponse200,
} from '../../../../repositories/offers-comparison/v2/add-offer-to-comparison';

export const mapDeleteOfferFromComparisonResponse = (
  response: IAddOfferToComparisonResponse,
): IAddOfferToComparisonResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};
