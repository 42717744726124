import { EBuyAnalyticsFormStatus } from '../../components/BuyAnalyticsModal';
import { IBuyAnalyticsProperty } from '../../types/buyAnalyticsModal';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EBuyAnalyticsModalActionTypes {
  SetStatus = 'buyAnalyticsModal/setStatus',
  SetModalVisibility = 'buyAnalyticsModal/setModalVisibility',
  SetReportAvailability = 'buyAnalyticsModal/setReportAvailability',
  SetPaymentStatusData = 'buyAnalyticsModal/setPaymentStatusData',
  SetPaymentTransactionId = 'buyAnalyticsModal/setPaymentTransactionId',
  SetModalVisibilityAfterAuth = 'buyAnalyticsModal/setModalVisibilityAfterAuth',
}

export interface IBuyAnalyticsAvailabilityData {
  reportPrice: number;
  isReportAvailable: boolean;
  property: IBuyAnalyticsProperty;
}

export interface IBuyAnalyticsPaymentStatusData {
  status: EBuyAnalyticsFormStatus;
  propertyName: string;
  propertyAddress: string;
  paymentUrl: string;
  userEmail: string;
}

export type TSetBuyAnalyticsModalVisibility = ITypedReduxAction<
  EBuyAnalyticsModalActionTypes.SetModalVisibility,
  boolean
>;

export type TSetBuyAnalyticsModalVisibilityAfterAuth =
  ITypedReduxAction<EBuyAnalyticsModalActionTypes.SetModalVisibilityAfterAuth>;

export type TSetBuyAnalyticsReportAvailability = ITypedReduxAction<
  EBuyAnalyticsModalActionTypes.SetReportAvailability,
  IBuyAnalyticsAvailabilityData
>;

export type TSetBuyAnalyticsModalStatus = ITypedReduxAction<
  EBuyAnalyticsModalActionTypes.SetStatus,
  EBuyAnalyticsFormStatus | null
>;

export type TSetBuyAnalyticsPaymentStatusData = ITypedReduxAction<
  EBuyAnalyticsModalActionTypes.SetPaymentStatusData,
  IBuyAnalyticsPaymentStatusData
>;

export type TSetBuyAnalyticsPaymentTransactionId = ITypedReduxAction<
  EBuyAnalyticsModalActionTypes.SetPaymentTransactionId,
  string | null
>;

export const setBuyAnalyticsModalStatus = actionGenerator<
  EBuyAnalyticsModalActionTypes.SetStatus,
  EBuyAnalyticsFormStatus | null
>(EBuyAnalyticsModalActionTypes.SetStatus);

export const setBuyAnalyticsModalVisibility = actionGenerator<
  EBuyAnalyticsModalActionTypes.SetModalVisibility,
  boolean
>(EBuyAnalyticsModalActionTypes.SetModalVisibility);

export const setBuyAnalyticsModalVisibilityAfterAuth =
  actionGenerator<EBuyAnalyticsModalActionTypes.SetModalVisibilityAfterAuth>(
    EBuyAnalyticsModalActionTypes.SetModalVisibilityAfterAuth,
  );

export const setBuyAnalyticsReportAvailability = actionGenerator<
  EBuyAnalyticsModalActionTypes.SetReportAvailability,
  IBuyAnalyticsAvailabilityData
>(EBuyAnalyticsModalActionTypes.SetReportAvailability);

export const setBuyAnalyticsPaymentStatusData = actionGenerator<
  EBuyAnalyticsModalActionTypes.SetPaymentStatusData,
  IBuyAnalyticsPaymentStatusData
>(EBuyAnalyticsModalActionTypes.SetPaymentStatusData);

export const setBuyAnalyticsPaymentTransactionId = actionGenerator<
  EBuyAnalyticsModalActionTypes.SetPaymentTransactionId,
  string | null
>(EBuyAnalyticsModalActionTypes.SetPaymentTransactionId);

export type TBuyAnalyticsModalActions =
  | TSetBuyAnalyticsModalVisibility
  | TSetBuyAnalyticsReportAvailability
  | TSetBuyAnalyticsModalStatus
  | TSetBuyAnalyticsPaymentStatusData
  | TSetBuyAnalyticsPaymentTransactionId
  | TSetBuyAnalyticsModalVisibilityAfterAuth;
