import { EBannerRotationActionTypes, TBannerRotationActions } from 'shared/actions/bannerRotation';
import { EBannerType } from 'shared/constants/banner';

export const DEFAULT_STATE: EBannerType = EBannerType.RealtorRequestTrap;

export const bannerRotationReducer = (
  state: EBannerType = DEFAULT_STATE,
  action: TBannerRotationActions,
): EBannerType => {
  switch (action.type) {
    case EBannerRotationActionTypes.SetBanner: {
      return action.payload;
    }

    default:
      return state;
  }
};
