import { EBannerType } from 'shared/constants/banner';

import { ITypedReduxAction } from '../../types/redux';

export enum EBannerRotationActionTypes {
  SetBanner = 'bannerRotation/set-banner',
}

export type TSetBannerRotation = ITypedReduxAction<EBannerRotationActionTypes.SetBanner, EBannerType>;

export type TBannerRotationActions = TSetBannerRotation;
