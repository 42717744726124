import { EActionType } from 'shared/types/dailyrentActionButtonsType';
import { RootAction } from 'shared/types/redux/RootAction';

import { EDailyrentActionTypeTypes } from '../../actions/dailyrentActionType';

export type TDailyrentActionTypeState = EActionType | null;

/**
 * С бэка приходит actionType – от него зависит, какие кнопки отрисуются в карточке.
 * @param state
 * @param action
 */
export function dailyrentActionTypeReducer(
  state: TDailyrentActionTypeState = null,
  action: RootAction,
): TDailyrentActionTypeState {
  switch (action.type) {
    case EDailyrentActionTypeTypes.SetActionTypeFromOffer: {
      return action.payload.offer.dailyrent?.actionType || null;
    }

    case EDailyrentActionTypeTypes.SetActionType: {
      return action.payload;
    }

    default:
      return state;
  }
}
