import { isDefined } from '../../utils';

export type TPrintActions = IPrintOptionsChanged | IPrintModalToggled;

export interface IPrintModalToggled {
  isOpen: boolean | undefined;
  type: 'IPrintModalToggled';
}

export interface IPrintModal {
  isCommentsVisible: boolean;
  isOpen: boolean;
  isPhonesVisible: boolean;
  isPrintModeEnabled: boolean;
}

export interface IPrintOptionsChanged {
  options: IChangePrintOptions;
  type: 'IPrintOptionsChanged';
}

export function toggle(isOpen?: boolean): IPrintModalToggled {
  return {
    isOpen,
    type: 'IPrintModalToggled',
  };
}

export interface IChangePrintOptions {
  isPrintModeEnabled?: boolean;
  isCommentsVisible?: boolean;
  isPhonesVisible?: boolean;
}

export function changePrintOptions(options: IChangePrintOptions): IPrintOptionsChanged {
  return {
    options,
    type: 'IPrintOptionsChanged',
  };
}

const defaultState: IPrintModal = {
  isCommentsVisible: true,
  isOpen: false,
  isPhonesVisible: true,
  isPrintModeEnabled: false,
};

export function printModalReducer(state: IPrintModal, action: TPrintActions): IPrintModal {
  switch (action.type) {
    case 'IPrintModalToggled':
      return {
        ...state,
        isOpen: isDefined<boolean>(action.isOpen) ? action.isOpen : !state.isOpen,
      };

    case 'IPrintOptionsChanged':
      return Object.assign({}, state, action.options);

    default:
      return state || defaultState;
  }
}
