import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  fetchSetHideOfferInHistory,
  ISetHideOfferInHistoryRequest,
} from 'shared/repositories/valuation-offer-history/v1/set-hide-offer-in-history';
import { createHideOfferInHistoryMapper } from 'shared/services/offersHistory/fetchHideOfferInHistory/mappers';
import { IApplicationContext } from 'shared/types/applicationContext';

export const fetchHideOfferInHistory = async (
  context: IApplicationContext,
  parameters: ISetHideOfferInHistoryRequest,
) => {
  try {
    const res = await fetchSetHideOfferInHistory({
      httpApi: context.httpApi,
      parameters,
      mappers: createHideOfferInHistoryMapper(parameters),
    });

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      context.logger.debug('set-hide-offer-in-history BadRequest');
    } else {
      context.logger.error(error, { message: 'Error in set-hide-offer-in-history' });
    }

    throw error;
  }
};
