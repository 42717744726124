/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetSimilarOffersModel,
  IGetSimilarOffersRequest,
  IMappers,
  IGetSimilarOffersResponse,
  IGetSimilarOffersResponseError,
  TGetSimilarOffersResponse,
} from './types';

const defaultConfig: TGetSimilarOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-engine',
  pathApi: '/v3/get-similar-offers/',
  hostType: 'api',
} as TGetSimilarOffersModel;

function createGetSimilarOffersModel(parameters: IGetSimilarOffersRequest): TGetSimilarOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetSimilarOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetSimilarOffersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetSimilarOffers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetSimilarOffersOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetSimilarOffersResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetSimilarOffersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetSimilarOffersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetSimilarOffersResponseError);
    }
  }

  return { response, statusCode } as TGetSimilarOffersResponse;
}

export { defaultConfig, createGetSimilarOffersModel, fetchGetSimilarOffers };
