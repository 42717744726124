import { IRosreestrState } from '../../types/rosreestr';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ERosreestrActionTypes {
  SetData = 'rosreestr/set-data',
}

export const setDataAction = actionGenerator<ERosreestrActionTypes.SetData, IRosreestrState | null>(
  ERosreestrActionTypes.SetData,
);

export type TRosreestrActions = ReturnType<typeof setDataAction>;
