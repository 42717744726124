export enum ENotAvailablePopupActionTypes {
  NOT_AVAILABLE_POPUP_SET_VALUE = 'notAvailablePopup/SET_VALUE',
}

export type TNotAvailablePopupSetValueAction = {
  type: ENotAvailablePopupActionTypes;
  value: boolean;
};

export const setNotAvailablePopupOpenValue = (value: boolean): TNotAvailablePopupSetValueAction => ({
  type: ENotAvailablePopupActionTypes.NOT_AVAILABLE_POPUP_SET_VALUE,
  value,
});

const initialState = false;

export function notAvailablePopupOpenReducer(
  state: boolean = initialState,
  action: TNotAvailablePopupSetValueAction,
): boolean {
  switch (action.type) {
    case ENotAvailablePopupActionTypes.NOT_AVAILABLE_POPUP_SET_VALUE:
      return action.value;

    default:
      return state;
  }
}
