import { IGetComparisonDesktopResponse } from 'shared/repositories/offers-comparison/v2/get-comparison-desktop';
import { actionGenerator } from 'shared/utils/redux/actionGenerator';

export const AddToComparisonActionType = 'offersComparison/ADD_TO_COMPARISON';

export const DeleteFromComparisonActionType = 'offersComparison/DELETE_FROM_COMPARISON';

export interface IAddToComparisonAction {
  type: 'offersComparison/ADD_TO_COMPARISON';
  offerId: number;
}

export interface IDeleteFromComparisonAction {
  type: 'offersComparison/DELETE_FROM_COMPARISON';
  offerId: number;
}

export enum EComparison {
  getComparison = 'offersComparison/GET_COMPARISON',
}

export const getComparisonOffers = actionGenerator<EComparison.getComparison, IGetComparisonDesktopResponse | null>(
  EComparison.getComparison,
);

export type TOfferComparisonActions =
  | IAddToComparisonAction
  | IDeleteFromComparisonAction
  | ReturnType<typeof getComparisonOffers>;
