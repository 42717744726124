import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { fetchMyHomeObjectsExists } from 'shared/repositories/price-estimator/v1/my-home-objects-exists';
import { createMyHomeObjectExistMapper } from 'shared/services/valuationTraps/fetchIsMyHomeObjectsExist/mappers';
import { IApplicationContext } from 'shared/types/applicationContext';

export const fetchIsMyHomeObjectsExist = async (context: IApplicationContext) => {
  try {
    const res = await fetchMyHomeObjectsExists({
      httpApi: context.httpApi,
      mappers: createMyHomeObjectExistMapper(),
    });

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      context.logger.debug('fetchIsMyHomeObjectsExist BadRequest');
    } else {
      context.logger.error(error, { message: 'Error in fetchIsMyHomeObjectsExist' });
    }

    throw error;
  }
};
