import { getAgentName } from './getAgentName';
import { getOffer } from './getOffer';
import { IState } from '../../store';
import { getAuthorLabel } from '../../utils/agent';

export const selectAgentDisplayName = (state: IState): string | null => {
  const agentName = getAgentName(state);
  const offer = getOffer(state);

  const agentLabel = getAuthorLabel(offer, false);

  return agentName && agentLabel ? `${agentLabel} «${agentName}»` : agentName;
};
