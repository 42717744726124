import { isNil } from 'ramda';

import { ECoworkingOfferType, IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { AccountType, FlatType } from '../../store/offer/constants';
import { isFromBuilder, isFromBuilderPresenter, isOfficialRepresentative } from '../agent';
import { FDealType, getDealType, isRent } from '../deal_type';
import { FOfferType, getOfferType, isCommercial, isFlat, isSuburban, isUrban } from '../offer_type';
import { isSuburbanOfferFromBuilder } from '../suburban';

export type TTrackingBuildingType = 'BC' | 'TC' | 'JK' | 'KP';

export interface ITrackingOptions {
  buildingType?: TTrackingBuildingType;
}

export function getCustomUrl(offer: IOfferSchema, agent: IAgentSchema | null, options?: ITrackingOptions): string {
  const dealType = getDealType(offer);
  const offerType = getOfferType(offer);
  const params: Array<string | undefined | boolean | number> = [];
  const isReliable = offer.newbuilding && offer.newbuilding.showJkReliableFlag;

  params.push(isRent(dealType) ? 'rent' : 'sale');
  params.push(getTrackingOfferType(offerType));

  if (options) {
    params.push(options.buildingType);
  }

  if (offer.trackingData) {
    params.push(!isNil(offer.trackingData.moId) && `mo_id=${offer.trackingData.moId}`);
    params.push(!isNil(offer.trackingData.oblId) && `obl_id=${offer.trackingData.oblId}`);
    params.push(!isNil(offer.trackingData.cityId) && `city_id=${offer.trackingData.cityId}`);
  }

  params.push(getTrackingObjectType(offerType, dealType));
  params.push(getTrackingObjType(offer));
  params.push(getTrackingRentType(dealType));
  params.push(getTrackingAgentType(agent));
  params.push(offer.cianId);
  params.push(
    `from_developer=${
      isFromBuilder(offer) || isFromBuilderPresenter(offer) || isSuburbanOfferFromBuilder(offer) ? 1 : 0
    }`,
  );
  params.push(`repres=${isOfficialRepresentative(offer) ? 1 : 0}`);
  params.push(`owner=${offer.isByHomeowner ? 1 : 0}`);
  params.push(`pod_snos=${offer.isDemolishedInMoscowProgramm ? 1 : 0}`);
  params.push(`nv=${isReliable ? 1 : 0}`);

  return `/${params.filter(Boolean).join('/')}/`;
}

/* istanbul ignore next */
function getTrackingObjectType(offerType: FOfferType, dealType: FDealType): string {
  if (dealType === FDealType.Sale) {
    if (offerType === FOfferType.FlatNew) {
      return 'object_type=2';
    } else {
      return 'object_type=1';
    }
  }

  return 'object_type=';
}

/* istanbul ignore next */
function getTrackingOfferType(offerType: FOfferType): string | undefined {
  if (isUrban(offerType)) {
    return 'flat';
  }

  if (isSuburban(offerType)) {
    return 'suburban';
  }

  if (isCommercial(offerType)) {
    return 'commercial';
  }

  return undefined;
}

/* istanbul ignore next */
function getTrackingRentType(dealType: FDealType): string | undefined {
  if (dealType === FDealType.RentDaily) {
    return 'rent_type=daily';
  }

  if (dealType === FDealType.RentLongterm) {
    return 'rent_type=long';
  }

  return undefined;
}

/* istanbul ignore next */
function getTrackingAgentType(agent: IAgentSchema | null): string {
  if (agent && agent.accountType === AccountType.Agency) {
    return 'spec=company';
  }

  if (agent && agent.accountType === AccountType.Specialist) {
    return 'spec=agent';
  }

  return 'spec=none';
}

/* istanbul ignore next */
export function getTrackingObjType(offer: IOfferSchema): string | undefined {
  const offerType = getOfferType(offer);

  if (offerType === FOfferType.Bed) {
    return 'ga_obj_type=10';
  }

  if (offerType === FOfferType.Room) {
    return 'ga_obj_type=0';
  }

  if (isFlat(offerType)) {
    if (offer.flatType === FlatType.OpenPlan) {
      return 'ga_obj_type=7';
    }

    if (offer.flatType === FlatType.Studio) {
      return 'ga_obj_type=9';
    }

    if (offer.roomsCount > 0 && offer.roomsCount < 6) {
      return `ga_obj_type=${offer.roomsCount}`;
    }

    if (offer.roomsCount >= 6) {
      return 'ga_obj_type=6';
    }
  }

  if (isSuburban(offerType)) {
    if (offerType === FOfferType.House) {
      return 'ga_obj_type=1';
    }

    if (offerType === FOfferType.HousePart) {
      return 'ga_obj_type=2';
    }

    if (offerType === FOfferType.Land) {
      return 'ga_obj_type=3';
    }

    if (offerType === FOfferType.Townhouse) {
      return 'ga_obj_type=4';
    }
  }

  if (offer.coworkingOfferType) {
    switch (offer.coworkingOfferType) {
      case ECoworkingOfferType.Office:
        return 'ga_obj_type=15';
      case ECoworkingOfferType.FixedWorkplace:
        return 'ga_obj_type=16';
      case ECoworkingOfferType.FlexibleWorkplace:
        return 'ga_obj_type=17';
      case ECoworkingOfferType.ConferenceHall:
        return 'ga_obj_type=18';
      case ECoworkingOfferType.MeetingRoom:
        return 'ga_obj_type=19';
      default:
        return 'ga_obj_type=14';
    }
  }

  if (isCommercial(offerType)) {
    switch (offerType) {
      case FOfferType.Office:
        return 'ga_obj_type=1';
      case FOfferType.TradeArea:
        return 'ga_obj_type=2';
      case FOfferType.Warehouse:
        return 'ga_obj_type=3';
      case FOfferType.PublicCatering:
        return 'ga_obj_type=4';
      case FOfferType.FreeAppointmentObject:
        return 'ga_obj_type=5';
      case FOfferType.Garage:
        return 'ga_obj_type=6';
      case FOfferType.Manufacture:
        return 'ga_obj_type=7';
      case FOfferType.AutoService:
        return 'ga_obj_type=9';
      case FOfferType.Business:
        return 'ga_obj_type=10';
      case FOfferType.Building:
        return 'ga_obj_type=11';
      case FOfferType.DomesticServices:
        return 'ga_obj_type=12';
      case FOfferType.CommercialLand:
        return 'ga_obj_type=13';
      default:
        return undefined;
    }
  }

  return undefined;
}
