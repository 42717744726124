import { EKnAuctionCompaniesActionTypes, IKnAuctionCompaniesState } from './types';

import type { GetUsersAuctionSuccessAction } from './actions/getKnAuctionCompaniesActionCreator';

const initialState: IKnAuctionCompaniesState = Array.of<number>();

export function knAuctionCompaniesReducer(
  state = initialState,
  action: GetUsersAuctionSuccessAction,
): IKnAuctionCompaniesState {
  switch (action.type) {
    case EKnAuctionCompaniesActionTypes.GetUsersAuctionSuccess:
      return action.payload;

    default:
      return state;
  }
}
