import { getEventBusIfDefined } from '@cian/event-bus';

export function createRenderCallback() {
  const eventBus = getEventBusIfDefined();

  return () => {
    if (eventBus) {
      eventBus.emit('metrics-loader', {
        type: 'unlock-initialization',
      });
    }
  };
}
