import { ISimilarOffer, TModelType } from '../types';

export enum ESimilarOffersActionType {
  ISimilarOffersReceived = 'ISimilarOffersReceived',
}

export enum ESimilarOffersModalActionType {
  ModalOpened = 'similarOffers/ModalOpened',
  ModalClosed = 'similarOffers/ModalClosed',
}

export interface ISimilarOffersReceivedAction {
  list: ISimilarOffer[];
  withoutCompetitors: boolean | null;
  modelVersion: string | null;
  type: ESimilarOffersActionType.ISimilarOffersReceived;
  modelType: TModelType;
}
