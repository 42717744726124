import { getPromoInfos } from './getPromoInfos';
import { MORTGAGE_PROMO_TYPES } from '../../constants/newbuildingPromos';
import { IState } from '../../store';
import { IPromoInfo } from '../../types/newbuildingPromos';

export const getNewbuildingPromos = (state: IState): IPromoInfo[] => {
  const promos = getPromoInfos(state);

  if (!promos.length) {
    return [];
  }

  return promos.filter(promo => !MORTGAGE_PROMO_TYPES.includes(promo.promoType));
};
