import { moveOfferToArchive } from '../../api/archive';
import { removeOffer } from '../../api/remove';
import { isDefined } from '../../utils';
import { AsyncActionStatus, TAsyncActionStatus } from '../common';
import { IThunkActionCreator } from '../index';

export type TRemoveModalActions =
  | IArchiveOfferRequested
  | IArchiveOfferCompleted
  | IArchiveOfferFailed
  | IRemoveOfferRequested
  | IRemoveOfferCompleted
  | IRemoveOfferFailed
  | IRemoveModalFrameChanged
  | IRemoveModalToggled;

export type TRemoveModalFrame = 'initial' | 'archive' | 'remove' | 'requestReview';

export const RemoveModalFrame = {
  Archive: 'archive' as TRemoveModalFrame,
  Initial: 'initial' as TRemoveModalFrame,
  Remove: 'remove' as TRemoveModalFrame,
  RequestReview: 'requestReview' as TRemoveModalFrame,
};

export interface IArchiveOfferRequested {
  type: 'IArchiveOfferRequested';
}

export interface IArchiveOfferCompleted {
  type: 'IArchiveOfferCompleted';
}

export interface IArchiveOfferFailed {
  type: 'IArchiveOfferFailed';
}

export interface IRemoveOfferRequested {
  type: 'IRemoveOfferRequested';
}

export interface IRemoveOfferCompleted {
  type: 'IRemoveOfferCompleted';
}

export interface IRemoveOfferFailed {
  type: 'IRemoveOfferFailed';
}

export interface IRemoveModalToggled {
  isOpen: boolean | undefined;
  type: 'IRemoveModalToggled';
}

export interface IRemoveModalFrameChanged {
  newActiveFrame: TRemoveModalFrame;
  type: 'IRemoveModalFrameChanged';
}

export interface IRemoveState {
  activeFrame: TRemoveModalFrame;
  isOpen: boolean;
  status: TAsyncActionStatus;
}

export function toggle(isOpen?: boolean): IRemoveModalToggled {
  return {
    isOpen,
    type: 'IRemoveModalToggled',
  };
}

export function changeActiveFrame(newActiveFrame: TRemoveModalFrame): IRemoveModalFrameChanged {
  return {
    newActiveFrame,
    type: 'IRemoveModalFrameChanged',
  };
}

const WAIT_QUEUE_TIMEOUT = 3000;

export function archive(): IThunkActionCreator {
  return (dispatch, getState, { config, custom: { makeRequest } }) => {
    const state = getState();
    const pythonOfferCardPublicApi = config.get<string>('pythonOfferCardPublicApi') as string;

    dispatch({
      type: 'IArchiveOfferRequested',
    });

    moveOfferToArchive(
      {
        makeRequest,
        pythonOfferCardPublicApi,
        subdomain: state.pageData.subdomain,
      },
      {
        dealType: state.pageData.dealType,
        offerId: state.pageData.offerId,
        offerType: state.pageData.offerType,
        subdomain: state.pageData.subdomain,
      },
    )
      .then(() => {
        setTimeout(() => {
          dispatch({
            type: 'IArchiveOfferCompleted',
          });
        }, WAIT_QUEUE_TIMEOUT);
      })
      .catch(() => {
        dispatch({
          type: 'IArchiveOfferFailed',
        });
      });
  };
}

export function remove(): IThunkActionCreator {
  return (dispatch, getState, { config, custom: { makeRequest } }) => {
    const state = getState();
    const pythonOfferCardPublicApi = config.get<string>('pythonOfferCardPublicApi') as string;

    dispatch({
      type: 'IRemoveOfferRequested',
    });

    removeOffer(
      {
        makeRequest,
        pythonOfferCardPublicApi,
        subdomain: state.pageData.subdomain,
      },
      {
        dealType: state.pageData.dealType,
        offerId: state.pageData.offerId,
        offerType: state.pageData.offerType,
        subdomain: state.pageData.subdomain,
      },
    )
      .then(() => {
        setTimeout(() => {
          dispatch({
            type: 'IRemoveOfferCompleted',
          });
        }, WAIT_QUEUE_TIMEOUT);
      })
      .catch(() => {
        dispatch({
          type: 'IRemoveOfferFailed',
        });
      });
  };
}

const defaultState: IRemoveState = {
  activeFrame: RemoveModalFrame.Initial,
  isOpen: false,
  status: AsyncActionStatus.Default,
};

export function removeModalReducer(state: IRemoveState, action: TRemoveModalActions): IRemoveState {
  switch (action.type) {
    case 'IRemoveModalToggled':
      return {
        ...state,
        activeFrame: RemoveModalFrame.Initial,
        isOpen: isDefined<boolean>(action.isOpen) ? action.isOpen : !state.isOpen,
        status: AsyncActionStatus.Default,
      };

    case 'IRemoveModalFrameChanged':
      return {
        ...state,
        activeFrame: action.newActiveFrame,
      };

    case 'IArchiveOfferRequested':
    case 'IRemoveOfferRequested':
      return {
        ...state,
        status: AsyncActionStatus.Loading,
      };

    case 'IArchiveOfferCompleted':
    case 'IRemoveOfferCompleted':
      return {
        ...state,
        status: AsyncActionStatus.Completed,
      };

    case 'IArchiveOfferFailed':
    case 'IRemoveOfferFailed':
      return {
        ...state,
        status: AsyncActionStatus.Failed,
      };

    default:
      return state || defaultState;
  }
}
