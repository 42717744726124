import { IState } from '../store';
import { IAbUseExperiment } from '../types/abUseExperiments';

export function abUseExperimentsSelector(state: IState): IAbUseExperiment[] {
  return state.abUseExperiments;
}

export function isAbUseExperimentsActive(
  experiments: IAbUseExperiment[],
  experimentName: string,
  groupName: string,
): boolean {
  const experiment = experiments.find(o => o.experimentName === experimentName);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === groupName;
}
