import { IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

interface ICreateEbcInfoParams {
  abGroup: number;
  breadCrumbs: string[];
  offerID: number;
  user: IUserSchema | null;
}

export function createEbcInfo({ abGroup, user, breadCrumbs, offerID }: ICreateEbcInfoParams) {
  return {
    user: {
      abGroup,
      isAuthorized: (user && user.isAuthenticated) || false,
      userId: user && user.realtyUserId ? Number(user.realtyUserId) : undefined,
    },
    page: {
      breadCrumbs,
      offerID,
    },
  };
}
