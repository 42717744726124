import { selectOfferRealtyUserId } from './selectOfferRealtyUserId';
import { IState } from '../../../store';
import { selectIsAuthenticated, selectRealtyUserId } from '../user';

export const selectIsOfferOwnedByUser = (state: IState): boolean => {
  const isAuthenticated = selectIsAuthenticated(state);
  const currentRealtyUserId = selectRealtyUserId(state);
  const offerUserId = selectOfferRealtyUserId(state);

  return isAuthenticated && currentRealtyUserId === offerUserId;
};
