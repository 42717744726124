export interface IPhoneCollapseAction {
  type: 'IPhoneCollapseAction';
  collapse: true;
}

export function setPhoneCollapse(): IPhoneCollapseAction {
  return {
    collapse: true,
    type: 'IPhoneCollapseAction',
  };
}

export function phoneCollapseReducer(state: boolean = false, action: IPhoneCollapseAction): boolean {
  switch (action.type) {
    case 'IPhoneCollapseAction':
      return action.collapse;

    default:
      return state;
  }
}
