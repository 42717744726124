import { batch } from 'react-redux';

import {
  setBuyAnalyticsModalStatus,
  setBuyAnalyticsModalVisibility,
  setBuyAnalyticsPaymentTransactionId,
} from './actions';
import { sendSoprAnalytics } from './sendSoprAnalytics';
import { unsubscribePaymentStatusUpdate } from './unsubscribePaymentStatusUpdate';
import { selectIsBuyAnalyticsModalFormSending } from '../../selectors/buyAnalyticsModal';
import { IThunkActionCreator } from '../../store';

export const closeBuyAnalyticsModal = (): IThunkActionCreator => {
  return (dispatch, getState): void => {
    const state = getState();
    const isSending = selectIsBuyAnalyticsModalFormSending(state);
    if (isSending) {
      return;
    }

    dispatch(sendSoprAnalytics('close_report'));

    batch(() => {
      dispatch(setBuyAnalyticsModalStatus(null));
      dispatch(setBuyAnalyticsPaymentTransactionId(null));
      dispatch(setBuyAnalyticsModalVisibility(false));
      dispatch(unsubscribePaymentStatusUpdate());
    });
  };
};
