import { getConfig } from '@cian/config/browser';
import { getLogger } from '@cian/logger/browser';
import { sendBeacon, InteractiveTimeObserver, ISubscriberEvent } from '@cian/user-interactive-time';
import { parseCookies } from '@cian/utils';
import { Store } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';

import { IState } from '../../../shared/store';

interface IDependencies {
  store: Store<IState>;
}

export function subscribeToInteractiveTime(dependencies: IDependencies) {
  const { store } = dependencies;
  const config = getConfig();
  const logger = getLogger();
  const state = store.getState();
  const offerId = state.offerData.offer.id;

  try {
    const schedulerInterval = config.getStrict<number>('userInteractiveTime.schedulerInterval');
    const inactiveTime = config.getStrict<number>('userInteractiveTime.inactiveTime');
    const debug = config.getStrict<boolean>('userInteractiveTime.debug.enabled');
    const userInteractiveTimeEnabled = config.getStrict<boolean>('userInteractiveTime.enabled');

    if (!userInteractiveTimeEnabled) {
      return;
    }
    const observer = new InteractiveTimeObserver({
      schedulerInterval,
      inactiveTime,
      debug,
    });

    observer.subscribe(createSubscriber(dependencies));
  } catch (error) {
    logger.error(error, { offerId, domain: 'services#subscribeToInteractiveTime()' });
  }
}

export function createSubscriber(dependencies: IDependencies) {
  return async ({ interactiveTime, willWindowHide }: ISubscriberEvent) => {
    const { store } = dependencies;
    const config = getConfig();
    const logger = getLogger();
    const state = store.getState();
    const offerId = state.offerData.offer.id;

    try {
      const userId = pathOr(null, ['offerData', 'user', 'realtyUserId'], state);
      const cid = parseCookies(document.cookie)['_CIAN_GK'];
      const soprUrl = config.getStrict<string>('soprExperiments.browserUrl');

      const parameters = {
        offer_id: offerId,
        user_id: userId,
        t: interactiveTime,
        cid,
      };

      sendBeacon(`${soprUrl}card-user-activity-time/`, JSON.stringify(parameters), willWindowHide);
    } catch (error) {
      logger.error(error, { offerId, domain: 'services#createSubscriber()' });
    }
  };
}
