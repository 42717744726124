import { addDays, differenceInCalendarDays, format, max, min, parse } from 'date-fns';

import { TDaysAvailability } from 'shared/types/bookingCalendar';

export const DEFAULT_INPUT_TEXT = 'Заезд – Выезд';

export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

/** Ограничивает диапазон ближайшей занятой датой, если она попадается внутри этого диапазона */
const getNormalizedEndDate = (minDate: Date, startDate: Date, endDate: Date, daysAvailability: TDaysAvailability) => {
  /** Количество дней от сегодняшнего до начальной даты диапазона */
  const index = Math.abs(differenceInCalendarDays(minDate, startDate));

  /** Количество дней в выбранном диапазоне */
  const daysInRange = Math.abs(differenceInCalendarDays(startDate, endDate));

  /** Массив доступности, начиная со следующего дня после выбранной начальной даты */
  // Может показаться что сдесь должжно быть index + 1, но так задумано
  // Так как каждая первая дата в занятых диапозонах это дата в которую гости заезжают.
  // Но эта дата может быть выбрана как дата выезда другого гостя, поэтому она не заблокирована для выбора.
  const slicedAvailability = daysAvailability.slice(index);

  /** Количество дней от начальной даты диапазона до следующей недоступной даты */
  const daysUntilUnavailable = slicedAvailability.findIndex(d => !d);

  let normalizedEndDate = endDate;
  if (daysUntilUnavailable !== -1 && daysInRange > daysUntilUnavailable) {
    normalizedEndDate = addDays(startDate, daysUntilUnavailable);
  }

  return normalizedEndDate;
};

export enum ValidationRuleTitle {
  NO_RANGE = 'noRange',
  MIN_RANGE = 'minRange',
  MAX_RANGE = 'maxRange',
  ONLY_CHECKOUT = 'onlyCheckout',
}

interface IUpdateDailyrentUrlParameters {
  currentUrl: string;
  dates?: { checkin: string; checkout: string };
  guestsCount?: number;
}

export const updateDailyrentUrlParameters = ({
  currentUrl,
  dates,
  guestsCount,
}: IUpdateDailyrentUrlParameters): string => {
  const url = new URL(currentUrl);

  if (dates) {
    url.searchParams.set('checkin', dates.checkin);
    url.searchParams.set('checkout', dates.checkout);
  }

  if (guestsCount && guestsCount > 1) {
    url.searchParams.set('min_beds', String(guestsCount));
  } else {
    url.searchParams.delete('min_beds');
  }

  return url.toString();
};

type TDailyrentQueryParams = 'checkin' | 'checkout' | 'min_beds';

export const deleteDailyrentQueryParams = (currentUrl: string, paramsToDelete: TDailyrentQueryParams[]) => {
  const url = new URL(currentUrl);

  paramsToDelete.forEach(p => {
    url.searchParams.delete(p);
  });

  return url.toString();
};

export const getDailyrentQueryParams = (currentUrl: string) => {
  const url = new URL(currentUrl);

  return {
    checkin: url.searchParams.get('checkin'),
    checkout: url.searchParams.get('checkout'),
    guestsCount: url.searchParams.get('min_beds'),
  };
};

export const getNormalizedInitialDates = (
  minDate: Date,
  dates: { from?: string; to?: string },
  daysAvailability: TDaysAvailability,
) => {
  let startDate = dates.from ? parse(dates.from, 'yyyy-MM-dd', new Date()) : null;
  let endDate = dates.to ? parse(dates.to, 'yyyy-MM-dd', new Date()) : null;

  if (!startDate || !endDate) {
    return { normalizedInitialStart: null, normalizedInitialEnd: null };
  }

  startDate = min([startDate, endDate]);
  const latestDate = max([startDate, endDate]);
  endDate = getNormalizedEndDate(minDate, startDate, latestDate, daysAvailability);

  return { normalizedInitialStart: startDate, normalizedInitialEnd: endDate };
};
