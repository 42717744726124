import {
  EBuildersProjectsLocationsActionTypes,
  TBuildersProjectsLocationsActions,
} from 'shared/actions/buildersProjectsLocations';

export const DEFAULT_STATE: number[] = [];

export const buildersProjectsLocationsReducer = (
  state: number[] = DEFAULT_STATE,
  action: TBuildersProjectsLocationsActions,
): number[] => {
  switch (action.type) {
    case EBuildersProjectsLocationsActionTypes.SetData: {
      return action.payload;
    }

    default:
      return state;
  }
};
