import { isNil } from 'ramda';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IState } from '../store';

export const isSuburbanOfferFromBuilder = ({ isResale, offerType }: IOfferSchema) =>
  offerType === 'suburban' && !isNil(isResale) && !isResale;

export const showCountrysideBuilderBadge = ({ isCountrysideOffersBuilderBadgeEnabled, offerData }: IState): boolean =>
  isCountrysideOffersBuilderBadgeEnabled && isSuburbanOfferFromBuilder(offerData.offer);
