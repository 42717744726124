export function getSourceParams() {
  if (typeof window === 'undefined') {
    return;
  }

  const parsedSearchParams = new URLSearchParams(window.location.search);

  const source = parsedSearchParams.get('source');
  const listingType = parsedSearchParams.get('listingType');

  return { source, listingType };
}
