import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IPageExtra } from '../../types';
import { isLeadFactory } from '../agent';
import { getSourceParams } from '../getSourceParams';

export function getCplPageExtra(offer: IOfferSchema, siteBlockId?: number | null): Partial<IPageExtra> | undefined {
  const sourceParams = getSourceParams();

  const extaParams = sourceParams && {
    pageTypeSource: sourceParams.source,
    listingTypeSource: sourceParams.listingType,
  };

  if (offer.dealType !== 'sale') {
    return extaParams;
  }

  const isFromDeveloper = Boolean(offer.newbuilding?.isFromDeveloper);

  return {
    cplDynamicCalltracking: siteBlockId ? 1 : 0,
    cplCalltracking: isFromDeveloper && !isLeadFactory(offer) ? 1 : 0,
    ...extaParams,
  };
}
