import type { IThunkActionCreator } from '../index';

export interface IGalleryData {
  isVideoPlayed: boolean;
  showElementId: number | undefined;
  isInitialized: boolean;
  isFullScreen: boolean;
}

export interface IVideoPlayed {
  type: 'IVideoPlayed';
  gallery: IVideoActionData;
}

export interface IElementShowed {
  type: 'IElementShowed';
  gallery: IElementViewData;
}

export interface IShowGalleryFullScreen {
  type: 'IShowGalleryFullScreen';
  gallery: IElementViewData;
}

export interface IHideGalleryFullScreen {
  type: 'IHideGalleryFullScreen';
  gallery: Pick<IElementViewData, 'isFullScreen'>;
}

export interface IVideoActionData {
  isVideoPlayed: boolean;
}

export interface IElementViewData {
  showElementId: number | undefined;
  isFullScreen?: boolean;
}

export interface IFotoramaInitialized {
  type: 'IFotoramaInitialized';
  gallery: {
    isInitialized: boolean;
  };
}

export function showGalleryElement(id: number): IThunkActionCreator {
  return dispatch => {
    dispatch<IElementShowed>({
      gallery: {
        showElementId: id,
      },
      type: 'IElementShowed',
    });
  };
}

export const showFullScreen = (id: number): IThunkActionCreator => {
  return dispatch => {
    dispatch<IShowGalleryFullScreen>({
      gallery: {
        showElementId: id,
        isFullScreen: true,
      },
      type: 'IShowGalleryFullScreen',
    });
  };
};

export const hideFullScreen = (): IThunkActionCreator => {
  return dispatch => {
    dispatch<IHideGalleryFullScreen>({
      gallery: {
        isFullScreen: false,
      },
      type: 'IHideGalleryFullScreen',
    });
  };
};

export function galleryReducer(
  state: IGalleryData,
  action: IVideoPlayed | IElementShowed | IFotoramaInitialized | IShowGalleryFullScreen | IHideGalleryFullScreen,
): IGalleryData {
  switch (action.type) {
    case 'IFotoramaInitialized':
    case 'IVideoPlayed':
    case 'IElementShowed':
    case 'IShowGalleryFullScreen':
    case 'IHideGalleryFullScreen':
      return { ...state, ...action.gallery };

    default:
      return state || {};
  }
}
