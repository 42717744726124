export interface IPromoBank {
  /** ID банка **/
  id: number;
  /** Название **/
  name: string;
  /** Ссылка на страницу ипотеки для партнеров **/
  cianMortgagePartnerLink?: string | null;
  /** Логотип банка **/
  logoUrl?: string | null;
  /** Круглое лого банка **/
  secondRoundImageUrl?: string | null;
  /** Квадратное лого банка **/
  secondSquareImageUrl?: string | null;
  /** Является ли партнером Циан ипотеки **/
  isPartner?: boolean | null;
}

export interface IMortgageParams {
  /** Процентная ставка (0.0 - 100.0) **/
  interestRate?: number | null;
  /** Максимальная сумма кредита **/
  maxCredit?: number | null;
  /** Максимальный срок, лет **/
  maxLoanTerm?: number | null;
  /** Первоначальный взнос в %(0.0 - 100.0) **/
  minInitialPaymentRate?: number | null;
}

export enum EPromoType {
  Bank = 'bank',
  /** Бонусы **/
  Bonus = 'bonus',
  /** ЦИАН ипотека **/
  CianMortgage = 'cianMortgage',
  /** Скидки **/
  Discount = 'discount',
  /** Материнский капитал **/
  MaternalCapital = 'maternalCapital',
  /** Военная ипотека **/
  MilitaryMortgage = 'militaryMortgage',
  /** Ипотека **/
  Mortgage = 'mortgage',
}

export interface IPromoInfo {
  /** Логотип застройщика/агента **/
  authorLogoUrl?: string | null;
  /** Название (имя) застройщика/агента **/
  authorName?: string | null;
  /** Список ЖК в которых доступна акция **/
  availableJks?: IAvailableJk[] | null;
  /** Банк - автор **/
  bank?: IPromoBank | null;
  /** Банки **/
  banks: IPromoBank[];
  /** ID компании, разместившей акцию **/
  companyId?: number | null;
  /** Описание акции **/
  description?: string | null;
  /** Срок действия акции **/
  end?: string | null;
  /** Особенности акции **/
  features?: IPromoFeature[] | null;
  /** Акция Черной Пятницы **/
  isBlackFriday?: boolean;
  /** Эксклюзивная акция на ЦИАН **/
  isExclusive?: boolean;
  /** Акция от банка **/
  isFromBank?: boolean | null;
  /** Акция от застройщика **/
  isFromBuilder: boolean;
  /** Параметры акции по ипотеке **/
  mortgageParams?: IMortgageParams | null;
  /** Название акции **/
  name: string;
  /** ID Акции **/
  promoId: number;
  /** Изображение для акции **/
  promoImage?: string | null;
  /** Тип акции **/
  promoType: EPromoType;
  /** Дата начала действия акции **/
  start?: string | null;
  /** Подзаголовок акции **/
  subtitle?: string | null;
  /** ID акции **/
  uniquePromoId: string;
}

export interface IPromoFeature {
  /** Название **/
  name: string;
  /** Значение **/
  value: string;
}

export interface IAvailableJk {
  /** ID ЖК **/
  id: number;
  /** Ссылка на карточку ЖК **/
  link: string;
  /** Название **/
  name: string;
}
