import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  IMappers,
  ISetHideOfferInHistoryRequest,
  ISetHideOfferInHistoryResponse,
} from 'shared/repositories/valuation-offer-history/v1/set-hide-offer-in-history';

export const createHideOfferInHistoryMapper = (
  parameters: ISetHideOfferInHistoryRequest,
): IMappers<TMappedResponse200, TMappedResponse400> => ({
  200: response => response,
  400: ({ message }) => {
    return new HttpBadStatusCodeError({
      domain: 'fetchHideOfferInHistory.ts',
      statusCode: 400,
      message: message || /* istanbul ignore next */ '',
      details: { ...parameters },
    });
  },
});

export type TMappedResponse200 = ISetHideOfferInHistoryResponse;
export type TMappedResponse400 = HttpBadStatusCodeError;

export type TMappedResponse = TMappedResponse200 | TMappedResponse400;
