export interface IEbcState {
  user: EBC.IUserInfo;
  page: Pick<EBC.IPageInfo, 'breadCrumbs' | 'offerID'>;
}

export interface ISetEbcInfo {
  type: 'ISetEbcInfo';
  payload: IEbcState;
}

export const setEbcInfo = (ebcInfo: IEbcState): ISetEbcInfo => ({
  payload: ebcInfo,
  type: 'ISetEbcInfo',
});

export type TEbcAction = ISetEbcInfo;

const initialState: IEbcState = {
  user: {
    abGroup: 0,
    isAuthorized: false,
  },
  page: {
    breadCrumbs: [],
  },
};

export function ebcReducer(state: IEbcState = initialState, action: TEbcAction): IEbcState {
  switch (action.type) {
    case 'ISetEbcInfo':
      return action.payload;
    default:
      return state;
  }
}
