import { IOfferData } from 'shared/types/offerData';

export function getObjectType(offerData: IOfferData, isShortened?: boolean): string {
  const { category, roomsCount, flatType, isApartments } = offerData.offer;

  if (category) {
    switch (category) {
      case 'townhouseSale':
      case 'townhouseRent':
        return 'таунхаус';

      case 'buildingRent':
      case 'buildingSale':
        return 'здание';

      case 'garageSale':
      case 'garageRent':
        return 'гараж';

      case 'commercialLandRent':
      case 'commercialLandSale':
        return isShortened ? 'комм. зем.' : 'коммерческая земля';

      case 'shoppingAreaRent':
      case 'shoppingAreaSale':
        return isShortened ? 'торг.пл.' : 'торговая площадь';

      case 'freeAppointmentObjectSale':
      case 'freeAppointmentObjectRent':
        return isShortened ? 'ПСН' : 'помещение свободного назначения';

      case 'publicCateringSale':
      case 'publicCateringRent':
        return 'общепит';

      case 'houseRent':
      case 'houseShareRent':
      case 'houseSale':
      case 'dailyHouseRent':
        return 'дом';

      case 'cottageSale':
      case 'cottageRent':
        return 'коттедж';

      case 'bedRent':
      case 'dailyBedRent':
        return 'койко-место';

      case 'warehouseRent':
      case 'warehouseSale':
        return 'склад';

      case 'flatRent':
      case 'flatSale':
      case 'dailyFlatRent':
      case 'newBuildingFlatSale':
        if (flatType === 'studio') {
          return isApartments ? (isShortened ? 'аппарт. студ.' : 'апартаменты-студия') : 'студия';
        }

        if (flatType === 'openPlan') {
          return isApartments ? 'апартаменты' : 'квартира';
        }

        if (roomsCount) {
          if (isApartments) {
            return roomsCount > 4
              ? isShortened
                ? 'многокомн. апарт.'
                : 'многокомнатные апартаменты'
              : isShortened
                ? `${roomsCount}-комн. апарт.`
                : `${roomsCount}-комнатные апартаменты`;
          }

          return roomsCount > 4
            ? isShortened
              ? 'многокомн. кв.'
              : 'многокомнатная квартира'
            : isShortened
              ? `${roomsCount}-комн. кв.`
              : `${roomsCount}-комнатная квартира`;
        }

        return '';

      case 'landSale':
        return 'участок';

      case 'roomRent':
      case 'roomSale':
      case 'dailyRoomRent':
        return 'комната';

      case 'officeRent':
      case 'officeSale':
        return 'офис';

      case 'domesticServicesRent':
      case 'domesticServicesSale':
        return isShortened ? 'пом. под быт. усл.' : 'помещение под бытовые услуги';

      case 'industryRent':
      case 'industrySale':
        return 'производство';

      case 'businessRent':
      case 'businessSale':
        return 'готовый бизнес';

      case 'carServiceRent':
      case 'carServiceSale':
        return 'автосервис';

      case 'flatShareSale':
        return 'доля';

      case 'houseShareSale':
        return 'часть дома';

      default:
        return '';
    }
  }

  return '';
}
