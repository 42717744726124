import { ca } from '@cian/analytics';

export function trackOpenReviewsGoToAppModal() {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Install_app_banner',
    label: 'desktop',
    name: 'oldevent',
    page: {
      extra: {
        bannerName: 'Скачайте_приложение_отсканируйте_QR-код_107',
      },
    },
    useLastProducts: true,
  });
}
