import { commercialPriceSelector } from './commercialPriceSelector';
import { residentialPriceSelector } from './residentialPriceSelector';
import { IPriceSelectorOptions } from './types';
import { IState } from '../../store';
import { getOfferType, isCommercial } from '../../utils/offer_type';

export function priceSelector(state: IState, options: IPriceSelectorOptions = {}): string | null {
  const {
    offerData: { offer },
  } = state;

  const offerType = getOfferType(offer);

  if (isCommercial(offerType)) {
    return commercialPriceSelector(state, options);
  }

  return residentialPriceSelector(state, options);
}
