import {
  fetchGetOfferCalendar as fetchGetOfferCalendarHelper,
  IGetOfferCalendarRequest,
} from 'shared/repositories/dailyrent/v1/get-offer-calendar';
import { IApplicationContext } from 'shared/types/applicationContext';

import { mapCalendarResponse, mapCalendarResponseError } from './mappers';
import { IGetOfferCalendarMappedResponse, TGetOfferCalendarMappedResponse } from './types';

export const DEFAULT_ERROR_MESSAGE = 'Произошла ошибка при проверке доступности';

export const fetchGetOfferCalendar = async (
  context: IApplicationContext,
  parameters: IGetOfferCalendarRequest,
): Promise<IGetOfferCalendarMappedResponse> => {
  const { httpApi } = context;

  const mappers = { 200: mapCalendarResponse, 400: mapCalendarResponseError };

  const calendar = (await fetchGetOfferCalendarHelper({
    httpApi,
    parameters,
    mappers,
  })) as TGetOfferCalendarMappedResponse;

  if (calendar.statusCode === 400) {
    throw new Error(DEFAULT_ERROR_MESSAGE);
  }

  return calendar.response;
};
