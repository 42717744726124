import { IState } from '../../store';

const tagsMapping: Record<string, string> = {
  pledge: 'encumbranceRealty',
  trades: 'tradeRealty',
};

export const selectAgentLists = (state: IState): string[] => {
  const agentLists = state.offerData.agent?.agentLists || [];
  const tags = state.offerData.offer?.bargainTerms?.tags || [];

  const result = [...agentLists];

  tags.forEach(rawTag => {
    const tag: string | undefined = tagsMapping[rawTag];
    if (tag && !result.includes(tag)) {
      result.push(tag);
    }
  });

  return result;
};
