import { DEFAULT_ERROR_MESSAGE } from './constants';
import { mappers } from './mappers';
import { IIGetPaymentUrlResult } from './types';
import { fetchGetPaymentUrl, IGetPaymentUrlRequest } from '../../../repositories/iap-reports-sale/v1/get-payment-url';
import { IApplicationContext } from '../../../types/applicationContext';
import { ResponseError } from '../../../utils/errors';

export const fetchBuyAnalyticsPaymentUrl = async (
  context: IApplicationContext,
  parameters: IGetPaymentUrlRequest,
): Promise<IIGetPaymentUrlResult> => {
  const { httpApi, logger } = context;

  try {
    const result = (await fetchGetPaymentUrl<IIGetPaymentUrlResult, IIGetPaymentUrlResult>({
      httpApi,
      parameters,
      mappers,
    })) as IIGetPaymentUrlResult;

    if (result.errorMessage) {
      logger.warning(
        new ResponseError({
          domain:
            'shared/services/commercialAnalyticsReports/fetchBuyAnalyticsPaymentUrl/service.ts#commercialAnalyticsReports#fetchBuyAnalyticsPaymentUrl()',
          message: result.errorMessage,
          details: { requestParameters: JSON.stringify(parameters) },
        }),
      );
    }

    return result;
  } catch (error) {
    logger.error(error, {
      domain:
        'shared/services/commercialAnalyticsReports/fetchBuyAnalyticsPaymentUrl/service.ts#commercialAnalyticsReports#fetchBuyAnalyticsPaymentUrl()',
      description: DEFAULT_ERROR_MESSAGE,
      requestParameters: JSON.stringify(parameters),
    });

    return {
      response: null,
      errorKey: 'integration',
      errorMessage: DEFAULT_ERROR_MESSAGE,
    };
  }
};
