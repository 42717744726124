/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ICategoryItemsSchema } from '../../entities/infrastructure/CategoryItemsSchema';

export type TGetInfrastructureForMapModel = IModel<IGetInfrastructureForMapRequest, TGetInfrastructureForMapResponse>;

export interface IGetInfrastructureForMapRequest {
  /** Соотношение сторон через двоеточие **/
  aspectRatio?: string | null;
  /** Координаты левого нижнего и правого верхнего угла через запятую **/
  bbox?: string | null;
  /** Тип сделки **/
  dealType: EDealType;
  /** Тип объявления **/
  offerType: EOfferType;
  /** Realty ID объявления **/
  realtyOfferId: number;
}

export type TGetInfrastructureForMapResponse =
  | IGetInfrastructureForMapResponse200
  | IGetInfrastructureForMapResponse400;

export interface IGetInfrastructureForMapResponse200 extends IModelResponse<IGetInfrastructureForMapResponse> {
  statusCode: 200;
}

export interface IGetInfrastructureForMapResponse400 extends IModelResponse<IGetInfrastructureForMapResponseError> {
  statusCode: 400;
}

export interface IGetInfrastructureForMapResponse {
  /** Координаты углов **/
  bbox: string;
  /** Список категорий с объектами **/
  infrastructure: ICategoryItemsSchema[];
}

export interface IGetInfrastructureForMapResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetInfrastructureForMapResponse | IGetInfrastructureForMapResponseError;

export enum EDealType {
  /** Аренда **/
  Rent = 'rent',
  /** Продажа **/
  Sale = 'sale',
}

export enum EOfferType {
  /** коммерческая **/
  Commercial = 'commercial',
  /** квартиры **/
  Flat = 'flat',
  /** новостройки **/
  Newobject = 'newobject',
  /** загородка **/
  Suburban = 'suburban',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetInfrastructureForMapResponse): TResponse200;
  400(response: IGetInfrastructureForMapResponseError): TResponse400;
}
