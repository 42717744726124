import {
  ISubmitContactsResponse200,
  TSubmitContactsResponse,
} from '../../../repositories/newbuilding-reserved-offers/v1/submit-contacts';
import { ResponseError } from '../../../utils/errors';

export function assertResponseStatus(
  response: TSubmitContactsResponse,
): asserts response is ISubmitContactsResponse200 {
  if (response.statusCode === 400) {
    const { errors, message } = response.response;

    throw new ResponseError({
      domain: 'NewbuildingCard.services.fetchSubmitContactsForBookedApartment',
      message,
      details: { errors },
    });
  }
}
