import { TGetPaymentUrlErrorKey, TIGetPaymentUrlMappers } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prepareErrorKey = (errors: any[]): TGetPaymentUrlErrorKey => {
  if (errors.length === 0) {
    return null;
  }

  return errors[0].key;
};

export const mappers: TIGetPaymentUrlMappers = {
  200: response => ({
    response,
    errorKey: null,
    errorMessage: null,
  }),
  400: ({ message, errors }) => ({
    response: null,
    errorKey: prepareErrorKey(errors),
    errorMessage: message,
  }),
};
