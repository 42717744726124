import { pathOr } from 'ramda';

import { IWorkTimeInfoSchema } from '../../repositories/offer-card/entities/offer/WorkTimeInfoSchema';
import { IState } from '../../store';

export const selectWorkTimeInfo = (state: IState) => {
  const workTimeInfo = pathOr<IWorkTimeInfoSchema | null>(null, ['offerData', 'offer', 'workTimeInfo'], state);

  if (!workTimeInfo) {
    return null;
  }

  const { callFrom, callTo, timezone, isAvailable } = workTimeInfo;

  if (callFrom && callTo && typeof timezone === 'number') {
    return { callFrom, callTo, timezone, isAvailable };
  }

  return null;
};
