import {
  EBestPlaceReportModalActionTypes,
  TBestPlaceReportModalActions,
} from 'shared/actions/bestPlaceReportModal/actions';
import { IBestPlaceReportModalState } from 'shared/types/bestPlaceReportModal';

export const DEFAULT_STATE: IBestPlaceReportModalState = {
  isOpened: false,
};

export const bestPlaceModalReducer = (
  state: IBestPlaceReportModalState = DEFAULT_STATE,
  action: TBestPlaceReportModalActions,
): IBestPlaceReportModalState => {
  switch (action.type) {
    case EBestPlaceReportModalActionTypes.OpenBestPlaceReportModal:
      return {
        ...state,
        isOpened: true,
      };
    case EBestPlaceReportModalActionTypes.CloseBestPlaceReportModal:
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};
