import { isNil } from 'ramda';

import { IOfferSchema, IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCategory } from './getCategory';
import { getCustomUrl } from './getCustomUrl';
import { getDealTypeSopr } from './getDealTypeSopr';
import { DEFAULT_OBJECT_TYPE } from '../../constants/ebc';
import { IPageExtra, ITrackingPageData } from '../../types';

interface IPageTrackingDataParams {
  agent: IAgentSchema | null;
  breadCrumbs: string[];
  offer: IOfferSchema;
  extra?: IPageExtra;
}

export function createPageTrackingData(params: IPageTrackingDataParams): ITrackingPageData {
  const { agent, offer, breadCrumbs, extra } = params;

  return {
    customPageURL: getCustomUrl(offer, agent),
    dealType: getDealTypeSopr(offer),
    objectType: getCategory(offer) || DEFAULT_OBJECT_TYPE,
    offerID: offer.cianId,
    offerPhone:
      offer.phones && offer.phones.length > 0 ? `${offer.phones[0].countryCode}${offer.phones[0].number}` : '',
    pageType: 'Card',
    region:
      offer.trackingData &&
      !isNil(offer.trackingData.moId) &&
      !isNil(offer.trackingData.oblId) &&
      !isNil(offer.trackingData.cityId)
        ? `${offer.trackingData.moId}-${offer.trackingData.oblId}-${offer.trackingData.cityId}`
        : '1',
    siteType: 'desktop',
    breadCrumbs,
    extra,
  };
}
