import { IState } from 'shared/store';
import { isSuburbanOfferFromBuilder } from 'shared/utils/suburban';

import { selectOffer } from './selectOffer';

export const selectIsFromSuburbanBuilder = (state: IState): boolean => {
  const offer = selectOffer(state);

  return isSuburbanOfferFromBuilder(offer);
};
