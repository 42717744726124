import { IDailyrentBookingCalendarState } from 'shared/store/dailyrentBookingCalendar/types';
import { TDaysAvailability } from 'shared/types/bookingCalendar';
import { IOfferData } from 'shared/types/offerData';

import { ITypedReduxAction } from '../../types/redux';

export enum EDailyrentBookingCalendarActionTypes {
  SetDaysAvailability = 'dailyrentBookingCalendar/setDaysAvailability',
  SetError = 'dailyrentBookingCalendar/setError',
  SetInitialLoading = 'dailyrentBookingCalendar/setInitialLoading',
  SetAvailabilityLoading = 'dailyrentBookingCalendar/setAvailabilityLoading',
  ToggleCalendarModal = 'dailyrentBookingCalendar/toggleCalendarModal',
  SetDates = 'dailyrentBookingCalendar/setDates',
  SetBookingCalendarDataFromOffer = 'dailyrentBookingCalendar/setBookingCalendarDataFromOffer',
  SetBookingCalendarAvailabilityData = 'dailyrentBookingCalendar/setBookingCalendarAvailabilityData',
  SetDailyrentCanBeBooked = 'dailyrentBookingCalendar/setDailyrentCanBeBooked',
  SetDailyrentGuestsCount = 'dailyrentBookingCalendar/setDailyrentGuestsCount',
}

export type TBookingAvailabilityData = Pick<
  IDailyrentBookingCalendarState,
  'defaultBookingMessage' | 'dateInputText' | 'price' | 'paymentSummary'
>;

export type TToggleCalendarModal = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.ToggleCalendarModal,
  boolean | void
>;
export type TSetError = ITypedReduxAction<EDailyrentBookingCalendarActionTypes.SetError, string>;
export type TSetInitialLoading = ITypedReduxAction<EDailyrentBookingCalendarActionTypes.SetInitialLoading, boolean>;
export type TSetAvailabilityLoading = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetAvailabilityLoading,
  boolean
>;
export type TSetDaysAvailability = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetDaysAvailability,
  TDaysAvailability
>;
export type TSetDates = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetDates,
  { from: string | undefined; to: string | undefined }
>;
export type TSetBookingCalendarDataFromOffer = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetBookingCalendarDataFromOffer,
  IOfferData
>;
export type TSetBookingCalendarAvailabilityData = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetBookingCalendarAvailabilityData,
  TBookingAvailabilityData
>;
export type TSetDailyrentCanBeBooked = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetDailyrentCanBeBooked,
  boolean
>;
export type TSetDailyrentGuestsCount = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetDailyrentGuestsCount,
  number
>;
export type TDailyrentBookingCalendarActions =
  | TToggleCalendarModal
  | TSetDaysAvailability
  | TSetInitialLoading
  | TSetAvailabilityLoading
  | TSetError
  | TSetDates
  | TSetBookingCalendarDataFromOffer
  | TSetBookingCalendarAvailabilityData
  | TSetDailyrentCanBeBooked
  | TSetDailyrentGuestsCount;
