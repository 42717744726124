import { Reducer } from '@reduxjs/toolkit';

import {
  EAddFeedbackStatus,
  EFeedbackActionTypes,
  IAddFeedbackStatusChanged,
  IFeedbackComplaintPopupClosed,
  IFeedbackComplaintPopupOpened,
  IFeedbackFormPopupClosed,
  IFeedbackFormPopupOpened,
  IFeedbackPopupClosed,
  IFeedbackPopupOpened,
  IFeedbackSentPopupClosed,
  IFeedbackSentPopupOpened,
  ISetFeedbackSent,
} from '../actions/feedback';

export interface IFeedback {
  status: EAddFeedbackStatus;
  isPopupOpened: boolean;
  isFormPopupOpened: boolean;
  isComplaintPopupOpened: boolean;
  isFeedbackSent: boolean;
  isFeedbackSentPopupOpened: boolean;
}

export type TFeedbackActions =
  | IFeedbackPopupOpened
  | IFeedbackPopupClosed
  | IFeedbackFormPopupOpened
  | IFeedbackFormPopupClosed
  | IFeedbackComplaintPopupOpened
  | IFeedbackComplaintPopupClosed
  | IAddFeedbackStatusChanged
  | ISetFeedbackSent
  | IFeedbackSentPopupOpened
  | IFeedbackSentPopupClosed;

const initialState = {
  isComplaintPopupOpened: false,
  isFeedbackSent: false,
  isFeedbackSentPopupOpened: false,
  isFormPopupOpened: false,
  isPopupOpened: false,
  status: EAddFeedbackStatus.Initial,
};

export const feedbackReducer: Reducer<IFeedback> = (state = initialState, action: TFeedbackActions) => {
  switch (action.type) {
    case EFeedbackActionTypes.openFeedbackPopup:
      return {
        ...state,
        isPopupOpened: true,
      };

    case EFeedbackActionTypes.closeFeedbackPopup:
      return {
        ...state,
        isPopupOpened: false,
      };

    case EFeedbackActionTypes.openFeedbackFormPopup:
      return {
        ...state,
        isFormPopupOpened: true,
      };

    case EFeedbackActionTypes.closeFeedbackFormPopup:
      return {
        ...state,
        isFormPopupOpened: false,
      };

    case EFeedbackActionTypes.openFeedbackComplaintPopup:
      return {
        ...state,
        isComplaintPopupOpened: true,
      };

    case EFeedbackActionTypes.closeFeedbackComplaintPopup:
      return {
        ...state,
        isComplaintPopupOpened: false,
      };

    case EFeedbackActionTypes.addFeedbackStatusChanged:
      return {
        ...state,
        status: action.status,
      };

    case EFeedbackActionTypes.setFeedbackSent:
      return {
        ...state,
        isFeedbackSent: true,
      };

    case EFeedbackActionTypes.openFeedbackSentPopup:
      return {
        ...state,
        isFeedbackSentPopupOpened: true,
      };

    case EFeedbackActionTypes.closeFeedbackSentPopup:
      return {
        ...state,
        isFeedbackSentPopupOpened: false,
      };

    default:
      return state;
  }
};
