// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IAgentSchema } from '../agent/AgentSchema';
import type { IAgentAvailabilityInfoSchema } from '../agent_availability/AgentAvailabilityInfoSchema';
import type { IGetReviewsSchema } from '../get_reviews/GetReviewsSchema';
import type { IOfferOfferAmenitiesGroupSchema } from '../offer_amenities/OfferOfferAmenitiesGroupSchema';
import type { IBreadcrumbForCardItemSchema } from '../offer/BreadcrumbForCardItemSchema';
import type { IBtiDataSchema } from '../bti_data/BtiDataSchema';
import type { IBusinessShoppingCenterCardSchema } from '../business_shopping_center/BusinessShoppingCenterCardSchema';
import type { ICompanyWithFeaturesSchema } from '../company_with_features/CompanyWithFeaturesSchema';
import type { ICountrysideProjectsSchema } from '../countryside_project/CountrysideProjectsSchema';
import type { IIncludedAmenitiesGroupSchema } from '../coworking_included_amenities/IncludedAmenitiesGroupSchema';
import type { INewbuildingOfferDecorationInfoSchema } from '../newbuilding_offer_decoration/NewbuildingOfferDecorationInfoSchema';
import type { IDegradationInfoSchema } from '../degradation_info/DegradationInfoSchema';
import type { IDiscountSchema } from '../discount/DiscountSchema';
import type { IFactoidSchema } from '../factoids/FactoidSchema';
import type { IOfferFeaturesGroupSchema } from '../offer_features/OfferFeaturesGroupSchema';
import type { IFloorPlanSchema } from '../floor_plan/FloorPlanSchema';
import type { IFormattedAreaPartsInfoSchema } from '../formatted_area_parts_info/FormattedAreaPartsInfoSchema';
import type { IHouseCardEntrypointDataSchema } from './HouseCardEntrypointDataSchema';
import type { INewbuildingHouseDecorationSchema } from '../newbuilding_decoration/NewbuildingHouseDecorationSchema';
import type { IIdenticalOffersSchema } from '../duplicates/IdenticalOffersSchema';
import type { IVillageForIntegrationSchema } from '../village/VillageForIntegrationSchema';
import type { INewBuildingWithFeaturesSchema } from '../newbuilding_with_features/NewBuildingWithFeaturesSchema';
import type { INewbuildingDynamicCalltrackingSchema } from '../newbuilding_dynamic_calltracking/NewbuildingDynamicCalltrackingSchema';
import type { INewbuildingFlatViewOrderSchema } from '../newbuilding_flat_view_order/NewbuildingFlatViewOrderSchema';
import type { IReliabilityInfoSchema } from '../newbuilding_reliability/ReliabilityInfoSchema';
import type { INewbuildingsNearbySchema } from '../newbuilding_nearby/NewbuildingsNearbySchema';
import type { IGetNoteForOfferResponseSchema } from '../get_note_for_offer_response/GetNoteForOfferResponseSchema';
import type { IOffenceSchema } from '../offence/OffenceSchema';
import type { IOfferSchema } from '../offer/OfferSchema';
import type { IOfferComparisonStatusSchema } from '../offers_comparison/OfferComparisonStatusSchema';
import type { IOfferPriceChangeSchema } from '../offer_price_change/OfferPriceChangeSchema';
import type { IPriceInfoSchema } from '../price_info/PriceInfoSchema';
import type { IPriceRelevanceSchema } from '../price_relevance/PriceRelevanceSchema';
import type { IRosreestrInfoSchema } from '../rosreestr_info/RosreestrInfoSchema';
import type { IRosreestrInfoV2Schema } from '../rosreestr_info/RosreestrInfoV2Schema';
import type { ISeoDataSchema } from '../seo_data/SeoDataSchema';
import type { ISeoLinkSchema } from '../seo_link/SeoLinkSchema';
import type { ISidebarItemSchema } from '../sidebar/SidebarItemSchema';
import type { INearbyNewbuildingOfferSchema } from '../nearby_newbuilding_offer/NearbyNewbuildingOfferSchema';
import type { ISpecialProjectItemSchema } from '../offer/SpecialProjectItemSchema';
import type { IOfferStatsSchema } from '../offer_stats/OfferStatsSchema';
import type { ICheckUserSubscriptionV1ItemSchema } from '../check_user_subscription_v1_item/CheckUserSubscriptionV1ItemSchema';
import type { ITourV2Schema } from '../newbuilding_tour/TourV2Schema';
import type { IToursSchema } from '../tour/ToursSchema';
import type { IUserSchema } from '../user/UserSchema';

export interface IAllOfferDataSchema {
  /** Информация по агенту **/
  agent?: IAgentSchema;
  /** Информация о доступности агента **/
  agentAvailability?: IAgentAvailabilityInfoSchema;
  /** Отзывы об агенте **/
  agentReviews?: IGetReviewsSchema;
  /** Группы включенных услуг **/
  amenities?: IOfferOfferAmenitiesGroupSchema[];
  /** ID пользователей участников электронного аукциона **/
  auctionUserIds?: number[];
  /** Уровень брендинга по подписке **/
  brandingLevel?: EBrandingLevel;
  /** Хлебные крошки **/
  breadcrumbs: IBreadcrumbForCardItemSchema[];
  /** Данные БТИ **/
  bti?: IBtiDataSchema;
  /** Информация о бизнес-центре **/
  businessShoppingCenter?: IBusinessShoppingCenterCardSchema;
  /** Информация по компании разместившей объявление **/
  company?: ICompanyWithFeaturesSchema;
  /** Проекты загородки **/
  countrysideProjects?: ICountrysideProjectsSchema;
  /** Включено в аренду коворкинга **/
  coworkingIncludedAmenities?: IIncludedAmenitiesGroupSchema[];
  /** Информация об отделке оффера **/
  decorationInfo?: INewbuildingOfferDecorationInfoSchema;
  /** Информация о деградации **/
  degradationInfo?: IDegradationInfoSchema;
  /** Скидка **/
  discount?: IDiscountSchema;
  /** Ссылка на внешнюю карточку объявки **/
  externalOfferUrl?: string;
  /** Фактоиды **/
  factoids?: IFactoidSchema[];
  /** Наборы табличных свойств объекта **/
  features?: IOfferFeaturesGroupSchema[];
  /** План этажа **/
  floorPlan?: IFloorPlanSchema;
  /** Информация о вакантных площадях для объявлений частями **/
  formattedAreaParts?: IFormattedAreaPartsInfoSchema;
  /** [DEPRECATED] Точка входа на карточку дома **/
  houseCardEntrypointData?: IHouseCardEntrypointDataSchema;
  /** Отделка в корпусе **/
  housingDecorations?: INewbuildingHouseDecorationSchema[];
  /** Информация о дублях **/
  identicalOffers?: IIdenticalOffersSchema;
  /** Забронирована ли квартира у застройщика **/
  isBookedFromDeveloper?: boolean;
  /** Оффер находится в избранном у текущего пользователя **/
  isFavorite?: boolean;
  /** Оффер находится в скрытых у текущего пользователя **/
  isHiddenByUser?: boolean;
  /** Доступна регистрация через Циан.Сделка **/
  isOnlineRegistrationAvailable?: boolean;
  /** Наличие субсидированной ипотеки **/
  isSubsidisedMortgage?: boolean;
  /** Информация о коттеджном посёлке **/
  kp?: IVillageForIntegrationSchema;
  /** Ссылка на карту с похожими объявлениями **/
  linkToMap?: string;
  /** Данные по новостройке **/
  newObject?: INewBuildingWithFeaturesSchema;
  /** Информация по динамическому calltracking новостроек **/
  newbuildingDynamicCalltracking?: INewbuildingDynamicCalltrackingSchema;
  /** Ссылка для баннера переориентации выдачи на первичку **/
  newbuildingFlatSaleQueryString?: string;
  /** Информация о записи на просмотр в ЖК **/
  newbuildingFlatViewOrder?: INewbuildingFlatViewOrderSchema;
  /** Рейтинг новостройки **/
  newbuildingRating?: number;
  /** Информация о надежности ЖК **/
  newbuildingReliability?: IReliabilityInfoSchema;
  /** Новостройки поблизости **/
  newbuildingsNearby?: INewbuildingsNearbySchema;
  /** Заметки пользователя **/
  notes?: IGetNoteForOfferResponseSchema;
  /** Список отзывов модератора **/
  offences?: IOffenceSchema[];
  /** Оффер **/
  offer: IOfferSchema;
  /** Количество подборок текущего пользователя, в которые добавлен оффер **/
  offerFoldersCount?: number;
  /** Цена в карточке объявления **/
  offerPrice?: string;
  /** Статус сравнения объявления **/
  offersComparisonStatus?: IOfferComparisonStatusSchema;
  /** История изменения цены объявления **/
  priceChanges?: IOfferPriceChangeSchema[];
  /** Информация о стоимости **/
  priceInfo?: IPriceInfoSchema;
  /** Актуальность цены **/
  priceRelevance?: IPriceRelevanceSchema;
  /** Информация по росреестру **/
  rosreestrInfo?: IRosreestrInfoSchema;
  /** Информация по росреестру v2 **/
  rosreestrInfoV2?: IRosreestrInfoV2Schema;
  /** Данные для SEO-разметки карточки **/
  seoData?: ISeoDataSchema;
  /** SEO перелинковка **/
  seoLinks: ISeoLinkSchema[];
  /** Сайдбар **/
  sidebar?: ISidebarItemSchema[];
  /** Новостройки в том же районе **/
  similarNewObjects?: INearbyNewbuildingOfferSchema[];
  /** Строка запроса для получения похожих объявлений на карте **/
  similarOffersMapQs?: string;
  /** Спец проект **/
  specialProject?: ISpecialProjectItemSchema;
  /** Статистика по офферу **/
  stats?: IOfferStatsSchema;
  /** Подписка пользователя на изменения цены **/
  subscription?: ICheckUserSubscriptionV1ItemSchema;
  /** Информация о туре по квартире **/
  tourV2?: ITourV2Schema;
  /** Туры по недвижимости **/
  tours?: IToursSchema;
  /** URL страницы с метками для трекинга **/
  trackingUrl?: string;
  /** Текущий пользователь **/
  user?: IUserSchema;
}
export enum EBrandingLevel {
  'FirstLevel' = 'firstLevel',
  'SecondLevel' = 'secondLevel',
  'ThirdLevel' = 'thirdLevel',
}
