import { getIsFlatViewOrderEnabled } from './getIsFlatViewOrderEnabled';
import { IState } from '../../../store';
import { getNewbuildingId } from '../../newObject/getNewbuildingId';

export function canMakeFlatViewOrder(state: IState): boolean {
  const isEnabled = getIsFlatViewOrderEnabled(state);
  const newbuildingId = getNewbuildingId(state);

  return Boolean(newbuildingId && isEnabled);
}
