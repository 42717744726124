import {
  TNewbuildingBookedFromDeveloperActions,
  ENewbuildingBookedFromDeveloperActionTypes,
} from '../../actions/newbuildingBookedFromDeveloper';
import {
  EBookingFromDeveloperStatus,
  INewbuildingBookedFromDeveloperState,
} from '../../types/newbuildingBookedFromDeveloper';

export const DEFAULT_STATE: INewbuildingBookedFromDeveloperState = {
  isConfirmOpen: false,
  status: EBookingFromDeveloperStatus.Initial,
};

export const newbuildingBookedFromDeveloperReducer = (
  state: INewbuildingBookedFromDeveloperState = DEFAULT_STATE,
  action: TNewbuildingBookedFromDeveloperActions,
): INewbuildingBookedFromDeveloperState => {
  switch (action.type) {
    case ENewbuildingBookedFromDeveloperActionTypes.CloseConfirm:
      return {
        ...state,
        isConfirmOpen: false,
        status: EBookingFromDeveloperStatus.Initial,
      };

    case ENewbuildingBookedFromDeveloperActionTypes.CloseSuccess:
      return {
        ...state,
        isConfirmOpen: false,
        status: EBookingFromDeveloperStatus.Initial,
      };

    case ENewbuildingBookedFromDeveloperActionTypes.OpenConfirm:
      return {
        ...state,
        isConfirmOpen: true,
      };

    case ENewbuildingBookedFromDeveloperActionTypes.SetBookingFailure:
      return {
        ...state,
        status: EBookingFromDeveloperStatus.Failure,
      };

    case ENewbuildingBookedFromDeveloperActionTypes.SetBookingLoading:
      return {
        ...state,
        status: EBookingFromDeveloperStatus.Loading,
      };

    case ENewbuildingBookedFromDeveloperActionTypes.SetBookingSuccess:
      return {
        ...state,
        status: EBookingFromDeveloperStatus.Success,
      };

    default:
      return state;
  }
};
