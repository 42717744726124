export const SUBURBAN = [
  'cottageRent',
  'cottageSale',
  'dailyHouseRent',
  'houseRent',
  'houseSale',
  'houseShareRent',
  'houseShareSale',
  'landRent',
  'landSale',
  'townhouseRent',
  'townhouseSale',
];
