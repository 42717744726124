import { EEstimationPeriods } from 'shared/repositories/price-estimator/entities/estimation_trend/OfferCardEstimationV3ResponseSchema';

import { actionGenerator } from '../../../utils/redux/actionGenerator';
import { TOfferValuationData } from '../types';

export enum EActionTypes {
  Request = 'offerValuation/request',
  Success = 'offerValuation/success',
  Failure = 'offerValuation/failure',
  SetEstimationPeriod = 'offerValuation/setEstimationPeriod',
  SetIsAdmin = 'offerValuation/setIsAdmin',
  ResetEstimation = 'offerValuation/resetEstimation',
}

export const requestAction = actionGenerator<EActionTypes.Request>(EActionTypes.Request);
export const successAction = actionGenerator<EActionTypes.Success, TOfferValuationData>(EActionTypes.Success);
export const failureAction = actionGenerator<EActionTypes.Failure>(EActionTypes.Failure);
export const setEstimationPeriod = actionGenerator<EActionTypes.SetEstimationPeriod, EEstimationPeriods>(
  EActionTypes.SetEstimationPeriod,
);
export const setIsAdmin = actionGenerator<EActionTypes.SetIsAdmin, boolean>(EActionTypes.SetIsAdmin);
export const resetEstimation = actionGenerator<EActionTypes.ResetEstimation>(EActionTypes.ResetEstimation);

export type TOfferValuationActions =
  | ReturnType<typeof requestAction>
  | ReturnType<typeof successAction>
  | ReturnType<typeof failureAction>
  | ReturnType<typeof setEstimationPeriod>
  | ReturnType<typeof setIsAdmin>
  | ReturnType<typeof resetEstimation>;
