import { UnknownError } from '@cian/peperrors/shared';

import {
  IAddOfferToComparisonResponse200,
  TAddOfferToComparisonResponse,
} from '../../../../repositories/offers-comparison/v2/add-offer-to-comparison';

export function assertUnknownError(
  response: TAddOfferToComparisonResponse,
): asserts response is IAddOfferToComparisonResponse200 {
  if (response.statusCode === 200) {
    return;
  }

  const { message, errors } = response.response;

  throw new UnknownError({
    domain: 'src/shared/services/offersComparison/addOfferToComparison.ts',
    message,
    details: {
      errors,
    },
  });
}
