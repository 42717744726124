/* eslint-disable max-lines, @typescript-eslint/no-non-null-assertion */
import { fetchGenplan } from '../../../node/services/fetchGenplan';
import { IGenplanScheme } from '../../types/genplan';
import { IThunkActionCreator } from '../index';

const SET_GENPLAN_ACTION = 'ISetGenplan';

export interface ISetGenplan {
  type: typeof SET_GENPLAN_ACTION;
  payload: IGenplanScheme | null;
}

interface IParams {
  kpId: number;
}

export function getGenplan(parameters: IParams): IThunkActionCreator<Promise<void>> {
  return async (dispatch, _, context) => {
    const genplan = await fetchGenplan(context, parameters);

    dispatch<ISetGenplan>({
      payload: genplan,
      type: SET_GENPLAN_ACTION,
    });
  };
}

export function genplanReducer(state: IGenplanScheme | null = null, action: ISetGenplan): IGenplanScheme | null {
  switch (action.type) {
    case SET_GENPLAN_ACTION:
      return action.payload;
    default:
      return state;
  }
}
