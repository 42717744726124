import { compose } from 'ramda';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export enum FDealType {
  Unexpected = 0, // Do not use
  Sale = 1 << 0,
  RentLongterm = 1 << 1,
  RentDaily = 1 << 2,
  Rent = RentLongterm | RentDaily,
  Any = Sale | Rent,
}

export function getDealType(offer: IOfferSchema): FDealType {
  const category = offer.category.toLowerCase();

  if (category.indexOf('daily') >= 0) {
    return FDealType.RentDaily;
  } else if (category.indexOf('rent') >= 0) {
    return FDealType.RentLongterm;
  } else if (category.indexOf('sale') >= 0) {
    return FDealType.Sale;
  }

  return FDealType.Unexpected;
}

export function isSale(dealType: FDealType) {
  return !!(dealType & FDealType.Sale);
}

export const getIsSaleByOffer = compose(isSale, getDealType);

export function isRent(dealType: FDealType) {
  return !!(dealType & FDealType.Rent);
}

export function isLongtermRent(dealType: FDealType) {
  return !!(dealType & FDealType.RentLongterm);
}

export const getIsRentByOffer = compose(isRent, getDealType);
