import { createSelector } from '@reduxjs/toolkit';

import { getAgentAvailabilityTitle } from 'shared/utils/getAgentAvailabilityTitle';

import { IState } from '../../store';

const selectAgentAvailability = (state: IState) => state.offerData.agentAvailability;

export const getAgentAvailability = createSelector(selectAgentAvailability, agentAvailability => {
  if (!agentAvailability) {
    return null;
  }

  const formattedTitle = getAgentAvailabilityTitle(agentAvailability);

  return { ...agentAvailability, title: formattedTitle };
});
