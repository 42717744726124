export interface IOffersComparisonStatusData {
  offersComparisonStatus: IOffersComparisonStatus | null;
}

export interface IOffersComparisonStatus {
  description: string | null;
  status: EStatus;
}

export enum EStatus {
  /** Уже добавлено в сравнение **/
  Added = 'added',
  /** Доступно для добавления в сравнение **/
  Available = 'available',
  /** Недоступно для добавления в сравнение **/
  Unavailable = 'unavailable',
}
