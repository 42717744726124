import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import {
  EDailyrentBookingCalendarActionTypes,
  TDailyrentBookingCalendarActions,
} from 'shared/actions/dailyrentBookingCalendar/types';
import { IDailyrentBookingCalendarState } from 'shared/store/dailyrentBookingCalendar/types';
import { withNoBreakSpaces } from 'shared/utils/formatters';

const initialState: IDailyrentBookingCalendarState = {
  open: false,
  canBeBooked: true,
  initialLoading: false,
  availabilityLoading: false,
  error: '',
  daysAvailability: [],
  showBookingCalendar: false,
  defaultBookingMessage: '',
  price: '',
  dateInputText: '',
  dates: {
    from: undefined,
    to: undefined,
  },
  guestsCount: 1,
  paymentSummary: null,
};

export const dailyrentBookingCalendarReducer = (
  state: IDailyrentBookingCalendarState = initialState,
  action: TDailyrentBookingCalendarActions,
): IDailyrentBookingCalendarState => {
  switch (action.type) {
    case EDailyrentBookingCalendarActionTypes.SetBookingCalendarDataFromOffer: {
      const {
        offer: { dailyrentAvailability, dailyrent },
      } = action.payload;

      if (!dailyrentAvailability) {
        return state;
      }

      const paymentSummary = dailyrent?.paymentSummary || null;

      const {
        showBookingCalendar,
        canBeBooked = false,
        onlineBookingMessage = '',
        dateInputText = '',
        pricePerDayRur,
      } = dailyrentAvailability;

      const price = pricePerDayRur ? withNoBreakSpaces(numberToPrettyString(pricePerDayRur)) + ' ₽/сут.' : '';

      return {
        ...state,
        showBookingCalendar,
        canBeBooked,
        defaultBookingMessage: onlineBookingMessage,
        dateInputText,
        price,
        paymentSummary,
      };
    }
    case EDailyrentBookingCalendarActionTypes.SetDates:
      return { ...state, dates: action.payload };
    case EDailyrentBookingCalendarActionTypes.ToggleCalendarModal: {
      const open = typeof action.payload === 'boolean' ? action.payload : !state.open;

      return { ...state, open };
    }
    case EDailyrentBookingCalendarActionTypes.SetInitialLoading:
      return { ...state, initialLoading: action.payload };
    case EDailyrentBookingCalendarActionTypes.SetAvailabilityLoading:
      return { ...state, availabilityLoading: action.payload };
    case EDailyrentBookingCalendarActionTypes.SetError:
      return { ...state, error: action.payload };
    case EDailyrentBookingCalendarActionTypes.SetDaysAvailability:
      return { ...state, daysAvailability: action.payload };
    case EDailyrentBookingCalendarActionTypes.SetBookingCalendarAvailabilityData:
      return { ...state, ...action.payload };
    case EDailyrentBookingCalendarActionTypes.SetDailyrentCanBeBooked:
      return { ...state, canBeBooked: action.payload };
    case EDailyrentBookingCalendarActionTypes.SetDailyrentGuestsCount:
      return { ...state, guestsCount: action.payload };
    default:
      return state;
  }
};
