import { RootAction } from 'shared/types/redux/RootAction';
import { getRandomSubarray } from 'shared/utils/array/getRandomSubarray';

import { IBankListState } from '../../types/banksList';

const initialState: IBankListState = {
  list: [],
  randomList: [],
};

export function banksListReducer(state: IBankListState = initialState, action: RootAction): IBankListState {
  switch (action.type) {
    case 'IMortgageDataFetched': {
      const { banksList } = action.payload;
      const actualBankIds = banksList.map(bank => bank.name);
      const actualBanks = state.list.filter(bank => actualBankIds.includes(bank.id));

      return {
        ...state,
        randomList: actualBanks.length > 4 ? getRandomSubarray(actualBanks, 4) : actualBanks,
      };
    }

    default:
      return state;
  }
}
