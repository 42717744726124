// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IOfferHistoryStatusCountItemSchema {
  /** Количество предложений для заданного статуса **/
  offersCount: number;
  /** Статус объявления **/
  status: EStatus;
}
export enum EStatus {
  /** Снято с публикации **/
  'Deactivated' = 'deactivated',
  /** Опубликовано **/
  'Published' = 'published',
}
