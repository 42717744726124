import { ETrackingReducerActions, ITrackingReducerAction, ITrackingReducerState } from './types';

const initState = {
  user: {},
  page: {},
} as ITrackingReducerState;

export function trackingReducer(
  state: ITrackingReducerState = initState,
  { type, payload }: ITrackingReducerAction,
): ITrackingReducerState {
  switch (type) {
    case ETrackingReducerActions.SetTrackingState:
      return { ...state, user: payload.user, page: payload.page };
    default:
      return state;
  }
}
