import { EPhoneWaringActionTypes, TPhoneWaringActions } from '../../actions/phoneWarning/actions';
import { IPhoneWarningState } from '../../types/phoneWarning';

const DEFAULT_STATE: IPhoneWarningState = {
  isOpen: false,
  target: null,
};

export const phoneWarningReducer = (
  state: IPhoneWarningState = DEFAULT_STATE,
  action: TPhoneWaringActions,
): IPhoneWarningState => {
  switch (action.type) {
    case EPhoneWaringActionTypes.Close:
      return {
        ...state,
        isOpen: false,
        target: null,
      };

    case EPhoneWaringActionTypes.Open:
      return {
        ...state,
        isOpen: true,
        target: action.payload,
      };

    default:
      return state;
  }
};
