import { fetchHideOfferInHistory } from 'shared/services/offersHistory/fetchHideOfferInHistory';
import { IThunkActionCreator } from 'shared/store';

export const hideOfferInHistory =
  (cianId: number): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState, context) => {
    const parameters = {
      cianId,
    };

    try {
      await fetchHideOfferInHistory(context, parameters);

      return;
    } catch (e) {
      context.logger.error(e);

      throw e;
    }
  };
