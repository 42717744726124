import { HttpBadStatusCodeError } from '@cian/peperrors/shared';
import { path } from 'ramda';

import { AuthRequiredError } from '../../../errors/AuthRequiredError';
import {
  IGetOfferFromHistoryWebRequest,
  IMappers,
} from '../../../repositories/valuation-offer-history/v2/get-offer-from-history-web';
import { TOfferFromHistoryData } from '../../../store/offerHistory';

export const offerFromHistoryMapper = (
  parameters: IGetOfferFromHistoryWebRequest,
): IMappers<TMappedResponse200, TMappedResponse400> => ({
  200: response => response,
  400: ({ message, errors }) => {
    if (path([0, 'key'], errors) === 'auth') {
      return new AuthRequiredError({
        domain: 'getOfferFromHistoryService.ts',
        message: message || /* istanbul ignore next */ '',
        details: { ...parameters },
      });
    }

    return new HttpBadStatusCodeError({
      domain: 'getOfferFromHistoryService.ts',
      statusCode: 400,
      message: message || /* istanbul ignore next */ '',
      details: { ...parameters },
    });
  },
});

export type TMappedResponse200 = TOfferFromHistoryData;
export type TMappedResponse400 = HttpBadStatusCodeError | AuthRequiredError;

export type TMappedResponse = TMappedResponse200 | TMappedResponse400;
