import { IRSState } from './types';

export const initialState: IRSState = {
  buyAnalyticsFreeReportData: {
    name: '',
    date: '',
    url: '',
  },
  buyAnalyticsLegalDocsUrl: '',
  bestPlaceAnalyticsReportExampleUrl: '',
  bestPlaceReportLegalDocsUrl: '',
  bestPlaceReportBaseUrl: '',
  bestPlaceReportProviderInfo: '',
};

export function rsReducer(state: IRSState = initialState): IRSState {
  return state;
}
