import { EBuyAnalyticsFormStatus } from '../../types';

export const CONTENT_LIST = [
  'Аналитика цен за 3 года',
  'Технические характеристики',
  'Кадастровый номер и стоимость',
  'Подборка похожих зданий с диапазонами цен',
  'Вакантные помещения и объявления с Циан',
  'Информация по текущим арендаторам',
  'Правообладатели и обременения по ним (при наличии выписки из Росреестра)',
  'Формат готовой презентации',
];

export const ERROR_MESSAGES_MAP = {
  empty: 'Обязательное поле',
  invalid: 'Неправильный формат email',
};

export const STATUS_MESSAGES: { [key in EBuyAnalyticsFormStatus]: string | null } = {
  [EBuyAnalyticsFormStatus.Pending]: 'Ожидаем подтверждение платежа',
  [EBuyAnalyticsFormStatus.Error]: 'Произошла ошибка, попробуйте ещё раз',
  [EBuyAnalyticsFormStatus.UserError]:
    'Оплата пока доступна только физлицам. Попробуйте сменить или создать аккаунт и оплатить как физлицо.',
  [EBuyAnalyticsFormStatus.Success]: null,
  [EBuyAnalyticsFormStatus.Canceled]: 'Произошла ошибка, попробуйте ещё раз',
  [EBuyAnalyticsFormStatus.Sending]: 'Загружаем страницу для оплаты отчёта',
};
