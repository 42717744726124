import { HttpTimeoutError } from '@cian/peperrors/shared';

import { fetchActualMortgageData } from '../../api/mortgage/fetchActualMortgageData';
import { fetchMortgageWidgetMarkup } from '../../api/mortgage/fetch_mortgage_widget_markup';
import {
  IMortgageBankCreditRate,
  IMortgageBankPropertyTypeRate,
  IMortgageBanksDataResponse,
  IMortgageBanksDataSettings,
} from '../../types/mortgage/mortgageBanksData';
import { IThunkActionCreator } from '../index';

export interface IMortgageProductRatesState {
  used?: number;
  new?: number;
}

export interface IMortgageRatesState {
  flat: IMortgageProductRatesState;
  suburban: IMortgageProductRatesState;
  appartments: IMortgageProductRatesState;
}

export interface IMortgageState {
  mortgageWidgetMarkup?: string;
  rates: IMortgageRatesState;
  bankIds?: string[];
}

export interface IMortgageWidgetMarkupFetched {
  type: 'IMortgageWidgetMarkupFetched';
  payload: string;
}

export interface IMortgageDataFetched {
  type: 'IMortgageDataFetched';
  payload: IMortgageBanksDataResponse;
}

type TActions = IMortgageWidgetMarkupFetched | IMortgageDataFetched;

const initialState: IMortgageState = {
  rates: {
    flat: {},
    suburban: {},
    appartments: {},
  },
  bankIds: undefined,
};

export function mortgageStateReducer(state: IMortgageState = initialState, action: TActions): IMortgageState {
  switch (action.type) {
    case 'IMortgageWidgetMarkupFetched':
      return {
        ...state,
        mortgageWidgetMarkup: action.payload,
      };
    case 'IMortgageDataFetched': {
      const { creditRate, banksList } = action.payload;

      return {
        ...state,
        rates: prepareMortgageRates(creditRate),
        bankIds: banksList?.length > 0 ? banksList.map(bank => bank.name) : undefined,
      };
    }
    default:
      return state || {};
  }
}

export function fetchMortgageCalculatorWidget(cookie: string): IThunkActionCreator {
  return async (dispatch, getState, context) => {
    const { telemetry } = context;
    const state = getState();

    let widgetMarkup = '';
    try {
      const mortgageWidgetApiUrl = context.config.getStrict<string>('mortgageWidgetApiUrl');

      const flatPrice = state.offerData.offer.bargainTerms.prices.rur;

      const resp = await fetchMortgageWidgetMarkup(context.custom.makeRequest, mortgageWidgetApiUrl, flatPrice, cookie);

      widgetMarkup = resp.responseBody;
    } catch (err) {
      if (err instanceof HttpTimeoutError) {
        telemetry.increment('mortgage_widget_timeout_offer');
      }

      context.logger.warning('[Degradation] Financial widget calculator', err);
    }

    dispatch<IMortgageWidgetMarkupFetched>({
      type: 'IMortgageWidgetMarkupFetched',
      payload: widgetMarkup,
    });
  };
}

export function fetchMortgageRatesState(): IThunkActionCreator {
  return async (dispatch, _getState, context) => {
    const mortgageDataCfg = context.config.get<IMortgageBanksDataSettings>('mortgage.banksData');
    if (!mortgageDataCfg) {
      return;
    }

    const data = await fetchActualMortgageData(mortgageDataCfg, context);
    if (data) {
      dispatch<IMortgageDataFetched>({
        type: 'IMortgageDataFetched',
        payload: data,
      });
    }
  };
}

const rateToPercent = (rate: number | undefined) => (rate ? Math.floor(rate * 10_000) / 100 : rate);

function prepareMortgageRates(creditRate: IMortgageBankCreditRate): IMortgageRatesState {
  return {
    flat: creditRate.flat
      ? {
          used: rateToPercent(creditRate.flat.used?.min),
          new: rateToPercent(creditRate.flat.new?.min),
        }
      : {},
    appartments: creditRate.apartments
      ? {
          used: rateToPercent(creditRate.apartments.used?.min),
          new: rateToPercent(creditRate.apartments.new?.min),
        }
      : {},
    suburban: prepareSuburbanRate(creditRate),
  };
}

function prepareSuburbanRate(creditRate: IMortgageBankCreditRate): IMortgageProductRatesState {
  const mortgageProducts = [creditRate.cottage, creditRate.land].filter(x => !!x) as IMortgageBankPropertyTypeRate[];

  const rates: { used: number[]; new: number[] } = {
    used: [],
    new: [],
  };

  for (const product of mortgageProducts) {
    const newMin = product?.new?.min;
    const usedMin = product?.used?.min;
    if (newMin) {
      rates.new.push(newMin);
    }
    if (usedMin) {
      rates.used.push(usedMin);
    }
  }

  return {
    new: rates.new.length > 0 ? rateToPercent(Math.min(...rates.new)) : undefined,
    used: rates.used.length > 0 ? rateToPercent(Math.min(...rates.used)) : undefined,
  };
}
