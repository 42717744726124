/* eslint-disable max-lines, @typescript-eslint/no-non-null-assertion */
import { IGetVillagePromotionsResponse } from '../../../node/repositories/countryside/v1/get-village-promotions';
import { getVillagePromotions } from '../../../node/services/villagePromotions/village_promotions';
import { IThunkActionCreator } from '../index';

export interface IPromotionsVillageFetched {
  type: 'IPromotionsVillageFetched';
  promotionsVillageData: IGetVillagePromotionsResponse;
}

export function fetchVillagePromotions(villageId: number): IThunkActionCreator<Promise<void>> {
  return (dispatch, getState, context) => {
    const { config, logger } = context;
    const offerCardTimeout = config.get<number>('offerCard.timeout') || undefined;

    return (
      getVillagePromotions(
        context.httpApi,
        {
          villageId,
        },
        logger,
        offerCardTimeout,
      )
        // TODO: тут может прийти IErrorResponseSchema
        .then((promotionsVillageData: IGetVillagePromotionsResponse) => {
          dispatch<IPromotionsVillageFetched>({
            promotionsVillageData,
            type: 'IPromotionsVillageFetched',
          });
        })
    );
  };
}

export type Action = IPromotionsVillageFetched;

export function villagePromotionsReducer(
  state: IGetVillagePromotionsResponse,
  action: Action,
): IGetVillagePromotionsResponse {
  switch (action.type) {
    case 'IPromotionsVillageFetched':
      return {
        ...state,
        ...action.promotionsVillageData,
      } as IGetVillagePromotionsResponse;
    default:
      return state || {};
  }
}
