import { IState } from '../../store';
import {
  TOfferFromHistoryData,
  TRoomsFilterActiveValue,
  TRoomsFilterOptions,
  TStatusFilterActiveValue,
  TStatusFilterOptions,
  TTypeFilterOptions,
  ETypeFilterValues,
  TCategoryFilterOptions,
  TCategoriesFilterActiveValue,
} from '../../store/offerHistory';

export const selectOffersHistoryData = (state: IState) => state.offersHistory.data.listing;

export const selectOffersHistorySpoilerData = (state: IState) => state.offersHistory.data.listingSpoiler;

export const selectStatusFilters = (state: IState): TStatusFilterOptions | null => {
  return state.offersHistory.data.listing.data?.statusFilters || null;
};

export const selectRoomsFilters = (state: IState): TRoomsFilterOptions | null => {
  return state.offersHistory.data.listing.data?.roomsFilters || null;
};

export const selectLocationFilters = (state: IState): TTypeFilterOptions | null => {
  return state.offersHistory.data.listing.data?.locationFilters || null;
};

export const selectCategoriesFilters = (state: IState): TCategoryFilterOptions | null => {
  return state.offersHistory.data.listing.data?.categoriesFilters || null;
};

export const selectIsOfferFromListingLoading = (state: IState): boolean => state.offersHistory.data.offers.isFetching;

export const selectIsOfferFromListingError = (state: IState): boolean => state.offersHistory.data.offers.isError;

export const selectIsOfferFromListingAuthError = (state: IState) => state.offersHistory.data.offers.isAuthError;

export const selectPageNumber = (state: IState): number => state.offersHistory.page;

export const selectResultsOnPage = (state: IState): number => state.offersHistory.resultsOnPage;

export const selectActiveRoomsFilter = (state: IState): TRoomsFilterActiveValue =>
  state.offersHistory.roomsFilterActiveValue;

export const selectActiveStatusFilter = (state: IState): TStatusFilterActiveValue =>
  state.offersHistory.statusFilterActiveValue;

export const selectActiveCategoriesFilter = (state: IState): TCategoriesFilterActiveValue =>
  state.offersHistory.categoriesFilterActiveValue;

export const selectActiveTypeFilter = (state: IState) => state.offersHistory.typeFilterActiveValue;

export const selectActiveOfferId = (state: IState) => state.offersHistory.activeOfferId;

export const selectViewedOffers = (state: IState) => state.offersHistory.viewedOffers;

export const selectDefaultFilters = (state: IState) => state.offersHistory.defaultFilters;

export const selectAdminAccess = (state: IState) => state.offersHistory.haveAdminAccess;

/** Возвращает данные для одного оффера из истории офферов */
export const selectOfferFromHistory = (state: IState): TOfferFromHistoryData | null => {
  const activeOfferId = selectActiveOfferId(state);

  if (!activeOfferId) {
    return null;
  }

  return state.offersHistory.data.offers.data[activeOfferId] || null;
};

const mapLocationVal: Record<ETypeFilterValues, string> = {
  [ETypeFilterValues.NearbyHouses]: 'similar',
  [ETypeFilterValues.SameHouse]: 'this',
};

export const selectFiltersStringified = (state: IState) => {
  const roomsFilterValue = selectActiveRoomsFilter(state) || 'all';
  const statusFilterValue = selectActiveStatusFilter(state) || 'all';
  const typeFilterValue = selectActiveTypeFilter(state) as ETypeFilterValues;
  const categoriesFilterValue = selectActiveCategoriesFilter(state) || 'all';

  return typeFilterValue
    ? `${mapLocationVal[typeFilterValue]}/${statusFilterValue}/${roomsFilterValue}`
    : `${statusFilterValue}/${categoriesFilterValue}`;
};
