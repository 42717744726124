import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ENewbuildingBookedFromDeveloperActionTypes {
  CloseConfirm = 'newbuildingBookedFromDeveloper/closeConfirm',
  CloseSuccess = 'newbuildingBookedFromDeveloper/closeSuccess',
  OpenConfirm = 'newbuildingBookedFromDeveloper/openConfirm',
  SetBookingFailure = 'newbuildingBookedFromDeveloper/setBookingFailure',
  SetBookingLoading = 'newbuildingBookedFromDeveloper/setBookingLoading',
  SetBookingSuccess = 'newbuildingBookedFromDeveloper/setBookingSuccess',
}

export type TCloseNewbuildingBookedFromDeveloperConfirm =
  ITypedReduxAction<ENewbuildingBookedFromDeveloperActionTypes.CloseConfirm>;
export type TCloseNewbuildingBookedFromDeveloperSuccess =
  ITypedReduxAction<ENewbuildingBookedFromDeveloperActionTypes.CloseSuccess>;
export type TOpenNewbuildingBookedFromDeveloperConfirm =
  ITypedReduxAction<ENewbuildingBookedFromDeveloperActionTypes.OpenConfirm>;
export type TSetNewbuildingBookedFromDeveloperFailure =
  ITypedReduxAction<ENewbuildingBookedFromDeveloperActionTypes.SetBookingFailure>;
export type TSetNewbuildingBookedFromDeveloperLoading =
  ITypedReduxAction<ENewbuildingBookedFromDeveloperActionTypes.SetBookingLoading>;
export type TSetNewbuildingBookedFromDeveloperSuccess =
  ITypedReduxAction<ENewbuildingBookedFromDeveloperActionTypes.SetBookingSuccess>;

export const openNewbuildingBookingConfirm = actionGenerator<ENewbuildingBookedFromDeveloperActionTypes.OpenConfirm>(
  ENewbuildingBookedFromDeveloperActionTypes.OpenConfirm,
);

export const closeNewbuildingBookingConfirm = actionGenerator<ENewbuildingBookedFromDeveloperActionTypes.CloseConfirm>(
  ENewbuildingBookedFromDeveloperActionTypes.CloseConfirm,
);

export const setNewbuildingBookingFailure =
  actionGenerator<ENewbuildingBookedFromDeveloperActionTypes.SetBookingFailure>(
    ENewbuildingBookedFromDeveloperActionTypes.SetBookingFailure,
  );

export const setNewbuildingBookingLoading =
  actionGenerator<ENewbuildingBookedFromDeveloperActionTypes.SetBookingLoading>(
    ENewbuildingBookedFromDeveloperActionTypes.SetBookingLoading,
  );

export const setNewbuildingBookingSuccess =
  actionGenerator<ENewbuildingBookedFromDeveloperActionTypes.SetBookingSuccess>(
    ENewbuildingBookedFromDeveloperActionTypes.SetBookingSuccess,
  );

export type TNewbuildingBookedFromDeveloperActions =
  | TCloseNewbuildingBookedFromDeveloperConfirm
  | TCloseNewbuildingBookedFromDeveloperSuccess
  | TOpenNewbuildingBookedFromDeveloperConfirm
  | TSetNewbuildingBookedFromDeveloperFailure
  | TSetNewbuildingBookedFromDeveloperLoading
  | TSetNewbuildingBookedFromDeveloperSuccess;
