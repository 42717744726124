import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  ICheckAccessToAdminApiResponse,
  IMappers,
} from 'shared/repositories/valuation-offer-history/v1/check-access-to-admin-api';

export const createCheckAccessToAdminMapper = (): IMappers<TMappedResponse200, TMappedResponse400> => ({
  200: response => response.hasAccess,
  400: ({ message }) => {
    return new HttpBadStatusCodeError({
      domain: 'fetchCheckAccessToAdminApi.ts',
      statusCode: 400,
      message: message || /* istanbul ignore next */ '',
    });
  },
});

export type TMappedResponse200 = ICheckAccessToAdminApiResponse['hasAccess'];
export type TMappedResponse400 = HttpBadStatusCodeError;

export type TMappedResponse = TMappedResponse200 | TMappedResponse400;
