import { EReadyBusinessType, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export const READY_BUSINESS_TITLE = 'Готовый бизнес';
export const RENTAL_BUSINESS_TITLE = 'Арендный бизнес';

export function getNewBusinessTitle(offer: IOfferSchema): string {
  if (
    offer.readyBusinessType === EReadyBusinessType.Other ||
    offer.readyBusinessType === EReadyBusinessType.ReadyBusiness
  ) {
    const [specialty] = offer.specialty?.specialties || [];

    return specialty?.rusName || READY_BUSINESS_TITLE;
  }

  return RENTAL_BUSINESS_TITLE;
}
