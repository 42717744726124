import { isDefined } from '../../utils';

export interface ILegendModalToggled {
  isOpen: boolean | undefined;
  type: 'ILegendModalToggled';
}

export interface ILegendState {
  isOpen: boolean;
}

const defaultState: ILegendState = {
  isOpen: false,
};

export function toggle(isOpen?: boolean): ILegendModalToggled {
  return {
    isOpen,
    type: 'ILegendModalToggled',
  };
}

export function legendModalReducer(state: ILegendState, action: ILegendModalToggled): ILegendState {
  switch (action.type) {
    case 'ILegendModalToggled':
      return {
        ...state,
        isOpen: isDefined<boolean>(action.isOpen) ? action.isOpen : !state.isOpen,
      };

    default:
      return state || defaultState;
  }
}
