import { abUseExperimentsSelector } from 'shared/selectors';
import { IState } from 'shared/store';

export type TNewbuildingSuburbanTrapExperimentGroup =
  /** Не показываем баннер **/
  | 'A'
  /** Показываем баннер **/
  | 'B';

export const NEWBUILDING_SUBURBAN_TRAP = 'newbuilding_suburban_trap_desktop';

export function selectNewbuildingSuburbanTrapGroup(state: IState): TNewbuildingSuburbanTrapExperimentGroup {
  const experiments = abUseExperimentsSelector(state);
  const experiment = experiments.find(o => o.experimentName === NEWBUILDING_SUBURBAN_TRAP);

  return (experiment?.groupName || 'A') as TNewbuildingSuburbanTrapExperimentGroup;
}
