import { INewbuildingChat, IWebimUserDataAuthed } from '../types/newbuildingChat';

export interface INewbuildingChatSetUserData {
  type: 'INewbuildingChatSetUserData';
  payload: IWebimUserDataAuthed | null;
}

const initialState = {
  userData: null,
};

export function newbuildingChatReducer(
  state: INewbuildingChat = initialState,
  action: INewbuildingChatSetUserData,
): INewbuildingChat {
  switch (action.type) {
    case 'INewbuildingChatSetUserData':
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
}
