import { EFlatTourBookingActionTypes, TSetScheduleData, TSetIsTooltipOpen } from '../../actions/flatTourBooking';
import { IFlatTourBookingState } from '../../types/flatTourBooking';

type TReduxActions = TSetScheduleData | TSetIsTooltipOpen;

const initialState: IFlatTourBookingState = {
  isTooltipOpen: false,
  schedule: null,
};

export const flatTourBookingReducer = (
  state: IFlatTourBookingState = initialState,
  action: TReduxActions,
): IFlatTourBookingState => {
  switch (action.type) {
    case EFlatTourBookingActionTypes.SetScheduleData:
      return {
        ...state,
        schedule: action.payload,
      };
    case EFlatTourBookingActionTypes.SetIsTooltipOpen:
      return {
        ...state,
        isTooltipOpen: action.payload,
      };
    default:
      return state;
  }
};
