import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  IGetHouseOfferHistoryDesktopRequest,
  IMappers,
} from '../../../../repositories/valuation-offer-history/v4/get-house-offer-history-desktop';
import { EDealType, IOffersHistoryListingData } from '../../../../store/offerHistory';
import { prepareOffersHistory } from '../../mappers';

export const createOffersHistoryListingMapper = (
  parameters: IGetHouseOfferHistoryDesktopRequest,
): IMappers<TMappedResponse200, TMappedResponse400> => ({
  200: response => prepareOffersHistory(response, parameters.dealType as EDealType),
  400: ({ message }) => {
    return new HttpBadStatusCodeError({
      domain: 'fetchOffersHistoryListing.ts',
      statusCode: 400,
      message: message || /* istanbul ignore next */ '',
      details: { ...parameters },
    });
  },
});

export type TMappedResponse200 = IOffersHistoryListingData;
export type TMappedResponse400 = HttpBadStatusCodeError;

export type TMappedResponse = TMappedResponse200 | TMappedResponse400;
