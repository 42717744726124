import { setDataAction } from './actions';
import { fetchRosreestrState } from '../../services/fetchRosreestrState';
import { IThunkActionCreator } from '../../store';

export const requestRosreestrState =
  (cianId: number): IThunkActionCreator<Promise<void>> =>
  async (dispatch, _, context) => {
    const state = await fetchRosreestrState(context, {
      cianId,
    });

    dispatch(setDataAction(state));
  };
