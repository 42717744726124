import { EComparison, TOfferComparisonActions } from 'shared/actions/comparison';
import { IСomparisonState } from 'shared/store/comparison';

const initialState: IСomparisonState = {
  comparison: null,
};

export const comparisonReducer = (
  state: IСomparisonState = initialState,
  action: TOfferComparisonActions,
): IСomparisonState => {
  switch (action.type) {
    case EComparison.getComparison:
      return {
        ...state,
        comparison: action.payload,
      };

    default:
      return state;
  }
};
