export enum EComplaintPopupActionTypes {
  COMPLAINT_POPUP_SET_VALUE = 'complaintPopup/SET_VALUE',
}

export interface IComplaintPopupSetValueAction {
  type: EComplaintPopupActionTypes;
  value: boolean;
}

export const setComplaintPopupOpenValue = (value: boolean): IComplaintPopupSetValueAction => ({
  type: EComplaintPopupActionTypes.COMPLAINT_POPUP_SET_VALUE,
  value,
});

const initialState = false;

export function complaintPopupOpenReducer(
  state: boolean = initialState,
  action: IComplaintPopupSetValueAction,
): boolean {
  switch (action.type) {
    case EComplaintPopupActionTypes.COMPLAINT_POPUP_SET_VALUE:
      return action.value;

    default:
      return state;
  }
}
