import { selectOfferAgent } from './selectOfferAgent';
import { IState } from '../../store';

export const selectAgencyName = (state: IState): string => {
  const agent = selectOfferAgent(state);

  if (!agent) {
    return '';
  }

  const { companyName, id } = agent;

  return companyName || `ID ${id}`;
};
