import { ca } from '@cian/analytics';

import { ISoprPage, ISoprUser } from './types';

export type TBuyAnalyticsReportLabel =
  | 'buy_report'
  | 'close_report'
  | 'report_payment'
  | 'report_example_download'
  | 'IAP_details';

export interface ITrackingBuyAnalyticsOptions {
  label: TBuyAnalyticsReportLabel;
  page: ISoprPage;
  user: ISoprUser;
}

export const trackingBuyAnalyticsReportEvent = ({ label, page, user }: ITrackingBuyAnalyticsOptions) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'click_sopr',
    label,
    page: {
      ...page,
      extra: {
        ...page.extra,
        type: 'building_report',
      },
    },
    user,
  });
};
