import { ERosreestrActionTypes, TRosreestrActions } from '../../actions/rosreestr';
import { IRosreestrState } from '../../types/rosreestr';

const defaultState = null;

export const rosreestrReducer = (
  state: IRosreestrState | null = defaultState,
  action: TRosreestrActions,
): IRosreestrState | null => {
  switch (action.type) {
    case ERosreestrActionTypes.SetData:
      return action.payload;
    default:
      return state;
  }
};
