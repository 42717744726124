import { getOfferDeactivatedLabel, getOfferDateEnd } from 'shared/services/offersHistory/mappers/utils';

import { IHouseOfferHistoryV4Schema } from '../../../repositories/valuation-offer-history/entities/offer_history/HouseOfferHistoryV4Schema';
import { IOffersHistoryListingData, EDealType } from '../../../store/offerHistory';

export const prepareOffers = (
  offers: IHouseOfferHistoryV4Schema[],
  dealType: EDealType,
): IOffersHistoryListingData['offers'] => {
  return offers.map(offer => ({
    ...offer,
    deactivatedTitle: getOfferDeactivatedLabel(offer, dealType),
    dateEnd: getOfferDateEnd(offer, dealType),
    expositionFull: `${offer.exposition} на Циан`,
  }));
};
