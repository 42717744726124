import { Dispatch } from '@reduxjs/toolkit';

import {
  fetchGetInfrastructureForMap,
  IGetInfrastructureForMapRequest,
  IGetInfrastructureForMapResponse,
  TGetInfrastructureForMapResponse,
} from '../../repositories/infrastructure-caching/v1/get-infrastructure-for-map';
import { IThunkActionCreator } from '../index';

interface IFetchInfrastructureMapSuccessAction {
  type: 'FETCH_INFRASTURCTURE_MAP';
  payload: IGetInfrastructureForMapResponse;
}

export type IFetchInfrastructureMapAction = IFetchInfrastructureMapSuccessAction;

export const fetchInfrastructureMap =
  (parameters: IGetInfrastructureForMapRequest): IThunkActionCreator =>
  async (dispatch: Dispatch<IFetchInfrastructureMapAction>, getState, extra) => {
    const { httpApi } = extra;

    const { statusCode, response }: TGetInfrastructureForMapResponse = await fetchGetInfrastructureForMap({
      httpApi,
      parameters,
    });

    if (statusCode !== 200) {
      return;
    }

    return dispatch({
      type: 'FETCH_INFRASTURCTURE_MAP',
      payload: response,
    });
  };

export type TInfrastructureMapState = {
  isLoaded: boolean;
  data?: IGetInfrastructureForMapResponse;
};

const initialState: TInfrastructureMapState = {
  isLoaded: false,
};

export const infrastructureMapReducer = (
  state: TInfrastructureMapState = initialState,
  action: IFetchInfrastructureMapAction,
) => {
  switch (action.type) {
    case 'FETCH_INFRASTURCTURE_MAP':
      return {
        isLoaded: true,
        data: action.payload,
      };

    default:
      return state;
  }
};
