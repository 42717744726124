import { createConsumer, createSubscriber, IConsumer, TSubscriberHandler } from '@cian/events-log';

import { TOPIC_USER } from '../topics';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

const getUserConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getUserConsumer._instance) {
    getUserConsumer._instance = createConsumer({ topic: TOPIC_USER });
  }

  return getUserConsumer._instance;
};

export function consumeUser(eventHandler: TSubscriberHandler) {
  const consumer = getUserConsumer();
  const subscriber = createSubscriber(eventHandler);

  consumer.subscribe(subscriber);

  return () => consumer.unsubscribe(subscriber);
}
