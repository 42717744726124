import {
  EUserType,
  fetchGetUserType,
  IGetUserTypeResponse200,
} from '../../../node/repositories/homeowners/v1/get-user-type';
import { IThunkActionCreator } from '../index';

export interface IHomeownerFetched {
  type: 'IHomeownerFetched';
  isHomeowner: boolean;
}

export interface IHomeownerFetchFailed {
  type: 'IHomeownerFetchFailed';
  isHomeowner: boolean;
}

export function getHomeowner(userId: number): IThunkActionCreator {
  return (dispatch, getState, { httpApi }) => {
    return fetchGetUserType({ httpApi, parameters: { userId } })
      .catch(() => {
        dispatch<IHomeownerFetchFailed>({
          isHomeowner: false,
          type: 'IHomeownerFetchFailed',
        });

        return false;
      })
      .then((data: IGetUserTypeResponse200) => {
        const isHomeowner = data && data.response && data.response.userType === EUserType.Homeowner;
        dispatch<IHomeownerFetched>({
          isHomeowner,
          type: 'IHomeownerFetched',
        });

        return isHomeowner;
      });
  };
}

type actions = IHomeownerFetched | IHomeownerFetchFailed;

export function homeownerReducer(state: boolean = false, action: actions): boolean {
  switch (action.type) {
    case 'IHomeownerFetched':
    case 'IHomeownerFetchFailed':
      return action.isHomeowner;
    default:
      return state;
  }
}
