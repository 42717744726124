import { loadableReady } from '@loadable/component';
import { Store } from '@reduxjs/toolkit';
import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { createRenderCallback } from './internal';
import { ApplicationContainer } from '../../../shared/containers/ApplicationContainer';
import { IState } from '../../../shared/store';
import { IApplicationContext } from '../../../shared/types/applicationContext';

interface IDependencies {
  store: Store<IState>;
  context: IApplicationContext;
}

export function renderApplication({ store, context }: IDependencies) {
  const { config } = context;

  const projectName = config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName as string);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  loadableReady(() => {
    hydrateRoot(
      rootElement,
      <ApplicationContainer renderCallback={createRenderCallback()} context={context} reduxStore={store} />,
    );
  });
}
