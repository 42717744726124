import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry } from '@cian/mf-registry/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext } from '../../../shared/types/applicationContext';
import { createMakeRequest } from '../../../shared/utils/make_request';

export function createContext(): IApplicationContext {
  const httpApi = getHttpApi();

  return {
    httpApi,
    config: getConfig(),
    logger: getLogger(),
    telemetry: getTelemetry(),
    custom: {
      // FIXME: DEPRECATED: использовать http-api
      makeRequest: createMakeRequest(httpApi),
    },
    microfrontendsRegistry: registry(),
  };
}
