import { EObjectType, IGenplan } from '@cian/countryside-genplan-component';
import * as R from 'ramda';

import { IState } from '../store';
import { isSuburbanOfferFromBuilder } from '../utils/suburban';

export const selectGenplan = ({ genplan, offerData: { offer } }: IState): IGenplan | null => {
  if (!isSuburbanOfferFromBuilder(offer) || !genplan || !genplan.image || !genplan.objects) {
    return null;
  }

  return {
    image: genplan.image,
    objects: genplan.objects.map(({ coordinates, cianId, objectType, uniqName }) => {
      const xCoords = coordinates.map(({ x }) => x);
      const yCoords = coordinates.map(({ y }) => y);

      return {
        id: cianId,
        polygonPath: coordinates.map(({ x, y }) => `${x} ${y}`).join(' '),
        center: [R.sum(xCoords) / xCoords.length, R.sum(yCoords) / yCoords.length],
        objectType,
        uniqName,
      };
    }),
    filters: genplan.objects.reduce<EObjectType[]>(
      (result, { objectType }) => (objectType && !result.includes(objectType) ? [...result, objectType] : result),
      [],
    ),
  };
};
