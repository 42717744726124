import { IState } from 'shared/store';

import { selectIsLeadFactory } from './isLeadFactory';
import { isNewbuildingSelector } from './isNewbuilding';
import { getIsFromBuilder, getIsFromSeller } from '../../newObject';

export function isNewbuildingPageSelector(state: IState) {
  const isFromBuilder = getIsFromBuilder(state);
  const isFromSeller = getIsFromSeller(state);
  const isFromLeadFactory = selectIsLeadFactory(state);

  const isNewbuilding = isNewbuildingSelector(state);

  return (isFromBuilder || isFromSeller || isFromLeadFactory) && isNewbuilding;
}
