import {
  prepareCategoryFilters,
  prepareLocationFilters,
  prepareRoomsFilters,
  prepareStatusFilters,
} from './prepareFilters';
import { prepareOffers } from './prepareOffers';
import { IGetHouseOfferHistoryDesktopResponse } from '../../../repositories/valuation-offer-history/v4/get-house-offer-history-desktop';
import { EDealType, IOffersHistoryListingData } from '../../../store/offerHistory';

export const prepareOffersHistory = (
  res: IGetHouseOfferHistoryDesktopResponse,
  dealType: EDealType,
): IOffersHistoryListingData => {
  const offers: IOffersHistoryListingData['offers'] = prepareOffers(res.offers, dealType);
  const roomsFilters = prepareRoomsFilters(res.roomCounts);
  const statusFilters = prepareStatusFilters(res.statusCounts, dealType);
  const locationFilters = prepareLocationFilters(res.locations);
  const categoriesFilters = prepareCategoryFilters(res.categories);

  return {
    offers,
    roomsFilters,
    statusFilters,
    locationFilters,
    totalCount: res.totalCount,
    activeFiltersValues: res.activeFiltersValues,
    categoriesFilters,
  };
};
