import { AddToComparisonActionType } from './actions';
import { SetComparisonNotificationType } from './comparisonNotification';
import { IAddOfferToComparisonRequest } from '../../repositories/offers-comparison/v2/add-offer-to-comparison';
import { addOfferToComparisonRequest } from '../../services/offersComparison';
import { EComparisonNotificationCategory } from '../../types/comparisonNotification';

import type { IThunkActionCreator } from '../../store';

export const addOfferToComparison =
  (parameters: IAddOfferToComparisonRequest): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState, context) => {
    const { httpApi } = context;
    const { offerId } = parameters;
    const response = await addOfferToComparisonRequest({ httpApi, parameters: { offerId } });

    if (response) {
      const { success, text, comparisonUrl, offerId } = response;
      if (success) {
        dispatch({
          type: AddToComparisonActionType,
          offerId,
        });
      }

      dispatch({
        type: SetComparisonNotificationType,
        text,
        url: comparisonUrl,
        category: success ? EComparisonNotificationCategory.Add : EComparisonNotificationCategory.Limit,
        offerId,
      });
    }
  };
