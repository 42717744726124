import { requestOfferFromHistoryActions, setActiveOfferId } from './actions';
import { getOfferFromHistory } from './getOfferFromHistory';
import {
  selectActiveOfferId,
  selectOfferFromHistory,
  selectOffersHistoryData,
  selectOffersHistorySpoilerData,
} from '../../selectors/offersHistory';
import { IThunkActionCreator } from '../../store';

export const openOfferFromHistory =
  (params: IParams = {}): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState) => {
    let state = getState();
    const offersHistoryData = selectOffersHistoryData(state);
    const offersHistorySpoilerData = selectOffersHistorySpoilerData(state);
    const activeOfferId = selectActiveOfferId(state);

    /** Выбираем id оффера, который нужно загрузить, в порядке приоритета */
    const cianId = params.cianId || offersHistoryData.data?.offers[0].id || offersHistorySpoilerData.data?.offers[0].id;

    /** Проверка на то, что вызывается открытие другого оффера */
    if (!cianId || activeOfferId === cianId) {
      return;
    }

    dispatch(setActiveOfferId(cianId));

    state = getState();
    const savedOfferFromHistory = selectOfferFromHistory(state);

    dispatch(requestOfferFromHistoryActions.reset());

    /** Повторно данные оффера загружаться не будут */
    if (savedOfferFromHistory) {
      return;
    }

    await dispatch(getOfferFromHistory({ cianId }));
  };

interface IParams {
  cianId?: number | null;
}
