import { isInRegionSelector, offerSelector } from './offerData';
import { Regions } from '../constants';
import { IState } from '../store';
import { FOfferType, getOfferType } from '../utils/offer_type';

export const showSuburbanBuildingsBanners = (state: IState) => {
  const isInArea = isInRegionSelector([
    Regions.Moscow,
    Regions.MoscowArea,
    Regions.Spb,
    Regions.SpbArea,
    Regions.Tatarstan,
  ])(state);
  const offer = offerSelector(state);
  const offerType = getOfferType(offer);
  const isLand = offerType === FOfferType.Land;

  return isLand && isInArea;
};
