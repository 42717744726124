import { TURIScheme } from '@cian/http-api/shared/URI/IURI';

import { IMakeRequest } from '../../utils/make_request';
import { filterServerResponse } from '../helpers';
import { IServerError } from '../types';

export interface ISuccessResponse {
  status: 'ok';
}

export function sendReclaim(
  makeRequest: IMakeRequest,
  host: string,
  realtyOfferId: number,
): Promise<ISuccessResponse | IServerError> {
  return makeRequest({
    body: `realty_id=${realtyOfferId}`,
    headers: [['Content-Type', 'application/x-www-form-urlencoded']],
    method: 'POST',
    uri: {
      host,
      path: '/ajax/fix/',
      scheme: document.location.protocol.slice(0, -1) as TURIScheme,
    },
  })
    .then(filterServerResponse)
    .then(response => JSON.parse(response.responseBody));
}
