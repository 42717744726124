import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EBestPlaceReportModalActionTypes {
  OpenBestPlaceReportModal = 'bestPlaceReportModal/openModal',
  CloseBestPlaceReportModal = 'bestPlaceReportModal/closeModal',
}

export type TOpenBestPlaceReportModal = ITypedReduxAction<EBestPlaceReportModalActionTypes.OpenBestPlaceReportModal>;

export type TCloseBestPlaceReportModal = ITypedReduxAction<EBestPlaceReportModalActionTypes.CloseBestPlaceReportModal>;

export const openBestPlaceReportModal = actionGenerator<EBestPlaceReportModalActionTypes.OpenBestPlaceReportModal>(
  EBestPlaceReportModalActionTypes.OpenBestPlaceReportModal,
);
export const closeBestPlaceReportModal = actionGenerator<EBestPlaceReportModalActionTypes.CloseBestPlaceReportModal>(
  EBestPlaceReportModalActionTypes.CloseBestPlaceReportModal,
);

export type TBestPlaceReportModalActions = TOpenBestPlaceReportModal | TCloseBestPlaceReportModal;
