/* eslint-disable */
import { IAreaPartSchema } from '../area_part/AreaPartSchema';
import { IBargainTermsSchema } from '../bargain_terms/BargainTermsSchema';
import { IBookingInfoSchema } from './BookingInfoSchema';
import { IBuildingSchema } from '../building/BuildingSchema';
import { IBusinessShoppingCenterNewCardSchema } from '../business_shopping_center_new_card/BusinessShoppingCenterNewCardSchema';
import { ICommercialOwnershipSchema } from '../commercial_ownership/CommercialOwnershipSchema';
import { ICoworkingSchema } from './CoworkingSchema';
import { IDeadlineSchema } from '../deadline/DeadlineSchema';
import { IDrainageSchema } from './DrainageSchema';
import { IElectricitySchema } from './ElectricitySchema';
import { IExportLinksSchema } from '../export_links/ExportLinksSchema';
import { IFlagsSchema } from './FlagsSchema';
import { IESOfferFloorPlanSchema } from '../floor_plan/ESOfferFloorPlanSchema';
import { IGarageSchema } from './GarageSchema';
import { IGasSchema } from './GasSchema';
import { IGeoSchema } from '../../../../../node/repositories/offer-card/entities/geo/GeoSchema';
import { IHouseSchema } from '../house/HouseSchema';
import { ISimpleVillageSchema } from '../village/SimpleVillageSchema';
import { ILandSchema_entities } from '../land/LandSchema';
import { IModerationInfoSchema } from '../moderation_info_value_object/ModerationInfoSchema';
import { IMonthlyIncomeSchema } from '../monthly_income/MonthlyIncomeSchema';
import { INewBuildingSchema } from './NewBuildingSchema';
import { IPhoneSchema } from './PhoneSchema';
import { IPhotoSchema_entities } from '../photo/PhotoSchema';
import { IPriceSchema_entities } from '../price/PriceSchema';
import { IPublishTermsSchema } from '../publish_terms/PublishTermsSchema';
import { IOfferRosreestrCheckSchema } from '../offer_rosreestr_check/OfferRosreestrCheckSchema';
import { ICommercialSpecialtySchema } from '../commercial_specialty/CommercialSpecialtySchema';
import { ITrackingDataSchema } from './TrackingDataSchema';
import { IValueAddedServicesSchema } from '../value_added_services/ValueAddedServicesSchema';
import { IVideoSchema } from '../video/VideoSchema';
import { IWaterSchema } from './WaterSchema';
import { IWorkTimeInfoSchema } from './WorkTimeInfoSchema';

export interface IOfferSchema {
  /** Доступ **/
  accessType?: EAccessType;
  /** Дата добавления **/
  added?: string;
  additionalPhoneLinesAllowed?: boolean;
  /** Площадь комнат, м2 **/
  allRoomsArea?: string;
  /** Дата перемещения объявления в архив **/
  archivedDate?: string;
  /** Описание частей площади объявления **/
  areaParts?: IAreaPartSchema[];
  /** Дата освобождения **/
  availableFrom?: string;
  /** Количество балконов **/
  balconiesCount?: number;
  /** Условия сделки **/
  bargainTerms?: IBargainTermsSchema;
  /** Количество спален **/
  bedroomsCount?: number;
  /** Количество спальных мест **/
  bedsCount?: number;
  /** По объекту доступна аналитика от BestPlace **/
  bestPlaceAnalyticsAvailable?: boolean;
  /** Информация о бронировании **/
  booking?: IBookingInfoSchema;
  /** ID Застройщика, который отображается в объявлении от застройщика, если у ЖК несколько застройщиков **/
  builderId?: number;
  /** Id застройщиков **/
  buildersIds?: number[];
  /** Информация о здании **/
  building?: IBuildingSchema;
  /** Кадастровый номер дома **/
  buildingCadastralNumber?: string;
  /** БЦ/ТЦ **/
  businessShoppingCenter?: IBusinessShoppingCenterNewCardSchema;
  /** Кадастровый номер **/
  cadastralNumber?: string;
  /** Тип подключенного calltracking-а **/
  callTrackingProvider?: ECallTrackingProvider;
  /** Можно частями **/
  canParts?: boolean;
  /** Кол-во грузовых лифтов **/
  cargoLiftsCount?: number;
  /** Список ID категории **/
  categoriesIds?: number[];
  /** Категория объявления **/
  category: ECategory;
  /** Ссылка на сертификат **/
  certificate?: string;
  /** Можно с детьми **/
  childrenAllowed?: boolean;
  /** ID объявления на ЦИАНе **/
  cianId?: number;
  /** ID пользователя в ЦИАНе **/
  cianUserId?: number;
  /** Количество совместных санузлов **/
  combinedWcsCount?: number;
  /** Информация о собственности коммерческого объекта **/
  commercialOwnership?: ICommercialOwnershipSchema;
  /** Состояние **/
  conditionRatingType?: EConditionRatingType;
  /** Состояние **/
  conditionType?: EConditionType;
  /** Информация о коворкинге **/
  coworking?: ICoworkingSchema;
  /** Тип помещения в коворкинге **/
  coworkingOfferType?: ECoworkingOfferType;
  deadline?: IDeadlineSchema;
  /** Кто платит комиссию в сделке v2 **/
  dealRentMonetization?: EDealRentMonetization;
  /** Версия сделки в аренде **/
  dealRentVersion?: EDealRentVersion;
  /** Тип сделки **/
  dealType: EDealType;
  /** Отделка **/
  decoration?: EDecoration;
  /** Дом подлежит сносу в рамках программы реновации **/
  demolishedInMoscowProgramm?: boolean;
  /** Текст объявления **/
  description?: string;
  /** Канализация **/
  drainage?: IDrainageSchema;
  /** Объем, м3/сутки **/
  drainageCapacity?: number;
  /** Тип канализации **/
  drainageType?: EDrainageType;
  /** Подъездные пути **/
  drivewayType?: EDrivewayType;
  /** Номер группы дубликатов **/
  duplicateGroupId?: number;
  /** Ссылка на редактирование оффера, старая форма **/
  edit?: string;
  /** Дата смены статуса объявления **/
  editDate?: string;
  /** Ссылка на редактирование фото **/
  editPhoto?: string;
  /** Электроснабжение **/
  electricity?: IElectricitySchema;
  /** Мощность, кВТ **/
  electricityPower?: number;
  /** Тип электроснабжения **/
  electricityType?: EElectricityType;
  /** Недвижимость **/
  estateType?: EEstateType;
  /** Ссылки для экспорта **/
  exportLinks?: IExportLinksSchema;
  /** ID объявления во внешней системе **/
  externalId?: string;
  /** Ссылка на оригинальное объявление **/
  externalUrl?: string;
  /** Флаги состояния объявления **/
  flags?: IFlagsSchema;
  /** Тип квартиры **/
  flatType?: EFlatType;
  /** Этаж с **/
  floorFrom?: number;
  /** Материал полов **/
  floorMaterialType?: EFloorMaterialType;
  /** Этаж **/
  floorNumber?: number;
  /** План этажа и полигон квартиры **/
  floorPlan?: IESOfferFloorPlanSchema;
  /** Этаж по **/
  floorTo?: number;
  /** Мебель **/
  furniture?: string;
  /** Мебель **/
  furniturePresence?: EFurniturePresence;
  /** Тип гаража **/
  garage?: IGarageSchema;
  /** Газоснабжение **/
  gas?: IGasSchema;
  /** Емкость, м3/час **/
  gasCapacity?: number;
  /** Давление, Мпа **/
  gasPressure?: number;
  /** Тип газоснабжения **/
  gasType?: EGasType;
  /** ГЕО **/
  geo: IGeoSchema;
  /** Есть баня **/
  hasBathhouse?: boolean;
  /** Есть ванна **/
  hasBathtub?: boolean;
  /** Заселение 24 часа **/
  hasCheckIn_24?: boolean;
  /** Есть кондиционер **/
  hasConditioner?: boolean;
  /** Есть посудомоечная машина **/
  hasDishwasher?: boolean;
  /** Есть канализация **/
  hasDrainage?: boolean;
  /** Есть электричество **/
  hasElectricity?: boolean;
  /** Есть обременение **/
  hasEncumbrances?: boolean;
  /** Есть оборудование **/
  hasEquipment?: boolean;
  /** Организация экскурсий **/
  hasExcursions?: boolean;
  /** Есть система пожаротушения **/
  hasExtinguishingSystem?: boolean;
  /** Объявление имеет цену, близкую к среднерыночной **/
  hasFairPrice?: boolean;
  /** Есть холодильник **/
  hasFridge?: boolean;
  /** Есть мебель (в комнатах для живой) **/
  hasFurniture?: boolean;
  /** Есть гараж **/
  hasGarage?: boolean;
  /** Есть газ **/
  hasGas?: boolean;
  /** Фен **/
  hasHairdryer?: boolean;
  /** Есть отопление **/
  hasHeating?: boolean;
  /** Есть интернет **/
  hasInternet?: boolean;
  /** Есть инвестпроект **/
  hasInvestmentProject?: boolean;
  /** Утюг **/
  hasIron?: boolean;
  /** Джакузи **/
  hasJacuzzi?: boolean;
  /** Есть мебель на кухне **/
  hasKitchenFurniture?: boolean;
  /** Есть лифт **/
  hasLift?: boolean;
  /** Есть свет **/
  hasLight?: boolean;
  /** Есть парковка **/
  hasParking?: boolean;
  /** Есть телефон **/
  hasPhone?: boolean;
  /** Есть бассейн **/
  hasPool?: boolean;
  /** Пандус (есть/нет) **/
  hasRamp?: boolean;
  /** Ответственное хранение **/
  hasSafeCustody?: boolean;
  /** Документы командированным **/
  hasSecondedDocs?: boolean;
  /** Есть охрана **/
  hasSecurity?: boolean;
  /** Есть витринные окна **/
  hasShopWindows?: boolean;
  /** Есть душевая кабина **/
  hasShower?: boolean;
  /** Чай-кофе-сахар **/
  hasTeaCoffeeSugar?: boolean;
  /** Трансфер **/
  hasTransfer?: boolean;
  /** Транспортные услуги **/
  hasTransportServices?: boolean;
  /** Есть телевизор **/
  hasTv?: boolean;
  /** Есть стиральная машина **/
  hasWasher?: boolean;
  /** Есть водоснабжение **/
  hasWater?: boolean;
  /** Есть Wi-Fi **/
  hasWifi?: boolean;
  /** Платные ТВ-каналы **/
  hasWiredInternet?: boolean;
  /** Список всех проверок, которые прошел собственник **/
  homeownerProofs?: EHomeownerProofs[];
  /** Информация о доме **/
  house?: IHouseSchema;
  /** ID объявления в Realty **/
  id?: number;
  /** Вход **/
  inputType?: EInputType;
  /** Апартаменты **/
  isApartments?: boolean;
  /** Объявление от собственника коммерческой **/
  isByCommercialOwner?: boolean;
  /** Объявление от собственника **/
  isByHomeowner?: boolean;
  /** Партнер ли ЦИАН **/
  isCianPartner?: boolean;
  /** Объект в зоне ограниченной видимости **/
  isClosedVisibility?: boolean;
  /** Таможня **/
  isCustoms?: boolean;
  /** Объявление в сделке v2 offline **/
  isDealRentOffline?: boolean;
  /** Осуществлялась ли подмена номера для сделки **/
  isDealRequestSubstitutionPhone?: boolean;
  /** Является ли дубликатом **/
  isDuplicate?: boolean;
  /** Подключен ли колтрекинг **/
  isEnabledCallTracking?: boolean;
  /** Является ли объявлением от застройщика (застройщик, не представитель) **/
  isFromBuilder?: boolean;
  /** Является ли объявлением от представителя фабрики лидов **/
  isFromLeadFactory?: boolean;
  /** Является ли объявлением от представителя застройщика **/
  isFromSeller?: boolean;
  /** Является ли объявление импортированным **/
  isImported?: boolean;
  /** Объявление закрытой базы **/
  isInHiddenBase?: boolean;
  /** Юр. адрес предоставляется (да/нет) **/
  isLegalAddressProvided?: boolean;
  /** Скрывать точный адрес для отображения **/
  isNeedHideExactAddress?: boolean;
  /** Объект скрыт **/
  isObjectHidden?: boolean;
  /** Помещение занято **/
  isOccupied?: boolean;
  /** Пентхаус **/
  isPenthouse?: boolean;
  /** Сдается ли в аренду частями **/
  isRentByParts?: boolean;
  /** Является ли объявление вторичкой (только для загородки) **/
  isResale?: boolean;
  /** Признак уникальности объявления **/
  isUnique?: boolean;
  /** Дата исследования для признака уникальности объявления **/
  isUniqueCheckDate?: string;
  /** Признак уникальности объявления Коммерческой **/
  isUniqueForCian?: boolean;
  /** Площадь кухни, м2 **/
  kitchenArea?: string;
  /** Информация о коттеджном посёлке (только для загородки) **/
  kp?: ISimpleVillageSchema;
  /** Информация об участке **/
  land?: ILandSchema_entities;
  /** Планировка **/
  layout?: ELayout;
  /** Инфраструктура и досуг **/
  leisure?: string;
  /** Жилая площадь, м2 **/
  livingArea?: string;
  /** Количество лоджий **/
  loggiasCount?: number;
  /** Площадь до **/
  maxArea?: string;
  /** Площадь от **/
  minArea?: string;
  /** Цена за весь объект за минимальную возможную площадь **/
  minPriceTotal?: number;
  /** Цена за весь объект в месяц за минимальную возможную площадь **/
  minPriceTotalPerMonth?: number;
  /** Цена за весь объект в месяц за минимальную возможную площадь **/
  minPriceTotalPerMonthRur?: number;
  /** Цена за весь объект за минимальную возможную площадь **/
  minPriceTotalRur?: number;
  /** НДС за весь объект в месяц за минимальную возможную площадь **/
  minVatPriceTotalPerMonthRur?: number;
  /** НДС за весь объект за минимальную возможную площадь **/
  minVatPriceTotalRur?: number;
  /** Данные модерации для отображения **/
  moderationInfo?: IModerationInfoSchema;
  /** Месячная прибыль **/
  monthlyIncome?: IMonthlyIncomeSchema;
  /** Наименование **/
  name?: string;
  /** Ссылка на редактирование оффера **/
  newEdit?: string;
  /** Информация о новостройке **/
  newbuilding?: INewBuildingSchema;
  /** Временный ID объявления/черновика (GUID) **/
  objectGuid?: string;
  /** Тип недвижимости **/
  offerType: EOfferType;
  /** Кол-во пассажирских лифтов **/
  passengerLiftsCount?: number;
  /** Вид разрешенного использования (ВРИ) **/
  permittedUseType?: EPermittedUseType;
  /** Можно с животными **/
  petsAllowed?: boolean;
  /** Кол-во телефонных линий **/
  phoneLinesCount?: number;
  /** Телефоны **/
  phones?: IPhoneSchema[];
  /** Фотографии объекта **/
  photos?: IPhotoSchema_entities[];
  /** Тип помещения **/
  placementType?: EPlacementType;
  /** Возможно изменить ВРИ **/
  possibleToChangePermittedUseType?: boolean;
  /** Цена за единицу площади **/
  pricePerUnitArea?: number;
  /** Цена за единицу площади **/
  pricePerUnitAreaEur?: number;
  /** Цена за кв.м. в месяц **/
  pricePerUnitAreaPerMonth?: number;
  /** Цена за кв.м. в год **/
  pricePerUnitAreaPerYear?: number;
  /** Цена за кв.м. в год **/
  pricePerUnitAreaPerYearEur?: number;
  /** Цена за кв.м. в год **/
  pricePerUnitAreaPerYearRur?: number;
  /** Цена за кв.м. в год **/
  pricePerUnitAreaPerYearUsd?: number;
  /** Цена за единицу площади **/
  pricePerUnitAreaRur?: number;
  /** Цена за единицу площади **/
  pricePerUnitAreaUsd?: number;
  /** Цена за весь объект **/
  priceTotal?: number;
  /** Цена за весь объект **/
  priceTotalEur?: number;
  /** Цена за весь объект в месяц **/
  priceTotalPerMonth?: number;
  /** Цена за весь объект в месяц **/
  priceTotalPerMonthEur?: number;
  /** Цена за весь объект в месяц **/
  priceTotalPerMonthRur?: number;
  /** Цена за весь объект в месяц **/
  priceTotalPerMonthUsd?: number;
  /** Цена за весь объект **/
  priceTotalRur?: number;
  /** Цена за весь объект **/
  priceTotalUsd?: number;
  /** Цены за кв.м. по валютам **/
  pricesPerSquareMeter?: IPriceSchema_entities;
  /** Цены за кв.м. в год по валютам **/
  pricesPerSquareMeterPerYear?: IPriceSchema_entities;
  /** Проектная декларация **/
  projectDeclarationUrl?: string;
  /** Текст промо **/
  promoLabel?: string;
  /** Тип недвижимости **/
  propertyType?: EPropertyType;
  /** Дата публикации **/
  publicationDate?: number;
  /** Условия размещения объявления **/
  publishTerms?: IPublishTermsSchema;
  /** Realty ID пользователя, из под которого показывается объявление **/
  publishedUserId?: number;
  /** Тип готового бизнеса **/
  readyBusinessType?: EReadyBusinessType;
  /** Ссылка на восстановление объявления **/
  recoveryLink?: string;
  /** Описание сдачи части в аренду **/
  rentByPartsDescription?: string;
  /** Тип ремонта **/
  repairType?: ERepairType;
  /** Площадь комнаты (комната, койко-место) **/
  roomArea?: string;
  /** Тип комнаты (комната) **/
  roomType?: ERoomType;
  /** Площадь комнат **/
  roomsArea?: string;
  /** Количество комнат **/
  roomsCount?: number;
  /** Количество комнат в продажу/аренду **/
  roomsForSaleCount?: number;
  /** Статус проверки собственника в Росреестре **/
  rosreestrCheck?: IOfferRosreestrCheckSchema;
  /** Количество раздельных санузлов **/
  separateWcsCount?: number;
  /** Название коттеджного поселка **/
  settlementName?: string;
  /** Размер доли (доля в квартире, доля в доме) **/
  shareAmount?: string;
  /** Показывать ли предупреждение о недопустимости предоплаты **/
  showWarningMessage?: boolean;
  /** Кол-во похожих объявлений на других этажах **/
  similarOffersCount?: number;
  /** Ссылка на похожие объявления на других этажах **/
  similarOffersLink?: string;
  /** Возможное назначение **/
  specialty?: ICommercialSpecialtySchema;
  /** Статус объявления **/
  status: EStatus;
  /** Номер налоговой **/
  taxNumber?: number;
  /** Таймзона в часах **/
  timezone?: number;
  /** Заголовок объявления **/
  title?: string;
  /** Общая площадь, м2 **/
  totalArea?: string;
  /** Данные для трекинга **/
  trackingData?: ITrackingDataSchema;
  /** Пользователь подтвердил исправления нарушений **/
  userConfirmedFix?: boolean;
  /** ID пользователя в Realty **/
  userId?: number;
  /** Программа доверия **/
  userTrust?: string;
  /** Программа доверия **/
  userTrustLevel?: EUserTrustLevel;
  /** VAS **/
  valueAddedServices?: IValueAddedServicesSchema;
  /** НДС за весь объект в месяц **/
  vatPriceTotalPerMonthRur?: number;
  /** НДС за весь объект **/
  vatPriceTotalRur?: number;
  /** Список добавленных видео **/
  videos?: IVideoSchema[];
  /** Возможна сельская ипотека **/
  villageMortgageAllowed?: boolean;
  /** Водоснабжение **/
  water?: IWaterSchema;
  /** Объем, м3/сутки **/
  waterCapacity?: number;
  /** Кол-во мокрых точек **/
  waterPipesCount?: number;
  /** Тип водоснабжения **/
  waterType?: EWaterType;
  /** Расположение санузла **/
  wcLocationType?: EWcLocationType;
  /** Тип санузла (комната) **/
  wcType?: EWcType;
  /** Количество санузлов **/
  wcsCount?: number;
  /** Куда выходят окна **/
  windowsViewType?: EWindowsViewType;
  /** Информация о доступности специалиста **/
  workTimeInfo?: IWorkTimeInfoSchema;
  /** Количество рабочих мест в помещении **/
  workplaceCount?: number;
}
export enum EAccessType {
  /** Свободный **/
  'Free' = 'free',
  /** Пропускная система **/
  'PassSystem' = 'passSystem',
}
export enum ECallTrackingProvider {
  /** Билайн **/
  'Beeline' = 'beeline',
  /** МТС **/
  'Mts' = 'mts',
  /** MTT **/
  'Mtt' = 'mtt',
}
export enum ECategory {
  /** Аренда койко-места **/
  'BedRent' = 'bedRent',
  /** Аренда здания **/
  'BuildingRent' = 'buildingRent',
  /** Продажа здания **/
  'BuildingSale' = 'buildingSale',
  /** Аренда готового бизнеса **/
  'BusinessRent' = 'businessRent',
  /** Продажа готового бизнеса **/
  'BusinessSale' = 'businessSale',
  /** Аренда автосервис **/
  'CarServiceRent' = 'carServiceRent',
  /** Продажа автосервиса **/
  'CarServiceSale' = 'carServiceSale',
  /** Аренда коммерческой земли **/
  'CommercialLandRent' = 'commercialLandRent',
  /** Продажа коммерческой земли **/
  'CommercialLandSale' = 'commercialLandSale',
  /** Аренда коттеджа **/
  'CottageRent' = 'cottageRent',
  /** Продажа коттеджа **/
  'CottageSale' = 'cottageSale',
  /** Посуточная аренда койко-места **/
  'DailyBedRent' = 'dailyBedRent',
  /** Посуточная аренда квартиры **/
  'DailyFlatRent' = 'dailyFlatRent',
  /** Посуточная аренда дома, дачи, коттеджа **/
  'DailyHouseRent' = 'dailyHouseRent',
  /** Посуточная аренда комнаты **/
  'DailyRoomRent' = 'dailyRoomRent',
  /** Аренда помещения под бытовые услуги **/
  'DomesticServicesRent' = 'domesticServicesRent',
  /** Продажа помещения под бытовые услуги **/
  'DomesticServicesSale' = 'domesticServicesSale',
  /** Аренда квартиры **/
  'FlatRent' = 'flatRent',
  /** Продажа квартиры **/
  'FlatSale' = 'flatSale',
  /** Продажа доли в квартире **/
  'FlatShareSale' = 'flatShareSale',
  /** Аренда помещения свободного назначения **/
  'FreeAppointmentObjectRent' = 'freeAppointmentObjectRent',
  /** Продажа помещения свободного назначения **/
  'FreeAppointmentObjectSale' = 'freeAppointmentObjectSale',
  /** Аренда гаража **/
  'GarageRent' = 'garageRent',
  /** Продажа гаража **/
  'GarageSale' = 'garageSale',
  /** Аренда дома/дачи **/
  'HouseRent' = 'houseRent',
  /** Продажа дома/дачи **/
  'HouseSale' = 'houseSale',
  /** Аренда части дома **/
  'HouseShareRent' = 'houseShareRent',
  /** Продажа части дома **/
  'HouseShareSale' = 'houseShareSale',
  /** Аренда производства **/
  'IndustryRent' = 'industryRent',
  /** Продажа производство **/
  'IndustrySale' = 'industrySale',
  /** Продажа участка **/
  'LandSale' = 'landSale',
  /** Продажа квартиры в новостройке **/
  'NewBuildingFlatSale' = 'newBuildingFlatSale',
  /** Аренда офиса **/
  'OfficeRent' = 'officeRent',
  /** Продажа офиса **/
  'OfficeSale' = 'officeSale',
  /** Аренда общепита **/
  'PublicCateringRent' = 'publicCateringRent',
  /** Продажа общепита **/
  'PublicCateringSale' = 'publicCateringSale',
  /** Аренда комнаты **/
  'RoomRent' = 'roomRent',
  /** Продажа комнаты **/
  'RoomSale' = 'roomSale',
  /** Аренда торговой площади **/
  'ShoppingAreaRent' = 'shoppingAreaRent',
  /** Продажа торговой площади **/
  'ShoppingAreaSale' = 'shoppingAreaSale',
  /** Аренда таунхауса **/
  'TownhouseRent' = 'townhouseRent',
  /** Продажа таунхауса **/
  'TownhouseSale' = 'townhouseSale',
  /** Аренда склада **/
  'WarehouseRent' = 'warehouseRent',
  /** Продажа склада **/
  'WarehouseSale' = 'warehouseSale',
}
export enum EConditionRatingType {
  /** Отличное **/
  'Excellent' = 'excellent',
  /** Хорошее **/
  'Good' = 'good',
  /** Удовлетворительное **/
  'Satisfactory' = 'satisfactory',
}
export enum EConditionType {
  /** Требуется косметический ремонт **/
  'CosmeticRepairsRequired' = 'cosmeticRepairsRequired',
  /** Дизайнерский ремонт **/
  'Design' = 'design',
  /** Под чистовую отделку **/
  'Finishing' = 'finishing',
  /** Требуется капитальный ремонт **/
  'MajorRepairsRequired' = 'majorRepairsRequired',
  /** Офисная отделка **/
  'Office' = 'office',
  /** Типовой ремонт **/
  'Typical' = 'typical',
}
export enum ECoworkingOfferType {
  /** Конференц-зал **/
  'ConferenceHall' = 'conferenceHall',
  /** Закрепленное рабочее место **/
  'FixedWorkplace' = 'fixedWorkplace',
  /** Незакрепленное рабочее место **/
  'FlexibleWorkplace' = 'flexibleWorkplace',
  /** Переговорная комната **/
  'MeetingRoom' = 'meetingRoom',
  /** Офис **/
  'Office' = 'office',
}
export enum EDealRentMonetization {
  /** Комиссию платит собственник **/
  'FeeByHomeowner' = 'feeByHomeowner',
  /** Комиссию платит арендатор **/
  'FeeByRenter' = 'feeByRenter',
  /** Комиссия включена в цену объявления **/
  'FeeIncludedInOfferPrice' = 'feeIncludedInOfferPrice',
}
export enum EDealRentVersion {
  'V1' = 'v1',
  'V2' = 'v2',
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EDecoration {
  /** Чистовая **/
  'Fine' = 'fine',
  /** Чистовая с мебелью **/
  'FineWithFurniture' = 'fineWithFurniture',
  /** Предчистовая **/
  'PreFine' = 'preFine',
  /** Черновая **/
  'Rough' = 'rough',
  /** Под ключ **/
  'Turnkey' = 'turnkey',
  /** Отделка неизвестна **/
  'Unknown' = 'unknown',
  /** Без отделки **/
  'Without' = 'without',
}
export enum EDrainageType {
  /** 'Автономная **/
  'Autonomous' = 'autonomous',
  /** 'Центральная **/
  'Central' = 'central',
  /** Выгребная яма **/
  'Cesspool' = 'cesspool',
  /** 'Септик **/
  'SepticTank' = 'septicTank',
  /** 'Ливневая **/
  'Storm' = 'storm',
  /** 'Очистные сооружения **/
  'TreatmentFacilities' = 'treatmentFacilities',
}
export enum EDrivewayType {
  /** Асфальтированная дорога **/
  'Asphalt' = 'asphalt',
  /** Грунтовая дорога **/
  'Ground' = 'ground',
  /** Нет **/
  'No' = 'no',
}
export enum EElectricityType {
  /** По границе участка **/
  'Border' = 'border',
  /** На участке **/
  'Location' = 'location',
  /** Нет **/
  'No' = 'no',
}
export enum EEstateType {
  /** В собственности **/
  'Owned' = 'owned',
  /** В аренде **/
  'Rent' = 'rent',
}
export enum EFlatType {
  /** Свободная планировка **/
  'OpenPlan' = 'openPlan',
  /** Комнаты **/
  'Rooms' = 'rooms',
  /** Студия **/
  'Studio' = 'studio',
}
export enum EFloorMaterialType {
  /** Асфальт **/
  'Asphalt' = 'asphalt',
  /** Бетон **/
  'Concrete' = 'concrete',
  /** Ламинат **/
  'Laminate' = 'laminate',
  /** Линолеум **/
  'Linoleum' = 'linoleum',
  /** Полимерный **/
  'Polymeric' = 'polymeric',
  /** Железобетон **/
  'ReinforcedConcrete' = 'reinforcedConcrete',
  /** Наливной пол **/
  'SelfLeveling' = 'selfLeveling',
  /** Плитка **/
  'Tile' = 'tile',
  /** Дерево **/
  'Wood' = 'wood',
}
export enum EFurniturePresence {
  /** Нет **/
  'No' = 'no',
  /** По желанию **/
  'Optional' = 'optional',
  /** Есть **/
  'Yes' = 'yes',
}
export enum EGasType {
  /** По границе участка **/
  'Border' = 'border',
  /** На участке **/
  'Location' = 'location',
  /** Нет **/
  'No' = 'no',
}
export enum EHomeownerProofs {
  /** Паспорт **/
  'Passport' = 'passport',
  /** Пользователь прошел проверку в Росреестре **/
  'Rosreestr' = 'rosreestr',
  /** Пользователь сдает как самозанятый **/
  'SelfEmployed' = 'selfEmployed',
}
export enum EInputType {
  /** Общий с улицы **/
  'CommonFromStreet' = 'commonFromStreet',
  /** Общий со двора **/
  'CommonFromYard' = 'commonFromYard',
  /** Отдельный с улицы **/
  'SeparateFromStreet' = 'separateFromStreet',
  /** Отдельный со двора **/
  'SeparateFromYard' = 'separateFromYard',
}
export enum ELayout {
  /** Кабинетная **/
  'Cabinet' = 'cabinet',
  /** Коридорная **/
  'Corridorplan' = 'corridorplan',
  /** Смешанная **/
  'Mixed' = 'mixed',
  /** Открытая **/
  'OpenSpace' = 'openSpace',
}
export enum EOfferType {
  /** коммерческая **/
  'Commercial' = 'commercial',
  /** квартиры **/
  'Flat' = 'flat',
  /** новостройки **/
  'Newobject' = 'newobject',
  /** загородка **/
  'Suburban' = 'suburban',
}
export enum EPermittedUseType {
  /** Cельскохозяйственное использование **/
  'Agricultural' = 'agricultural',
  /** Деловое управление **/
  'BusinessManagement' = 'businessManagement',
  /** Общее пользование территории **/
  'CommonUseArea' = 'commonUseArea',
  /** Высотная застройка **/
  'HighriseBuildings' = 'highriseBuildings',
  /** Гостиничное обслуживание **/
  'HotelAmenities' = 'hotelAmenities',
  /** Индивидуальное жилищное строительство (ИЖС) **/
  'IndividualHousingConstruction' = 'individualHousingConstruction',
  /** Промышленность **/
  'Industry' = 'industry',
  /** Отдых (рекреация) **/
  'Leisure' = 'leisure',
  /** Малоэтажное жилищное строительство (МЖС) **/
  'LowriseHousing' = 'lowriseHousing',
  /** Общественное использование объектов капитального строительств **/
  'PublicUseOfCapitalConstruction' = 'publicUseOfCapitalConstruction',
  /** Обслуживание автотранспорта **/
  'ServiceVehicles' = 'serviceVehicles',
  /** Торговые центры **/
  'ShoppingCenters' = 'shoppingCenters',
  /** Склады **/
  'Warehouses' = 'warehouses',
}
export enum EPlacementType {
  /** Помещение в торговом комплексе **/
  'ShoppingMall' = 'shoppingMall',
  /** Street retail **/
  'StreetRetail' = 'streetRetail',
}
export enum EPropertyType {
  /** здание **/
  'Building' = 'building',
  /** помещение свободного назначения **/
  'FreeAppointment' = 'freeAppointment',
  /** гараж **/
  'Garage' = 'garage',
  /** производство **/
  'Industry' = 'industry',
  /** земля **/
  'Land' = 'land',
  /** офис **/
  'Office' = 'office',
  /** торговая площадь **/
  'ShoppingArea' = 'shoppingArea',
  /** склад **/
  'Warehouse' = 'warehouse',
}
export enum EReadyBusinessType {
  /** Другое (например, автосервис, аптека, химчистка) **/
  'Other' = 'other',
  /** Готовый бизнес **/
  'ReadyBusiness' = 'readyBusiness',
  /** Арендный бизнес **/
  'RentalBusiness' = 'rentalBusiness',
}
export enum ERepairType {
  /** Косметический **/
  'Cosmetic' = 'cosmetic',
  /** Дизайнерский **/
  'Design' = 'design',
  /** Евроремонт **/
  'Euro' = 'euro',
  /** Без ремонта **/
  'No' = 'no',
}
export enum ERoomType {
  /** Оба варианта **/
  'Both' = 'both',
  /** Смежная **/
  'Combined' = 'combined',
  /** Изолированная **/
  'Separate' = 'separate',
}
export enum EStatus {
  /** Заблокировано **/
  'Blocked' = 'blocked',
  /** Деактивировано **/
  'Deactivated' = 'deactivated',
  /** Удалено **/
  'Deleted' = 'deleted',
  /** Черновик **/
  'Draft' = 'draft',
  /** Промодерировано **/
  'Moderate' = 'moderate',
  /** Опубликовано **/
  'Published' = 'published',
  /** Отклонено **/
  'Refused' = 'refused',
  /** Удалено модератором **/
  'RemovedByModerator' = 'removedByModerator',
  /** Продано **/
  'Sold' = 'sold',
}
export enum EUserTrustLevel {
  /** Очень много жалоб **/
  'Danger' = 'danger',
  /** Исключен из программы **/
  'Excluded' = 'excluded',
  /** участвует **/
  'Involved' = 'involved',
  /** Новый пользователь **/
  'New' = 'new',
  /** не участвует **/
  'NotInvolved' = 'notInvolved',
}
export enum EWaterType {
  /** Автономное **/
  'Autonomous' = 'autonomous',
  /** Центральное **/
  'Central' = 'central',
  /** Водонапорная станция **/
  'PumpingStation' = 'pumpingStation',
  /** Водозаборный узел **/
  'WaterIntakeFacility' = 'waterIntakeFacility',
  /** Водонапорная башня **/
  'WaterTower' = 'waterTower',
}
export enum EWcLocationType {
  /** В доме **/
  'Indoors' = 'indoors',
  /** На улице **/
  'Outdoors' = 'outdoors',
}
export enum EWcType {
  /** Совмещенный **/
  'Combined' = 'combined',
  /** Раздельный **/
  'Separate' = 'separate',
}
export enum EWindowsViewType {
  /** На улицу **/
  'Street' = 'street',
  /** Во двор **/
  'Yard' = 'yard',
  /** На улицу и двор **/
  'YardAndStreet' = 'yardAndStreet',
}
