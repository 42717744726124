import { IState } from '../store';

export function getAbCallButtonText(state: IState): string {
  const {
    pageData: { dealType, offerType },
    offerData: { offer },
  } = state;

  const isFromBuilder = dealType === 'sale' && offerType === 'flat' && Boolean(offer.isFromBuilder);

  if (isFromBuilder) {
    return 'Контакты застройщика';
  }

  return 'Показать телефон';
}
