/* eslint-disable max-lines */
import { TCurrency } from '@cian/price-history-change-widget';

import {
  TUserTrustLevel,
  TAccountType,
  TLeaseType,
  TAreaUnitType,
  TFlatType,
  TPropertyType,
  TGarageType,
  TBuildingClassType,
  TVentilationType,
  TConditioningType,
  THeatingType,
  TExtinguishingSystemType,
  TNewObjectRoomType,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { TAddressType } from 'shared/types/geo/address';

export const AddressType = {
  Country: 'country' as TAddressType,
  District: 'district' as TAddressType,
  House: 'house' as TAddressType,
  Location: 'location' as TAddressType,
  Okrug: 'okrug' as TAddressType,
  Raion: 'raion' as TAddressType,
  Road: 'road' as TAddressType,
  Street: 'street' as TAddressType,
  Underground: 'underground' as TAddressType,
};

export const UserTrustLevel = {
  Danger: 'danger' as TUserTrustLevel,
  Excluded: 'excluded' as TUserTrustLevel,
  Involved: 'involved' as TUserTrustLevel,
  New: 'new' as TUserTrustLevel,
  NotInvolved: 'notInvolved' as TUserTrustLevel,
};

export const AccountType = {
  Agency: 'agency' as TAccountType,
  ManagementCompany: 'managementCompany' as TAccountType,
  RentDepartment: 'rentDepartment' as TAccountType,
  Specialist: 'specialist' as TAccountType,
};

export const Currency = {
  Eur: 'eur' as TCurrency,
  Rur: 'rur' as TCurrency,
  Usd: 'usd' as TCurrency,
};

export const LeaseType = {
  Direct: 'direct' as TLeaseType,
  JointVenture: 'jointVenture' as TLeaseType,
  Sublease: 'sublease' as TLeaseType,
};

export const AreaUnit = {
  Hectare: 'hectare' as TAreaUnitType,
  Sotka: 'sotka' as TAreaUnitType,
};

export const FlatType = {
  OpenPlan: 'openPlan' as TFlatType,
  Rooms: 'rooms' as TFlatType,
  Studio: 'studio' as TFlatType,
};

export const PropertyType = {
  Building: 'building' as TPropertyType,
  FreeAppointment: 'freeAppointment' as TPropertyType,
  Garage: 'garage' as TPropertyType,
  Industry: 'industry' as TPropertyType,
  Land: 'land' as TPropertyType,
  Office: 'office' as TPropertyType,
  ShoppingArea: 'shoppingArea' as TPropertyType,
  Warehouse: 'warehouse' as TPropertyType,
};

export const GarageType = {
  Box: 'box' as TGarageType,
  Garage: 'garage' as TGarageType,
  ParkingPlace: 'parkingPlace' as TGarageType,
};

export const BuildingClassType = {
  A: 'a' as TBuildingClassType,
  APlus: 'aPlus' as TBuildingClassType,
  B: 'b' as TBuildingClassType,
  BMinus: 'bMinus' as TBuildingClassType,
  BPlus: 'bPlus' as TBuildingClassType,
  C: 'c' as TBuildingClassType,
  CPlus: 'cPlus' as TBuildingClassType,
  D: 'd' as TBuildingClassType,
};

export const VentilationType = {
  Forced: 'forced' as TVentilationType,
  Natural: 'natural' as TVentilationType,
  No: 'no' as TVentilationType,
};

export const ConditioningType = {
  Central: 'central' as TConditioningType,
  Local: 'local' as TConditioningType,
  No: 'no' as TConditioningType,
};

export const HeatingType = {
  Autonomous: 'autonomous' as THeatingType,
  AutonomousGas: 'autonomous_gas' as THeatingType,
  Boiler: 'boiler' as THeatingType,
  Central: 'central' as THeatingType,
  CentralCoal: 'central_coal' as THeatingType,
  CentralGas: 'central_gas' as THeatingType,
  Diesel: 'diesel' as THeatingType,
  Electric: 'electric' as THeatingType,
  Fireplace: 'fireplace' as THeatingType,
  No: 'no' as THeatingType,
  Other: 'other' as THeatingType,
  SolidFuelBoiler: 'solid_fuel_boiler' as THeatingType,
  Stove: 'stove' as THeatingType,
};

export const ExtinguishingSystemType = {
  Alarm: 'alarm' as TExtinguishingSystemType,
  Gas: 'gas' as TExtinguishingSystemType,
  Hydrant: 'hydrant' as TExtinguishingSystemType,
  No: 'no' as TExtinguishingSystemType,
  Powder: 'powder' as TExtinguishingSystemType,
  Sprinkler: 'sprinkler' as TExtinguishingSystemType,
};

export const NewObjectRoomType = {
  Bed: 'bed' as TNewObjectRoomType,
  FiveRoom: 'fiveRoom' as TNewObjectRoomType,
  FourRoom: 'fourRoom' as TNewObjectRoomType,
  Large: 'large' as TNewObjectRoomType,
  OneRoom: 'oneRoom' as TNewObjectRoomType,
  OpenPlan: 'openPlan' as TNewObjectRoomType,
  Room: 'room' as TNewObjectRoomType,
  Share: 'share' as TNewObjectRoomType,
  Studio: 'studio' as TNewObjectRoomType,
  ThreeRoom: 'threeRoom' as TNewObjectRoomType,
  TwoRoom: 'twoRoom' as TNewObjectRoomType,
};
