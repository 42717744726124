/**
 * Это легаси код, им нельзя пользоваться.
 */
import { IHttpApi } from '@cian/http-api';
import {
  IHTTPCompletedResponse,
  IHTTPRequestConfig,
  mergeBaseUri,
  mergePaths,
  THTTPRequestMethod,
} from '@cian/http-api/shared';

export interface IHTTPSimplifiedRequestConfig {
  uri: {
    scheme?: 'http' | 'https';
    host?: string;
    port?: number;
    path: string;
    query?: string;
  };
  method?: THTTPRequestMethod;
  headers?: [string, string][];
  body?: string;
  withCredentials?: boolean;
}

export interface IMakeRequest {
  (config: IHTTPSimplifiedRequestConfig): Promise<IHTTPCompletedResponse>;
}

export function createMakeRequest(
  client: IHttpApi,
  apiBaseUrl?: string,
  proxyHeaders?: [string, string][],
): IMakeRequest {
  let headers: [string, string][] = [];

  if (proxyHeaders) {
    headers = headers.concat(proxyHeaders);
  }

  return (requestConfig: IHTTPSimplifiedRequestConfig) => {
    const config = {
      ...requestConfig,
      headers: requestConfig.headers ? headers.concat(requestConfig.headers) : headers,
      uri:
        (apiBaseUrl &&
          !requestConfig.uri.host &&
          mergeBaseUri(apiBaseUrl, {
            path: (path: string) => mergePaths(path, requestConfig.uri.path),
            port: requestConfig.uri.port,
            query: requestConfig.uri.query,
            scheme: requestConfig.uri.scheme,
          })) ||
        requestConfig.uri,
    };

    if (!config.uri.host) {
      throw new Error(`Host is not configured for request: ${JSON.stringify(config)}`);
    }

    return client.httpRequest(config as IHTTPRequestConfig);
  };
}
