import { ca } from '@cian/analytics';

export const trackSafeRentShow = (productid: number) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Show',
    category: 'SafeRent',
    label: 'Arrange_inspection_button',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        productid,
      },
    },
  });
};
export const trackSafeRentClick = (productid: number) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'SafeRent',
    label: 'Arrange_inspection_button',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        productid,
      },
    },
  });
};
export const trackSafeRentFormSend = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'SafeRent',
    label: 'Form',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
    },
  });
};
export const trackSafeRentFormSentSuccess = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Success',
    category: 'SafeRent',
    label: 'Form_sent',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
    },
  });
};

export const trackSafeRentSimilarForm = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Success',
    category: 'SafeRent',
    label: 'Form_similar_objects',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
    },
  });
};

export const trackSafeRentSimilarFormClick = (accepted: boolean = false) => {
  ca('eventSite', {
    name: 'oldevent',
    action: accepted ? 'ClickSendSimilarYes' : 'ClickSendSimilarNo',
    category: 'SafeRent',
    label: 'Form_sent',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
    },
  });
};

export const trackEntryToShowClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'SafeRent',
    label: 'Arrange_inspection_button_simple',
  });
};

export const trackEntryToShowSuccessModal = (productid: number) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Success',
    category: 'SafeRent',
    label: 'Arrange_inspection_simple',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        productid,
      },
    },
  });
};
