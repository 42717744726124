import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'shared/selectors';
import { getSaveSearchMicrofrontend } from 'shared/services/getSaveSearchMicrofrontend';

import { SaveSearchProvider as Provider } from '../../../packages/SaveSearchModal';
import { useApplicationContext } from '../ApplicationContext';

enum ESource {
  Card = 'card',
  Listing = 'listing',
  Map = 'map',
}

export const SaveSearchModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const context = useApplicationContext();
  const fetchManifest = React.useCallback(async () => getSaveSearchMicrofrontend(context), [context]);
  const user = useSelector(selectUser);
  const email = React.useMemo(() => (user?.isAuthenticated ? user.email : null), [user]);
  const isAuthenticated = !!user?.isAuthenticated;

  const params = {
    email,
    isAuthenticated,
    source: ESource.Card,
  };

  return (
    <Provider fetchManifest={fetchManifest} params={params}>
      {children}
    </Provider>
  );
};
