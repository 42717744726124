export enum EModelTypes {
  UserBased = 'userBased',
  ItemBased = 'itemBased',
  HistoryBased = 'historyBased',
  NewItemBased = 'newItemBased',
  NewobjectItemBased = 'newobjectItemBased',
}

export type TModelType = `${EModelTypes}`;

export interface ISimilarOffersReceivedData {
  modelVersion?: string | null;
  offers: ISimilarOffer[];
  withoutCompetitors?: boolean | null;
}

export enum ETransportType {
  Transport = 'transport',
  Walk = 'walk',
}

export interface ISimilarOfferUnderground {
  color: string;
  iconType?: string | null;
  name: string;
  time?: string | null;
  transportType?: ETransportType | null;
}

export enum EColor {
  /** Синий **/
  Blue = 'blue',
  /** Синий для только для Циан **/
  BlueOnlyCian = 'blueOnlyCian',
  /** Зеленый **/
  Green = 'green',
  /** Зеленый для проверенного собственника **/
  GreenForCheckedOwner = 'greenForCheckedOwner',
  /** Зеленый для собственника **/
  GreenForOwner = 'greenForOwner',
  /** Серый **/
  Grey = 'grey',
  /** Оранжевый **/
  Orange = 'orange',
  /** Белый **/
  White = 'white',
}

export interface IPhotoLabel {
  color: EColor;
  name: string;
}

export interface IPhone {
  countryCode?: string | null;
  number?: string | null;
}

export interface ISimilarOffer {
  address: string;
  cianId: number;
  formattedPrice: string;
  gaVariant: string;
  photoLabel?: IPhotoLabel | null;
  photoUrl?: string | null;
  publishedUserId?: number | null;
  realtyId: number;
  title: string;
  totalPriceRur: number;
  underground?: ISimilarOfferUnderground | null;
  url: string;
  isFromBuilder?: boolean | null;
  newbuildingName?: string | null;
  phones?: IPhone[] | null;
}

export interface ISimilarState {
  userBased: {
    list: ISimilarOffer[];
    withoutCompetitors: boolean | null;
    modelVersion: string | null;
  };
  itemBased: {
    list: ISimilarOffer[];
    withoutCompetitors: boolean | null;
    modelVersion: string | null;
  };
  historyBased: {
    list: ISimilarOffer[];
    withoutCompetitors: boolean | null;
    modelVersion: string | null;
  };
  newItemBased: {
    list: ISimilarOffer[];
    withoutCompetitors: boolean | null;
    modelVersion: string | null;
  };
  newobjectItemBased: {
    list: ISimilarOffer[];
    withoutCompetitors: boolean | null;
    modelVersion: string | null;
  };
}
