export interface IAside {
  height: number;
  isNavigationActive: boolean;
  activeAnchor: string;
  isCompactModeEnabled: boolean;
}

export interface IAsideCompactModeAction {
  isCompactModeEnabled: boolean;
  type: 'IAsideCompactMode';
}

export interface IAsideHeightAction {
  height: number;
  type: 'IAsideHeight';
}

export interface INavigationToggleAction {
  isActive: boolean | undefined;
  type: 'INavigationToggle';
}

export interface INavigationAnchorChangedAction {
  activeAnchor: string;
  type: 'INavigationAnchorChanged';
}

export function setCompactMode(isEnable: boolean): IAsideCompactModeAction {
  return {
    isCompactModeEnabled: isEnable,
    type: 'IAsideCompactMode',
  };
}

export function setHeight(height: number): IAsideHeightAction {
  return {
    height,
    type: 'IAsideHeight',
  };
}

export function toggle(isActive?: boolean): INavigationToggleAction {
  return {
    isActive,
    type: 'INavigationToggle',
  };
}

export function changeNavigationAnchor(activeAnchor: string): INavigationAnchorChangedAction {
  return {
    activeAnchor,
    type: 'INavigationAnchorChanged',
  };
}

const defaultState: IAside = {
  height: 0,
  isNavigationActive: false,
  activeAnchor: '#photos',
  isCompactModeEnabled: false,
};

export function asideReducer(
  state: IAside,
  action: IAsideHeightAction | IAsideCompactModeAction | INavigationToggleAction | INavigationAnchorChangedAction,
): IAside {
  switch (action.type) {
    case 'IAsideHeight':
      return {
        ...state,
        height: action.height,
      };

    case 'IAsideCompactMode':
      return {
        ...state,
        isCompactModeEnabled: action.isCompactModeEnabled,
      };

    case 'INavigationToggle':
      return {
        ...state,
        isNavigationActive: typeof action.isActive !== 'undefined' ? action.isActive : !state.isNavigationActive,
      };

    case 'INavigationAnchorChanged':
      return {
        ...state,
        activeAnchor: action.activeAnchor,
      };

    default:
      return state || defaultState;
  }
}
