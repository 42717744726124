import { IHttpApi } from '@cian/http-api';

import { assertResponseStatus } from './asserts';
import {
  fetchSubmitContacts,
  ISubmitContactsRequest,
  ISubmitContactsResponse200,
  ISubmitContactsResponse204,
  ISubmitContactsResponse400,
} from '../../repositories/newbuilding-reserved-offers/v1/submit-contacts';

export const fetchSubmitContactsForBookedApartment = async (
  httpApi: IHttpApi,
  parameters: ISubmitContactsRequest,
): Promise<boolean> => {
  const response = await fetchSubmitContacts<
    ISubmitContactsResponse400,
    ISubmitContactsResponse200,
    ISubmitContactsResponse204
  >({ httpApi, parameters });

  assertResponseStatus(response);

  return true;
};
