import { isNil } from 'ramda';

import {
  IBuildingSchema,
  ILandSchema,
  INewObjectSchema,
  IOfferSchema,
  TCategory,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { FDealType, getDealType } from './deal_type';
import { getLandAreaUnitType } from './offer_helpers';
import { IOffer } from '../types/offerData';

/* istanbul ignore next */
export function getFloorInfo(floorNumber: number | null, buildingInfo: IBuildingSchema | null): string {
  if (floorNumber && buildingInfo && !isNil(buildingInfo.floorsCount)) {
    return `этаж ${floorNumber}/${buildingInfo.floorsCount}`;
  }

  if (floorNumber) {
    return `этаж ${floorNumber}`;
  }

  return '';
}

export function getMultiAdsFloorInfo(offer: IOffer): string {
  const { areaParts, floorNumber, building } = offer;
  const hasAreaPartsData = areaParts && !!areaParts.length;
  // Проверка на null - на всякий случай, если бэк заполнит поля null'ами
  const isNumber = (value: number | undefined | null): value is number => value !== undefined && value !== null;

  if (hasAreaPartsData) {
    const floors = areaParts
      .reduce<(number | undefined)[]>((acc, { floorFrom, floorTo }) => [...acc, floorFrom, floorTo], [])
      .filter(isNumber);

    const minFloor = floors.length !== 0 ? Math.min(...floors) : null;
    const maxFloor = floors.length !== 0 ? Math.max(...floors) : null;

    if (minFloor !== null && maxFloor !== null) {
      const isMinMaxFloorsEqual = minFloor === maxFloor;

      if (!isMinMaxFloorsEqual) {
        return `этажность от ${minFloor} до ${maxFloor}`;
      }
    }
  }

  if (floorNumber && building && !isNil(building.floorsCount)) {
    return `этаж ${floorNumber}/${building.floorsCount}`;
  }

  return '';
}

/* istanbul ignore next */
export function getTotalArea(area: string | null, land: ILandSchema | null): string {
  if (!area) {
    return '';
  }

  if (land) {
    return `${Number(land.area)} ${getLandAreaUnitType(land)}`;
  }
  if (area) {
    return `${Number(area)} м.кв.`;
  }

  return '';
}

/* istanbul ignore next */
export function getJkName(category: TCategory, newObject: INewObjectSchema | null): string {
  if (newObject && category) {
    return category === 'newBuildingFlatSale' ? `в ${newObject.name}` : '';
  }

  return '';
}

/* istanbul ignore next */
export function getDealTypeText(offer: IOfferSchema): string {
  const dealType = getDealType(offer);
  const rentPrefix = offer.isApartments ? 'Сдаются' : 'Сдаётся';

  if (dealType === FDealType.Sale) {
    return offer.isApartments ? 'Продаются' : 'Продаётся';
  }

  if (dealType === FDealType.RentDaily) {
    return `${rentPrefix} посуточно`;
  }

  return rentPrefix;
}
