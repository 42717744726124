/* istanbul ignore next */
import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getOfferType, isSuburban } from '../offer_type';

export const getParentId = (offer: IOfferSchema, kpId: number | null): number | undefined => {
  const offerType = getOfferType(offer);

  if (offer.newbuilding && offer.newbuilding.id && !isSuburban(offerType)) {
    return Number(offer.newbuilding.id);
  }

  if (offer.coworkingOfferType && offer.coworking) {
    return offer.coworking.id;
  }

  if (offer.businessShoppingCenter && !isSuburban(offerType)) {
    const { id, parentId } = offer.businessShoppingCenter;

    return parentId || id || undefined;
  }

  return kpId || undefined;
};
