/* eslint-disable max-lines */
import { requestAuthentication } from 'shared/actions/authentication';
import { selectIsAuthenticated } from 'shared/selectors';

import {
  trackSafeRentClick,
  trackSafeRentFormSentSuccess,
  trackSafeRentSimilarForm,
  trackSafeRentSimilarFormClick,
  trackEntryToShowSuccessModal,
} from './tracking';
import { IValues as IValuesSendCode } from '../../api/deal_rent/send-validation-code';
import {
  fetchPrefetchFormData,
  TPrefetchFormDataResponse,
} from '../../repositories/deal-rent-announcements/v1/prefetch-form-data';
import {
  fetchSaveForm,
  ISaveFormRequest,
  TSaveFormResponse,
} from '../../repositories/deal-rent-announcements/v1/save-form';
import {
  ISendFormToSimilarOffersRequest,
  fetchSendFormToSimilarOffers,
} from '../../repositories/deal-rent-announcements/v1/send-form-to-similar-offers';
import {
  TSendValidationCodeResponse,
  fetchSendValidationCode,
} from '../../repositories/deal-rent-announcements/v1/send-validation-code';
import { IThunkActionCreator } from '../index';
import {
  openRenterForm,
  prefetchRenterFormStateError,
  prefetchRenterFormStateStart,
  prefetchRenterFormStateSuccess,
} from '../renterForm/actions';

export interface IOpen {
  type: 'deal-rent/open';
}
export interface IClose {
  type: 'deal-rent/close';
}
export interface IOpenSendSimilar {
  type: 'deal-rent/openSendSimilar';
}
export interface ICloseSendSimilar {
  type: 'deal-rent/closeSendSimilar';
}
export interface IOpenSimilarSuccess {
  type: 'deal-rent/openSimilarSuccess';
}
export interface ICloseSimilarSuccess {
  type: 'deal-rent/closeSimilarSuccess';
}
export interface IOpenSuccess {
  type: 'deal-rent/openSuccess';
}
export interface ICloseSuccess {
  type: 'deal-rent/closeSuccess';
}
export interface IOpenFailure {
  type: 'deal-rent/openFailure';
}
export interface ICloseFailure {
  type: 'deal-rent/closeFailure';
}
export interface ISaveFormStart {
  type: 'deal-rent/saveFormStart';
}
export interface ISaveFormSuccess {
  type: 'deal-rent/saveFormSuccess';
}
export interface ISaveFormError {
  type: 'deal-rent/saveFormError';
  payload: string;
}
export interface IPrefetchFormStateStart {
  type: 'deal-rent/prefetchFormStateStart';
}
export interface IPrefetchFormStateSuccess {
  type: 'deal-rent/prefetchFormStateSuccess';
  payload: {
    form: ISaveFormRequest;
    phoneConfirmed: boolean;
  };
}
export interface IPrefetchFormStateError {
  type: 'deal-rent/prefetchFormStateError';
}
export interface ISendSMSStart {
  type: 'deal-rent/sendSMSStart';
}
export interface ISendSMSSuccess {
  type: 'deal-rent/sendSMSSuccess';
  payload: number;
}
export interface ISendSMSAlreadyConfirmed {
  type: 'deal-rent/sendSMSAlreadyConfirmed';
}
export interface ISendSMSError {
  type: 'deal-rent/sendSMSError';
  payload: string;
}
export interface IChangePhoneConfirmed {
  type: 'deal-rent/changePhoneConfirmed';
  payload: boolean;
}

export interface ISelectEntryToShow {
  type: 'deal-rent/selectEntryToShow';
  payload: string;
}

export interface IOpenEntryToShow {
  type: 'deal-rent/openEntryToShow';
}

export interface ICloseEntryToShow {
  type: 'deal-rent/closeEntryToShow';
}

export interface IOpenEntryToShowSuccessModal {
  type: 'deal-rent/openEntryToShowSuccessModal';
}

export interface ICloseEntryToShowSuccessModal {
  type: 'deal-rent/closeEntryToShowSuccessModal';
}

export type actions =
  | IOpen
  | IClose
  | IOpenSendSimilar
  | ICloseSendSimilar
  | IOpenSimilarSuccess
  | ICloseSimilarSuccess
  | IOpenSuccess
  | ICloseSuccess
  | IOpenFailure
  | ICloseFailure
  | ISaveFormStart
  | ISaveFormSuccess
  | ISaveFormError
  | IPrefetchFormStateStart
  | IPrefetchFormStateSuccess
  | IPrefetchFormStateError
  | ISendSMSStart
  | ISendSMSSuccess
  | ISendSMSError
  | ISendSMSAlreadyConfirmed
  | IChangePhoneConfirmed
  | ISelectEntryToShow
  | IOpenEntryToShow
  | ICloseEntryToShow
  | IOpenEntryToShowSuccessModal
  | ICloseEntryToShowSuccessModal;

export const open = (): IOpen => ({
  type: 'deal-rent/open',
});
export const close = (): IClose => ({
  type: 'deal-rent/close',
});
export const openSendSimilar = (): IOpenSendSimilar => ({
  type: 'deal-rent/openSendSimilar',
});
export const closeSendSimilar = (): ICloseSendSimilar => ({
  type: 'deal-rent/closeSendSimilar',
});
export const openSimilarSuccess = (): IOpenSimilarSuccess => ({
  type: 'deal-rent/openSimilarSuccess',
});
export const closeSimilarSuccess = (): ICloseSimilarSuccess => ({
  type: 'deal-rent/closeSimilarSuccess',
});
export const openSuccess = (): IOpenSuccess => ({
  type: 'deal-rent/openSuccess',
});
export const closeSuccess = (): ICloseSuccess => ({
  type: 'deal-rent/closeSuccess',
});
export const openFailure = (): IOpenFailure => ({
  type: 'deal-rent/openFailure',
});
export const closeFailure = (): ICloseFailure => ({
  type: 'deal-rent/closeFailure',
});
export const saveFormStart = (): ISaveFormStart => ({
  type: 'deal-rent/saveFormStart',
});
export const saveFormSuccess = (): ISaveFormSuccess => ({
  type: 'deal-rent/saveFormSuccess',
});
export const saveFormError = (message: string): ISaveFormError => ({
  type: 'deal-rent/saveFormError',
  payload: message,
});
export const prefetchFormStateStart = (): IPrefetchFormStateStart => ({
  type: 'deal-rent/prefetchFormStateStart',
});
export const prefetchFormStateSuccess = (values: {
  form: ISaveFormRequest;
  phoneConfirmed: boolean;
}): IPrefetchFormStateSuccess => ({
  type: 'deal-rent/prefetchFormStateSuccess',
  payload: values,
});
export const prefetchFormStateError = (): IPrefetchFormStateError => ({
  type: 'deal-rent/prefetchFormStateError',
});
export const sendSMSStart = (): ISendSMSStart => ({
  type: 'deal-rent/sendSMSStart',
});
export const sendSMSSuccess = (seconds: number): ISendSMSSuccess => ({
  type: 'deal-rent/sendSMSSuccess',
  payload: seconds,
});
export const sendSMSError = (error: string): ISendSMSError => ({
  type: 'deal-rent/sendSMSError',
  payload: error,
});
export const sendSMSAlreadyConfirmed = (): ISendSMSAlreadyConfirmed => ({
  type: 'deal-rent/sendSMSAlreadyConfirmed',
});
export const changePhoneConfirmed = (value: boolean): IChangePhoneConfirmed => ({
  type: 'deal-rent/changePhoneConfirmed',
  payload: value,
});
export const selectEntryToShow = (value: string): ISelectEntryToShow => ({
  type: 'deal-rent/selectEntryToShow',
  payload: value,
});
export const openEntryToShow = (): IOpenEntryToShow => ({
  type: 'deal-rent/openEntryToShow',
});
export const closeEntryToShow = (): ICloseEntryToShow => ({
  type: 'deal-rent/closeEntryToShow',
});
export const openEntryToShowSuccessModal = (): IOpenEntryToShowSuccessModal => ({
  type: 'deal-rent/openEntryToShowSuccessModal',
});
export const closeEntryToShowSuccessModal = (): ICloseEntryToShowSuccessModal => ({
  type: 'deal-rent/closeEntryToShowSuccessModal',
});

export const saveForm =
  (values: ISaveFormRequest): IThunkActionCreator =>
  async (dispatch, getState, context) => {
    dispatch(saveFormStart());

    const isSimilarOffersExperimentEnabled = context.config.get('dealRentSimilarOffers.enabled') as string;

    try {
      const {
        offerData: {
          offer: { id },
        },
      } = getState();

      const responseData: TSaveFormResponse = await fetchSaveForm({
        httpApi: context.httpApi,
        parameters: {
          ...values,
          objectId: id,
        },
        config: {
          requestConfig: { headers: [['Content-Type', 'application/json']], withCredentials: true },
        },
      });

      if (responseData.statusCode !== 200) {
        if (responseData.response.errors.find(({ code }) => code === 'FormAlreadySent')) {
          dispatch(saveFormSuccess());
          dispatch(close());
          dispatch(openFailure());
        } else {
          dispatch(saveFormError(responseData.response.message));
        }
      } else {
        dispatch(saveFormSuccess());
        dispatch(close());

        if (values.showDate) {
          dispatch(openEntryToShowSuccessModal());
          trackEntryToShowSuccessModal(id);
        } else if (isSimilarOffersExperimentEnabled) {
          dispatch(openSendSimilar());
          trackSafeRentSimilarForm();
        } else {
          dispatch(openSuccess());
          trackSafeRentFormSentSuccess();
        }
      }
    } catch (error) {
      dispatch(saveFormError(error));
    }
  };

export const sendValidationCode =
  (values: IValuesSendCode): IThunkActionCreator =>
  async (dispatch, getState, context) => {
    dispatch(sendSMSStart());

    try {
      const responseData: TSendValidationCodeResponse = await fetchSendValidationCode({
        httpApi: context.httpApi,
        parameters: { phone: values.phone },
        config: {
          requestConfig: { headers: [['Content-Type', 'application/json']], withCredentials: true },
        },
      });

      if (responseData.statusCode !== 200) {
        if (responseData.response.errors.find(({ code }) => code === 'NoConfirmationRequired')) {
          dispatch(sendSMSAlreadyConfirmed());
        } else {
          dispatch(sendSMSError(responseData.response.message));
        }
      } else {
        const {
          response: { secondsToRetry },
        } = responseData;
        if (secondsToRetry !== null && secondsToRetry !== undefined) {
          dispatch(sendSMSSuccess(secondsToRetry));
        }
      }
    } catch (error) {
      dispatch(sendSMSError(error));
    }
  };

export const prefetchFormState = (): IThunkActionCreator => async (dispatch, getState, context) => {
  const state = getState();
  const isAuthenticated = selectIsAuthenticated(state);
  const {
    offerData: {
      offer: { id },
    },
  } = state;

  if (!isAuthenticated) {
    dispatch(
      requestAuthentication({
        onSuccess: prefetchFormState,
      }),
    );

    return;
  }

  dispatch(prefetchRenterFormStateStart());
  trackSafeRentClick(id);

  try {
    const responseData: TPrefetchFormDataResponse = await fetchPrefetchFormData({
      httpApi: context.httpApi,
      parameters: {},
      config: {
        ecnhancersConfig: {
          timeout: 500,
        },
        requestConfig: { withCredentials: true },
      },
    });

    if (responseData.statusCode === 200) {
      const {
        response: { form, phoneConfirmed },
      } = responseData;
      dispatch(
        prefetchRenterFormStateSuccess({
          phoneConfirmed: Boolean(phoneConfirmed),
          form: form ? { ...form, phone: (form.phone || '').replace('+7', '') } : {},
        }),
      );
    } else {
      dispatch(prefetchRenterFormStateError());
    }
  } catch (error) {
    dispatch(prefetchRenterFormStateError());
  }

  dispatch(closeEntryToShow());

  dispatch(openRenterForm());
};

export function sendFormToSimilarOfferAction(parameters: ISendFormToSimilarOffersRequest): IThunkActionCreator {
  return async (dispatch, getState, { httpApi, logger }) => {
    trackSafeRentSimilarFormClick(true);

    try {
      await fetchSendFormToSimilarOffers({
        httpApi,
        parameters,
        config: {
          requestConfig: {
            headers: [['Content-Type', 'application/json']],
          },
        },
      });
      dispatch(closeSendSimilar());
      dispatch(openSimilarSuccess());
    } catch (e) {
      logger.error(e);
    }
  };
}

export function selectEntryToShowAction(date: string): IThunkActionCreator {
  return async dispatch => {
    dispatch(selectEntryToShow(date));
    dispatch(prefetchFormState());
  };
}
