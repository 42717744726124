import { selectOffer } from 'shared/selectors/offerData';
import { IState } from 'shared/store';
import { FDealType, getDealType } from 'shared/utils/deal_type';

export const isRentDailySelector = (state: IState) => {
  const offer = selectOffer(state);
  const dealType = getDealType(offer);

  return dealType === FDealType.RentDaily;
};
