import { selectBrandingLevel } from './selectBrandingLevel';
import { selectIsAgencyBrandingAvailable } from './selectIsAgencyBrandingAvailable';
import { IState } from '../../store';
import { EBrandingLevel } from '../../types/offerData';

export const selectIsAgencyBottomBrandingAvailable = (state: IState): boolean => {
  const isAvailable = selectIsAgencyBrandingAvailable(state);
  const brandingLevel = selectBrandingLevel(state);

  return (
    isAvailable && !!brandingLevel && [EBrandingLevel.SecondLevel, EBrandingLevel.ThirdLevel].includes(brandingLevel)
  );
};
