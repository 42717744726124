import {
  IGetOfferCalendarResponse,
  IGetOfferCalendarResponse400,
  IGetOfferCalendarResponseError,
} from 'shared/repositories/dailyrent/v1/get-offer-calendar';
import { IGetOfferCalendarMappedResponse200 } from 'shared/services/fetchGetOfferCalendar/types';
import { TDaysAvailability } from 'shared/types/bookingCalendar';

export const mapCalendarResponse = (response: IGetOfferCalendarResponse): IGetOfferCalendarMappedResponse200 => {
  const mappedCalendar = response.calendar.split('').map(Number) as TDaysAvailability;

  return {
    statusCode: 200,
    headers: [],
    response: { calendar: mappedCalendar },
  };
};

export const mapCalendarResponseError = (response: IGetOfferCalendarResponseError): IGetOfferCalendarResponse400 => {
  return {
    statusCode: 400,
    headers: [],
    response: {
      errors: response.errors,
      message: response.message,
    },
  };
};
