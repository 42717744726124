import { actionGenerator } from 'shared/utils/redux/actionGenerator';

export enum EGetMyHomeObjectsExists {
  setMyHomeExists = 'valuationTraps/set-my-home-exists',
}

export const setMyHomeExists = actionGenerator<EGetMyHomeObjectsExists.setMyHomeExists, boolean>(
  EGetMyHomeObjectsExists.setMyHomeExists,
);

export type TValuationTrapsActions = ReturnType<typeof setMyHomeExists>;
