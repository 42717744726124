import { IThunkContext } from '@cian/favorites-assign-widget/es/shared/types/redux';

import { ResponseError } from 'shared/errors/responseError';
import {
  fetchCheckPrice as fetchCheckPriceHelper,
  ICheckPriceRequest,
  ICheckPriceResponse,
  TCheckPriceResponse,
} from 'shared/repositories/dailyrent/v1/check-price';

const DEFAULT_ERROR_MESSAGE = 'Произошла ошибка при проверке доступности';

export const fetchCheckPrice = async (
  context: IThunkContext,
  parameters: ICheckPriceRequest,
): Promise<ICheckPriceResponse> => {
  const { httpApi } = context;

  const data = (await fetchCheckPriceHelper({
    httpApi,
    parameters,
  })) as TCheckPriceResponse;

  if (data.statusCode === 400) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { key, code, message } = data.response.errors?.[0] || ({} as any);

    if (key && code && message) {
      throw new ResponseError({ domain: 'dailyrentBookingCalendar#checkPrice', message, details: { key, code } });
    }

    throw new ResponseError({ domain: 'dailyrentBookingCalendar#unknown', message: DEFAULT_ERROR_MESSAGE });
  }

  return data.response;
};
