/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetNewRecommendedOffersModel,
  IGetNewRecommendedOffersRequest,
  IMappers,
  IGetNewRecommendedOffersResponse,
  IGetNewRecommendedOffersResponseError,
  TGetNewRecommendedOffersResponse,
} from './types';

const defaultConfig: TGetNewRecommendedOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-engine',
  pathApi: '/v1/get-new-recommended-offers/',
  hostType: 'api',
} as TGetNewRecommendedOffersModel;

function createGetNewRecommendedOffersModel(
  parameters: IGetNewRecommendedOffersRequest,
): TGetNewRecommendedOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewRecommendedOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetNewRecommendedOffersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetNewRecommendedOffers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetNewRecommendedOffersOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetNewRecommendedOffersResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetNewRecommendedOffersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewRecommendedOffersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetNewRecommendedOffersResponseError);
    }
  }

  return { response, statusCode } as TGetNewRecommendedOffersResponse;
}

export { defaultConfig, createGetNewRecommendedOffersModel, fetchGetNewRecommendedOffers };
