import { similarOffersReceived } from './similarOffersReceived';
import { getItemBasedSimilarOffers } from '../../../api/similar';
import { IThunkActionCreator } from '../../index';

export function requestItemBasedSimilarOffers(
  offerId: number,
  publishedUserId: number | null,
): IThunkActionCreator<Promise<void>> {
  return (dispatch, getState, context) => {
    // обработки ошибок нет, и это нормально — деградируем без показа блока
    return getItemBasedSimilarOffers(context, { cianOfferId: offerId, publishedUserId }).then(res => {
      if (res.statusCode === 200) {
        dispatch(similarOffersReceived(res.response, 'itemBased'));
      }
    });
  };
}
