import { ETargetType } from '../../types/phoneWarning';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EPhoneWaringActionTypes {
  Close = 'phoneWaring/close',
  Open = 'phoneWaring/open',
}

export type TOpenPhoneWarning = ITypedReduxAction<EPhoneWaringActionTypes.Open, ETargetType>;
export type TClosePhoneWarning = ITypedReduxAction<EPhoneWaringActionTypes.Close, void>;

export const closePhoneWarning = actionGenerator<EPhoneWaringActionTypes.Close>(EPhoneWaringActionTypes.Close);

export type TPhoneWaringActions = TOpenPhoneWarning | TClosePhoneWarning;
