import { selectIsAgencyBrandingAvailable } from './selectIsAgencyBrandingAvailable';
import { selectIsGoldOrPlatinumBrandingLevel } from './selectIsGoldOrPlatinumBrandingLevel';
import { IState } from '../../store';

export const selectIsSimilarOffersForAgencyBrandingAvailable = (state: IState): boolean => {
  const isBrandingAvailable = selectIsAgencyBrandingAvailable(state);
  const isGoldOrPlatinumLevel = selectIsGoldOrPlatinumBrandingLevel(state);

  return isBrandingAvailable && isGoldOrPlatinumLevel;
};
