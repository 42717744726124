import { ESimilarOffersActionType, ISimilarOffersReceivedAction } from '../actions';
import { ISimilarState } from '../types';

const defaultState: ISimilarState = {
  userBased: {
    list: [],
    withoutCompetitors: false,
    modelVersion: '',
  },
  itemBased: {
    list: [],
    withoutCompetitors: false,
    modelVersion: '',
  },
  historyBased: {
    list: [],
    withoutCompetitors: false,
    modelVersion: '',
  },
  newItemBased: {
    list: [],
    withoutCompetitors: false,
    modelVersion: '',
  },
  newobjectItemBased: {
    list: [],
    withoutCompetitors: false,
    modelVersion: '',
  },
};

export function similarOffersReducer(
  state: ISimilarState = defaultState,
  action: ISimilarOffersReceivedAction,
): ISimilarState {
  switch (action.type) {
    case ESimilarOffersActionType.ISimilarOffersReceived:
      return {
        ...state,
        [action.modelType]: {
          list: action.list,
          withoutCompetitors: action.withoutCompetitors,
          modelVersion: action.modelVersion,
        },
      };

    default:
      return state;
  }
}
