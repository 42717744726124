export interface IComparisonNotification {
  text: string;
  category: EComparisonNotificationCategory;
  offerId: number;
  url?: string | null;
}

export enum EComparisonNotificationCategory {
  Add = 'add',
  Limit = 'limit',
  Remove = 'remove',
}
