import { isNil } from 'ramda';

import { TDealType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import {
  requestOffersHistoryListingActions,
  saveDefaultFilters,
  setRoomsFilter,
  setStatusFilter,
  setTypeFilter,
} from './actions';
import { fetchOffersHistoryListing } from '../../services/offersHistory/fetchOffersHistoryListing';
import { IThunkActionCreator } from '../../store';
import {
  ETypeFilterValues,
  FILTER_ALL_VALUE,
  EDealType,
  mapOfferLocationsEnums,
  mapOfferStatusEnums,
  mapOfferRoomsEnums,
} from '../../store/offerHistory';

const { success, successLoadMore, request, failure } = requestOffersHistoryListingActions;

const getSuccessAction = (isLoadMore?: boolean) => (isLoadMore ? successLoadMore : success);

const mapDealTypes: Record<TDealType, EDealType> = {
  sale: EDealType.Sale,
  rent: EDealType.Rent,
};

export const getOffersHistory =
  (isLoadMore?: boolean): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState, context) => {
    const {
      offersHistory: {
        page,
        roomsFilterActiveValue,
        statusFilterActiveValue,
        typeFilterActiveValue,
        resultsOnPage,
        categoriesFilterActiveValue,
        orderByValue,
      },
      offerData: {
        offer: { cianId, dealType },
      },
    } = getState();

    dispatch(request(!!isLoadMore));

    try {
      const res = await fetchOffersHistoryListing(context, {
        offerId: cianId,
        resultsOnPage,
        page,
        orderBy: orderByValue,
        dealType: mapDealTypes[dealType],
        offerStatus: filterOutDefaultVal(statusFilterActiveValue),
        roomsCount: filterOutDefaultVal(roomsFilterActiveValue),
        category: filterOutDefaultVal(categoriesFilterActiveValue),
        isNearby: isNil(typeFilterActiveValue) ? undefined : typeFilterActiveValue === ETypeFilterValues.NearbyHouses,
      });

      /** Предустановка активных значений из ответа API, если таковые есть, или дефолтов */
      if (res.activeFiltersValues) {
        const { locations: _locations, statusCounts: _statusCounts, roomCounts: _roomCounts } = res.activeFiltersValues;

        const location = mapOfferLocationsEnums[_locations];
        const statusCounts = _statusCounts ? mapOfferStatusEnums[_statusCounts] : FILTER_ALL_VALUE;
        const roomCounts = _roomCounts ? mapOfferRoomsEnums[_roomCounts] : FILTER_ALL_VALUE;

        dispatch(setTypeFilter(location));
        dispatch(setStatusFilter(statusCounts));
        dispatch(setRoomsFilter(roomCounts));
        dispatch(saveDefaultFilters({ location, roomCounts, statusCounts }));
      }

      dispatch(getSuccessAction(isLoadMore)(res));
    } catch (e) {
      dispatch(failure());
    }
  };

/** Отфильтровывает значение 'all' из фильтра, т.к в api оно не должно отправляться */
function filterOutDefaultVal<V extends string | undefined>(value: V | typeof FILTER_ALL_VALUE): V | undefined {
  return value === FILTER_ALL_VALUE ? undefined : value;
}
