import { abUseExperimentsSelector } from 'shared/selectors';
import { IState } from 'shared/store';

export type TNewbuildingFlatSaleTrapExperimentGroup =
  /** Не показываем баннер **/
  | 'A'
  /** Показываем баннер **/
  | 'B';

export const NEWBUILDING_FLAT_SALE_TRAP = 'newbuilding_flatsale_trap_desktop';

export function selectNewbuildingFlatSaleTrapGroup(state: IState): TNewbuildingFlatSaleTrapExperimentGroup {
  const experiments = abUseExperimentsSelector(state);
  const experiment = experiments.find(o => o.experimentName === NEWBUILDING_FLAT_SALE_TRAP);

  return (experiment?.groupName || 'A') as TNewbuildingFlatSaleTrapExperimentGroup;
}
