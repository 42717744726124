import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { BuyForm, SuccessScreen } from './components';
import { SUCCESS_MODAL_HEIGHT, USUAL_MODAL_HEIGHT, USUAL_MODAL_TITLE } from './constants';
import { EBuyAnalyticsFormStatus, IBuyAnalyticsModalWidgetProps } from './types';
import { TBuyAnalyticsReportLabel } from '../../utils/tracking/trackingBuyAnalyticsReportEvent';

export const BuyAnalyticsModal: React.FC<IBuyAnalyticsModalWidgetProps> = ({
  isOpened,
  buildingName,
  buildingAddress,
  userEmail,
  reportPrice,
  status,
  freeReportData,
  legalDocsUrl,
  isLegalDocsUrlEnabled,
  onClose,
  onSubmit,
  onUpdatePaymentStatus,
  onTrackEvent,
}) => {
  const isSuccess = React.useMemo(() => status === EBuyAnalyticsFormStatus.Success, [status]);
  const modalHeight = React.useMemo(() => (isSuccess ? SUCCESS_MODAL_HEIGHT : USUAL_MODAL_HEIGHT), [isSuccess]);
  const modalTitle = React.useMemo(() => (isSuccess ? undefined : USUAL_MODAL_TITLE), [isSuccess]);

  const handleTrackEvent = React.useCallback(
    (label: TBuyAnalyticsReportLabel) => () => {
      onTrackEvent(label);
    },
    [onTrackEvent],
  );

  React.useEffect(() => {
    if (isOpened) {
      onUpdatePaymentStatus();
    }
  }, [isOpened, onUpdatePaymentStatus]);

  const modalContent = React.useMemo(() => {
    if (isSuccess) {
      return <SuccessScreen onTrackClickEvent={handleTrackEvent('IAP_details')} />;
    }

    return (
      <BuyForm
        buildingName={buildingName}
        buildingAddress={buildingAddress}
        userEmail={userEmail}
        reportPrice={reportPrice}
        status={status}
        freeReportData={freeReportData}
        legalDocsUrl={legalDocsUrl}
        isLegalDocsUrlEnabled={isLegalDocsUrlEnabled}
        onSubmit={onSubmit}
        onTrackClickEvent={handleTrackEvent('report_example_download')}
      />
    );
  }, [
    isSuccess,
    status,
    buildingName,
    buildingAddress,
    userEmail,
    reportPrice,
    freeReportData,
    legalDocsUrl,
    isLegalDocsUrlEnabled,
    onSubmit,
    handleTrackEvent,
  ]);

  return (
    <ModalWindow
      width={688}
      height={modalHeight}
      open={isOpened}
      onClose={onClose}
      outside
      fixed={!isSuccess}
      title={modalTitle}
    >
      {modalContent}
    </ModalWindow>
  );
};

BuyAnalyticsModal.displayName = 'BuyAnalyticsModal';
