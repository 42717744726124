import { LinkButton, UIHeading1, UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SuccessScreen.css';
import { CreSpaceLogo } from '../CreSpaceLogo';

export interface ISuccessScreenProps {
  onTrackClickEvent: () => void;
}

export const SuccessScreen: React.FC<ISuccessScreenProps> = ({ onTrackClickEvent }) => {
  return (
    <div data-testid="SuccessScreen" className={styles['container']}>
      <div className={styles['success-wrapper']}>
        <div className={styles['success-title']}>
          <UIHeading1>Оплата прошла успешно</UIHeading1>
        </div>
        <UIText1>
          Отчёт будет сформирован и отправлен на почту в течение 10 минут. Проверьте папку «Спам» — иногда письма
          попадают туда.
        </UIText1>
      </div>
      <div className={styles['ads-wrapper']}>
        <div className={styles['ads-logo']}>
          <CreSpaceLogo />
        </div>
        <div className={styles['ads-title']}>
          <UIHeading2>Получите доступ к аналитике по тысячам зданий</UIHeading2>
        </div>
        <div className={styles['ads-description']}>
          <UIText1>
            Циан CRE.Space — новый сервис с информацией по объектам коммерческой недвижимости. Технические
            характеристики, аналитика, инфраструктура, заполняемость, вакантные помещения — всё в одном месте.
          </UIText1>
        </div>
        <LinkButton
          theme="fill_secondary"
          size="XS"
          href="https://promo.cre.space"
          target="_blank"
          onClick={onTrackClickEvent}
        >
          Подробнее
        </LinkButton>
      </div>
    </div>
  );
};

SuccessScreen.displayName = 'SuccessScreen';
