import { IState } from '../../store';
import { IPageExtra } from '../../types';

export function getPriceExtra(state: IState): Partial<IPageExtra> | undefined {
  const {
    offerData: { priceInfo },
  } = state;

  if (!priceInfo || !priceInfo.pricePerSquareValue) {
    return undefined;
  }

  return {
    additional_price: priceInfo.pricePerSquareValue,
  };
}
