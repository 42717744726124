export enum EAddFeedbackStatus {
  Initial = 'Initial',
  Loading = 'Loading',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export enum EFeedbackActionTypes {
  openFeedbackPopup = 'feedback/IFeedbackPopupOpened',
  closeFeedbackPopup = 'feedback/IFeedbackPopupClosed',
  openFeedbackFormPopup = 'feedback/IFeedbackFormPopupOpened',
  closeFeedbackFormPopup = 'feedback/IFeedbackFormPopupClosed',
  openFeedbackComplaintPopup = 'feedback/IFeedbackComplaintPopupOpened',
  closeFeedbackComplaintPopup = 'feedback/IFeedbackComplaintPopupClosed',
  openFeedbackSentPopup = 'feedback/IFeedbackSentPopupOpened',
  closeFeedbackSentPopup = 'feedback/IFeedbackSentPopupClosed',
  setFeedbackSent = 'feedback/ISetFeedbackSent',
  addFeedbackStatusChanged = 'feedback/IAddFeedbackStatusChanged',
}

export interface IFeedbackPopupOpened {
  type: EFeedbackActionTypes.openFeedbackPopup;
}
export interface IFeedbackPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackPopup;
}
export interface IFeedbackFormPopupOpened {
  type: EFeedbackActionTypes.openFeedbackFormPopup;
}
export interface IFeedbackFormPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackFormPopup;
}
export interface IFeedbackComplaintPopupOpened {
  type: EFeedbackActionTypes.openFeedbackComplaintPopup;
}
export interface IFeedbackComplaintPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackComplaintPopup;
}
export interface IAddFeedbackStatusChanged {
  type: EFeedbackActionTypes.addFeedbackStatusChanged;
  status: EAddFeedbackStatus;
}
export interface ISetFeedbackSent {
  type: EFeedbackActionTypes.setFeedbackSent;
}
export interface IFeedbackSentPopupOpened {
  type: EFeedbackActionTypes.openFeedbackSentPopup;
}
export interface IFeedbackSentPopupClosed {
  type: EFeedbackActionTypes.closeFeedbackSentPopup;
}

export function openFeedbackPopup(): IFeedbackPopupOpened {
  return {
    type: EFeedbackActionTypes.openFeedbackPopup,
  };
}

export function closeFeedbackPopup(): IFeedbackPopupClosed {
  return {
    type: EFeedbackActionTypes.closeFeedbackPopup,
  };
}

export function openFeedbackSentPopup(): IFeedbackSentPopupOpened {
  return {
    type: EFeedbackActionTypes.openFeedbackSentPopup,
  };
}

export function closeFeedbackSentPopup(): IFeedbackSentPopupClosed {
  return {
    type: EFeedbackActionTypes.closeFeedbackSentPopup,
  };
}
