import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { createOffersHistorySpoilerMapper, TMappedResponse } from './mappers';
import {
  fetchGetHouseOfferHistorySpoiler,
  IGetHouseOfferHistorySpoilerRequest,
} from '../../../repositories/valuation-offer-history/v1/get-house-offer-history-spoiler';
import { IApplicationContext } from '../../../types/applicationContext';

export const fetchOffersHistorySpoiler = async (
  context: IApplicationContext,
  parameters: IGetHouseOfferHistorySpoilerRequest,
) => {
  try {
    const res = (await fetchGetHouseOfferHistorySpoiler({
      httpApi: context.httpApi,
      parameters,
      mappers: createOffersHistorySpoilerMapper(parameters),
    })) as TMappedResponse;

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      context.logger.debug('get-house-offer-history-spoiler BadRequest', { details: error.printDetails() });
    } else {
      context.logger.error(error, { message: 'Error in get-house-offer-history-spoiler', ...parameters });
    }

    throw error;
  }
};
