import { trackOpenReviewsGoToAppModal } from 'shared/tracking/dailyrentReviews';

import { IThunkActionCreator } from '../../store';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EReviewsGoToAppModalActionTypes {
  Close = 'reviewsGoToAppModal/close',
  Open = 'reviewsGoToAppModal/open',
}

export type TOpenReviewsGoToAppModal = ITypedReduxAction<EReviewsGoToAppModalActionTypes.Open>;
export type TCloseReviewsGoToAppModal = ITypedReduxAction<EReviewsGoToAppModalActionTypes.Close>;

const openReviewsGoToAppModalAction = actionGenerator<EReviewsGoToAppModalActionTypes.Open>(
  EReviewsGoToAppModalActionTypes.Open,
);

export const closeReviewsGoToAppModal = actionGenerator<EReviewsGoToAppModalActionTypes.Close>(
  EReviewsGoToAppModalActionTypes.Close,
);

export type TReviewsGoToAppModalActions = TOpenReviewsGoToAppModal | TCloseReviewsGoToAppModal;

export const openReviewsGoToAppModal = (): IThunkActionCreator => {
  return async (dispatch): Promise<void> => {
    dispatch(openReviewsGoToAppModalAction());
    trackOpenReviewsGoToAppModal();
  };
};
