import {
  IActiveFiltersValuesSchema,
  ELocations,
  ERoomCounts,
} from '../../../repositories/valuation-offer-history/entities/offer_history/ActiveFiltersValuesSchema';
import {
  EDealType,
  ECategory as ECategoryRequest,
  ERoomsCount,
  EOfferStatus as EOfferStatusRequest,
  EOrderBy,
} from '../../../repositories/valuation-offer-history/entities/offer_history/HouseOfferHistoryRequestV4Schema';
import {
  EStatus as EOfferStatusData,
  IHouseOfferHistoryV4Schema,
} from '../../../repositories/valuation-offer-history/entities/offer_history/HouseOfferHistoryV4Schema';
import { EValue as ETypeFilterValues } from '../../../repositories/valuation-offer-history/entities/offer_history/OfferHistoryLocationFilterItemSchema';
import { ECategory as ESpoilerCategoryRequest } from '../../../repositories/valuation-offer-history/entities/offer_history/OfferHistorySpoilerRequestV1Schema';
import { EPriceDiff } from '../../../repositories/valuation-offer-history/entities/offer_history/PricesV4Schema';
import { EValue as ECategoryValue } from '../../../repositories/valuation-offer-history/entities/suburban_offer_history/SuburbanOfferCategoryFilterSchema';
import { EActiveCategory } from '../../../repositories/valuation-offer-history/entities/suburban_offer_history/SuburbanOfferHistorySpoilerFiltersSchema';
import { IGetOfferFromHistoryWebResponse } from '../../../repositories/valuation-offer-history/v2/get-offer-from-history-web';
export { ETypeFilterValues };

export interface IFilterOption<S = string> {
  value: S;
  label: string;
}

export interface IOffer extends IHouseOfferHistoryV4Schema {
  deactivatedTitle: string | null;
  dateEnd: string;
  expositionFull: string;
}

export interface IOfferEstimated {
  title: string;
  price?: string | null;
  subprice?: string | null;
}

export const FILTER_ALL_VALUE = 'all';
export type TStatusFilterOptionValue = EOfferStatusRequest | typeof FILTER_ALL_VALUE;
export type TRoomsFilterOptionValue = ERoomsCount | typeof FILTER_ALL_VALUE;
export type TCategoriesFilterOptionValue = ECategoryRequest | typeof FILTER_ALL_VALUE;

export type TStatusFilterOption = IFilterOption<TStatusFilterOptionValue>;
export type TRoomsFilterOption = IFilterOption<TRoomsFilterOptionValue>;
export type TTypeFilterOption = IFilterOption<ETypeFilterValues>;
export type TCategoryFilterOption = IFilterOption<TCategoriesFilterOptionValue>;
export type TSpoilerCategoryFilterOption = IFilterOption<ESpoilerCategoryRequest>;

/** Наборы опций (данных) для рендера UI компонентов фильтров */
export type TStatusFilterOptions = TStatusFilterOption[];
export type TRoomsFilterOptions = TRoomsFilterOption[];
export type TTypeFilterOptions = TTypeFilterOption[];
export type TCategoryFilterOptions = TCategoryFilterOption[];
export type TSpoilerCategoryFilterOptions = TSpoilerCategoryFilterOption[];

/** Значения активных фильтров. Хранятся в сторе. Передаются в UI компонент в качестве value */
export type TStatusFilterActiveValue = EOfferStatusRequest | typeof FILTER_ALL_VALUE | undefined;
export type TRoomsFilterActiveValue = ERoomsCount | typeof FILTER_ALL_VALUE | undefined;
export type TTypeFilterActiveValue = ETypeFilterValues | undefined;
export type TCategoriesFilterActiveValue = ECategoryRequest | typeof FILTER_ALL_VALUE | undefined;

export interface ISuburbanOfferHistorySpoilerFilters {
  activeCategory: ESpoilerCategoryRequest;
  categories: TSpoilerCategoryFilterOptions;
}

export interface IOffersHistorySpoilerData {
  offers: IOffer[];
  totalCount?: number;
  filters?: ISuburbanOfferHistorySpoilerFilters;
  totalCountText?: string;
}

export interface IOffersHistoryListingData {
  offers: IOffer[];
  roomsFilters?: TRoomsFilterOptions | null;
  statusFilters?: TStatusFilterOptions | null;
  locationFilters?: TTypeFilterOptions | null;
  categoriesFilters?: TCategoryFilterOptions | null;
  activeFiltersValues?: IActiveFiltersValuesSchema | null;
  totalCount?: number;
}

export interface IOffersHistoryDefaultFilters {
  location?: ETypeFilterValues;
  roomCounts?: TRoomsFilterOptionValue;
  statusCounts?: TStatusFilterOptionValue;
  category?: TCategoriesFilterOptionValue;
}

export type TOfferFromHistoryData = IGetOfferFromHistoryWebResponse;

export interface IOffersHistoryData {
  listingSpoiler: TFetchable<IOffersHistorySpoilerData | null>;
  listing: TFetchable<IOffersHistoryListingData | null>;
  offers: TFetchable<Record<number, TOfferFromHistoryData>>;
}

export interface IOffersHistoryState {
  data: IOffersHistoryData;
  statusFilterActiveValue: TStatusFilterActiveValue;
  roomsFilterActiveValue: TRoomsFilterActiveValue;
  typeFilterActiveValue: TTypeFilterActiveValue;
  categoriesFilterActiveValue: TCategoriesFilterActiveValue;
  defaultFilters: IOffersHistoryDefaultFilters;
  page: number;
  resultsOnPage: number;
  activeOfferId: number | null;
  isFiltersChanged: boolean;
  viewedOffers: number[];
  orderByValue: EOrderBy | undefined;
  haveAdminAccess: boolean;
}

type TFetchable<T> = {
  isFetching: boolean;
  isError: boolean;
  isLoadMore: boolean;
  isAuthError?: boolean;
  data: T;
};

export {
  EOfferStatusData,
  EOfferStatusRequest,
  ERoomsCount,
  EPriceDiff,
  EDealType,
  ECategoryRequest,
  ECategoryValue,
  ESpoilerCategoryRequest,
  EActiveCategory,
};

/** Две одинаковые енамки по типам не подходят одна к другой из-за разных исходных имен, поэтому используем маппер */
export const mapOfferStatusEnums: Record<EOfferStatusData, EOfferStatusRequest> = {
  [EOfferStatusData.Published]: EOfferStatusRequest.Published,
  [EOfferStatusData.Deactivated]: EOfferStatusRequest.Deactivated,
};

export const mapOfferRoomsEnums: Record<ERoomCounts, ERoomsCount> = {
  [ERoomCounts.Five]: ERoomsCount.Five,
  [ERoomCounts.Four]: ERoomsCount.Four,
  [ERoomCounts.One]: ERoomsCount.One,
  [ERoomCounts.SixPlus]: ERoomsCount.SixPlus,
  [ERoomCounts.Studio]: ERoomsCount.Studio,
  [ERoomCounts.OnlyRoom]: ERoomsCount.OnlyRoom,
  [ERoomCounts.Three]: ERoomsCount.Three,
  [ERoomCounts.Two]: ERoomsCount.Two,
};

export const mapOfferLocationsEnums: Record<ELocations, ETypeFilterValues> = {
  [ELocations.NearbyHouses]: ETypeFilterValues.NearbyHouses,
  [ELocations.SameHouse]: ETypeFilterValues.SameHouse,
};

export const mapOfferSpoilerActiveCategoryEnums: Partial<Record<EActiveCategory, ESpoilerCategoryRequest>> = {
  [EActiveCategory.CottageSale]: ESpoilerCategoryRequest.CottageSale,
  [EActiveCategory.HouseSale]: ESpoilerCategoryRequest.HouseSale,
  [EActiveCategory.TownhouseSale]: ESpoilerCategoryRequest.TownhouseSale,
  [EActiveCategory.LandSale]: ESpoilerCategoryRequest.LandSale,
  [EActiveCategory.HouseShareSale]: ESpoilerCategoryRequest.HouseShareSale,
};

export const mapOfferSpoilerCategoryEnums: Partial<Record<ECategoryValue, ESpoilerCategoryRequest>> = {
  [ECategoryValue.CottageSale]: ESpoilerCategoryRequest.CottageSale,
  [ECategoryValue.HouseSale]: ESpoilerCategoryRequest.HouseSale,
  [ECategoryValue.TownhouseSale]: ESpoilerCategoryRequest.TownhouseSale,
  [ECategoryValue.LandSale]: ESpoilerCategoryRequest.LandSale,
  [ECategoryValue.HouseShareSale]: ESpoilerCategoryRequest.HouseShareSale,
};

export const mapOfferCategoriesEnums: Partial<Record<ESpoilerCategoryRequest, ECategoryRequest>> = {
  [ESpoilerCategoryRequest.CottageSale]: ECategoryRequest.CottageSale,
  [ESpoilerCategoryRequest.HouseSale]: ECategoryRequest.HouseSale,
  [ESpoilerCategoryRequest.TownhouseSale]: ECategoryRequest.TownhouseSale,
  [ESpoilerCategoryRequest.LandSale]: ECategoryRequest.LandSale,
  [ESpoilerCategoryRequest.HouseShareSale]: ECategoryRequest.HouseShareSale,
};
