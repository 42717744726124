import { mergeBaseUri } from '@cian/http-api/shared';

import { TDealType, TOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IMakeRequest } from '../utils/make_request';

export interface IChangeFavoriteResponse {
  status: string;
  count: {
    total: number;
  };
  folderInfo: {
    folder: {
      id?: number;
      name?: string;
    };
    hasFolders: boolean;
  };
  newbuilding_id?: number;
}

export function changeFavorite(
  makeRequest: IMakeRequest,
  apiBaseUrl: string,
  subdomain: string,
  offerId: number,
  dealType: TDealType,
  offerType: TOfferType,
  isFavorite: boolean,
  addToFolder: boolean,
): Promise<IChangeFavoriteResponse> {
  const reqBody = `action=${isFavorite ? 'assign' : 'remove'}&oid=${offerId}&addToFolder=${addToFolder}`;

  return makeRequest({
    body: reqBody,
    headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
    method: 'POST',
    uri: mergeBaseUri(apiBaseUrl.replace('www', subdomain), {
      path: `/ajax/${dealType}/${offerType}/favorites`,
    }),
  }).then(response => JSON.parse(response.responseBody));
}
