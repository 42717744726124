import { setBuyAnalyticsModalStatus } from './actions';
import { sendSoprAnalytics } from './sendSoprAnalytics';
import { getRequestUrl } from './utils';
import { EBuyAnalyticsFormStatus } from '../../components/BuyAnalyticsModal';
import {
  selectBuyAnalyticsPropertyId,
  selectIsBuyAnalyticsModalFormSending,
  selectIsBuyAnalyticsModalStatusPending,
} from '../../selectors/buyAnalyticsModal';
import { fetchBuyAnalyticsPaymentUrl } from '../../services/commercialAnalyticsReports/fetchBuyAnalyticsPaymentUrl';
import { IThunkActionCreator } from '../../store';

export function getPaymentUrl(email: string): IThunkActionCreator<Promise<void>> {
  return async (dispatch, getState, context) => {
    const state = getState();

    const isSending = selectIsBuyAnalyticsModalFormSending(state);
    const isPending = selectIsBuyAnalyticsModalStatusPending(state);
    const propertyId = selectBuyAnalyticsPropertyId(state);
    if (!propertyId || !email || isSending || isPending) {
      return;
    }

    dispatch(sendSoprAnalytics('report_payment'));
    dispatch(setBuyAnalyticsModalStatus(EBuyAnalyticsFormStatus.Sending));

    const requestParams = {
      email,
      propertyId,
      requestUrl: getRequestUrl(),
    };

    try {
      const { response, errorMessage, errorKey } = await fetchBuyAnalyticsPaymentUrl(context, requestParams);

      if (window && response && !errorMessage && !errorKey) {
        window.location.href = response.paymentUrl;
      } else {
        const status = errorKey === 'realtyUserId' ? EBuyAnalyticsFormStatus.UserError : EBuyAnalyticsFormStatus.Error;
        dispatch(setBuyAnalyticsModalStatus(status));
      }
    } catch {
      dispatch(setBuyAnalyticsModalStatus(EBuyAnalyticsFormStatus.Error));
    }
  };
}
