import { IHttpApi } from '@cian/http-api';

import { IFastFiltersUserSchema } from '../repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { fetchGetUserDataByRequest } from '../repositories/monolith-python/v1/get-user-data-by-request';

interface IGetUserParams {
  subdomain: string;
  headers?: [string, string][];
}

export function getUser(httpApi: IHttpApi, params: IGetUserParams): Promise<IFastFiltersUserSchema> {
  const { subdomain, headers } = params;

  return fetchGetUserDataByRequest({ httpApi, parameters: {}, config: { subdomain, requestConfig: { headers } } }).then(
    ({ response }) => response.data.user,
  );
}
