import { IWidgetMarkupUnderMapFetched } from './underMap';

export interface IWidgetsMarkupState {
  underMapMarkup: string;
}

export function widgetMarkupReducer(
  state: IWidgetsMarkupState,
  action: IWidgetMarkupUnderMapFetched,
): IWidgetsMarkupState {
  switch (action.type) {
    case 'IWidgetMarkupUnderMapFetched':
      return {
        ...state,
        underMapMarkup: action.payload,
      };
    default:
      return state || {};
  }
}
