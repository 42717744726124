import { similarOffersReceived } from './similarOffersReceived';
import { getHistoryBasedSimilarOffers } from '../../../api/similar';
import { IThunkActionCreator } from '../../index';

export function requestHistoryBasedSimilarOffers(): IThunkActionCreator<Promise<void>> {
  return (dispatch, getState, context) => {
    // обработки ошибок нет, и это нормально — деградируем без показа блока
    return getHistoryBasedSimilarOffers(context).then(res => {
      if (res.statusCode === 200) {
        dispatch(similarOffersReceived(res.response, 'historyBased'));
      }
    });
  };
}
