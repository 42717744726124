import { DeleteFromComparisonActionType } from './actions';
import { SetComparisonNotificationType } from './comparisonNotification';
import { IDeleteOfferFromComparisonRequest } from '../../repositories/offers-comparison/v1/delete-offer-from-comparison';
import { deleteOfferFromComparisonRequest } from '../../services/offersComparison';
import { EComparisonNotificationCategory } from '../../types/comparisonNotification';

import type { IThunkActionCreator } from '../../store';

export const deleteOfferFromComparison =
  (parameters: IDeleteOfferFromComparisonRequest): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState, context) => {
    const { httpApi } = context;
    const { offerId } = parameters;
    const response = await deleteOfferFromComparisonRequest({ httpApi, parameters: { offerId } });

    if (response) {
      const { text, comparisonUrl, offerId } = response;
      dispatch({
        type: DeleteFromComparisonActionType,
        offerId,
      });
      dispatch({
        type: SetComparisonNotificationType,
        text,
        url: comparisonUrl,
        category: EComparisonNotificationCategory.Remove,
        offerId,
      });
    }
  };
