import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { isNil } from 'ramda';

import { IPriceSelectorOptions } from './types';
import { IState } from '../../store';
import { FDealType, getDealType } from '../../utils/deal_type';
import { CurrencySymbol, getPriceRentTimeSuffix, withNoBreakSpaces } from '../../utils/formatters';
import { selectIsOfferWithMultiAds } from '../offerData';

export function commercialPriceSelector(state: IState, options: IPriceSelectorOptions = {}): string | null {
  const {
    offerData: { offer },
  } = state;

  const { useSharePrefix, currencySign: currencySignOption } = options;
  const isCommercialMultiAds = selectIsOfferWithMultiAds(state);
  // Для случаев, когда у мультиобъявления все слоты имеют одинаковые цены
  const arePriceEqualForCommercialMultiAds = (from: string, to: string) => isCommercialMultiAds && from === to;

  const dealType = getDealType(offer);
  const postfix = getPriceRentTimeSuffix(offer);
  const sharePrefix = useSharePrefix ? 'за ' : '';
  const currencySign = currencySignOption || CurrencySymbol.Rur;

  if (dealType & FDealType.Rent) {
    if (!isNil(offer.priceTotalPerMonthRur)) {
      if (!isNil(offer.minPriceTotalPerMonthRur)) {
        const from = withNoBreakSpaces(numberToPrettyString(Math.round(offer.minPriceTotalPerMonthRur)));
        const to = withNoBreakSpaces(numberToPrettyString(Math.round(offer.priceTotalPerMonthRur)));

        if (arePriceEqualForCommercialMultiAds(from, to)) {
          return `от ${from} ${currencySign}${postfix}`;
        }

        return `от ${from} до ${to} ${currencySign}${postfix}`;
      }
      const price = withNoBreakSpaces(numberToPrettyString(Math.round(offer.priceTotalPerMonthRur)));

      return `${sharePrefix}${price} ${currencySign}${postfix}`;
    }
  }

  if (!isNil(offer.priceTotalRur)) {
    if (!isNil(offer.minPriceTotalRur)) {
      const from = withNoBreakSpaces(numberToPrettyString(Math.round(offer.minPriceTotalRur)));
      const to = withNoBreakSpaces(numberToPrettyString(Math.round(offer.priceTotalRur)));

      if (arePriceEqualForCommercialMultiAds(from, to)) {
        return `от ${from} ${currencySign}`;
      }

      return `от ${from} до ${to} ${currencySign}`;
    }
    const price = withNoBreakSpaces(numberToPrettyString(Math.round(offer.priceTotalRur)));

    return `${sharePrefix}${price} ${currencySign}`;
  }

  return null;
}
