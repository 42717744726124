import { IState } from '../../store';
import { ICoworkingAmenitiesGroup } from '../../types/coworkingAmenities';

export const selectCoworkingIncludedAmenities = (state: IState): ICoworkingAmenitiesGroup[] => {
  const amenitiesGroups = state.offerData.coworkingIncludedAmenities || [];

  return amenitiesGroups.reduce((acc, currentItem) => {
    const filteredAmenities = currentItem.amenities.filter(({ isAvailable }) => isAvailable);

    if (filteredAmenities.length) {
      return [
        ...acc,
        {
          ...currentItem,
          amenities: filteredAmenities,
        },
      ];
    }

    return acc;
  }, []);
};
