import { ECategory, EOfferType } from 'shared/repositories/offer-card/entities/offer/OfferSchema';

import { IState } from '../../../store';

export const isShowLayoutNotApprovedDisclaimer = (state: IState) => {
  const { offer } = state.offerData;

  const isShare = offer.category === ECategory.FlatShareSale;
  const isResidentialFund = offer.offerType === EOfferType.Flat;

  // т.к. поле новое, во всех старых объявлениях у него будет null
  // в контексте фичи null === true, поэтому если пришел false, сохраняем false
  // во всех остальных случаях ставим true
  const isLayoutApproved = offer.isLayoutApproved === false ? offer.isLayoutApproved : true;

  return isShare && isResidentialFund && !isLayoutApproved;
};
