// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IOfferHistoryRoomCountItemSchema {
  /** Количество предложений для заданной комнатности **/
  offersCount: number;
  /** Количество комнат квартиры **/
  roomsCount: ERoomsCount;
}
export enum ERoomsCount {
  /** Пять комнат **/
  'Five' = 'five',
  /** Четыре комнаты **/
  'Four' = 'four',
  /** Одна комната **/
  'One' = 'one',
  /** Отдельная комната(покупка/аренда отдельной комнаты в квартире) **/
  'OnlyRoom' = 'onlyRoom',
  /** Шесть и более комнат **/
  'SixPlus' = 'sixPlus',
  /** Студия **/
  'Studio' = 'studio',
  /** Три комнаты **/
  'Three' = 'three',
  /** Две комнаты **/
  'Two' = 'two',
}
