import { ISuburbanProjectSuggest } from 'shared/types/suburbanBuildersProjects';

export enum ESuburbanBuildersProjectsActionTypes {
  SET_SUBURBAN_BUILDERS_PROJECTS_ACTION = 'suburbanBuildersProjects/SET_VALUE',
}

export interface ISetSuburbanBuildersProjects {
  type: ESuburbanBuildersProjectsActionTypes.SET_SUBURBAN_BUILDERS_PROJECTS_ACTION;
  payload: ISuburbanProjectSuggest[];
}
