import { IThunkActionCreator } from './index';
import { resolveAbUseExperiments } from '../services/abUseExperiments';
import { IAbUseExperiment } from '../types/abUseExperiments';

export interface IAbUseExperimentsFetched {
  type: 'IAbUseExperimentsFetched';
  payload: IAbUseExperiment[];
}

export function getAbUseExperiments(): IThunkActionCreator {
  return async (dispatch, getState, context) => {
    const experiments = await resolveAbUseExperiments(context, []);

    dispatch<IAbUseExperimentsFetched>({
      type: 'IAbUseExperimentsFetched',
      payload: experiments,
    });
  };
}

export function abUseExperimentsReducer(
  state: IAbUseExperiment[],
  action: IAbUseExperimentsFetched,
): IAbUseExperiment[] {
  switch (action.type) {
    case 'IAbUseExperimentsFetched':
      return [...action.payload];

    default:
      return state || [];
  }
}
