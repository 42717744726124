import { mergeBaseUri } from '@cian/http-api/shared';

import { filterServerResponse } from './helpers';
import { ISimilarOffersData } from '../store/map_similar';
import { IMakeRequest } from '../utils/make_request';

export function fetchSimilarObjects(
  makeRequest: IMakeRequest,
  apiBaseUrl: string,
  bounds: [[number, number], [number, number]],
  similarQS: string,
  subdomain: string,
) {
  return makeRequest({
    method: 'GET',
    uri: mergeBaseUri(apiBaseUrl.replace('www', subdomain), {
      path: '/ajax/map/roundabout/',

      query: `${similarQS}&in_polygon[0]=${getFormattedBoundsQuery(bounds)}`,
    }),
  })
    .then(filterServerResponse)
    .then(response => {
      let data: ISimilarOffersData;

      try {
        data = JSON.parse(response.responseBody);
      } catch (error) {
        throw new Error();
      }

      return data;
    });
}

export function getFormattedBoundsQuery(bounds: [[number, number], [number, number]]) {
  return `${bounds[0][1].toFixed(6)}_${bounds[0][0].toFixed(6)},${bounds[1][1].toFixed(6)}_${bounds[0][0].toFixed(
    6,
  )},${bounds[1][1].toFixed(6)}_${bounds[1][0].toFixed(6)},${bounds[0][1].toFixed(6)}_${bounds[1][0].toFixed(
    6,
  )},${bounds[0][1].toFixed(6)}_${bounds[0][0].toFixed(6)}`;
}
