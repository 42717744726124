import { ECategory } from '../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';

const SET_INFRASTRUCTURE_CATEGORIES_ACTION = 'SET_INFRASTRUCTURE_CATEGORIES';

export interface IInfrastructureCategoriesAction {
  type: typeof SET_INFRASTRUCTURE_CATEGORIES_ACTION;
  payload: ECategory[];
}

const initialState: ECategory[] = [];

export const setInfrastructureCategories = (categories: ECategory[]): IInfrastructureCategoriesAction => {
  return {
    payload: categories,
    type: SET_INFRASTRUCTURE_CATEGORIES_ACTION,
  };
};

export const infrastructureCategoriesReducer = (
  state: ECategory[] = initialState,
  action: IInfrastructureCategoriesAction,
) => {
  switch (action.type) {
    case SET_INFRASTRUCTURE_CATEGORIES_ACTION:
      return action.payload;

    default:
      return state;
  }
};
