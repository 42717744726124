import { TDealType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { requestOffersHistoryListingSpoilerActions, setCategoriesFilter } from './actions';
import { fetchOffersHistorySpoiler } from '../../services/offersHistory/fetchOffersHistorySpoiler';
import { IThunkActionCreator } from '../../store';
import { ESpoilerCategoryRequest, EDealType, mapOfferCategoriesEnums } from '../../store/offerHistory';

const { success, request, failure } = requestOffersHistoryListingSpoilerActions;

const mapDealTypes: Record<TDealType, EDealType> = {
  sale: EDealType.Sale,
  rent: EDealType.Rent,
};

export const getOffersHistorySpoiler =
  (category?: ESpoilerCategoryRequest): IThunkActionCreator<Promise<void>> =>
  async (dispatch, getState, context) => {
    const {
      offerData: {
        offer: { cianId, dealType },
      },
    } = getState();

    dispatch(request());

    try {
      const res = await fetchOffersHistorySpoiler(context, {
        offerId: cianId,
        dealType: mapDealTypes[dealType],
        category,
      });

      dispatch(success(res));

      if (res.filters?.activeCategory) {
        const activeCategory = mapOfferCategoriesEnums[res.filters.activeCategory];

        dispatch(setCategoriesFilter(activeCategory));
      }
    } catch (e) {
      dispatch(failure());
    }
  };
