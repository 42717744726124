import { offerSelector, selectUser, abGroupSelector } from '../../selectors';
import { selectAgent } from '../../selectors/selectAgent';
import { IThunkActionCreator } from '../../store';
import { getPage } from '../../utils/tracking/getPage';
import { getUser } from '../../utils/tracking/getUser';
import {
  TBuyAnalyticsReportLabel,
  trackingBuyAnalyticsReportEvent,
} from '../../utils/tracking/trackingBuyAnalyticsReportEvent';

export const sendSoprAnalytics = (label: TBuyAnalyticsReportLabel): IThunkActionCreator => {
  return (_, getState): void => {
    const state = getState();

    const user = selectUser(state);
    const abGroup = abGroupSelector(state);
    const agent = selectAgent(state);
    const offer = offerSelector(state);

    trackingBuyAnalyticsReportEvent({
      label,
      page: getPage(agent, offer, null),
      user: getUser(user, abGroup),
    });
  };
};
