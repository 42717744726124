import { selectOfferAgent } from './selectOfferAgent';
import { IState } from '../../store';
import { isAgency as getIsAgency } from '../../utils/agent';

export const selectIsAgencyBrandingAvailable = (state: IState): boolean => {
  const agent = selectOfferAgent(state);

  if (!agent || agent.isDeveloper) {
    return false;
  }

  const isAgency = getIsAgency(agent);

  return Boolean(isAgency || agent.isSubAgent);
};
