import { IState } from '../../../store';
import { getOfferType, FOfferType } from '../../../utils/offer_type';
import { getIsFromBuilder, getIsFromSeller } from '../../newObject';

export function isNewbuildingFromDeveloperSelector(state: IState) {
  const isFromBuilder = getIsFromBuilder(state);
  const isFromSeller = getIsFromSeller(state);
  const { offer } = state.offerData;
  const offerType = getOfferType(offer);

  return (isFromBuilder || isFromSeller) && offerType === FOfferType.FlatNew;
}
