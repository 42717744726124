/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import { TGetNewbuildingCallbackWidgetModel, TGetNewbuildingCallbackWidgetResponse } from './types';

const defaultConfig = {
  apiType: 'public',
  assertStatusCodes: [200],
  method: 'GET',
  microserviceName: 'newbuilding-callback-widget-frontend',
  pathApi: '/v1/get-widget/',
  hostType: 'api',
  responseType: 'json',
} as TGetNewbuildingCallbackWidgetModel;

function createGetNewbuildingCallbackWidgetModel() {
  return {
    ...defaultConfig,
  };
}

export interface IGetNewbuildingCallbackWidgetOptions {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
}

async function fetchGetNewbuildingCallbackWidget({
  httpApi,
  config,
}: IGetNewbuildingCallbackWidgetOptions): Promise<TGetNewbuildingCallbackWidgetResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetNewbuildingCallbackWidgetModel(), config);

  return { response, statusCode } as TGetNewbuildingCallbackWidgetResponse;
}

export { defaultConfig, createGetNewbuildingCallbackWidgetModel, fetchGetNewbuildingCallbackWidget };
