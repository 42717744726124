import { format } from 'date-fns';

import { IAgentAvailabilitySchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export const getAgentAvailabilityTitle = (agentAvailability: IAgentAvailabilitySchema | null): string | null => {
  if (!agentAvailability) {
    return null;
  }

  // Если отпуск - выводим title без даты
  if (agentAvailability.title && agentAvailability.vacationTo) {
    return agentAvailability.title;
  }

  // Если отпуска нет - выводим title с датой с учётом таймзоны пользователя, просматривающего страницу
  if (agentAvailability.title && agentAvailability.availableFrom && agentAvailability.availableTo) {
    const formattedFrom = format(new Date(agentAvailability.availableFrom), 'H:mm');
    const formattedTo = format(new Date(agentAvailability.availableTo), 'H:mm');

    return agentAvailability.title
      .replace(/с\s\d+:\d{2}/, `с ${formattedFrom}`)
      .replace(/по\s\d+:\d{2}/, `по ${formattedTo}`);
  }

  return agentAvailability.title;
};
