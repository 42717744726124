import { resolveSpecialPromoInfoList } from '../../../node/services/resolveSpecialPromoInfoList';
import { IThunkActionCreator } from '../index';

export interface ISpecialPromoInfo {
  builderId: number;
  spId: number;
  isPreview: boolean;
  logoSmallUrl: string;
  colorHex: string;
  pinUrl: string;
  textShowOffers: string;
  region: number;
  jkMapInfo: {
    zoom: number;
    lat: number;
    lng: number;
  };
}

export interface ISpecialPromoInfoList {
  infoList: ISpecialPromoInfo[];
}

export type TSpecialPromoInfoAction =
  | { type: 'ISpecialPromoInfoSuccess'; payload: ISpecialPromoInfo }
  | { type: 'ISpecialPromoInfoError' };

export type TSpecialPromoInfoState = ISpecialPromoInfo | null;

export function specialPromoInfoReducer(
  state: TSpecialPromoInfoState = null,
  action: TSpecialPromoInfoAction,
): TSpecialPromoInfoState {
  switch (action.type) {
    case 'ISpecialPromoInfoSuccess':
      return action.payload;
    case 'ISpecialPromoInfoError':
      return null;
    default:
      return state;
  }
}

const CACHING_KEY = 'specialPromoInfo';
// TODO: после обновления @cian/config вынести в runtime-settings
const CACHING_TIME = 1000 * 60 * 30; // 30 минут

interface ICacheItem {
  expiredDate: number;
  specialPromoInfo: ISpecialPromoInfoList;
}

const cache = new Map<string, ICacheItem>();

const isValidCache = (item?: ICacheItem): boolean => {
  return !!item && item.expiredDate > Date.now();
};

function updateCacheItem(key: string, specialPromoInfo: ISpecialPromoInfoList) {
  const cacheItem = cache.get(key);

  if (!isValidCache(cacheItem)) {
    cache.set(key, {
      expiredDate: Date.now() + CACHING_TIME,
      specialPromoInfo,
    });
  }
}

/**
 * @todo Перенести весь код на node.
 */
export function setSpecialPromoInfo(canPreview: boolean, withCache?: boolean): IThunkActionCreator {
  return (dispatch, getState, { httpApi }) => {
    const {
      offerData: { offer },
    } = getState();
    const regionId = offer.geo.address && offer.geo.address[0] && offer.geo.address[0].id;

    return Promise.resolve()
      .then(() => {
        if (withCache) {
          const cacheItem = cache.get(CACHING_KEY);

          if (cacheItem && isValidCache(cacheItem)) {
            return cacheItem.specialPromoInfo;
          }
        }

        /**
         * Импорт из node. Код используется только на node.
         */
        return resolveSpecialPromoInfoList(httpApi).then((specialPromoInfo: ISpecialPromoInfoList) => {
          if (withCache) {
            updateCacheItem(CACHING_KEY, specialPromoInfo);
          }

          return specialPromoInfo;
        });
      })
      .then((specialPromoInfo: ISpecialPromoInfoList) => {
        const specialPromo = specialPromoInfo.infoList.find(sp => sp.region === regionId);
        if (specialPromo && (!specialPromo.isPreview || canPreview)) {
          dispatch({
            payload: specialPromo,
            type: 'ISpecialPromoInfoSuccess',
          });
        }
      })
      .catch(() => {
        dispatch({
          type: 'ISpecialPromoInfoError',
        });
      });
  };
}
