import { isOfferInGageSelector } from './isOfferInGage';
import { offerSelector } from './offer';
import { IState } from '../../store';
import { getDealType, isSale } from '../../utils/deal_type';
import { getOfferType, isResidential } from '../../utils/offer_type';

export const selectIsMortgageBlockAvailable = (state: IState): boolean => {
  const offer = offerSelector(state);
  const isOfferInGage = isOfferInGageSelector(state);
  const dealType = getDealType(offer);
  const offerType = getOfferType(offer);

  return !(isOfferInGage || !isSale(dealType) || !isResidential(offerType));
};
