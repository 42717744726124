import { init as initGlobalBehaviors } from '@cian/global-behaviors/browser';

import { setupDependencies, initDependencies } from './app';

initGlobalBehaviors();

setupDependencies();

initDependencies();

const { runApp } = require('./services/runApp');
runApp();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (module as any).hot.accept();
}
