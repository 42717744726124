import { setCategoriesFilter, setRoomsFilter, setStatusFilter } from './actions';
import { selectDefaultFilters } from '../../selectors/offersHistory';
import { IThunkActionCreator } from '../../store';
import { FILTER_ALL_VALUE } from '../../store/offerHistory';

export const setDefaultRooms = (): IThunkActionCreator => (dispatch, getState) => {
  const { roomCounts } = selectDefaultFilters(getState());

  dispatch(setRoomsFilter(roomCounts));
};

export const setDefaultStatus = (): IThunkActionCreator => (dispatch, getState) => {
  const { statusCounts } = selectDefaultFilters(getState());

  dispatch(setStatusFilter(statusCounts));
};

export const setDefaultCategory = (): IThunkActionCreator => dispatch => {
  dispatch(setCategoriesFilter(FILTER_ALL_VALUE));
};
