/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TDeleteOfferFromComparisonModel,
  IDeleteOfferFromComparisonRequest,
  IMappers,
  IDeleteOfferFromComparisonResponse,
  IDeleteOfferFromComparisonResponseError,
  TDeleteOfferFromComparisonResponse,
} from './types';

const defaultConfig: TDeleteOfferFromComparisonModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'offers-comparison',
  pathApi: '/v1/delete-offer-from-comparison/',
  hostType: 'api',
} as TDeleteOfferFromComparisonModel;

function createDeleteOfferFromComparisonModel(
  parameters: IDeleteOfferFromComparisonRequest,
): TDeleteOfferFromComparisonModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IDeleteOfferFromComparisonOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IDeleteOfferFromComparisonRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchDeleteOfferFromComparison<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IDeleteOfferFromComparisonOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TDeleteOfferFromComparisonResponse
> {
  const { response, statusCode } = await httpApi.fetch(createDeleteOfferFromComparisonModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IDeleteOfferFromComparisonResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IDeleteOfferFromComparisonResponseError);
    }
  }

  return { response, statusCode } as TDeleteOfferFromComparisonResponse;
}

export { defaultConfig, createDeleteOfferFromComparisonModel, fetchDeleteOfferFromComparison };
