import { IHouseOfferHistoryV4Schema } from 'shared/repositories/valuation-offer-history/entities/offer_history/HouseOfferHistoryV4Schema';
import { EDealType, EOfferStatusData } from 'shared/store/offerHistory';

export const getOfferDeactivatedLabel = (offer: IHouseOfferHistoryV4Schema, dealType: EDealType): string | null => {
  if (offer.status === EOfferStatusData.Deactivated) {
    return dealType === EDealType.Sale ? 'Снято с продажи' : 'Снято с публикации';
  }

  return null;
};
