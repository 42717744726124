import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { AVAILABLE_STATUS_LIST, TITLE_MAPPING } from './constants';
import { ErrorContent } from '../../components/ErrorContent';
import { LoadingContent } from '../../components/LoadingContent';
import { useContext } from '../../utils/useContext';

export const FallbackModalContainer: React.FC = () => {
  const context = useContext();

  const content = React.useMemo(() => {
    switch (context.status) {
      case 'loading': {
        return <LoadingContent />;
      }
      case 'failed': {
        const { onOpen } = context;

        return <ErrorContent onRetry={onOpen} />;
      }
      default:
        return null;
    }
  }, [context]);

  if (!AVAILABLE_STATUS_LIST.includes(context.status)) {
    return null;
  }

  return (
    <ModalWindow
      open={context.open}
      onClose={context.onClose}
      title={TITLE_MAPPING[context.status]}
      width="100%"
      maxWidth={540}
      content={content}
      escape={false}
      size="M"
      fixed
    />
  );
};
