import { createStore, IState } from '../../../shared/store';
import { IApplicationContext } from '../../../shared/types/applicationContext';

export function createAppState(context: IApplicationContext) {
  const defaultState = context.config.getStrict<IState>('defaultState');

  const store = createStore(context, defaultState as IState, true);

  return store;
}
