import { IThunkActionCreator, IState } from '.';
import { setOfferHidden } from './offer';
import { postHideOffer, postRestoreOffer } from '../api/hidden_objects';
import { EDealType, EOfferType } from '../repositories/hidden-objects/entities/schemas/HideOfferRequestSchema';
import { IHideOfferWebsiteRequest } from '../repositories/hidden-objects/v1/hide-offer-website';
import {
  trackHiddenObjects,
  EHideOfferAnalyticsCategory,
  ERestoreEventSource,
  selectProductsParams,
} from '../tracking/hide_offer';

export enum EHideOfferState {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface ISetHideOfferInfo {
  status: EHideOfferState;
  errorMessage?: string;
}

export interface IHideOffer {
  status: EHideOfferState;
  errorMessage?: string;
  isComplainOpened: boolean;
  isTopPopupOpened: boolean;
}

export interface IHideOfferComplainToggleAction {
  isComplainOpened: boolean;
  type: 'IHideOfferComplainToggleAction';
}

export interface IHideOfferTopPopupToggleAction {
  isTopPopupOpened: boolean;
  type: 'IHideOfferTopPopupToggleAction';
}

export interface ISetHideOfferState {
  type: 'ISetHideOfferState';
  status: EHideOfferState;
  errorMessage?: string;
}

export const errorMessages = {
  errorWhileHide: 'Ошибка при попытке скрыть объявление',
  errorWhileRestore: 'Ошибка при попытке восстановить объявление',
  limitExceeded: 'Вы можете скрыть не более 1000 объявлений',
};
const LIMIT_EXCEEDED_ERROR_CODE = 'limitExceeded';

export function setHideOfferState(hideOfferInfo: ISetHideOfferInfo): ISetHideOfferState {
  return {
    type: 'ISetHideOfferState',
    ...hideOfferInfo,
  };
}

export function toggleComplainPopup(isComplainOpened: boolean): IHideOfferComplainToggleAction {
  return {
    isComplainOpened,
    type: 'IHideOfferComplainToggleAction',
  };
}

export function toggleTopPopup(isTopPopupOpened: boolean): IHideOfferTopPopupToggleAction {
  return {
    isTopPopupOpened,
    type: 'IHideOfferTopPopupToggleAction',
  };
}

function selectRequestParameters(state: IState): IHideOfferWebsiteRequest {
  const { dealType, id, offerType, publishedUserId, geo } = state.offerData.offer;
  let houseId;
  /* istanbul ignore next */
  if (geo) {
    const house = geo.address.find(a => a.type === 'house');
    houseId = house && house.id;
  }

  return {
    offerId: id,
    dealType: dealType as EDealType,
    offerType: offerType as EOfferType,
    publishedUserId: publishedUserId || 0,
    houseId,
  };
}

export function hideOffer(): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const state = getState();
    const parameters = selectRequestParameters(state);
    const isAuthenticated = state.offerData.user && state.offerData.user.isAuthenticated;
    const { httpApi } = context;
    /* istanbul ignore next */
    if (!isAuthenticated && window.waitForLoginPopup) {
      const callback = async () => {
        try {
          const result = await postHideOffer(httpApi, parameters);

          if (result.statusCode === 200) {
            trackHiddenObjects({
              action: 'Hide',
              category: EHideOfferAnalyticsCategory.HIDE,
              productParams: selectProductsParams(state),
            });

            window.location.hash = 'HIDE_SUCCESS';
          } else {
            window.location.hash = 'HIDE_ERROR';
          }
        } catch (e) {
          console.error(e);

          window.location.hash = 'HIDE_ERROR';
        }

        window.location.reload();
      };

      window.waitForLoginPopup('hide', callback);

      return;
    }

    dispatch(setHideOfferState({ status: EHideOfferState.FETCHING }));

    return postHideOffer(httpApi, parameters)
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(setHideOfferState({ status: EHideOfferState.SUCCESS }));
          dispatch(setOfferHidden(true));
          trackHiddenObjects({
            action: 'Hide',
            category: EHideOfferAnalyticsCategory.HIDE,
            productParams: selectProductsParams(state),
          });
        } else if (result.statusCode === 400) {
          const { errors } = result.response;
          const isLimitExceededError =
            errors && errors.length > 0 && (errors[0] as { [key: string]: string }).code === LIMIT_EXCEEDED_ERROR_CODE;
          const payload = isLimitExceededError
            ? { status: EHideOfferState.ERROR, errorMessage: errorMessages.limitExceeded }
            : { status: EHideOfferState.ERROR, errorMessage: errorMessages.errorWhileHide };

          dispatch(setHideOfferState(payload));
        }
      })
      .catch((error: Error) => {
        context.logger.error(error, { details: 'Failed to hide offer' });
        dispatch(
          setHideOfferState({
            status: EHideOfferState.ERROR,
            errorMessage: errorMessages.errorWhileHide,
          }),
        );
      });
  };
}

export function restoreOffer(eventSource?: ERestoreEventSource): IThunkActionCreator {
  return (dispatch, getState, context) => {
    const offerId = getState().offerData.offer.id;

    dispatch(setHideOfferState({ status: EHideOfferState.FETCHING }));

    return postRestoreOffer(context.httpApi, { offerId })
      .then(result => {
        if (result.statusCode === 200) {
          dispatch(setHideOfferState({ status: EHideOfferState.SUCCESS }));
          dispatch(setOfferHidden(false));
          trackHiddenObjects({
            action: eventSource as string,
            category: EHideOfferAnalyticsCategory.RESTORE,
            productParams: selectProductsParams(getState()),
          });
        } else {
          dispatch(
            setHideOfferState({
              status: EHideOfferState.ERROR,
              errorMessage: errorMessages.errorWhileRestore,
            }),
          );
        }
      })
      .catch((error: Error) => {
        context.logger.error(error, { deatails: 'Failed to restore offer' });
        dispatch(
          setHideOfferState({
            status: EHideOfferState.ERROR,
            errorMessage: errorMessages.errorWhileRestore,
          }),
        );
      });
  };
}

const defaultState: IHideOffer = {
  status: EHideOfferState.INIT,
  isComplainOpened: false,
  isTopPopupOpened: false,
};

export function hideOfferReducer(
  state: IHideOffer,
  action: IHideOfferComplainToggleAction | IHideOfferTopPopupToggleAction | ISetHideOfferState,
): IHideOffer {
  const { type, ...payload } = action;

  switch (type) {
    case 'IHideOfferComplainToggleAction':
      return {
        ...state,
        ...payload,
      };

    case 'IHideOfferTopPopupToggleAction':
      return {
        ...state,
        ...payload,
      };

    case 'ISetHideOfferState': {
      let isTopPopupOpened =
        (payload as ISetHideOfferState).status === EHideOfferState.SUCCESS ||
        (payload as ISetHideOfferState).status === EHideOfferState.ERROR ||
        state.isTopPopupOpened;

      if ((payload as ISetHideOfferState).status === EHideOfferState.FETCHING) {
        isTopPopupOpened = false;
      }

      return {
        ...state,
        ...payload,
        isTopPopupOpened,
      };
    }

    default:
      return state || defaultState;
  }
}
