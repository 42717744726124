import { ETrackingReducerActions, ITrackingReducerAction } from './types';
import { ITrackingPageData, IUserTrackingData } from '../../types';

export function setTrackingData(user: IUserTrackingData, page: ITrackingPageData): ITrackingReducerAction {
  return {
    type: ETrackingReducerActions.SetTrackingState,
    payload: {
      user,
      page,
    },
  };
}
