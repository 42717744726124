import { IState } from '../store';
import { IAnalyticsData } from '../types';

export function getAnalyticsData(state: IState): IAnalyticsData {
  const {
    offerData: { offer, agent, user, kp },
  } = state;

  return {
    offer,
    agent,
    user,
    village: kp,
  };
}
