/* eslint-disable max-lines */
import { pick } from 'ramda';

import {
  EGetOfferFromHistory,
  EGetOffersHistoryListing,
  EGetOffersHistoryListingSpoiler,
  EOffersHistory,
  TOffersHistoryActions,
} from '../../actions/offersHistory';
import { FILTER_ALL_VALUE, IOffersHistoryData, IOffersHistoryState } from '../../store/offerHistory';

export const initialState: IOffersHistoryState = {
  data: {
    listing: {
      isFetching: false,
      isError: false,
      data: null,
      isLoadMore: false,
    },
    listingSpoiler: {
      isFetching: false,
      isError: false,
      data: null,
      isLoadMore: false,
    },
    offers: {
      isFetching: false,
      isError: false,
      isAuthError: false,
      isLoadMore: false,
      data: {},
    },
  },
  roomsFilterActiveValue: FILTER_ALL_VALUE,
  statusFilterActiveValue: FILTER_ALL_VALUE,
  categoriesFilterActiveValue: undefined,
  typeFilterActiveValue: undefined,
  orderByValue: undefined,
  page: 1,
  resultsOnPage: 10,
  activeOfferId: null,
  isFiltersChanged: false,
  viewedOffers: [],
  defaultFilters: {},
  haveAdminAccess: false,
};

export const offersHistoryReducer = (
  state: IOffersHistoryState = initialState,
  action: TOffersHistoryActions,
): IOffersHistoryState => {
  switch (action.type) {
    case EGetOffersHistoryListing.request:
    case EGetOffersHistoryListing.success:
    case EGetOffersHistoryListing.successLoadMore:
    case EGetOffersHistoryListing.failure:
      return {
        ...state,
        data: {
          ...state.data,
          listing: reduceOffersListingData(state.data.listing, action),
        },
      };

    case EGetOfferFromHistory.reset:
    case EGetOfferFromHistory.request:
    case EGetOfferFromHistory.success:
    case EGetOfferFromHistory.failure:
    case EGetOfferFromHistory.authFailure:
      return {
        ...state,
        data: {
          ...state.data,
          offers: reduceOffersFromListingData(state.data.offers, action),
        },
      };

    case EGetOffersHistoryListingSpoiler.request:
    case EGetOffersHistoryListingSpoiler.success:
    case EGetOffersHistoryListingSpoiler.failure:
      return {
        ...state,
        data: {
          ...state.data,
          listingSpoiler: reduceOffersListingSpoilerData(state.data.listingSpoiler, action),
        },
      };

    case EOffersHistory.setPage:
      return {
        ...state,
        page: action.payload,
      };

    case EOffersHistory.setRoomsFilter:
      return {
        ...state,
        roomsFilterActiveValue: action.payload,
        isFiltersChanged: true,
      };

    case EOffersHistory.setStatusFilter:
      return {
        ...state,
        statusFilterActiveValue: action.payload,
        isFiltersChanged: true,
      };

    case EOffersHistory.setTypeFilter:
      return {
        ...state,
        typeFilterActiveValue: action.payload,
        isFiltersChanged: true,
      };

    case EOffersHistory.setCategoriesFilter:
      return {
        ...state,
        categoriesFilterActiveValue: action.payload,
        isFiltersChanged: true,
      };

    case EOffersHistory.setActiveOfferId:
      return {
        ...state,
        activeOfferId: action.payload,
      };

    case EOffersHistory.setIsFiltersChanged:
      return {
        ...state,
        isFiltersChanged: action.payload,
      };

    case EOffersHistory.setViewedOffer:
      return {
        ...state,
        viewedOffers: state.viewedOffers.includes(action.payload)
          ? state.viewedOffers
          : state.viewedOffers.concat(action.payload),
      };

    case EOffersHistory.resetFilters:
      return {
        ...state,
        ...pick(
          [
            'roomsFilterActiveValue',
            'statusFilterActiveValue',
            'typeFilterActiveValue',
            'orderByValue',
            'page',
            'isFiltersChanged',
          ],
          initialState,
        ),
      };

    case EOffersHistory.resetListingData:
      return {
        ...state,
        data: {
          ...state.data,
          listing: initialState.data.listing,
        },
      };

    case EOffersHistory.saveDefaultFilters:
      return {
        ...state,
        defaultFilters: {
          ...state.defaultFilters,
          ...action.payload,
        },
      };

    case EOffersHistory.setSortOffers:
      return {
        ...state,
        orderByValue: action.payload,
        isFiltersChanged: true,
      };

    case EOffersHistory.setAdminAccess:
      return {
        ...state,
        haveAdminAccess: action.payload,
      };

    default:
      return state;
  }
};

/** Редюсер ветки данных для конкретных офферов из листинга */
function reduceOffersFromListingData(
  state: IOffersHistoryData['offers'],
  action: TOffersHistoryActions,
): IOffersHistoryData['offers'] {
  switch (action.type) {
    case EGetOfferFromHistory.request:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case EGetOfferFromHistory.success:
      return {
        ...state,
        isFetching: false,
        isError: false,
        isAuthError: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload.data,
        },
      };

    case EGetOfferFromHistory.failure:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case EGetOfferFromHistory.authFailure:
      return {
        ...state,
        isFetching: false,
        isAuthError: true,
      };

    case EGetOfferFromHistory.reset:
      return {
        ...state,
        isFetching: false,
        isError: false,
        isAuthError: false,
      };

    /* istanbul ignore next */
    default:
      return state;
  }
}

/** Редюсер ветки данных листинга */
function reduceOffersListingData(
  state: IOffersHistoryData['listing'],
  action: TOffersHistoryActions,
): IOffersHistoryData['listing'] {
  switch (action.type) {
    case EGetOffersHistoryListing.request:
      return {
        ...state,
        isFetching: true,
        isError: false,
        isLoadMore: action.payload,
      };

    case EGetOffersHistoryListing.success:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: action.payload,
        isLoadMore: false,
      };

    case EGetOffersHistoryListing.successLoadMore: {
      const prevOffers = state.data?.offers || [];

      return {
        ...state,
        isFetching: false,
        data: {
          ...action.payload,
          offers: prevOffers.concat(action.payload.offers),
        },
      };
    }

    case EGetOffersHistoryListing.failure:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    /* istanbul ignore next */
    default:
      return state;
  }
}

/** Редюсер ветки данных листинга спойлера */
function reduceOffersListingSpoilerData(
  state: IOffersHistoryData['listingSpoiler'],
  action: TOffersHistoryActions,
): IOffersHistoryData['listingSpoiler'] {
  switch (action.type) {
    case EGetOffersHistoryListingSpoiler.request:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case EGetOffersHistoryListingSpoiler.success:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: action.payload,
      };

    case EGetOffersHistoryListingSpoiler.failure:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    /* istanbul ignore next */
    default:
      return state;
  }
}
