import { UnknownError } from '@cian/peperrors/shared';

import { TDeleteOfferFromComparisonResponse } from '../../../../repositories/offers-comparison/v1/delete-offer-from-comparison';

export const assertUnknownError = (response: TDeleteOfferFromComparisonResponse): void => {
  if (response.statusCode !== 400) {
    return;
  }

  const { message, errors } = response.response;

  throw new UnknownError({
    domain: 'src/shared/services/offersComparison/deleteOfferFromComparison.ts',
    message,
    details: {
      errors,
    },
  });
};
