export type TPushSubscribed = boolean;

export interface IPushSubscribedSetValueAction {
  type: 'IPushSubscribedSetValueAction';
  value: boolean;
}

const initialState = false;

export function pushSubscribedReducer(
  state: TPushSubscribed = initialState,
  action: IPushSubscribedSetValueAction,
): TPushSubscribed {
  switch (action.type) {
    case 'IPushSubscribedSetValueAction':
      return action.value;

    default:
      return state;
  }
}
