// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IHouseOfferHistoryRequestV4Schema {
  /** Категория объявления **/
  category?: ECategory;
  /** Тип сделки **/
  dealType?: EDealType;
  /** Идентификатор дома realty **/
  houseId?: number;
  /** Отдать объявления в соседних домах **/
  isNearby?: boolean;
  /** Идентификатор объявления **/
  offerId?: number;
  /** Статус объявлений **/
  offerStatus?: EOfferStatus;
  /** Порядок сортировки (будет использоваться только КС) **/
  orderBy?: EOrderBy;
  /** Номер страницы результатов **/
  page?: number;
  /** Кол-во результатов в ответе **/
  resultsOnPage?: number;
  /** Количество комнат **/
  roomsCount?: ERoomsCount;
}
export enum ECategory {
  /** Аренда койко-места **/
  'BedRent' = 'bedRent',
  /** Аренда здания **/
  'BuildingRent' = 'buildingRent',
  /** Продажа здания **/
  'BuildingSale' = 'buildingSale',
  /** Аренда готового бизнеса **/
  'BusinessRent' = 'businessRent',
  /** Продажа готового бизнеса **/
  'BusinessSale' = 'businessSale',
  /** Аренда автосервис **/
  'CarServiceRent' = 'carServiceRent',
  /** Продажа автосервиса **/
  'CarServiceSale' = 'carServiceSale',
  /** Аренда коммерческой земли **/
  'CommercialLandRent' = 'commercialLandRent',
  /** Продажа коммерческой земли **/
  'CommercialLandSale' = 'commercialLandSale',
  /** Аренда коттеджа **/
  'CottageRent' = 'cottageRent',
  /** Продажа коттеджа **/
  'CottageSale' = 'cottageSale',
  /** Посуточная аренда койко-места **/
  'DailyBedRent' = 'dailyBedRent',
  /** Посуточная аренда квартиры **/
  'DailyFlatRent' = 'dailyFlatRent',
  /** Посуточная аренда дома, дачи, коттеджа **/
  'DailyHouseRent' = 'dailyHouseRent',
  /** Посуточная аренда комнаты **/
  'DailyRoomRent' = 'dailyRoomRent',
  /** Аренда помещения под бытовые услуги **/
  'DomesticServicesRent' = 'domesticServicesRent',
  /** Продажа помещения под бытовые услуги **/
  'DomesticServicesSale' = 'domesticServicesSale',
  /** Аренда квартиры **/
  'FlatRent' = 'flatRent',
  /** Продажа квартиры **/
  'FlatSale' = 'flatSale',
  /** Продажа доли в квартире **/
  'FlatShareSale' = 'flatShareSale',
  /** Аренда помещения свободного назначения **/
  'FreeAppointmentObjectRent' = 'freeAppointmentObjectRent',
  /** Продажа помещения свободного назначения **/
  'FreeAppointmentObjectSale' = 'freeAppointmentObjectSale',
  /** Аренда гаража **/
  'GarageRent' = 'garageRent',
  /** Продажа гаража **/
  'GarageSale' = 'garageSale',
  /** Аренда дома/дачи **/
  'HouseRent' = 'houseRent',
  /** Продажа дома/дачи **/
  'HouseSale' = 'houseSale',
  /** Аренда части дома **/
  'HouseShareRent' = 'houseShareRent',
  /** Продажа части дома **/
  'HouseShareSale' = 'houseShareSale',
  /** Аренда производства **/
  'IndustryRent' = 'industryRent',
  /** Продажа производство **/
  'IndustrySale' = 'industrySale',
  /** Продажа участка **/
  'LandSale' = 'landSale',
  /** Продажа квартиры в новостройке **/
  'NewBuildingFlatSale' = 'newBuildingFlatSale',
  /** Аренда офиса **/
  'OfficeRent' = 'officeRent',
  /** Продажа офиса **/
  'OfficeSale' = 'officeSale',
  /** Аренда общепита **/
  'PublicCateringRent' = 'publicCateringRent',
  /** Продажа общепита **/
  'PublicCateringSale' = 'publicCateringSale',
  /** Аренда комнаты **/
  'RoomRent' = 'roomRent',
  /** Продажа комнаты **/
  'RoomSale' = 'roomSale',
  /** Аренда торговой площади **/
  'ShoppingAreaRent' = 'shoppingAreaRent',
  /** Продажа торговой площади **/
  'ShoppingAreaSale' = 'shoppingAreaSale',
  /** Аренда таунхауса **/
  'TownhouseRent' = 'townhouseRent',
  /** Продажа таунхауса **/
  'TownhouseSale' = 'townhouseSale',
  /** Аренда склада **/
  'WarehouseRent' = 'warehouseRent',
  /** Продажа склада **/
  'WarehouseSale' = 'warehouseSale',
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EOfferStatus {
  /** Снято с публикации **/
  'Deactivated' = 'deactivated',
  /** Опубликовано **/
  'Published' = 'published',
}
export enum EOrderBy {
  /** Сортировка по площади по возрастанию **/
  'AreaAsc' = 'areaAsc',
  /** Сортировка по площади по убыванию **/
  'AreaDesc' = 'areaDesc',
  /** Сортировка по цене по возрастанию **/
  'PriceAsc' = 'priceAsc',
  /** Сортировка по цене по убыванию **/
  'PriceDesc' = 'priceDesc',
  /** Сортировка по дате публикации по возрастанию **/
  'PubDateAsc' = 'pubDateAsc',
  /** Сортировка по дате публикации по убыванию **/
  'PubDateDesc' = 'pubDateDesc',
}
export enum ERoomsCount {
  /** Пять комнат **/
  'Five' = 'five',
  /** Четыре комнаты **/
  'Four' = 'four',
  /** Одна комната **/
  'One' = 'one',
  /** Отдельная комната(покупка/аренда отдельной комнаты в квартире) **/
  'OnlyRoom' = 'onlyRoom',
  /** Шесть и более комнат **/
  'SixPlus' = 'sixPlus',
  /** Студия **/
  'Studio' = 'studio',
  /** Три комнаты **/
  'Three' = 'three',
  /** Две комнаты **/
  'Two' = 'two',
}
