import { GenericError } from '@cian/peperrors/shared';

import { offerFromHistoryMapper, TMappedResponse, TMappedResponse200 } from './mappers';
import {
  fetchGetOfferFromHistoryWeb,
  IGetOfferFromHistoryWebRequest,
} from '../../../repositories/valuation-offer-history/v2/get-offer-from-history-web';
import { IApplicationContext } from '../../../types/applicationContext';

export const fetchOfferFromHistory = async (
  context: IApplicationContext,
  params: IParams,
): Promise<TMappedResponse200> => {
  const parameters: IGetOfferFromHistoryWebRequest = {
    cianId: params.cianId,
  };

  try {
    const res = (await fetchGetOfferFromHistoryWeb({
      httpApi: context.httpApi,
      parameters,
      mappers: offerFromHistoryMapper(parameters),
    })) as TMappedResponse;

    if (res instanceof Error) {
      throw res;
    }

    return res;
  } catch (error) {
    if (error instanceof GenericError) {
      context.logger.debug('get-offer-from-history-web BadRequest', { details: error.printDetails() });
    } else {
      context.logger.error(error, { message: 'Error in get-offer-from-history-web', ...parameters });
    }

    throw error;
  }
};

interface IParams {
  cianId: number;
}
