import {
  NEWBUILDING_TRUST_AND_SAFETY,
  NEWBUILDING_RENT_TRAP,
  NEWBUILDING_SUBURBAN_TRAP,
  NEWBUILDING_FLAT_SALE_TRAP,
} from 'shared/selectors/abExperiments';

export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';

export const EXPERIMENTS: string[] = [
  NEWBUILDING_TRUST_AND_SAFETY,
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
  NEWBUILDING_RENT_TRAP,
  NEWBUILDING_SUBURBAN_TRAP,
  NEWBUILDING_FLAT_SALE_TRAP,
];
