// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ICreateRealtyByCianIdRequest,
  TCreateRealtyByCianIdModel,
  IMappers,
  TCreateRealtyByCianIdResponse,
  ICreateRealtyByCianIdResponse,
  ICreateRealtyByCianIdResponseError,
} from './types';

export const defaultConfig: TCreateRealtyByCianIdModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'price-estimator',
  pathApi: '/v1/create-realty-by-cian-id/',
  requestType: 'json',
} as TCreateRealtyByCianIdModel;

export function createCreateRealtyByCianIdModel(parameters: ICreateRealtyByCianIdRequest): TCreateRealtyByCianIdModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateRealtyByCianIdOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICreateRealtyByCianIdRequest;
}

export async function fetchCreateRealtyByCianId<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICreateRealtyByCianIdOptions<TResponse200, TResponse400>): Promise<
  TCreateRealtyByCianIdResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createCreateRealtyByCianIdModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICreateRealtyByCianIdResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICreateRealtyByCianIdResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCreateRealtyByCianIdResponse;
}
