/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TChangePriceUnsubscribeModel,
  IChangePriceUnsubscribeRequest,
  IMappers,
  IChangePriceUnsubscribeResponse,
  IChangePriceUnsubscribeResponseError,
  TChangePriceUnsubscribeResponse,
} from './types';

const defaultConfig: TChangePriceUnsubscribeModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/change-price-unsubscribe/',
  hostType: 'api',
} as TChangePriceUnsubscribeModel;

function createChangePriceUnsubscribeModel(parameters: IChangePriceUnsubscribeRequest): TChangePriceUnsubscribeModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IChangePriceUnsubscribeOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IChangePriceUnsubscribeRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchChangePriceUnsubscribe<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IChangePriceUnsubscribeOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TChangePriceUnsubscribeResponse
> {
  const { response, statusCode } = await httpApi.fetch(createChangePriceUnsubscribeModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IChangePriceUnsubscribeResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IChangePriceUnsubscribeResponseError);
    }
  }

  return { response, statusCode } as TChangePriceUnsubscribeResponse;
}

export { defaultConfig, createChangePriceUnsubscribeModel, fetchChangePriceUnsubscribe };
