import { ISuburbanProjectSuggest } from 'shared/types/suburbanBuildersProjects';

import { ESuburbanBuildersProjectsActionTypes, ISetSuburbanBuildersProjects } from './types';

export function suburbanBuildersProjectsReducer(
  state: ISuburbanProjectSuggest[] = [],
  action: ISetSuburbanBuildersProjects,
): ISuburbanProjectSuggest[] {
  switch (action.type) {
    case ESuburbanBuildersProjectsActionTypes.SET_SUBURBAN_BUILDERS_PROJECTS_ACTION:
      return action.payload;
    default:
      return state;
  }
}
