/* istanbul ignore next */
import { IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ISoprUser } from './types';

export function getUser(user: IUserSchema | null, abGroup: number): ISoprUser {
  return {
    abGroup,
    cianUserId: user && user.cianUserId ? Number(user.cianUserId) : undefined,
    email: user && user.email ? user.email : undefined,
    type: user && user.ga && user.ga.type ? user.ga.type || 'not_authorized' : 'not_authorized',
    userId: user && user.realtyUserId ? Number(user.realtyUserId) : undefined,
  };
}
