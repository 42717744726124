import { pathOr } from 'ramda';

import { IPhoneSchema } from '../../repositories/offer-card/entities/offer/PhoneSchema';
import { IState } from '../../store';
import { IValidPhone } from '../../types/offerData';

export function getPhones(state: IState): IValidPhone[] {
  const phones = pathOr<IPhoneSchema[]>([], ['offerData', 'offer', 'phones'], state);
  const isPhonesAvailable = !!phones && !phones.some(phone => !phone.countryCode || !phone.number);

  return isPhonesAvailable ? (phones as IValidPhone[]) : [];
}
