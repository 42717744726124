import { selectUser } from './selectUser';
import { IState } from '../../../store';

export const selectFirstConfirmedPhone = (state: IState): string | null => {
  const user = selectUser(state);

  const phones = user?.phones;
  const candidate = phones && phones.find(phone => phone.confirmed && phone.phone);

  return candidate ? candidate.phone : null;
};
