import { IState } from 'shared/store';

export const selectDailyrentBookingCalendarOpen = (state: IState) => {
  return state.dailyrentBookingCalendar.open;
};
export const selectDailyrentBookingCalendarInitialLoading = (state: IState) => {
  return state.dailyrentBookingCalendar.initialLoading;
};
export const selectDailyrentBookingCalendarAvailabilityLoading = (state: IState) => {
  return state.dailyrentBookingCalendar.availabilityLoading;
};
export const selectDailyrentBookingCalendarError = (state: IState) => {
  return state.dailyrentBookingCalendar.error;
};
export const selectDailyrentBookingCalendarDaysAvailability = (state: IState) => {
  return state.dailyrentBookingCalendar.daysAvailability;
};
export const selectDailyrentBookingCalendarDates = (state: IState) => {
  return state.dailyrentBookingCalendar.dates;
};
export const selectShowDailyrentBookingCalendarInput = (state: IState) => {
  return state.dailyrentBookingCalendar.showBookingCalendar;
};
export const selectDailyrentBookingCalendarDefaultMessage = (state: IState) => {
  return state.dailyrentBookingCalendar.defaultBookingMessage;
};
export const selectDailyrentBookingCalendarCanBeBooked = (state: IState) => {
  return state.dailyrentBookingCalendar.canBeBooked;
};
export const selectDailyrentBookingCalendarDateInputText = (state: IState) => {
  return state.dailyrentBookingCalendar.dateInputText;
};
export const selectDailyrentBookingCalendarPrice = (state: IState) => {
  return state.dailyrentBookingCalendar.price;
};
export const selectDailyrentBookingCalendarGuestsCount = (state: IState) => {
  return state.dailyrentBookingCalendar.guestsCount;
};
export const selectDailyrentBookingPaymentSummary = (state: IState) => {
  return state.dailyrentBookingCalendar.paymentSummary;
};
