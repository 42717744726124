import { selectBrandingLevel } from './selectBrandingLevel';
import { selectOfferAgent } from './selectOfferAgent';
import { IAgentMetricSchema } from '../../../node/repositories/offer-card/entities/agent/AgentMetricSchema';
import { IState } from '../../store';

export const selectAgencyFeatures = (state: IState): IAgentMetricSchema[] => {
  const brandingLevel = selectBrandingLevel(state);
  const agent = selectOfferAgent(state);

  if (!agent || !brandingLevel) {
    return [];
  }

  if (agent.metrics && !!brandingLevel) {
    return agent.metrics;
  }

  return [];
};
