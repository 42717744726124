import { IUserPermissionSchema, IUserPhoneSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IThunkActionCreator } from '.';
import { getUser } from '../api/user';
import { IFastFiltersUserSchema } from '../repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { IOfferData } from '../types/offerData';

export enum EUserDataStatus {
  Initial,
  Loading,
  Succeed,
  Failed,
}

export interface IUserDataStatusChanged {
  type: 'IUserDataStatusChanged';
  status: EUserDataStatus;
  user?: IFastFiltersUserSchema & { companyName?: string };
}
/* istanbul ignore next */
export function updateUserData(): IThunkActionCreator<Promise<IFastFiltersUserSchema>> {
  return (dispatch, getState, context) => {
    const {
      pageData: { subdomain },
    } = getState();

    return getUser(context.httpApi, { subdomain })
      .then((user: IFastFiltersUserSchema) => {
        dispatch<IUserDataStatusChanged>({
          status: EUserDataStatus.Succeed,
          type: 'IUserDataStatusChanged',
          user,
        });

        return user;
      })
      .catch(error => {
        context.logger.error(error);

        dispatch<IUserDataStatusChanged>({
          status: EUserDataStatus.Failed,
          type: 'IUserDataStatusChanged',
        });

        throw error;
      });
  };
}

export type TUserDataActions = IUserDataStatusChanged;
/* istanbul ignore next */
export function userDataReducer(state: IOfferData, action: TUserDataActions): IOfferData {
  switch (action.type) {
    case 'IUserDataStatusChanged':
      if (action.status === EUserDataStatus.Succeed && action.user) {
        const nextUser = {
          cianName: action.user.isAuthenticated ? (action.user.displayName as string) : null,
          cianUserId: action.user.isAuthenticated ? (action.user.id as number) : null,
          companyName: action.user.isAuthenticated ? action.user.companyName || '' : '',
          criteoEmail: action.user.isAuthenticated ? (action.user.criteoEmail as string) : null,
          email: action.user.isAuthenticated ? (action.user.email as string) : null,
          firstName: action.user.isAuthenticated ? (action.user.firstName as string) : null,
          ga: null,
          isAgent: action.user.isAuthenticated ? (action.user.isAgent as boolean) : null,
          isAuthenticated: action.user.isAuthenticated as boolean,
          lastName: action.user.isAuthenticated ? (action.user.lastName as string) : null,
          permissions: action.user.isAuthenticated ? (action.user.permissions as IUserPermissionSchema) : null,
          phones: action.user.isAuthenticated ? (action.user.phones as IUserPhoneSchema[]) : null,
          realtyUserId: action.user.isAuthenticated ? (action.user.userId as number) : null,
        };

        return {
          ...state,
          user: nextUser,
        };
      }

      return state;

    default:
      return state;
  }
}
