/* Длина телефона с символами +/-/space */
const EMAIL_REGX =
  /^([0-9a-zA-Z](([+\-_.][0-9a-zA-Z]*)|(\.[0-9a-zA-Z]+))*)+@(([0-9a-zA-Zа-яА-Я][-\w]*[0-9a-zA-Zа-яА-Я]*\.)+[a-zA-Z0-9а-яА-Я]{2,17})$/;
const FAKE_EMAIL_REGX = /@social\.cian\.ru$/;

export function isEmail(email: string): boolean {
  return EMAIL_REGX.test(email);
}

export function isFakeEmail(email: string): boolean {
  return FAKE_EMAIL_REGX.test(email);
}
