import { EBrandingLevel } from 'shared/types/offerData';

import { selectBrandingLevel } from './selectBrandingLevel';
import { IState } from '../../store';

export const selectIsGoldOrPlatinumBrandingLevel = (state: IState): boolean => {
  const brandingLevel = selectBrandingLevel(state);

  return !!brandingLevel && [EBrandingLevel.SecondLevel, EBrandingLevel.ThirdLevel].includes(brandingLevel);
};
