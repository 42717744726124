import { equals } from 'ramda';

import { IOfferHistoryCategoryFilterItemSchema } from '../../../repositories/valuation-offer-history/entities/offer_history/OfferHistoryCategoryFilterItemSchema';
import { IOfferHistoryLocationFilterItemSchema } from '../../../repositories/valuation-offer-history/entities/offer_history/OfferHistoryLocationFilterItemSchema';
import {
  IOfferHistoryRoomCountItemSchema,
  ERoomsCount,
} from '../../../repositories/valuation-offer-history/entities/offer_history/OfferHistoryRoomCountItemSchema';
import {
  IOfferHistoryStatusCountItemSchema,
  EStatus,
} from '../../../repositories/valuation-offer-history/entities/offer_history/OfferHistoryStatusCountItemSchema';
import { ISuburbanOfferHistorySpoilerFiltersSchema } from '../../../repositories/valuation-offer-history/entities/suburban_offer_history/SuburbanOfferHistorySpoilerFiltersSchema';
import {
  TRoomsFilterOptions,
  TRoomsFilterOption,
  TStatusFilterOptions,
  TStatusFilterOption,
  FILTER_ALL_VALUE,
  mapOfferStatusEnums,
  TTypeFilterOptions,
  TCategoryFilterOptions,
  TCategoryFilterOption,
  ISuburbanOfferHistorySpoilerFilters,
  mapOfferCategoriesEnums,
  mapOfferSpoilerActiveCategoryEnums,
  mapOfferSpoilerCategoryEnums,
  ESpoilerCategoryRequest,
  ECategoryRequest,
  EDealType,
} from '../../../store/offerHistory';

/**
 * Надписи на кнопках фильтров, которые будет видеть юзер
 * */
const filtersLabels = {
  [ERoomsCount.Studio]: 'Студия',
  [ERoomsCount.OnlyRoom]: 'Комната',
  [ERoomsCount.One]: '1',
  [ERoomsCount.Two]: '2',
  [ERoomsCount.Three]: '3',
  [ERoomsCount.Four]: '4',
  [ERoomsCount.Five]: '5',
  [ERoomsCount.SixPlus]: '6+',
};

/**
 * маппер фильтров комнат
 * Если фильтров комнат меньше 2х - возвращает пустые фильтры (скрывает весь блок фильтров комнат)
 * Добавляет стандартный фильтр "Все"
 * */
type TPrepareRoomsFilters = (apiFilters: IOfferHistoryRoomCountItemSchema[]) => TRoomsFilterOptions | null;

const _prepareRoomsFilters: TPrepareRoomsFilters = apiFilters => {
  const filtersCount = apiFilters.length;

  if (filtersCount < 2) {
    return null;
  }

  const allFilter: TRoomsFilterOption = { value: FILTER_ALL_VALUE, label: 'Все' };

  const preparedApiFilters = apiFilters.map(filter => {
    return {
      value: filter.roomsCount,
      label: filtersLabels[filter.roomsCount],
    };
  });

  return [allFilter, ...preparedApiFilters];
};

/**
 * маппер фильтров статусов
 * Если фильтров статусов меньше 2х - возвращает пустые фильтры (скрывает весь блок фильтров статусов)
 * Добавляет стандартный фильтр "Все"
 * */
type TPrepareStatusFilters = (
  apiFilters: IOfferHistoryStatusCountItemSchema[],
  dealType: EDealType,
) => TStatusFilterOptions | null;

const _prepareStatusFilters: TPrepareStatusFilters = (apiFilters, dealType) => {
  const filtersCount = apiFilters.length;

  /**
   * Если фильтр статусов меньше 2х - скрываем весь блок фильтров статусов
   * */
  if (filtersCount < 2) {
    return null;
  }

  const allFilter: TStatusFilterOption = { value: FILTER_ALL_VALUE, label: 'Все' };

  const publishedTitle = dealType === EDealType.Sale ? 'В продаже' : 'Активно';
  const deactivatedTitle = dealType === EDealType.Sale ? 'Снято с продажи' : 'Снято';
  const filtersStatusLabels = {
    [EStatus.Published]: publishedTitle,
    [EStatus.Deactivated]: deactivatedTitle,
  };

  const preparedApiFilters = apiFilters.map(filter => {
    return {
      value: mapOfferStatusEnums[filter.status],
      label: filtersStatusLabels[filter.status],
    };
  });

  return [allFilter, ...preparedApiFilters];
};

type TPrepareLocationFilters = (
  apiFilters: IOfferHistoryLocationFilterItemSchema[] | null | undefined,
) => TTypeFilterOptions | null;

const _prepareLocationFilters: TPrepareLocationFilters = apiFilters => {
  /** Если опций меньше 2х - скрываем фильтр */
  if (!apiFilters || apiFilters.length < 2) {
    return null;
  }

  return apiFilters.map(filter => ({
    value: filter.value,
    label: filter.name,
  }));
};

type TPrepareCategoryFilters = (
  apiFilters: IOfferHistoryCategoryFilterItemSchema[],
) => TCategoryFilterOptions | undefined;

const _prepareCategoryFilters: TPrepareCategoryFilters = apiFilters => {
  /** Если опций меньше 2х - скрываем фильтр */
  if (!apiFilters || apiFilters.length < 2) {
    return;
  }

  const allFilter: TCategoryFilterOption = { value: FILTER_ALL_VALUE, label: 'Все' };

  const preparedApiFilters = apiFilters.map(filter => ({
    value: mapOfferCategoriesEnums[filter.value] as ECategoryRequest,
    label: filter.name,
  }));

  return [allFilter, ...preparedApiFilters];
};

type TPrepareSpoilerCategoryFilters = (
  apiFilters: ISuburbanOfferHistorySpoilerFiltersSchema | null | undefined,
) => ISuburbanOfferHistorySpoilerFilters | undefined;

const _prepareSpoilerCategoryFilters: TPrepareSpoilerCategoryFilters = apiFilters => {
  if (!apiFilters || apiFilters.categories.length < 2) {
    return;
  }

  return {
    activeCategory: mapOfferSpoilerActiveCategoryEnums[apiFilters.activeCategory] as ESpoilerCategoryRequest,
    categories: apiFilters.categories.map(filter => ({
      value: mapOfferSpoilerCategoryEnums[filter.value] as ESpoilerCategoryRequest,
      label: filter.name,
    })),
  };
};

/**
 * Надстройка над мапперами фильтров
 * Добавляет мемоизацию, кэширует входящие (apiFilters) и выходящие (preparedFilters) параметры
 * Это нужно для того, чтоб при повторных запросах к апи, фильтры не пересобирались в новый объект, структура которого
 * точно такая же, как и до этого ( проблема {a: 1} !== {a: 1} )
 *  * S - маппер фильтров
 *  * DT - dealType - Тип сделки (аренда/продажа)
 *  * AF - apiFilters, то что приходит из api
 *  * PF - preparedFilters - фильтры, которые будем хранить в сторе
 * */
const prepareFilters = <DT, AF, PF, S extends (apiFilters: AF, dealType?: DT) => PF>(
  filtersMapper: S,
): ((apiFilters: AF, dealType?: DT) => PF) => {
  let __apiFiltersCache: AF | null = null;
  let __preparedFiltersCache: PF | null = null;

  return (apiFilters, dealType) => {
    if (__preparedFiltersCache && __apiFiltersCache && equals(__apiFiltersCache, apiFilters)) {
      return __preparedFiltersCache;
    }

    const preparedFilters = filtersMapper(apiFilters, dealType);

    __apiFiltersCache = apiFilters;
    __preparedFiltersCache = preparedFilters;

    return preparedFilters;
  };
};

/**
 * Клиентские функции для подготовки фильтров комнат и статусов
 * */
export const prepareRoomsFilters = prepareFilters<
  undefined,
  IOfferHistoryRoomCountItemSchema[],
  TRoomsFilterOptions | null,
  TPrepareRoomsFilters
>(_prepareRoomsFilters);

export const prepareStatusFilters = prepareFilters<
  EDealType,
  IOfferHistoryStatusCountItemSchema[],
  TStatusFilterOptions | null,
  TPrepareStatusFilters
>(_prepareStatusFilters);

export const prepareLocationFilters = prepareFilters<
  undefined,
  IOfferHistoryLocationFilterItemSchema[] | null | undefined,
  TTypeFilterOptions | null,
  TPrepareLocationFilters
>(_prepareLocationFilters);

export const prepareCategoryFilters = prepareFilters<
  undefined,
  IOfferHistoryCategoryFilterItemSchema[] | null | undefined,
  TCategoryFilterOptions | undefined,
  TPrepareCategoryFilters
>(_prepareCategoryFilters);

export const prepareSpoilerCategoryFilters = prepareFilters<
  undefined,
  ISuburbanOfferHistorySpoilerFiltersSchema | null | undefined,
  ISuburbanOfferHistorySpoilerFilters | undefined,
  TPrepareSpoilerCategoryFilters
>(_prepareSpoilerCategoryFilters);
