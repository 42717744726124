import { ITypedEvent } from './event';

export enum EUserEventType {
  Authenticate = 'authenticate',
  Authenticated = 'authenticated',
  AuthenticationModalClosed = 'authentication_modal_closed',
}

export type TUserEvent =
  | ITypedEvent<EUserEventType.Authenticate>
  | ITypedEvent<EUserEventType.Authenticated>
  | ITypedEvent<EUserEventType.AuthenticationModalClosed>;
