import { IState } from '../../../store';
import { selectAgentLists } from '../selectAgentLists';

export const isShowAuctionDisclaimer = (state: IState) => {
  const agentLists = selectAgentLists(state);

  const { publishedUserId } = state.offerData.offer;
  const { auctionBanks } = state;

  const auction = !!publishedUserId && auctionBanks.includes(publishedUserId);

  return Boolean(auction || agentLists.includes('tradeRealty'));
};
