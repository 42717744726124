import {
  fetchGetNewRecommendedOffers,
  IGetNewRecommendedOffersRequest,
} from '../repositories/search-engine/v1/get-new-recommended-offers';
import {
  fetchGetOffersFromHistory,
  TGetOffersFromHistoryResponse,
} from '../repositories/search-engine/v1/get-offers-from-history';
import {
  IGetRecommendedOffersRequest,
  TGetRecommendedOffersResponse,
  fetchGetRecommendedOffers,
} from '../repositories/search-engine/v3/get-recommended-offers';
import {
  IGetSimilarOffersRequest,
  TGetSimilarOffersResponse,
  fetchGetSimilarOffers,
} from '../repositories/search-engine/v3/get-similar-offers';
import { IApplicationContext } from '../types/applicationContext';

export function getItemBasedSimilarOffers(
  { httpApi, config }: IApplicationContext,
  parameters: IGetSimilarOffersRequest,
): Promise<TGetSimilarOffersResponse> {
  return fetchGetSimilarOffers({
    httpApi,
    parameters,
    config: { ecnhancersConfig: { timeout: config.get<number>('getSimilarOffers.timeout') || 8000 } },
  });
}

export function getUserBasedSimilarOffers(
  { httpApi, config }: IApplicationContext,
  parameters: IGetRecommendedOffersRequest,
): Promise<TGetRecommendedOffersResponse> {
  return fetchGetRecommendedOffers({
    httpApi,
    parameters,
    config: { ecnhancersConfig: { timeout: config.get<number>('getRecommendedOffers.timeout') || 8000 } },
  });
}

export function getHistoryBasedSimilarOffers({
  httpApi,
  config,
}: IApplicationContext): Promise<TGetOffersFromHistoryResponse> {
  return fetchGetOffersFromHistory({
    httpApi,
    parameters: {},
    config: { ecnhancersConfig: { timeout: config.get<number>('getOffersFromHistory.timeout') || 8000 } },
  });
}

export function getNewItemBasedSimilarOffers(
  { httpApi, config }: IApplicationContext,
  parameters: IGetNewRecommendedOffersRequest,
): Promise<TGetOffersFromHistoryResponse> {
  return fetchGetNewRecommendedOffers({
    httpApi,
    parameters,
    config: { ecnhancersConfig: { timeout: config.get<number>('getNewRecommendedOffers.timeout') || 8000 } },
  });
}
