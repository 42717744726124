// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetHouseOfferHistoryDesktopRequest,
  TGetHouseOfferHistoryDesktopModel,
  IMappers,
  TGetHouseOfferHistoryDesktopResponse,
  IGetHouseOfferHistoryDesktopResponse,
  IGetHouseOfferHistoryDesktopResponseError,
} from './types';

export const defaultConfig: TGetHouseOfferHistoryDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'valuation-offer-history',
  pathApi: '/v4/get-house-offer-history-desktop/',
  requestType: 'json',
} as TGetHouseOfferHistoryDesktopModel;

export function createGetHouseOfferHistoryDesktopModel(
  parameters: IGetHouseOfferHistoryDesktopRequest,
): TGetHouseOfferHistoryDesktopModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetHouseOfferHistoryDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetHouseOfferHistoryDesktopRequest;
}

export async function fetchGetHouseOfferHistoryDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetHouseOfferHistoryDesktopOptions<TResponse200, TResponse400>): Promise<
  TGetHouseOfferHistoryDesktopResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetHouseOfferHistoryDesktopModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetHouseOfferHistoryDesktopResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetHouseOfferHistoryDesktopResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetHouseOfferHistoryDesktopResponse;
}
