import { setBuyAnalyticsModalVisibility, setBuyAnalyticsModalVisibilityAfterAuth } from './actions';
import { IThunkActionCreator } from '../../store';

export const openBuyAnalyticsModal = (): IThunkActionCreator => {
  return (dispatch): void => {
    dispatch(setBuyAnalyticsModalVisibility(true));
  };
};

export const openBuyAnalyticsModalAfterAuth = (): IThunkActionCreator => {
  return (dispatch): void => {
    dispatch(setBuyAnalyticsModalVisibilityAfterAuth());
  };
};
