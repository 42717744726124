import {
  ClearComparisonNotificationType,
  SetComparisonNotificationType,
  TComparisonNotificationActions,
} from '../../actions/comparison/comparisonNotification';
import { EComparisonNotificationCategory } from '../../types/comparisonNotification';

export interface IComparisonNotificationState {
  text: string;
  category: EComparisonNotificationCategory;
  offerId: number;
  url?: string | null;
}

export function comparisonNotificationReducer(
  state: IComparisonNotificationState | null = null,
  action: TComparisonNotificationActions,
): IComparisonNotificationState | null {
  switch (action.type) {
    case SetComparisonNotificationType:
      return {
        text: action.text,
        url: action.url,
        category: action.category,
        offerId: action.offerId,
      };
    case ClearComparisonNotificationType:
      return null;
    default:
      return state;
  }
}
