import { TURIScheme } from '@cian/http-api/shared/URI/IURI';

import { TDealType, TOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IMakeRequest } from '../../utils/make_request';
import { isResponseContainsKey } from '../../utils/response';
import { filterServerResponse } from '../helpers';
import { IServerError } from '../types';

export interface ISuccessResponse {
  status: 'ok';
}

export function sendComment(
  makeRequest: IMakeRequest,
  host: string,
  text: string,
  action: 'save' | 'delete',
  oid: number,
  dealType: TDealType,
  offerType: TOfferType,
  csrfToken: string,
): Promise<ISuccessResponse | IServerError> {
  return makeRequest({
    body: [`text=${encodeURIComponent(text)}`, `action=${action}`, `oid=${oid}`, `csrf_token=${csrfToken}`].join('&'),
    headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
    method: 'POST',
    uri: {
      host,
      path: `/ajax/${dealType}/${offerType}/notes/`,
      scheme: document.location.protocol.slice(0, -1) as TURIScheme,
    },
  })
    .then(filterServerResponse)
    .then(response => JSON.parse(response.responseBody))
    .then(response => {
      if (!isResponseContainsKey<ISuccessResponse, IServerError>(response, 'errors')) {
        return response;
      }

      throw response;
    });
}
